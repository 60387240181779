import clsx from 'clsx'
import { Drawer, Icon, OptionMetric2 } from 'components'
import ModalStatus, {
	statusExcerciseIcons as arrayStatus
} from 'components/modal/modalStatus'
import {
	AssignedExecutedMetricType,
	DropDownProps,
	Icons,
	SessionStatus
} from 'interfaces'
import React, { useEffect, useState } from 'react'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { DrawerSets } from './drawerSets'

export const DropDown: React.FC<DropDownProps> = ({
	exercise,
	lastData,
	setLastData,
	lastScroll,
	setMetricsExecuted2,
	status,
	handleChangeMetrics,
	assignedProgramSlug,
	refetchData,
	isCompletedExercise
}): JSX.Element => {
	const refExercise = React.useRef<HTMLDivElement | null>(null)
	const [open2, setOpen2] = useState(false)
	const [changeAssignedMetric, setChangeAssignedMetric] = useState('')
	const [lastExercise, setLastExercise] = useState('')
	const [openModal, setOpenModal] = useState(false)
	const [openSetsDrawer, setOpenSetsDrawer] = useState(false)
	const [statusExercise, setStatusExercise] = useState<{
		status: number
		slug: string
	}>({
		status: status ?? 0,
		slug: exercise.slug
	})
	const [isInjury, setIsInjury] = useState<boolean>(false)

	useEffect(() => {
		if (status !== SessionStatus.Normal) {
			setIsInjury(true)
		}
	}, [])

	useEffect(() => {
		setLastExercise(exercise.slug)
		setLastData({
			...lastData,
			exerciseSlug: lastExercise
		})
	}, [changeAssignedMetric])

	useEffect(() => {
		if (document)
			document.getElementById(lastScroll.exerciseSlug)?.scrollIntoView({
				behavior: 'smooth',
				block: 'center'
			})
	}, [lastScroll.sessionSlug])

	useEffect(() => {
		if (open2 === false) {
			if (setMetricsExecuted2) setMetricsExecuted2([])
		}
	}, [open2])

	const [stateValue, setStateValue] = useState({ slug: '', value: '---' })

	const [assignedMetricsExecuted, setAssignedMetricsExecuted] = useState<
		{ slug: string; value: string }[]
	>([])

	useEffect(() => {
		setAssignedMetricsExecuted(
			exercise.assigned_metrics.map(me => me.assigned_execute_metric)
		)
	}, [exercise.assigned_metrics])

	useEffect(() => {
		setAssignedMetricsExecuted(prev => [
			...prev.map(it => (it.slug === stateValue.slug ? stateValue : it))
		])
	}, [stateValue])

	useEffect(() => {
		let aux = [] as {
			slugAME: string
			body: AssignedExecutedMetricType
		}[]
		aux = exercise?.assigned_metrics.map((assMe, idx) => ({
			slugAME: !assMe.assigned_execute_metric.value
				? ''
				: assMe.assigned_execute_metric.slug,
			body: {
				assigned_metric: assMe.slug,
				value: assignedMetricsExecuted[idx]?.value
			}
		}))

		if (setMetricsExecuted2) setMetricsExecuted2(aux)

		return () => {
			if (setMetricsExecuted2) setMetricsExecuted2([])
		}
	}, [assignedMetricsExecuted])

	return (
		<div id={exercise.slug} ref={refExercise}>
			<div
				className={clsx(
					'flex  justify-between',
					'h-auto p-2 w-[96%] ml-[2%] my-1',
					'shadow sm:rounded-lg border-[1px]',
					'border-l-4 border-l-custom rounded-lg',
					'bg-white border-gray-300 hover:bg-gray-200',
					open2 ? 'sm:rounded-b-none mt-1 mb-0' : ''
				)}
				aria-hidden
				onClick={() => setOpen2(!open2)}
			>
				<div className="flex flex-col w-full justify-start pl-4">
					<div className={clsx('w-full flex justify-start gap-2')}>
						{isCompletedExercise && (
							<div className="flex justify-center items-center">
								<Icon
									src={Icons.check}
									fillCircle
									className="w-5 h-5 text-custom"
								/>
							</div>
						)}
						<p className="w-full font-Roboto font-bold text-2xl line-clamp-2">
							{exercise.name}
						</p>
					</div>
					{/* Sets btn and status */}
					<div className="flex w-full justify-between">
						{exercise.assigned_metrics.length > 0 ? (
							<div />
						) : (
							<button
								type="button"
								className="my-2 h-6 w-24 bg-custom rounded text-white font-semibold"
								onClick={() => setOpenSetsDrawer(true)}
							>
								Sets
							</button>
						)}
						<button
							onClick={() => setOpenModal(true)}
							className={clsx(
								'cursor-pointer my-auto',
								'px-2 hover:opacity-80',
								'flex flex-row mr-2'
							)}
							type="button"
						>
							<span className="underline flex flex-1">Status:</span>
							<div className="w-6">
								<Icon
									className="ml-2 mt-1"
									src={
										statusExercise.status === 0
											? arrayStatus.filter(
													item => item.id === exercise.status
											  )[0].icon
											: arrayStatus.filter(
													item => item.id === statusExercise.status
											  )[0].icon
									}
								/>
							</div>
						</button>
					</div>
				</div>

				{exercise.assigned_metrics.length > 0 && (
					<div className="flex flex-col justify-center items-center h-full">
						<ChevronRightIcon
							className={clsx(
								open2 ? '-rotate-90 transform' : 'rotate-90',
								'h-6 w-6 text-custom relative top-2'
							)}
							aria-hidden="true"
						/>
					</div>
				)}
				{/* SetsDrawer */}
				<Drawer
					open={openSetsDrawer}
					setOpen={(val: boolean) => setOpenSetsDrawer(val)}
					title={`${exercise.name} - Sets`}
					stretch
					mobileFull
				>
					<DrawerSets
						exercise={exercise}
						isInjury={isInjury}
						status={status}
						assignedProgramSlug={assignedProgramSlug}
						handleOpenModalStatus={() => setOpenModal(true)}
						statusExercise={statusExercise}
						refetchData={refetchData}
						setOpenSetsDrawer={val => setOpenSetsDrawer(val)}
					/>
				</Drawer>
			</div>

			{exercise.assigned_metrics.length > 0 && open2 && (
				<div
					className={clsx(
						'text-sm text-gray-500 bg-white',
						'border-[1px] border-t-0 border-gray-300 w-[96%] ml-[2%]',
						open2 ? 'mt-0' : 'mt-1'
					)}
				>
					<div
						className={clsx(
							'flex items-center justify-between',
							'w-full bg-gray-700 p-2 cursor-default'
						)}
					>
						<h1 className="text-white font-Roboto text-xl">
							Units of measurement
						</h1>
						<div className="flex w-[200px]">
							<div className="border-r-2 border-gray-300 px-1 h-6 self-center w-[5px]" />
							<div
								className={clsx('flex justify-center items-center w-[90px]')}
							>
								<Icon
									fillPath
									className={clsx('h-[15px] w-[15px] text-white')}
									src={Icons.pencil}
								/>
							</div>
							<div className="border-r-2 border-gray-300 px-1 h-6 self-center w-[5px]" />
							<div className="flex justify-center items-center w-[70px]">
								<Icon
									fillPath
									className={clsx('h-[15px] w[15px] text-white')}
									src={Icons.paperPen}
								/>
							</div>
						</div>
					</div>
					<div className="px-2">
						{exercise.assigned_metrics.map(metric => {
							return (
								<div
									key={metric.slug}
									className={clsx(
										'flex items-center justify-between w-full',
										'pt-2 pb-1 border-b-gray-300',
										'border-b-[1px] last:border-b-0 cursor-default'
									)}
								>
									<p
										className={clsx(
											'font-Roboto font-normal',
											'text-lg text-gray-700'
										)}
									>
										{metric.type_metric.name}
									</p>
									<div className="flex w-[200px]">
										<div
											className={clsx(
												'border-r-2 border-gray-300',
												'px-1 h-6 self-center w-[5px]'
											)}
										/>
										{/** value base */}
										<p
											className={clsx(
												'font-Roboto font-bold text-lg',
												'text-gray-700 w-full',
												'flex justify-center items-center'
											)}
										>
											{metric.value}
										</p>
										{/** and value base */}
										<div
											className={clsx(
												'border-r-2 border-gray-300',
												'px-1 h-6 self-center w-[5px]'
											)}
										/>
										{/** mode input */}
										<OptionMetric2
											status={exercise.status}
											key={metric.slug}
											item={metric}
											type={metric.type_metric.type}
											value={
												metric.assigned_execute_metric.value
													? metric.assigned_execute_metric.value
													: '--'
											}
											changeValue={handleChangeMetrics}
											setChangeAssignedMetric={setChangeAssignedMetric}
											setStateValue={setStateValue}
											statusExercise={statusExercise.status}
										/>

										{/** fin mode input */}
									</div>
								</div>
							)
						})}
					</div>
				</div>
			)}
			<ModalStatus
				open={openModal}
				setOpen={(val: boolean) => setOpenModal(val)}
				setValue={setStatusExercise}
				value={statusExercise}
				slug={exercise.slug}
				defaultValue={exercise.status}
				refetchData={refetchData}
			/>
		</div>
	)
}
