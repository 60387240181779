/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Button, Input } from 'components'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { AthleteByIdResponse, athleteType } from 'interfaces'
import { useCustomer, useToast } from 'hooks'
import Cookies from 'universal-cookie'
import { updateClient } from 'api'
import {
	QueryObserverResult,
	RefetchOptions,
	RefetchQueryFilters
} from 'react-query'
import Select, { SingleValue } from 'react-select'
import { ISport, sports } from './constant'

type InfoAthleteInputs = {
	height: string | null
	weight: string | null
	sport: string | null
	position: string | null
	school: string | null
}

interface Props {
	athlete: athleteType | undefined
	setUpdateInfoAthleteDrawer: React.Dispatch<React.SetStateAction<boolean>>
	refetch: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
	) => Promise<QueryObserverResult<AthleteByIdResponse | null, unknown>>
}

export const DrawerInfoGeneralAthlete: React.FC<Props> = ({
	athlete,
	setUpdateInfoAthleteDrawer,
	refetch
}): JSX.Element => {
	const { showToast } = useToast()
	const customer = useCustomer()
	const cookies = new Cookies()
	const token = cookies.get('token')

	const {
		register,
		control,
		handleSubmit,
		formState: { errors, isValid }
	} = useForm<InfoAthleteInputs>({
		mode: 'all',
		defaultValues: {
			height: athlete?.height,
			weight: athlete?.weight,
			sport: athlete?.sport,
			position: athlete?.position,
			school: athlete?.school
		}
	})

	const [athleteId, setAthleteId] = useState<number>()

	useEffect(() => {
		setAthleteId(athlete?.id)
	}, [athlete])

	const onSubmit: SubmitHandler<InfoAthleteInputs> = async data => {
		try {
			await updateClient(token, athleteId, customer.id, data)
			showToast(
				'Updated info Client',
				'Info client was updated successfully',
				'success'
			)
			refetch()
			setUpdateInfoAthleteDrawer(false)
		} catch (error) {
			console.error(error)
		}
	}

	const customStyles = {
		control: (base: any, state: any) => ({
			...base,
			background: '#FFF',
			borderColor: null,
			border: 0,
			borderRadius: 0,
			boxShadow: state.isFocused ? null : null,
			'&:hover': {
				// Overwrittes the different states of border
				borderColor: null
			}
		}),
		option: (base: any, state: any) => ({
			...base,
			background: state.isFocused ? process.env.REACT_APP_COLOR : '#FFF',
			color: state.isFocused ? '#FFF' : '#333',
			fontFamily: 'Roboto Condensed, sans-serif',
			fontWeight: 500,
			fontSize: 18,
			'&:hover': {
				borderColor: '#B6B6B6'
			},
			height: 46
		})
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="flex flex-col justify-start px-5 py-6 pb-[68px] h-[calc(100vh-145px)] overflow-hidden overflow-y-scroll">
				<div className="w-full flex flex-col gap-2">
					<span className="text-[24px] leading-[18px] text-[#454952] font-bold">
						Athlete
					</span>
					<span className="text-[18px] leading-[18px] text-[#454952] font-normal">
						Information
					</span>
				</div>
				<div className="w-full h-[2px] my-3 bg-black opacity-10" />
				<div className="w-full flex flex-col gap-2">
					<Input
						name="height"
						title="Height"
						type="number"
						min={0}
						register={register}
						// rules={rules.comments}
						placeholder="Height"
						error={errors.height}
						className="mb-3"
						squared
					/>
					<Input
						name="weight"
						title="Weight"
						type="number"
						min={0}
						register={register}
						// rules={rules.comments}
						placeholder="Weight"
						error={errors.weight}
						className="mb-3"
						squared
					/>
					<Controller
						control={control}
						render={({ field: { onChange, value, name } }) => {
							const currentSelection = sports.find(c => c.value === value)

							const handleSelectChange = (
								selectedOpt: SingleValue<ISport>
							): void => {
								onChange(selectedOpt?.value)
							}
							return (
								<div className="w-full flex flex-col">
									<div className="text-xs mb-1 font-Roboto font-bold md:text-lg">
										Sports
									</div>

									<Select
										className={clsx(
											'appearance-none block w-full py-1 bg-[transparent]',
											'placeholder-gray-400 font-Roboto',
											'border border-gray-300 shadow-sm'
										)}
										value={currentSelection}
										name={name}
										options={sports}
										styles={customStyles}
										onChange={handleSelectChange}
										defaultValue={
											athlete?.sport
												? ({
														label: athlete.sport,
														value: athlete.sport
												  } as unknown as ISport)
												: sports[0]
										}
										components={{
											IndicatorSeparator: () => null
										}}
									/>
								</div>
							)
						}}
						name="sport"
					/>
					<Input
						name="position"
						title="Position"
						register={register}
						// rules={rules.comments}
						placeholder="Position"
						error={errors.position}
						className="mb-3"
						squared
					/>
					<Input
						name="school"
						title="School"
						register={register}
						// rules={rules.comments}
						placeholder="School"
						error={errors.school}
						className="mb-3"
						squared
					/>
				</div>
			</div>
			<div
				className={clsx(
					'shadow-[0px_2px_5px_3px_rgba(0,0,0,0.2)] bg-white flex items-center justify-center',
					'min-h-[64px] max-h-[64px] absolute w-full bottom-0'
				)}
			>
				<div className="w-full px-6 py-3">
					<Button
						customColor
						size="full"
						type="submit"
						label="Update"
						disabled={!isValid}
					/>
				</div>
			</div>
		</form>
	)
}
