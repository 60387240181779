import { XIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { EmptyStateNotification, Loading, NotificationCard } from 'components'
import { Images } from 'interfaces'
import React, { useContext, useEffect, useState } from 'react'
import { NotificationContext } from 'context'
import { DrawerNotificationsProps } from './drawerNotifications.interface'

export const DrawerNotifications: React.FC<
	DrawerNotificationsProps
> = props => {
	const { handleClickCard } = props

	const TabsOptions = [
		{
			tabName: 'All',
			tabPath: 'ALL'
		},
		{
			tabName: 'Unread',
			tabPath: 'NOREAD'
		},
		{
			tabName: 'Read',
			tabPath: 'READ'
		}
	]
	const {
		notifications: data,
		ref,
		removeNotifications,
		isLoading,
		removeAllNotifications,
		updateNotificationStatus,
		setGetAllNotifications,
		setIsRead,
		isRead,
		getAllNotifications
	} = useContext(NotificationContext)

	const [currentTab, setCurrentTab] = useState('ALL')
	const [arrayOfNotifications, setArrayOfNotifications] = useState(data)

	const toggleTab = (currentTabSelected: string): void => {
		setCurrentTab(currentTabSelected)
	}

	useEffect(() => {
		setArrayOfNotifications(data)
	}, [data, isRead, getAllNotifications, currentTab])

	// toggle between tabs of notifications
	const handleNotificationsArray = (currentTabSelected: string): void => {
		// let arrayToShow

		if (currentTabSelected === 'NOREAD') {
			setIsRead(false)
			setGetAllNotifications(currentTabSelected)
		} else if (currentTabSelected === 'READ') {
			setIsRead(true)
			setGetAllNotifications(currentTabSelected)
		} else {
			setGetAllNotifications(currentTabSelected)
		}
	}

	return (
		<section className="relative">
			{isLoading && (
				<div className="absolute inset-0 flex justify-center items-center pt-[100%]">
					<Loading />
				</div>
			)}

			<section className="h-screen flex flex-col gap-4">
				<div className="w-full h-[48px] flex justify-center gap-6 px-8 mt-8">
					{TabsOptions.map(tab => (
						<button
							key={tab.tabPath}
							type="button"
							onClick={() => {
								toggleTab(tab.tabPath)
								handleNotificationsArray(tab.tabPath)
							}}
							className={`${
								currentTab === tab.tabPath &&
								'text-[#4AD669] border-b-2 border-b-[#4AD669]'
							}`}
						>
							{tab.tabName}
						</button>
					))}
				</div>

				{arrayOfNotifications.length > 1 && (
					<div className="w-full flex justify-end pr-10">
						<button
							type="button"
							className="text-[#4AD669]  border-b-2 border-b-[#4AD669] max-w-max"
							onClick={removeAllNotifications}
						>
							Clear all
						</button>
					</div>
				)}

				<div
					className={clsx(
						'h-full pb-32',
						arrayOfNotifications.length > 0
							? 'overflow-y-scroll'
							: 'overflow-y-hidden'
					)}
				>
					{arrayOfNotifications.length === 0 ? (
						<EmptyStateNotification />
					) : (
						arrayOfNotifications.map(item => (
							<div
								key={item.id}
								className={clsx(
									item.is_seen && 'bg-opacity-50',
									'bg-[#E8EEF9] w-[95%] mx-auto mt-2 p-[16px] flex items-start h-[118px] rounded-[20px]'
								)}
							>
								<NotificationCard
									itemId={item.id}
									title={item.subject as string}
									programName={item.name}
									coachName={item.trainer.name}
									createdAt={item.date_time}
									handleClick={() => {
										updateNotificationStatus(item.id)
										handleClickCard()
									}}
									image={item.customer.image ?? Images.dBat}
									initialDate={item.info.start_date}
									notificationType={item.notification_type}
								/>

								<button
									type="button"
									title="xbuttonicon"
									className="text-blue-300 hover:text-red-500"
									onClick={() => {
										removeNotifications(item.id)
									}}
								>
									<XIcon className="w-6 h-6 " />
								</button>
							</div>
						))
					)}
					<div className="flex items-center justify-center mt-2">
						<div ref={ref} />
					</div>
				</div>
			</section>
		</section>
	)
}
