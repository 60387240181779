/* eslint-disable @typescript-eslint/no-explicit-any */
export interface ISport {
	label: string
	value: string
}
export const sports: ISport[] = [
	{
		label: '---',
		value: 'default sport'
	},
	// #
	{
		label: '3x3 Basketball',
		value: '3x3 Basketball'
	},
	// A
	{
		label: 'Alpine Skiing',
		value: 'Alpine Skiing'
	},
	{
		label: 'Archery',
		value: 'Archery'
	},
	{
		label: 'Artistic Gymnastics',
		value: 'Artistic Gymnastics'
	},
	{
		label: 'Artistic Swimming',
		value: 'Artistic Swimming'
	},
	{
		label: 'Athletics',
		value: 'Athletics'
	},
	// B
	{
		label: 'Badminton',
		value: 'Badminton'
	},
	{
		label: 'Baseball Softball',
		value: 'Baseball Softball'
	},
	{
		label: 'Basketball',
		value: 'Basketball'
	},
	{
		label: 'Beach Handball',
		value: 'Beach Handball'
	},
	{
		label: 'Beach Volleyball',
		value: 'Beach Volleyball'
	},
	{
		label: 'Biathlon',
		value: 'Biathlon'
	},
	{
		label: 'Bobsleigh',
		value: 'Bobsleigh'
	},
	{
		label: 'Boxing',
		value: 'Boxing'
	},
	{
		label: 'Breaking',
		value: 'Breaking'
	},
	// C
	{
		label: 'Canoe Flatwater',
		value: 'Canoe Flatwater'
	},
	{
		label: 'Canoe Slalom',
		value: 'Canoe Slalom'
	},
	{
		label: 'Cross-Country Skiing',
		value: 'Cross-Country Skiing'
	},
	{
		label: 'Curling',
		value: 'Curling'
	},
	{
		label: 'Cycling BMX Freestyle',
		value: 'Cycling BMX Freestyle'
	},
	{
		label: 'Cycling BMX Racing',
		value: 'Cycling BMX Racing'
	},
	{
		label: 'Cycling Mountain Bike',
		value: 'Cycling Mountain Bike'
	},
	{
		label: 'Cycling Road',
		value: 'Cycling Road'
	},
	{
		label: 'Cycling Track',
		value: 'Cycling Track'
	},
	// D
	{
		label: 'Diving',
		value: 'Diving'
	},
	// E
	{
		label: 'Equestrian',
		value: 'Equestrian'
	},
	// F
	{
		label: 'Fencing',
		value: 'Fencing'
	},
	{
		label: 'Figure Skating',
		value: 'Figure Skating'
	},
	{
		label: 'Football',
		value: 'Football'
	},
	{
		label: 'Freestyle Skiing',
		value: 'Freestyle Skiing'
	},
	{
		label: 'Futsal',
		value: 'Futsal'
	},
	// G
	{
		label: 'Golf',
		value: 'Golf'
	},
	// H
	{
		label: 'Handball',
		value: 'Handball'
	},
	{
		label: 'Hockey',
		value: 'Hockey'
	},
	// I
	{
		label: 'Ice Hockey',
		value: 'Ice Hockey'
	},
	// J
	{
		label: 'Judo',
		value: 'Judo'
	},
	// K
	{
		label: 'Karate',
		value: 'Karate'
	},
	// L
	{
		label: 'Luge',
		value: 'Luge'
	},
	// M
	{
		label: 'Marathon Swimming',
		value: 'Marathon Swimming'
	},
	{
		label: 'Modern Pentathlon',
		value: 'Modern Pentathlon'
	},
	// N
	{
		label: 'Nordic Combined',
		value: 'Nordic Combined'
	},
	// R
	{
		label: 'Rhythmic Gymnastics',
		value: 'Rhythmic Gymnastics'
	},
	{
		label: 'Rowing',
		value: 'Rowing'
	},
	{
		label: 'Rugby Sevens',
		value: 'Rugby Sevens'
	},
	// S
	{
		label: 'Sailing',
		value: 'Sailing'
	},
	{
		label: 'Shooting',
		value: 'Shooting'
	},
	{
		label: 'Short Track Speed Skating',
		value: 'Short Track Speed Skating'
	},
	{
		label: 'Skateboarding',
		value: 'Skateboarding'
	},
	{
		label: 'Skeleton',
		value: 'Skeleton'
	},
	{
		label: 'Ski Jumping',
		value: 'Ski Jumping'
	},
	{
		label: 'Ski Mountaineering',
		value: 'Ski Mountaineering'
	},
	{
		label: 'Snowboard',
		value: 'Snowboard'
	},
	{
		label: 'Speed Skating',
		value: 'Speed Skating'
	},
	{
		label: 'Sport Climbing',
		value: 'Sport Climbing'
	},
	{
		label: 'Surfing',
		value: 'Surfing'
	},
	{
		label: 'Swimming',
		value: 'Swimming'
	},
	// T
	{
		label: 'Table Tennis',
		value: 'Table Tennis'
	},
	{
		label: 'Taekwondo',
		value: 'Taekwondo'
	},
	{
		label: 'Tennis',
		value: 'Tennis'
	},
	{
		label: 'Trampoline',
		value: 'Trampoline'
	},
	{
		label: 'Triathlon',
		value: 'Triathlon'
	},
	// V
	{
		label: 'Volleyball',
		value: 'Volleyball'
	},
	// W
	{
		label: 'Water Polo',
		value: 'Water Polo'
	},
	{
		label: 'Weightlifting',
		value: 'Weightlifting'
	},
	{
		label: 'Wrestling',
		value: 'Wrestling'
	}
]

export const customStyles = {
	container: (base: any) => ({
		...base,
		padding: 0
	}),
	control: (base: any, state: any) => ({
		...base,
		background: '#FFF',
		borderColor: null,
		border: 0,
		borderRadius: 0,
		maxHeight: 70,
		boxShadow: state.isFocused ? null : null,
		'&:hover': {
			// Overwrittes the different states of border
			borderColor: null
		}
	}),
	multiValue: (base: any) => ({
		...base,
		background: '#00000000',
		borderRadius: 4,
		borderWidth: 1,
		borderColor: process.env.REACT_APP_COLOR
	}),
	option: (base: any, state: any) => ({
		...base,
		background: state.isFocused ? process.env.REACT_APP_COLOR : '#FFF',
		color: state.isFocused ? '#FFF' : '#333',
		fontFamily: 'Roboto Condensed, sans-serif',
		fontWeight: 500,
		fontSize: 18,
		'&:hover': {
			borderColor: '#B6B6B6'
		},
		height: 48
	}),
	indicadorsContainer: (base: any) => ({
		...base,
		maxHeight: 70
	}),
	valueContainer: (base: any) => ({
		...base,
		maxHeight: 70,
		overflow: 'hidden',
		overflowY: 'scroll'
	})
}
