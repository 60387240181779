import { BaseURL } from 'api/environment'
import axios from 'axios'

export const deleteLibraryExercise = async (
	token: string,
	slug: string
): Promise<number | null> => {
	let res = null as number | null
	await axios
		.delete(`${BaseURL}/library_exercises/${slug}/`, {
			headers: {
				Authorization: `Token ${token}`
			}
		})
		.then(
			async response => {
				res = response.status
			},
			error => {
				res = error.status
				console.error(error)
			}
		)
	return res
}
