import clsx from 'clsx'
// import { useDevice } from 'hooks'
import { Images, LayoutProps } from 'interfaces'
import React from 'react'

export const SimpleLayout: React.FC<LayoutProps> = ({
	children
}): JSX.Element => {
	// const device = useDevice()

	return (
		<div className="w-screen h-screen flex">
			<div
				className={clsx(
					'flex flex-col gap-8 px-4 w-full h-full pt-10',
					'md:px-10 md:min-w-[40vw] 2xl:px-36',
					'items-center justify-center'
				)}
			>
				<div className="flex items-center justify-center">
					<img
						src={
							process.env.REACT_APP_LOGIN_LOGO !== 'false'
								? process.env.REACT_APP_LOGIN_LOGO
								: Images.logo
						}
						alt="logo"
						className="w-52 md:w-60"
					/>
				</div>
				<div className="mx-auto w-full h-fit">{children}</div>
			</div>
			<div className="hidden relative lg:block h-full md:min-w-[60vw]">
				<img
					className="absolute inset-0 h-full w-full object-fill"
					src={process.env.REACT_APP_LOGIN_IMAGE || Images.upperhand_login}
					alt="banner"
				/>
			</div>
		</div>
	)
}
