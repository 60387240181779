import React, { useEffect, useState } from 'react'
import { Input, InputList, PickerTime } from 'components'
import { ListElemetProps, OptionsProps } from 'interfaces'
import { useForm } from 'react-hook-form'

export const OptionMetric: React.FC<OptionsProps> = ({
	item,
	type,
	value,
	changeValue
}): JSX.Element => {
	const [selectVal, setSelectVal] = useState<ListElemetProps>({
		id: '',
		name: '',
		value: ''
	})
	const [timeVal, setTimeVal] = useState(value || '')

	const { register, watch } = useForm({
		mode: 'onChange',
		defaultValues: {
			value
		}
	})
	const watchName = watch('value')

	let option = [] as ListElemetProps[]
	if (item.type_metric.options) {
		option = item.type_metric.options.map((optionItem, index) => {
			return {
				id: `${index}`,
				name: optionItem,
				value: optionItem
			}
		})
	}

	useEffect(() => {
		if (type === 'select')
			if (selectVal.id !== '') {
				changeValue(item.slug, selectVal.value)
			}
	}, [selectVal])

	useEffect(() => {
		if (type === 'time')
			if (timeVal !== '') {
				changeValue(item.slug, timeVal)
			}
	}, [timeVal])

	useEffect(() => {
		if (type === 'number') {
			changeValue(item.slug, watchName)
		}
	}, [watchName])

	return (
		<div key={item.slug} className="pt-2">
			<div className="font-Roboto my-2">{item.type_metric.name}</div>
			{type === 'select' ? (
				<InputList
					list={option}
					name="option"
					defaultValue={
						option
							.filter(itemList => itemList.value === value)
							.map(optionItem => ({
								id: optionItem.id,
								name: optionItem.name,
								value
							}))[0]
					}
					squared
					setItem={setSelectVal}
				/>
			) : type === 'time' ? (
				<PickerTime
					defaultValue={value}
					// title="Base value"
					asterisk
					setItem={setTimeVal}
					icon
				/>
			) : (
				<Input squared name="value" type="number" register={register} min={0} />
			)}
		</div>
	)
}
