import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import {
	ChevronDownIcon,
	ChevronLeftIcon,
	BellIcon
} from '@heroicons/react/outline'
import clsx from 'clsx'
import { Icons, Images, LayoutProps } from 'interfaces'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useCustomer, useDevice, useToast, useUser } from 'hooks'
import { Loading } from 'components/loading'
import { Icon } from 'components/icon'
import { updateProfileImage } from 'api'
import Cookies from 'universal-cookie'
import { Drawer } from 'components/drawer'
import { DrawerNotifications } from 'components/drawerContens/drawerNotifications/drawerNotifications'
import { NotificationContext } from 'context'

export const Layout: React.FC<LayoutProps> = ({
	children,
	title,
	isLoading = false,
	stretch = false,
	back = false,
	tabs = false
}): JSX.Element => {
	const { signOut, user, isLoading: loadingUser, refetch } = useUser()
	const Location = useLocation()
	const navigate = useNavigate()
	const urlPath = `/${Location.pathname.split('/')[1]}`
	const device = useDevice()
	const { showToast } = useToast()
	const customer = useCustomer()

	const cookies = new Cookies()
	const token = cookies.get('token')

	const [profileImage, setProfileImage] = useState<string | undefined>()
	const [showDrawerNotification, setShowDrawerNotification] =
		useState<boolean>(false)

	const { notifications } = useContext(NotificationContext)

	const navigation = [
		{
			name: 'Home',
			href: '/dashboard',
			icon: Icons.home,
			current: urlPath === '/dashboard'
		},
		{
			name: 'Programs',
			href: '/programs',
			icon: Icons.programs,
			current: urlPath === '/programs' || urlPath.search('/program') > -1
		},
		{
			name: 'Athletes',
			href: '/athletes',
			icon: Icons.athetles,
			current:
				urlPath === '/athletes' ||
				urlPath.search('/athlete') > -1 ||
				urlPath.search('/assign-program-athlete') > -1
		},
		{
			name: 'In training',
			href: '/training',
			icon: Icons.training,
			current: urlPath === '/training' || urlPath.search('/training') > -1
		},
		{
			name: 'Schedule',
			href: '/schedule',
			icon: Icons.schedule,
			current: urlPath === '/schedule' || urlPath.search('/schedule') > -1
		}
	]

	const navigationClient = [
		{
			name: 'Home',
			href: '/dashboard',
			icon: Icons.home,
			current: urlPath === '/dashboard'
		},
		{
			name: 'Athletes',
			href: '/athletes',
			icon: Icons.athetles,
			current:
				urlPath === '/athletes' ||
				urlPath.search('/athlete') > -1 ||
				urlPath.search('/assign-program-athlete') > -1
		},
		{
			name: 'In training',
			href: '/training',
			icon: Icons.training,
			current: urlPath === '/training' || urlPath.search('/training') > -1
		}
	]

	const editProfile = async (): Promise<void> => {
		if (profileImage) {
			let res
			try {
				res = await updateProfileImage(token, profileImage)
				if (res)
					showToast('Edit photo', 'photo was edited successfully', 'success')
				refetch()
			} catch (error) {
				console.error(error)
			}
		}
	}
	useEffect(() => {
		editProfile()
	}, [profileImage])

	const handleMediaUpload = (file: File): void => {
		const reader = new FileReader()
		reader.onload = e => {
			const uploadedObj = { file, url: e.target?.result as string }

			setProfileImage(uploadedObj.url)
		}
		if (file) reader.readAsDataURL(file) // convert to base64 string
	}

	const hasNotification = notifications.some(
		notification => notification.is_seen === false
	)

	return (
		<div className="relative w-screen min-h-screen max-h-screen flex flex-col justify-start items-center overflow-hidden">
			<header className="w-full h-[68px] flex bg-gradient-to-r from-gray-700 to-gray-800 shadow">
				<div className="w-full px-4 flex justify-between">
					<div className="flex-1 flex items-center justify-start">
						{back ? (
							<button
								type="button"
								onClick={() => navigate(-1)}
								className={clsx(
									'text-white cursor-pointer font-Roboto font-bold flex items-center',
									'hover:underline'
								)}
							>
								<ChevronLeftIcon
									className="h-4 w-4 text-white mr-1"
									aria-hidden="true"
								/>
								Back
							</button>
						) : (
							<Link to="/dashboard">
								<div className="max-h-[36px] max-w-[104px] md:max-h-[46px] md:max-w-[114px]">
									{process.env.REACT_APP_LOGIN_WHITE !== 'false' ? (
										<img src={process.env.REACT_APP_LOGIN_WHITE} alt="logo" />
									) : (
										<Icon
											fillPath
											className={clsx(
												'mr-2 h-[36px] w-[104px] text-white',
												'md:h-[46px] md:w-[114px]'
											)}
											src={Icons.logo}
										/>
									)}
								</div>
							</Link>
						)}
					</div>

					<div data-testid="notificationIcon" className="flex gap-1 -mr-6">
						{customer.role === 'Client' && (
							<div className="flex items-center relative">
								<button
									title="notification"
									type="button"
									onClick={() => {
										setShowDrawerNotification(true)
									}}
								>
									<BellIcon
										className={clsx('h-4 w-4 text-white mr-2 fill-white')}
									/>
								</button>
								{hasNotification && (
									<div
										data-testid="redDot"
										className="bg-[red] absolute right-2 top-[35%] h-2 w-2 rounded-full"
									/>
								)}
							</div>
						)}

						<button
							title="setting"
							type="button"
							onClick={() => navigate('/settings')}
							className="lg:ml-4"
						>
							<Icon className="w-5 h-5" src={Icons.settings_icon} />
						</button>
					</div>

					<Drawer
						open={showDrawerNotification}
						setOpen={setShowDrawerNotification}
						title="NOTIFICATIONS"
						stretch
						mobileFull
					>
						<DrawerNotifications
							handleClickCard={() => {
								setShowDrawerNotification(false)
							}}
						/>
					</Drawer>

					<div className="ml-4 flex items-center md:ml-6 z-10">
						{/* Profile dropdown */}
						<Menu as="div" className="ml-3 relative">
							<div>
								<Menu.Button
									className={clsx(
										'max-w-xs  flex items-center text-sm rounded-full',
										'focus:outline-none focus:ring-0 focus:ring-offset-0'
									)}
								>
									<span className="sr-only">Open user menu</span>

									<img
										className="h-8 w-8 rounded-full mr-2"
										src={user?.image || Images.default_avatar}
										alt=""
									/>
									<div>
										<h3 className="font-Roboto font-semibold text-sm text-left text-white">
											Welcome
										</h3>
										{user?.first_name && user.last_name && (
											<h3
												className={clsx(
													'font-Roboto font-bold text-left text-white text-[18px]',
													'md:text-[22px]'
												)}
											>
												{`${user?.first_name} ${user?.last_name}`}
											</h3>
										)}
									</div>
									<div>
										<ChevronDownIcon
											className="h-4 w-4 text-white"
											aria-hidden="true"
										/>
									</div>
								</Menu.Button>
							</div>
							<Transition
								as={Fragment}
								enter="transition ease-out duration-100"
								enterFrom="transform opacity-0 scale-95"
								enterTo="transform opacity-100 scale-100"
								leave="transition ease-in duration-75"
								leaveFrom="transform opacity-100 scale-100"
								leaveTo="transform opacity-0 scale-95"
							>
								<Menu.Items
									className={clsx(
										'origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white',
										'focus:outline-none focus:ring-0 focus:ring-offset-0'
									)}
								>
									<Menu.Item>
										{({ active }) => (
											<>
												<label
													htmlFor="upload-media"
													className={clsx(
														active ? 'text-custom' : 'text-gray-700',
														'block px-4 py-2 text-sm cursor-pointer',
														'hover:bg-gray-100 hover:text-custom'
													)}
												>
													Change image
												</label>
												<input
													type="file"
													id="upload-media"
													name="upload-image"
													accept="image/*"
													onChange={event =>
														event.target.files
															? handleMediaUpload(event.target.files[0])
															: null
													}
													className="hidden"
												/>
											</>
										)}
									</Menu.Item>
									<Menu.Item>
										{({ active }) => (
											<Link to="/">
												<button
													type="button"
													className={clsx(
														active ? 'text-custom' : 'text-gray-700',
														'w-full block px-4 py-2 text-sm text-left cursor-pointer',
														'hover:bg-gray-100'
													)}
													onClick={() => cookies.remove('customer_color')}
												>
													Customer
												</button>
											</Link>
										)}
									</Menu.Item>
									<Menu.Item>
										{({ active }) => (
											<button
												type="button"
												onClick={() => signOut()}
												className={clsx(
													active ? 'text-custom' : 'text-gray-700',
													'w-full block px-4 py-2 text-sm text-left cursor-pointer',
													'hover:bg-gray-100'
												)}
											>
												Sign out
											</button>
										)}
									</Menu.Item>
								</Menu.Items>
							</Transition>
						</Menu>
					</div>
				</div>
			</header>
			<main
				className={clsx(
					'w-full flex',
					'min-h-[calc(100vh-138px)] max-h-[calc(100vh-138px)]',
					'lg:min-h-[calc(100vh-68px)] lg:max-h-[calc(100vh-68px)]'
				)}
			>
				{loadingUser ? (
					<Loading />
				) : (
					<>
						<aside
							className={clsx(
								'hidden min-w-[192px] w-[192px] lg:flex lg:flex-col lg:w-48 xl:w-56 2xl:w-64',
								'shadow-[0px_2px_5px_3px_rgba(0,0,0,0.2)] z-10'
							)}
						>
							{/* Sidebar component, swap this element with another sidebar if you like */}
							<nav className="h-full">
								{customer.role !== 'Client' &&
									navigation.map(item => (
										<Link to={item.href} replace key={`${item.name}-nav`}>
											<div
												className={clsx(
													item.current
														? 'text-gray-800 border-r-2 border-r-custom'
														: 'text-gray-font ',
													'group flex items-center px-4 py-7 font-Roboto text-2xl font-normal',
													'hover:bg-gray-200'
												)}
											>
												<div className="max-h-[30px] max-w-[30px] mr-6">
													<Icon
														fillPath
														className={clsx(
															item.current ? 'text-custom' : 'text-gray-font ',
															'mr-2 min-h-[30px] min-w-[30px] '
														)}
														src={item.icon}
													/>
												</div>
												{item.name}
											</div>
										</Link>
									))}
								{customer.role === 'Client' &&
									navigationClient.map(item => (
										<Link to={item.href} replace key={`${item.name}-nav`}>
											<div
												className={clsx(
													item.current
														? 'text-gray-800 border-r-2 border-r-custom'
														: 'text-gray-font ',
													'group flex items-center px-4 py-7 font-Roboto text-2xl font-normal',
													'hover:bg-gray-200'
												)}
											>
												<div className="max-h-[30px] max-w-[30px] mr-6">
													<Icon
														fillPath
														className={clsx(
															item.current ? 'text-custom' : 'text-gray-font ',
															'mr-2 min-h-[30px] min-w-[30px] '
														)}
														src={item.icon}
													/>
												</div>
												{item.name}
											</div>
										</Link>
									))}
							</nav>
						</aside>
						<section
							className={clsx(
								{ 'bg-[#eeeff2]': device === 'web' },
								stretch ? '' : 'py-4 px-6 md:p-12',
								tabs ? 'overflow-y-hidden' : 'overflow-y-scroll',
								'w-full overflow-hidden hide-scroll-bar',
								'min-h-[calc(100vh-138px)] max-h-[calc(100vh-138px)]',
								'lg:min-h-[calc(100vh-68px)] lg:max-h-[calc(100vh-68px)]'
							)}
						>
							{isLoading ? (
								<Loading />
							) : (
								<>
									{title && (
										<div className="max-w-7xl px-4 sm:px-6 md:px-8">
											<h1 className="text-2xl font-semibold text-gray-900 mb-3">
												{title}
											</h1>
										</div>
									)}
									{children}
								</>
							)}
						</section>
					</>
				)}
			</main>
			<footer
				className={clsx(
					'w-full h-[70px] fixed bottom-0 z-10 flex shadow-[0px_2px_5px_3px_rgba(0,0,0,0.2)] bg-white',
					'lg:hidden'
				)}
			>
				<ul className="w-full grid gap-5 md:gap-11 grid-flow-col justify-center items-center">
					{customer.role !== 'Client' &&
						navigation.map(item => (
							<Link to={item.href} replace key={`${item.name}-nav-mobil`}>
								<li
									className={clsx(
										'cursor-pointer flex flex-col items-center justify-center py-3 font-Roboto text-sm font-normal',
										item.current
											? 'text-gray-800 border-t-2 border-t-custom'
											: 'text-gray-font '
									)}
								>
									<div className="max-h-[19px] max-w-[19px] mb-1 flex items-center justify-center">
										<Icon
											fillPath
											className={clsx(
												item.current ? 'text-custom' : 'text-gray-font ',
												'min-h-[19px] min-w-[19px] '
											)}
											src={item.icon}
										/>
									</div>
									{item.name}
								</li>
							</Link>
						))}
					{customer.role === 'Client' &&
						navigationClient.map(item => (
							<Link to={item.href} replace key={`${item.name}-nav-mobil`}>
								<li
									className={clsx(
										'cursor-pointer flex flex-col items-center justify-center py-3 font-Roboto text-sm font-normal',
										item.current
											? 'text-gray-800 border-t-2 border-t-custom'
											: 'text-gray-font '
									)}
								>
									<div className="max-h-[19px] max-w-[19px] mb-1 flex items-center justify-center">
										<Icon
											fillPath
											className={clsx(
												item.current ? 'text-custom' : 'text-gray-font ',
												'min-h-[19px] min-w-[19px] '
											)}
											src={item.icon}
										/>
									</div>
									{item.name}
								</li>
							</Link>
						))}
				</ul>
			</footer>
		</div>
	)
}
