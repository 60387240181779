import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Button, Modal, Notes, ProgressBar, Skeleton } from 'components'
import {
	AssignedProgramProps,
	AssignedProgramResponse,
	Icons,
	Images
} from 'interfaces'
import {
	ArchiveIcon,
	PencilIcon,
	TrashIcon,
	PaperClipIcon,
	PlayIcon,
	DownloadIcon
	// ChevronDoubleRightIcon
} from '@heroicons/react/outline'
import clsx from 'clsx'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import {
	GET_LIST_ATHLETES_ASSIGNED_PROGRAM,
	getAssignedProgramBySlug,
	getListAthletesAssignedProgram,
	deleteAssignedProgram,
	editAssignedProgram,
	getAssignedProgramReport
} from 'api'
import Cookies from 'universal-cookie'
import { Dialog, Transition } from '@headlessui/react'
import { useClickOutside, useToast } from 'hooks'

export const AssignedProgramItem: React.FC<AssignedProgramProps> = ({
	name,
	slug,
	athleteId,
	refetch,
	checkedProgram,
	handleCheckProgram,
	setProgramName,
	setOpenSessionMobile
}): JSX.Element => {
	const ref = useRef(null)
	const { showToast } = useToast()
	const [assignedProgram, setAssignedProgram] = useState<
		AssignedProgramResponse | null | undefined
	>(undefined)

	const [stats, setStats] = useState<number | undefined>(0)
	const [statsExecute, setStatsExecute] = useState<number | undefined>(0)

	const [modalNotes, setModalNotes] = useState(false)
	const [session, setSession] = useState('')
	const [idSession, setIdSession] = useState(0)
	const [openOptions, setOpenOptions] = useState(false)
	const [listSession, setListSession] = useState(false)
	const [showModal, setShowModal] = useState(false)

	const [downloaded, setDownloaded] = useState(false)

	const cookies = new Cookies()
	const token = cookies.get('token')

	useClickOutside(ref, () => setOpenOptions(false))

	const { data, isLoading } = useQuery(
		[slug, token],
		() => getAssignedProgramBySlug({ assignProgramSlug: slug, stats: true }),
		{
			refetchOnMount: 'always'
		}
	)
	const navigate = useNavigate()

	const { data: listAthletes } = useQuery(
		[GET_LIST_ATHLETES_ASSIGNED_PROGRAM, slug, athleteId, token],
		() =>
			getListAthletesAssignedProgram({
				athleteIds: `${athleteId}`,
				programSlugs: slug
			}),
		{
			refetchOnMount: 'always'
		}
	)

	useEffect(() => {
		setAssignedProgram(data)
	}, [data])

	const handleClickEdit = (): void => {
		if (listAthletes && athleteId)
			navigate(
				`/assign-program-athlete/${listAthletes[0].assigned_program.program}/${athleteId}/${slug}`
			)
	}

	useEffect(() => {
		setStats(assignedProgram?.assigned_program_stats?.sessions)
		setStatsExecute(assignedProgram?.assigned_program_stats?.sessions_execute)
	}, [
		assignedProgram?.assigned_program_stats?.sessions,
		assignedProgram?.assigned_program_stats?.sessions_execute
	])

	const handleDeleteAssignedProgram = async (): Promise<void> => {
		try {
			const res = await deleteAssignedProgram({ slug })
			if (res && res === 204) {
				showToast(
					'Delete assigned program',
					'Assigned program was deleted successfully',
					'warning'
				)
				refetch()
			}
		} catch (error) {
			console.error(error)
		}

		setShowModal(false)
	}

	const handleArchiveAssignedProgram = async (): Promise<void> => {
		const assignedProgramArchived = await editAssignedProgram({
			slug,
			archived: true
		})

		if (assignedProgramArchived) {
			showToast(
				'Assigned program archive',
				'Assingned program was archived successfully',
				'success'
			)
			refetch()
			setOpenOptions(false)
		}
	}

	const handleClickView = (): void => {
		setOpenOptions(false)
		handleCheckProgram(slug)
		setProgramName(name)
		if (setOpenSessionMobile) setOpenSessionMobile(true)
	}

	const handleExportReport = async (): Promise<void> => {
		setDownloaded(true)
		await getAssignedProgramReport(token, assignedProgram?.id || 0, name)
		setDownloaded(false)
	}

	return (
		<div>
			{isLoading ? (
				<Skeleton />
			) : (
				<div
					className={clsx(
						'flex flex-col mt-2 border-2 shadow-[0px_4px_20px_rgba(0,0,0,0.16)] h-[100px]',
						'relative rounded-lg'
					)}
				>
					{/* <ChevronDoubleRightIcon className="w-5 h-5 absolute top-1/2 -right-5" /> */}
					<img
						src={assignedProgram?.image || Images.program_cover}
						alt="cover"
						className={clsx(
							'object-cover w-full h-[100px] rounded-lg border-custom',
							slug === checkedProgram ? 'border-4' : 'border'
						)}
					/>
					<div className="absolute inset-0 min-h-[100px] p-2 flex flex-col justify-between">
						<div className="w-full flex justify-between items-center gap-2">
							<p className="w-full font-Roboto font-bold text-2xl text-white line-clamp-1">
								{name}
							</p>
							<div className="w-[65px] relative flex justify-end">
								<Button
									type="button"
									className={clsx('')}
									label=""
									color="Tranparent"
									rounded
									size="extra-small"
									iconLeft={Icons.burguer}
									onClick={() => setOpenOptions(!openOptions)}
								/>
								{openOptions && (
									<div
										ref={ref}
										className={clsx(
											'absolute right-0 mt-2 min-w-[180px] max-w-auto',
											'origin-top-right rounded-md bg-white shadow-lg z-10',
											'border border-gray-200 shadow-xl'
										)}
									>
										<div>
											<button
												type="button"
												className={clsx(
													'font-Roboto font-normal text-sm',
													'group flex gap-2 w-full items-center p-2',
													'hover:text-white hover:bg-custom'
												)}
												onClick={() => handleClickView()}
											>
												<PlayIcon className="w-5 h-5" />
												View
											</button>
										</div>
										<div className="w-full h-[1px] bg-gray-300" />
										<div>
											<div>
												<button
													type="button"
													className={clsx(
														'font-Roboto font-normal text-sm',
														'group flex gap-2 w-full items-center rounded-t-md p-2',
														'hover:text-white hover:bg-custom',
														listSession
															? 'text-white bg-custom border-b border-b-white'
															: ''
													)}
													onClick={() => {
														setListSession(!listSession)
													}}
												>
													<PaperClipIcon className="w-5 h-5" />
													Notes
												</button>
												{listSession && (
													<div className="w-full">
														{assignedProgram?.assigned_program_sessions.map(
															item => (
																<div
																	key={item.program_session.slug}
																	className={clsx(
																		'w-full p-2 flex items-center',
																		'hover:text-white hover:bg-custom',
																		'cursor-pointer'
																	)}
																	aria-hidden="true"
																	onClick={() => {
																		setSession(item.program_session.name)
																		setIdSession(item.id)
																		setModalNotes(true)
																		setOpenOptions(false)
																		setListSession(false)
																	}}
																>
																	{item.program_session.name}
																</div>
															)
														)}
													</div>
												)}
											</div>
										</div>
										<div className="w-full h-[1px] bg-gray-300" />
										<div>
											<button
												type="button"
												className={clsx(
													'font-Roboto font-normal text-sm',
													'group flex gap-2 w-full items-center p-2',
													'hover:text-white hover:bg-custom'
												)}
												onClick={() => handleArchiveAssignedProgram()}
											>
												<ArchiveIcon className="w-5 h-5" />
												Archive
											</button>
										</div>
										<div className="w-full h-[1px] bg-gray-300" />
										<div>
											<button
												type="button"
												className={clsx(
													'font-Roboto font-normal text-sm',
													'group flex gap-2 w-full items-center p-2',
													'hover:text-white hover:bg-custom'
												)}
												onClick={() => handleClickEdit()}
											>
												<PencilIcon className="w-5 h-5" />
												Edit
											</button>
										</div>
										<div className="w-full h-[1px] bg-gray-300" />
										<div>
											<button
												type="button"
												className={clsx(
													'font-Roboto font-normal text-sm',
													'group flex gap-2 w-full items-center rounded-b-md p-2',
													'hover:text-white hover:bg-custom'
												)}
												onClick={() => setShowModal(true)}
											>
												<TrashIcon className="w-5 h-5" />
												Delete
											</button>
										</div>
									</div>
								)}
							</div>
							<Modal
								type="danger"
								open={showModal}
								setOpen={(val: boolean) => setShowModal(val)}
								handleClick={() => handleDeleteAssignedProgram()}
							>
								<>
									<h3 className="text-lg leading-6 font-medium text-gray-900">
										Delete
									</h3>
									<div className="mt-2">
										<p className="text-sm text-gray-500">
											Do you Want to delete these assigned program?
										</p>
									</div>
								</>
							</Modal>
						</div>
						<div className="flex justify-between">
							<div>
								<p className="font-Roboto font-bold text-sm text-white mb-2">
									{assignedProgram?.assigned_program_stats?.sessions_execute}/
									{assignedProgram?.assigned_program_stats?.sessions} Completed
								</p>
								<ProgressBar
									steps={
										assignedProgram?.assigned_program_stats?.sessions
											? assignedProgram?.assigned_program_stats.sessions
											: 0
									}
									percent={(100 * (statsExecute || 0)) / (stats || 0)}
								/>
							</div>
							<button
								type="button"
								className="bg-custom flex justify-center items-center rounded-2xl px-3 h-[30px]"
								onClick={() => handleExportReport()}
							>
								{downloaded ? (
									<svg
										width="20"
										height="20"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
										className="animate-spin fill-white"
									>
										<path
											d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
											opacity=".25"
										/>
										<path
											// eslint-disable-next-line max-len
											d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
										/>
									</svg>
								) : (
									<DownloadIcon className={clsx('w-5 h-5 text-white')} />
								)}
							</button>
						</div>
					</div>
					<Transition appear show={modalNotes} as={Fragment}>
						<Dialog
							as="div"
							className="relative z-30"
							onClose={() => setModalNotes(false)}
						>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<div className="fixed inset-0 bg-black bg-opacity-25" />
							</Transition.Child>

							<div className="fixed inset-0 overflow-y-auto">
								<div className="flex min-h-full items-center justify-center p-4 text-center">
									<Transition.Child
										as={Fragment}
										enter="ease-out duration-300"
										enterFrom="opacity-0 scale-95"
										enterTo="opacity-100 scale-100"
										leave="ease-in duration-200"
										leaveFrom="opacity-100 scale-100"
										leaveTo="opacity-0 scale-95"
									>
										<div className="w-[440px] h-[390px] bg-white rounded-lg border border-[#B5BACE]">
											<Notes
												idSession={idSession}
												session={session}
												setModalNotes={setModalNotes}
											/>
										</div>
									</Transition.Child>
								</div>
							</div>
						</Dialog>
					</Transition>
				</div>
			)}
		</div>
	)
}

export default AssignedProgramItem
