import { Icon } from 'components'
import { Icons, ModalCalendarProps } from 'interfaces'
import React, { useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import './styles.css'
import moment from 'moment'
import Cookies from 'universal-cookie'
import { updateFinishedDate } from 'api'
import { useToast } from 'hooks'
import {
	TrashIcon,
	ChevronLeftIcon,
	ChevronRightIcon
} from '@heroicons/react/outline'
import clsx from 'clsx'
import { Modal } from '.'

export const ModalCalendar: React.FC<ModalCalendarProps> = ({
	finishedDate,
	slugProgram,
	session,
	sessionsCompleted,
	setOpenDrawer
}): JSX.Element => {
	const cookies = new Cookies()
	const token = cookies.get('token')
	const { showToast } = useToast()
	const [endDate, setEndDate] = useState<Date | null>(
		finishedDate ? moment(finishedDate).toDate() : null
	)

	const [openModal, setOpenModal] = useState(false)
	const [openCalendar, setOpenCalendar] = useState(false)

	const handleRequestUpdateDate = async (finalDate: Date): Promise<void> => {
		setEndDate(finalDate)
		const res = await updateFinishedDate(
			token,
			slugProgram,
			moment(finalDate).format('yyyy-MM-DD')
		)
		if (res) {
			showToast(
				`Session ${session} finished ${moment(finalDate).format('MM/DD/yyyy')}`,
				'This session was finished successfully',
				'success'
			)
		} else {
			showToast(
				'Session Finished',
				'Error: update date finished session',
				'error'
			)
		}
		setOpenCalendar(false)
	}

	const handleDeleteDate = async (): Promise<void> => {
		setEndDate(null)
		const res = await updateFinishedDate(token, slugProgram, null)
		if (res) {
			showToast(
				`Session Finished`,
				`Clear date was done successfully`,
				'success'
			)
		} else {
			showToast(
				'Session Finished',
				'Error: update date finished session',
				'error'
			)
		}
		setOpenDrawer(true)
	}

	const handleChange = (date: Date): void => {
		setEndDate(date)
		setOpenDrawer(true)
		// setOpenModal(true)
	}

	const handleSelect = (date: Date): void => {
		setEndDate(date)
		setOpenDrawer(true)
		// setOpenModal(true)
	}

	const handleConfirmModal = (): void => {
		handleRequestUpdateDate(endDate || new Date())

		setOpenModal(false)
		setOpenCalendar(false)
		setOpenDrawer(true)
	}

	const today = new Date()
	// const excludesDates = Array.from(Array(365), (_, k) =>
	// 	moment(today).add(k, 'd').toDate()
	// )

	// React.useEffect(() => {
	// 	console.warn('dates', excludesDates)
	// }, [])

	return (
		<div
			className={clsx('relative h-full flex justify-center items-center')}
			aria-hidden
			onClick={() => setOpenCalendar(true)}
		>
			<DatePicker
				open={openCalendar}
				selected={endDate !== null ? moment(endDate).toDate() : new Date()}
				onChange={(date: Date) => handleChange(date)}
				adjustDateOnChange
				onSelect={(date: Date) => handleSelect(date)}
				onCalendarClose={() => setOpenDrawer(true)}
				shouldCloseOnSelect={false}
				className="w-full flex gap-1 text-[#6B7280] text-lg"
				withPortal={openCalendar}
				portalId="modal-calendar"
				disabled={!sessionsCompleted.includes(slugProgram)}
				excludeDateIntervals={[
					{
						start: moment(today).add(1, 'd').toDate(),
						end: moment(today).add(1, 'y').toDate()
					}
				]}
				maxDate={new Date()}
				minDate={moment(new Date()).subtract(1, 'year').toDate()}
				customInput={
					<div className="w-full h-7 flex justify-between items-center">
						<span className="w-[90px] hover:border hover:border-custom hover:rounded-md group">
							{endDate ? moment(endDate).format('MM/DD/yyyy') : 'mm/dd/yyyy'}
						</span>
						{!endDate && (
							<div
								className={clsx(
									'w-[28px] h-5 flex justify-center items-center mr-1',
									'group-hover:bg-custom group-hover:rounded-md'
								)}
							>
								<Icon
									src={Icons.pencil}
									fillPath
									className="w-[18px] h-[18px] text-custom group-hover:text-white"
								/>
							</div>
						)}
					</div>
				}
				renderCustomHeader={({
					date,
					decreaseMonth,
					increaseMonth,
					prevMonthButtonDisabled,
					nextMonthButtonDisabled
				}) => (
					<div
						style={{
							marginLeft: 16,
							marginRight: 16,
							display: 'flex',
							justifyContent: 'space-between'
						}}
					>
						<button
							type="button"
							onClick={decreaseMonth}
							disabled={prevMonthButtonDisabled}
						>
							{prevMonthButtonDisabled ? (
								<div className="w-5 h-5" />
							) : (
								<ChevronLeftIcon className="w-5 h-5 text-custom" />
							)}
						</button>
						<span className="react-datepicker__current-month">
							{moment(date).format('MMMM')} {moment(date).year()}
						</span>
						<button
							type="button"
							onClick={increaseMonth}
							disabled={nextMonthButtonDisabled}
						>
							{nextMonthButtonDisabled ? (
								<div className="w-5 h-5" />
							) : (
								<ChevronRightIcon className="w-5 h-5 text-custom" />
							)}
						</button>
					</div>
				)}
				renderDayContents={(dayOfMonth, date) => {
					return (
						<button
							type="button"
							className={clsx(
								'w-full h-full',
								date &&
									(date > today ||
										date < moment(today).subtract(1, 'y').toDate())
									? 'cursor-not-allowed hover:bg-gray-300 rounded-[0.3rem]'
									: ''
							)}
							disabled={
								date &&
								(date > today || date < moment(today).subtract(1, 'y').toDate())
							}
							aria-hidden
							onClick={() => setOpenModal(true)}
						>
							{dayOfMonth}
						</button>
					)
				}}
			>
				<Modal
					type="warning"
					open={openModal}
					setOpen={() => setOpenModal(false)}
					handleClick={() => handleConfirmModal()}
				>
					<>
						<header
							className={clsx(
								'w-full flex justify-center items-center gap-3 ',
								'text-xl text-slate-800 font-medium font-Roboto'
							)}
						>
							<p>Set date</p>
							<p className="font-bold">
								{moment(endDate).format('MMMM DD, yyyy')}
							</p>
						</header>
						<p className="w-full text-lg text-slate-800 font-Roboto font-normal">
							Are you sure you want to set this date?
						</p>
					</>
				</Modal>
			</DatePicker>
			{endDate && (
				<div
					className="w-full h-7 flex justify-center items-center hover:bg-custom hover:rounded-md group"
					aria-hidden
					onClick={() => handleDeleteDate()}
				>
					<TrashIcon className="w-5 h-5 text-custom group-hover:text-white" />
				</div>
			)}
		</div>
	)
}
