import clsx from 'clsx'
import { CardExercise } from 'components/box/athletes/cardExercise'
import { CarouselMetrics } from 'components/carousel'
import React from 'react'
import { DrawerExerciseMobileProps } from './athlete.interface'
import { SetsNavigation } from './setsNavigation'
import { TableExercise } from './tableExercise'

export const DrawerExerciseMobile: React.FC<DrawerExerciseMobileProps> = ({
	setOpenModalExercise,
	listExercise,
	checkSlugExercise,
	handleCheckExercise,
	setExerciseName,
	/// ///////
	statsIndex,
	setStatsIndex,
	setStatsMetrics,
	handleMetricForSets,
	arrayOfSets,
	arrayOfMetrics,
	setArrayOfSets,
	setArrayOfMetrics
}): JSX.Element => {
	return (
		<div className="flex flex-col gap-4">
			<div className="px-10">
				<div className="w-full flex justify-end items-center py-2">
					<button
						type="button"
						className="h-[30px] border border-gray-400 px-5 rounded-md"
						onClick={() => setOpenModalExercise(true)}
					>
						Screenshot Metrics
					</button>
				</div>
				<div className="flex flex-col gap-5">
					<div className="w-full h-[222px] flex flex-col items-center bg-white p-3 shadow-xl border border-gray-300 rounded-xl">
						<TableExercise statsIndex={statsIndex} arrayOfSets={arrayOfSets} />
						<SetsNavigation
							statsIndex={statsIndex}
							setStatsIndex={setStatsIndex}
							arrayOfSets={arrayOfSets}
							handleMetricForSets={handleMetricForSets}
						/>
					</div>
					<div className="w-full h-[222px] flex justify-center items-center bg-white rounded-xl shadow-xl border border-gray-300">
						<CarouselMetrics indexSets={statsIndex} metrics={arrayOfMetrics} />
					</div>
				</div>
			</div>
			<span className="tw-full text-sm text-gray-400 text-center">
				Select a exercise for more details
			</span>
			<div
				className={clsx(
					'flex flex-col gap-3 px-5 pb-4',
					'min-h-[calc(100vh-440px)] max-h-[calc(100vh-440px)]',
					'lg:min-h-[calc(100vh-610px)] lg:max-h-[calc(100vh-610px)]',
					'overflow-hidden hide-scroll-bar overflow-y-scroll'
				)}
			>
				{listExercise?.map(exercise => (
					<CardExercise
						key={exercise.slug}
						exercise={exercise}
						checkedExercise={checkSlugExercise}
						handleCheckExercise={handleCheckExercise}
						setExerciseName={setExerciseName}
						statsIndex={statsIndex}
						setArrayOfSets={setArrayOfSets}
						setStatsMetrics={setStatsMetrics}
						setArrayOfMetrics={setArrayOfMetrics}
						setStatsIndex={setStatsIndex}
					/>
				))}
			</div>
		</div>
	)
}
