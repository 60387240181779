/* eslint-disable camelcase */
import { validationInfoInterface } from 'interfaces'

export const getValidationMessage = (
	validationInfo: validationInfoInterface
): string | null => {
	const { typeForm, librarySetsBreakDown, setsBreakDown, name, comments } =
		validationInfo
	if (!name) {
		return 'You should add a name for the exercise'
	}
	if (!comments) {
		return 'You should add a comment for the exercise'
	}
	if (typeForm !== 'editSession') {
		if (librarySetsBreakDown.length === 0) {
			return 'You shoud create at least one set'
		}
		if (
			librarySetsBreakDown.some(
				({ library_metrics }) => library_metrics.length === 0
			)
		) {
			return 'You shoud create at least one metric per set'
		}
		if (
			librarySetsBreakDown.some(({ library_metrics }) =>
				library_metrics.some(
					lib_metric =>
						lib_metric.default_value === '00:00:00' ||
						lib_metric.default_value === '---' ||
						lib_metric.default_value === ''
				)
			)
		) {
			return 'You shoud enter a value of each metric'
		}
		return null
	}
	if (setsBreakDown.length === 0) {
		return 'You shoud create at least one set'
	}
	if (setsBreakDown.some(({ metrics }) => metrics.length === 0)) {
		return 'You shoud create at least one metric per set'
	}
	if (
		setsBreakDown.some(({ metrics }) =>
			metrics.some(
				metric =>
					metric.default_value === '00:00:00' ||
					metric.default_value === '---' ||
					metric.default_value === ''
			)
		)
	) {
		return 'You shoud enter a value of each metric'
	}
	return null
}
