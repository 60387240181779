import React, { useRef } from 'react'
// import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { DrawerType } from 'interfaces'
import clsx from 'clsx'
import { useClickOutside } from 'hooks'
import { Transition } from '@headlessui/react'

export const Drawer: React.FC<DrawerType> = ({
	open = false,
	setOpen,
	title,
	children,
	stretch,
	mobileFull = false
}): JSX.Element => {
	const ref = useRef(null)
	useClickOutside(ref, () => setOpen(false))

	return (
		<Transition
			show={open}
			className={clsx(
				'fixed inset-0 w-screen h-full overflow-hidden backdrop-blur-[2px] z-30'
			)}
			enter="transform transition ease-in-out duration-500 sm:duration-700"
			enterFrom="translate-y-full md:translate-x-full md:translate-y-0"
			enterTo="translate-y-0 md:translate-x-0"
			leave="transform transition ease-in-out duration-500 sm:duration-700"
			leaveFrom="translate-y-0 md:translate-x-0"
			leaveTo="translate-y-full md:translate-x-full md:translate-y-0"
		>
			<div className="absolute inset-0 overflow-hidden" />
			<div
				ref={ref}
				className={clsx(
					'min-w-full md:min-w-[460px] max-w-full h-full',
					'absolute bottom-0 right-0 flex z-40',
					'shadow-[0px_2px_5px_3px_rgba(0,0,0,0.2)]',
					mobileFull ? 'top-0' : ''
				)}
			>
				<section
					className={clsx(
						'pointer-events-auto w-screen md:max-w-[460px]',
						'flex h-full flex-col overflow-y-hidden bg-white shadow-xl'
					)}
				>
					<header
						className={clsx(
							'w-full h-[68px] flex justify-between items-center bg-gradient-to-r from-gray-700 to-gray-800 py-6 px-4'
						)}
					>
						<p className="text-lg font-medium font-Roboto text-white">
							{title}
						</p>
						<button
							type="button"
							className={clsx('rounded-md text-indigo-200 hover:text-white')}
							onClick={() => setOpen(false)}
						>
							<span className="sr-only">Close panel</span>
							<XIcon className="h-6 w-6" aria-hidden="true" />
						</button>
					</header>
					<main
						className={clsx('relative flex-1 w-full', {
							'py-6 px-4 sm:px-6': !stretch
						})}
					>
						{children}
					</main>
				</section>
			</div>
		</Transition>
	)
}
