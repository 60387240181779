import { ASSIGNED_PROGRAMS_ENDPOINTS } from 'api/common'
import { API } from 'api/config'
import { createAssignedProgramsResType } from 'interfaces'

type CreateAssignedPrograms = {
	customToken?: string
	customer: string
	athleteIds: string
	programSlug: string
}
export const createAssignedPrograms = async ({
	athleteIds,
	customer,
	programSlug
}: CreateAssignedPrograms): Promise<createAssignedProgramsResType | null> => {
	let res = null as createAssignedProgramsResType | null
	await API()
		.post(
			`${ASSIGNED_PROGRAMS_ENDPOINTS.BASE}/assign/`,
			{
				program: programSlug
			},
			{
				params: {
					'x-customer-id': customer,
					ids: athleteIds
				}
			}
		)
		.then(
			async response => {
				res = response.data
			},
			async error => {
				res = error.response.data
			}
		)

	return res
}
