import { API, SESSION_GROUPS_ENDPOINTS } from 'api'

type DeleteGroupProps = {
	customToken?: string
	slugGroup: string
	customerId: number
	actionType: string
}

export const deleteGroup = async ({
	actionType,
	customerId,
	slugGroup
}: DeleteGroupProps): Promise<number | null> => {
	let res = null as number | null

	await API()
		.delete(
			`${SESSION_GROUPS_ENDPOINTS.BASE}/${slugGroup}/?x-customer-id=${customerId}&action=${actionType}/`
		)
		.then(
			async response => {
				res = response.status
			},
			error => {
				res = error.status
				console.error(error)
			}
		)

	return res
}
