import { API, SESSION_GROUPS_ENDPOINTS } from 'api'
import { InGroupResponse, InGroupType } from 'interfaces'

type GetInGroupsProps = {
	customToken?: string
	customerId: number
	date?: string
	month?: number
	startWeek?: string
	endWeek?: string
	search?: string
	page?: number
	limit?: number
	status?: 'active' | 'inactive'
	dateFrom?: string
}

export const getInGroups = async ({
	customerId,
	date,
	dateFrom,
	endWeek,
	limit,
	month,
	page,
	search,
	startWeek,
	status
}: GetInGroupsProps): Promise<InGroupResponse | null> => {
	let res = null as InGroupResponse | null

	const params = {
		'x-customer-id': customerId,
		...(date && { date }),
		...(month && { month }),
		...(startWeek && { start_week: startWeek }),
		...(endWeek && { end_week: endWeek }),
		...(search && { search }),
		...(page && { offset: page }),
		...(limit && { limit }),
		...(status && { status }),
		...(dateFrom && { date_from: dateFrom })
	}

	await API()
		.get(SESSION_GROUPS_ENDPOINTS.BASE, { params })
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)

	return res
}

type GetInGroupProps = {
	customToken?: string
	customerId: number
	slug: string
}
export const getInGroup = async ({
	customerId,
	slug
}: GetInGroupProps): Promise<InGroupType | null> => {
	let res = null as InGroupType | null

	await API()
		.get(`${SESSION_GROUPS_ENDPOINTS.BASE}/${slug}`, {
			params: {
				'x-customer-id': customerId
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)

	return res
}
