/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseURL } from 'api'
import axios from 'axios'
import { athleteType } from 'interfaces'

export const updateClient = async (
	token: string,
	athleteId: number | undefined,
	customer: number,
	body: {
		height: string | null
		weight: string | null
		sport: string | null
		position: string | null
		school: string | null
	}
): Promise<athleteType | null> => {
	let res = null as athleteType | null
	const { height, weight, sport, position, school } = body
	if (athleteId) {
		await axios
			.put(
				`${BaseURL}/clients/${athleteId}?x-customer-id=${customer}&assigned_program_state=active`,
				{
					height,
					weight,
					sport,
					position,
					school
				},
				{
					headers: {
						Authorization: `Token ${token}`
					}
				}
			)
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
				}
			)
	}
	return res
}

export const updateMetricsClient = async (
	token: string,
	athleteId: number | undefined,
	customer: number,
	body: {
		variable_metric_fields: {
			[key: string]: string
		}
	}
): Promise<athleteType | null> => {
	let res = null as athleteType | null
	if (athleteId) {
		await axios
			.put(
				`${BaseURL}/clients/${athleteId}?x-customer-id=${customer}&assigned_program_state=active`,
				{
					variable_metric_fields: body.variable_metric_fields
				},
				{
					headers: {
						Authorization: `Token ${token}`
					}
				}
			)
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
				}
			)
	}
	return res
}
