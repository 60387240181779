/* eslint-disable camelcase */
import React, { useRef, useState } from 'react'
import { Button, Icon, Input, Loading } from 'components'
import {
	CreateExerciseInputs,
	DrawerCreateExerciseType,
	Icons,
	librarySetsType,
	setType,
	validationInfoInterface
} from 'interfaces'
import clsx from 'clsx'
import { SubmitHandler, useForm } from 'react-hook-form'
import Cookies from 'universal-cookie'
import { useQuery } from 'react-query'
import {
	createLibraryExercises,
	editExercises,
	editLibraryExercises,
	getListTypeMetrics,
	GET_TYPE_METRICS
} from 'api'
import { useToast } from 'hooks'
import { getValidationMessage } from 'lib'
import { VideoFormExercise } from 'components/VideoExercise'
import { SetsMetricsList, LibrarySetsList } from './components'

export const DrawerFormExercise: React.FC<DrawerCreateExerciseType> = ({
	session,
	typeForm,
	setOpen,
	refetchUp,
	exercise,
	libraryExercise
}): JSX.Element => {
	const { showToast } = useToast()
	const scrollRef = useRef<HTMLDivElement>(null)
	const cookies = new Cookies()
	const token = cookies.get('token')
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues
	} = useForm<CreateExerciseInputs>({
		mode: 'all',
		defaultValues: {
			name: exercise?.name || libraryExercise?.name,
			comments: exercise?.description || libraryExercise?.description
		}
	})
	const rules = {
		name: {
			required: { value: true, message: 'This is required' }
		},
		comments: {
			required: { value: true, message: 'This is required' }
		}
	}

	const [setsBreakDown, setSetsBreakDown] = useState<setType[]>(
		exercise?.sets || []
	)
	const [librarySetsBreakDown, setLibrarySetsBreakDown] = useState<
		librarySetsType[]
	>(libraryExercise?.library_sets_breakdown || [])
	const [videoUrl, setVideoUrl] = useState<string | undefined | null>(
		exercise?.video?.url || libraryExercise?.video?.url || undefined || null
	)

	const { data: listMetrics, isLoading } = useQuery(
		[GET_TYPE_METRICS, token],
		() => getListTypeMetrics(token),
		{
			refetchOnMount: 'always'
		}
	)

	const validationInfo: validationInfoInterface = {
		typeForm,
		librarySetsBreakDown,
		setsBreakDown,
		name: getValues('name'),
		comments: getValues('comments')
	}

	const onSubmit: SubmitHandler<CreateExerciseInputs> = async data => {
		const body = {
			name: data.name,
			description: data.comments,
			video_url: videoUrl,
			library_sets_breakdown: librarySetsBreakDown,
			library_metrics: []
		}
		switch (typeForm) {
			case 'create':
				try {
					await createLibraryExercises(token, body)
					showToast(
						'Created exercises',
						'Exercise was created successfully',
						'success'
					)
					refetchUp()
					setOpen(false)
				} catch (error) {
					console.error(error)
					showToast('Error', `Exercise wasn't created successfully`, 'error')
				}

				break
			case 'editSession':
				if (exercise && session) {
					const bodyEdit = {
						name: data.name,
						description: data.comments,
						video_url: videoUrl,
						metrics: [],
						sets: setsBreakDown,
						program_session: session.slug
					}
					try {
						await editExercises(token, exercise.slug, bodyEdit)
						showToast(
							'Edited exercises',
							'Exercise was edited successfully',
							'success'
						)
						refetchUp()
						setOpen(false)
					} catch (error) {
						console.error(error)
						showToast('Error', `Exercise wasn't edited successfully`, 'error')
					}
				}
				break
			case 'edit':
				if (libraryExercise) {
					const bodyEdit = {
						name: data.name,
						description: data.comments,
						video_url: videoUrl,
						library_metrics: [],
						library_sets_breakdown: librarySetsBreakDown
					}
					try {
						const res = await editLibraryExercises(
							token,
							libraryExercise.slug,
							bodyEdit
						)
						if (res) {
							showToast(
								'Edited exercises',
								'Exercise was edited successfully',
								'success'
							)
							refetchUp()
							setOpen(false)
						} else {
							showToast('Error', `Exercise wasn't edited successfully`, 'error')
						}
					} catch (error) {
						console.error(error)
					}
				}
				break
			default:
				console.error('NO CASE FOUND - ', typeForm)
				break
		}
	}

	return (
		<form
			className="flex flex-col h-full w-full absolute"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="flex-1 h-10 overflow-y-scroll hide-scroll-bar ">
				{isLoading ? (
					<Loading />
				) : (
					<div className="flex-1 p-6 pb-3">
						{/* NAME AND COMMENTS FORM */}
						<div ref={scrollRef} className={clsx('flex flex-col')}>
							<Input
								name="name"
								title="Name of the exercise"
								register={register}
								rules={rules.name}
								placeholder="write a name of the exercise"
								error={errors.name}
								className="mb-4"
								squared
								asterisk
								maxLength={50}
							/>
							<Input
								name="comments"
								title="Comments of the exercise"
								register={register}
								rules={rules.comments}
								placeholder="Comments of the exercise"
								error={errors.comments}
								className="mb-3"
								squared
								asterisk
							/>

							<VideoFormExercise
								video={exercise?.video || libraryExercise?.video || null}
								setVideoUrl={setVideoUrl}
							/>

							<hr className="my-2" />

							{/* CONDITIONAL RENDER OF LIBRARY */}
							{typeForm === 'editSession' ? (
								<SetsMetricsList
									exercise={exercise?.slug || ''}
									listMetrics={listMetrics || []}
									setsBreakDown={setsBreakDown}
									setSetsBreakDown={setSetsBreakDown}
								/>
							) : (
								<LibrarySetsList
									exercise={exercise?.slug || ''}
									listMetrics={listMetrics || []}
									setsBreakDown={librarySetsBreakDown}
									setSetsBreakDown={setLibrarySetsBreakDown}
								/>
							)}
						</div>
					</div>
				)}
			</div>

			{/* CREATE EXERCISE AND ERROR MESSAGE */}
			<footer className="flex-none px-3 py-2">
				{getValidationMessage(validationInfo) !== null && (
					<div className="flex justify-center">
						<Icon
							src={Icons.error}
							fillPath
							className="w-4 h-4 mr-1 text-[#CE3768]"
						/>
						<p className="text-[#CE3768] text-xs text-center mb-1">
							{getValidationMessage(validationInfo)}
						</p>
					</div>
				)}
				<Button
					customColor
					size="full"
					type="submit"
					label={
						typeForm === 'create'
							? 'Create exercise'
							: 'Apply changes to the exercise'
					}
					disabled={getValidationMessage(validationInfo) !== null}
				/>
			</footer>
		</form>
	)
}
