export enum Images {
	program_cover = '/asset/images/program_cover.png',
	upperhand_login = '/asset/images/upperhand.png',
	athRepublic_login = '/asset/images/ath-republic.jpg',
	logo = '/asset/images/logo.png',
	programs = '/asset/images/programs.png',
	athetles = '/asset/images/athletes.png',
	training = '/asset/images/training.png',
	programs2 = '/asset/images/programs2.png',
	athetles2 = '/asset/images/athletes2.png',
	training2 = '/asset/images/training2.png',
	default_avatar = '/asset/images/default_avatar.png',
	newNotes = '/asset/images/new-notes.png',
	schedule = '/asset/images/schedule.png',
	schedule2 = '/asset/images/schedule2.png',
	maintenance = '/asset/images/maintenance.png',
	notFound = '/asset/images/notfound.png',
	dBat = '/asset/images/Dbat.png',
	empty_notifications = '/asset/images/empty_notifications.png',
	coach_avatar = '/asset/images/coach_avatar.png'
}

export enum Icons {
	pencil = '/asset/svg/pencil.svg',
	logo = '/asset/svg/logo.svg',
	home = '/asset/svg/home.svg',
	programs = '/asset/svg/programs.svg',
	athetles = '/asset/svg/athetles.svg',
	training = '/asset/svg/training.svg',
	plus = '/asset/svg/plus.svg',
	copy = '/asset/svg/copy.svg',
	camera = '/asset/svg/camera.svg',
	clone = '/asset/svg/clone.svg',
	trash = '/asset/svg/trash.svg',
	paperPen = '/asset/svg/paperPen.svg',
	spin = '/asset/svg/spin.svg',
	check = '/asset/svg/check.svg',
	archive = '/asset/svg/archive.svg',
	unarchive = '/asset/svg/unarchive.svg',
	burguer = '/asset/svg/burguerMenu.svg',
	arrowGreen = '/asset/svg/arrowGreen.svg',
	arrowRed = '/asset/svg/arrowRed.svg',
	equals = '/asset/svg/equals.svg',
	noVariant = '/asset/svg/noVariant.svg',
	schedule = '/asset/svg/schedule.svg',
	group = '/asset/svg/group.svg',
	loading = '/asset/svg/loading.svg',
	logout = 'asset/svg/logout.svg',
	faceHappy = 'asset/svg/face.svg',
	broken = 'asset/svg/broken-bone.svg',
	faceDepression = 'asset/svg/depression.svg',
	weights = 'asset/svg/weights.svg',
	thinking = 'asset/svg/thinking.svg',
	timeless = 'asset/svg/hourglass.svg',
	addVideo = 'asset/svg/add-video.svg',
	playVideo = 'asset/svg/play-video.svg',
	error = 'asset/svg/error.svg',
	help = 'asset/svg/help.svg',
	close = 'asset/svg/close.svg',
	email_notification_icon = 'asset/svg/email_notification_icon.svg',
	inapp_icon = 'asset/svg/inapp_icon.svg',
	reminder = 'asset/svg/reminder.svg',
	settings_icon = 'asset/svg/settings_icon.svg',
	arrow_settings = '/asset/svg/arrow_drop_down.svg',
	bell_icon = '/asset/svg/bell_icon.svg',
	group_notification = 'asset/svg/group_notification.svg',
	program_notification = 'asset/svg/program_notification.svg'
}

export enum GIF {
	emptyMetric = '/asset/gif/empty-state-metrics-athlete.gif',
	emptyCharts = '/asset/gif/empty-state-charts.gif'
}

export type IconsType = Icons
