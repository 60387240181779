/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { Disclosure } from '@headlessui/react'
import clsx from 'clsx'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { AssignedProgramConfigProps } from 'interfaces'
import { AssignedProgramAthConfig } from 'components'

export const AssignedProgramConfig: React.FC<AssignedProgramConfigProps> = ({
	session,
	athletes,
	athletesInfo,
	refetchList,
	refetchAssignedProgram
}): JSX.Element => {
	const [athletesState, setAthletesState] = useState(athletes)

	useEffect(() => {
		setAthletesState(athletes)
	}, [athletes])

	return (
		<Disclosure>
			{({ open }) => (
				<>
					<Disclosure.Button
						className={clsx(
							'flex justify-between items-center',
							'h-[72px] p-2 w-full',
							'shadow sm:rounded-lg border-[1px] border-l-4 border-l-custom cursor-pointer rounded-lg',
							'bg-white hover:bg-gray-200 border-gray-300'
						)}
					>
						<div className="flex flex-col w-full justify-start pl-4">
							<h3 className="text-base text-left leading-6text-gray-900 font-Roboto font-bold lg:text-xl line-clamp-1">
								{session.program_session.name}
							</h3>
							<div className="text-xs text-gray-500 lg:text-lg text-left">
								<p>{`${session.assigned_exercises.length} Exercises`}</p>
							</div>
						</div>
						<div className="flex justify-center items-center h-full">
							<ChevronRightIcon
								className={clsx(
									open ? '-rotate-90 transform' : 'rotate-90',
									'h-6 w-6 text-custom'
								)}
								aria-hidden="true"
							/>
						</div>
					</Disclosure.Button>
					<Disclosure.Panel className="">
						{session.assigned_exercises &&
							session.assigned_exercises.length > 0 &&
							session.assigned_exercises.map((exercise, idx) => {
								const posE = `${idx}-${exercise.slug}`
								return (
									<Disclosure key={posE}>
										{({ open: open2 }) => (
											<>
												<Disclosure.Button
													type="button"
													className={clsx(
														'flex justify-between items-center',
														'h-[72px] p-2 w-[96%] ml-[2%] mt-1',
														'shadow sm:rounded-lg border-[1px]',
														'border-l-4 border-l-custom  cursor-pointer rounded-lg',
														'bg-white border-gray-300 hover:bg-gray-200',
														open2 ? 'rounded-b-none' : ''
													)}
												>
													<div className="flex flex-col w-full justify-start pl-4">
														<h3
															className={clsx(
																'text-base text-left leading-6text-gray-900 font-Roboto font-bold',
																'lg:text-xl line-clamp-1'
															)}
														>
															{exercise.name}
														</h3>
														<div className="text-xs text-gray-500 lg:text-lg text-left line-clamp-2">
															{exercise.description}
														</div>
													</div>
													<div className="flex justify-center items-center h-full">
														<ChevronRightIcon
															className={clsx(
																open2 ? '-rotate-90 transform' : 'rotate-90',
																'h-6 w-6 text-custom'
															)}
															aria-hidden="true"
														/>
													</div>
												</Disclosure.Button>

												<Disclosure.Panel>
													{athletesState &&
														athletesState.length > 0 &&
														athletesState.map((athlete, index) => {
															const posA = `${index}-${athlete.client_reference_id}`
															// const auxAthle = athletesInfo.filter(
															// 	item => item.id === athlete.client_reference_id
															// )[0]
															return athletesInfo.map(item => {
																return (
																	<AssignedProgramAthConfig
																		key={posA}
																		session={session}
																		exercise={exercise}
																		athlete={athlete}
																		athleteInfo={item}
																		refetchList={refetchList}
																		refetchAssignedProgram={
																			refetchAssignedProgram
																		}
																	/>
																)
															})
														})}
												</Disclosure.Panel>
											</>
										)}
									</Disclosure>
								)
							})}
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	)
}
