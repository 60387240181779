import { Dialog, Transition } from '@headlessui/react'
import { updateStatusAssignedExercises } from 'api'
import { Icon } from 'components/icon'
import { useClickOutside } from 'hooks'
import { Icons, SessionStatus, modalStatusExercise } from 'interfaces'
import React, { Fragment, useRef } from 'react'
import Cookies from 'universal-cookie'

export const statusExcerciseIcons = [
	{
		id: SessionStatus.Normal,
		item: 'Normal',
		icon: Icons.faceHappy
	},
	{
		id: SessionStatus.Injured,
		item: 'Injured',
		icon: Icons.broken
	},
	{
		id: SessionStatus.LackOfTime,
		item: 'Lack of time',
		icon: Icons.timeless
	},
	{
		id: SessionStatus.Reluctant,
		item: 'Reluctant to execute',
		icon: Icons.faceDepression
	},
	{
		id: SessionStatus.NoEquipment,
		item: 'No equipment',
		icon: Icons.weights
	},
	{
		id: SessionStatus.Other,
		item: 'Other',
		icon: Icons.thinking
	}
]

const ModalStatus: React.FC<modalStatusExercise> = ({
	open,
	setOpen,
	setValue,
	value,
	slug,
	defaultValue,
	refetchData
}) => {
	const modalRef = useRef<HTMLDivElement>(null)
	useClickOutside(modalRef, () => {
		setOpen(false)
	})
	const cookies = new Cookies()
	const token = cookies.get('token')
	const sendStatusExercise = async (statusExer: {
		slug: string
		status: number
	}): Promise<void> => {
		const body = [
			{
				slug,
				status: statusExer.status
			}
		]

		if (body) {
			try {
				await updateStatusAssignedExercises(token, body)
				refetchData()
			} catch (error) {
				console.error(error)
			}
		}
	}

	const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
		sendStatusExercise({ slug, status: Number(e.target.value) })
			.then(() => console.warn('success'))
			.catch(err => console.error(err))
		setValue({ slug, status: Number(e.target.value) })
		setOpen(false)
	}
	return (
		<Transition appear show={open} as={Fragment}>
			<Dialog
				as="div"
				className="z-50 fixed inset-0"
				onClose={() => setOpen(false)}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25" />
				</Transition.Child>
				<div
					aria-hidden="true"
					className="fixed inset-0 overflow-y-auto"
					onClick={() => setOpen(false)}
				/>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4">
							<div
								ref={modalRef}
								className="font-Roboto p-4 w-[420px] bg-white rounded-lg border border-[#B5BACE]"
							>
								<h3 className="font-bold mb-5">Status</h3>
								<ol className="flex flex-col gap-[18px] px-1">
									{statusExcerciseIcons.map(item => (
										<li
											key={item.id}
											className="flex items-center p-2 rounded hover:bg-slate-100"
										>
											<input
												value={item.id}
												onChange={handleChange}
												checked={
													item.id === value.status || item.id === defaultValue
												}
												className="mr-[10px] w-6 h-6 accent-custom rounded-full"
												type="radio"
												id={`status-${item.id}`}
												name="status"
											/>
											<label
												className="flex w-full font-normal text-lg cursor-pointer "
												htmlFor={`status-${item.id}`}
											>
												{item.item}
												<Icon className="w-6 h-5 ml-auto" src={item.icon} />
											</label>
										</li>
									))}
								</ol>
							</div>
						</div>
					</div>
				</Transition.Child>
			</Dialog>
		</Transition>
	)
}

export default ModalStatus
