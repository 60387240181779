import React from 'react'
import { Tab } from '@headlessui/react'
import clsx from 'clsx'
import { athleteType, InGroupType } from 'interfaces'
import { ClipboardIcon } from '@heroicons/react/outline'
import { BoxAthletes2, GroupBox, Loading } from 'components'

export interface TrainingGroupsProps {
	groups: InGroupType[]
	setGroupSlug: (val: string) => void
	refetch: () => void
	refetchGroup: () => void
	statusGroup: 'active' | 'inactive'
	refGroup: (node?: Element | null | undefined) => void
	hasNextPageGroup: boolean | undefined
	isFetchingNextPageGroup: boolean
	athletes: athleteType[]
	status: boolean
	isLoading: boolean
	refx: (node?: Element | null | undefined) => void
	isFetchingNextPage: boolean
}

export const TrainingGroups: React.FC<TrainingGroupsProps> = ({
	groups,
	setGroupSlug,
	refetch,
	refetchGroup,
	statusGroup,
	refGroup,
	hasNextPageGroup,
	isFetchingNextPageGroup,
	athletes,
	status,
	isLoading,
	refx,
	isFetchingNextPage
}): JSX.Element => {
	const options = [
		{ id: '1', name: 'Groups' },
		{ id: '2', name: 'Athletes' }
	]

	return (
		<div className="w-full px-5">
			<Tab.Group>
				<Tab.List className="flex space-x-1 rounded-xl bg-white p-1 border border-custom">
					{options.map(option => (
						<Tab
							key={option.id}
							className={({ selected }) =>
								clsx(
									'w-full rounded-lg py-2.5 text-base font-Roboto font-bold leading-5 text-white',
									'ring-white ring-opacity-60 ring-offset-2 ring-offset-custom focus:outline-none focus:ring-2',
									selected
										? 'bg-custom shadow'
										: 'text-[#929BB2] hover:bg-custom hover:text-white'
								)
							}
						>
							{option.name}
						</Tab>
					))}
				</Tab.List>
				<Tab.Panels className="mt-2">
					<Tab.Panel
						className={clsx(
							'flex flex-col px-6 py-2',
							'min-h-[calc(100vh-450px)] max-h-[calc(100vh-450px)]',
							'overflow-hidden hide-scroll-bar overflow-y-scroll'
						)}
					>
						{groups.length < 1 ? (
							<div
								className={clsx(
									'flex items-center gap-4 text-gray-400 text-3xl font-Roboto',
									'bg-white border border-gray-300 rounded-lg p-4 shadow'
								)}
							>
								<ClipboardIcon className="text-custom w-7 h-7" />
								Empty group list
							</div>
						) : (
							groups
								.filter(grp => grp.status === statusGroup)
								.map(group => (
									<GroupBox
										key={group.slug}
										{...group}
										handleSelectGroup={(val: string) => {
											setGroupSlug(val)
										}}
										handleRefetch={() => {
											if (refetch) refetch()
										}}
										refetchGroup={() => refetchGroup()}
									/>
								))
						)}
						<div className="flex items-center justify-center mt-2">
							{hasNextPageGroup && statusGroup === 'active' && (
								<div ref={refGroup}>
									{isFetchingNextPageGroup && <Loading isSmall />}
								</div>
							)}
							{hasNextPageGroup && statusGroup === 'inactive' && (
								<div ref={refGroup}>
									{isFetchingNextPageGroup && <Loading isSmall />}
								</div>
							)}
						</div>
					</Tab.Panel>

					<Tab.Panel
						className={clsx(
							'flex flex-col px-6 py-2',
							'min-h-[calc(100vh-450px)] max-h-[calc(100vh-450px)]',
							'overflow-hidden hide-scroll-bar overflow-y-scroll'
						)}
					>
						{status && isLoading ? (
							athletes?.map((athlete, index) => {
								const pos = `${index}-${athlete.id}`
								return (
									<BoxAthletes2
										id={athlete.id}
										key={pos}
										image={athlete.profile_image?.url}
										fullname={`${athlete.first_name} ${athlete.last_name}`}
										status={status}
										assignedPrograms={athlete.assigned_programs}
										refetchUp={refetch}
									/>
								)
							})
						) : !athletes?.length ? (
							<div
								className={clsx(
									'flex items-center gap-4 text-gray-400 text-3xl font-Roboto',
									'bg-white border border-gray-300 rounded-lg p-4 shadow'
								)}
							>
								<ClipboardIcon className="text-custom w-7 h-7" />
								Empty athlete list
							</div>
						) : (
							athletes?.map((athlete, index) => {
								const pos = `${index}-${athlete.id}`
								return (
									<BoxAthletes2
										id={athlete.id}
										key={pos}
										image={athlete.profile_image?.url}
										fullname={`${athlete.first_name} ${athlete.last_name}`}
										status={status}
										assignedPrograms={athlete.assigned_programs}
										refetchUp={refetch}
									/>
								)
							})
						)}
						<div className="h-10 md:col-span-2 xl:col-span-3 2xl:col-span-4 flex items-center justify-center mt-2">
							<div ref={refx}>{isFetchingNextPage && <Loading isSmall />}</div>
						</div>
					</Tab.Panel>
				</Tab.Panels>
			</Tab.Group>
		</div>
	)
}
