import { PencilIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { athleteType, Images } from 'interfaces'
import * as React from 'react'
import { InfoAthlete } from './infoAthlete'

export interface Props {
	athlete: athleteType | undefined
	setUpdateInfoAthleteDrawer: React.Dispatch<React.SetStateAction<boolean>>
}
export const CardDataAthlete: React.FC<Props> = ({
	athlete,
	setUpdateInfoAthleteDrawer
}): JSX.Element => {
	return (
		<div className="hidden lg:flex h-[222px] min-w-auto max-w-auto w-auto shadow-xl border border-gray-300 rounded-2xl bg-white">
			<img
				src={athlete?.profile_image?.url || Images.default_avatar}
				alt="avatar"
				className="h-full w-[200px] max-w-[200px] rounded-l-2xl"
			/>
			<div className="w-[290px] h-full p-4 font-Roboto text-base">
				<div className="w-full flex justify-between items-center">
					<span className="font-bold">Details:</span>
					<div
						className={clsx(
							'w-fit flex gap-1 justify-center items-center cursor-pointer px-3',
							'hover:bg-custom group rounded-full'
						)}
						aria-hidden
						onClick={() => setUpdateInfoAthleteDrawer(true)}
					>
						<PencilIcon className="w-5 h-5 text-slate-500 group-hover:text-white" />
						<span
							className={clsx(
								'font-Roboto text-slate-500 text-base font-bold',
								'hidden sm:block group-hover:text-white'
							)}
						>
							Edit
						</span>
					</div>
				</div>
				<div className="w-full h-[1px] bg-gray-200 my-[2px]" />
				<InfoAthlete athlete={athlete} />
			</div>
		</div>
	)
}
