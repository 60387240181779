import { BaseURL } from 'api/environment'
import axios from 'axios'

export const deleteNotifications = async (
	token: string,
	notificationId?: number
): Promise<number | null> => {
	let response = null as number | null

	await axios
		.delete(`${BaseURL}/notifications/${notificationId}`, {
			headers: {
				Authorization: `Token ${token}`
			}
		})
		.then(async data => {
			response = data.status
		})
		.catch(error => {
			console.error(error)
		})

	return response
}
