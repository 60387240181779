import React from 'react'
import clsx from 'clsx'

export const MessageError: React.FC = ({ children }): JSX.Element => {
	return (
		<div
			className={clsx(
				'w-full h-5 rounded-xl',
				'font-Roboto text-xs text-red-500 text-left'
			)}
		>
			{children}
		</div>
	)
}
