/* eslint-disable max-len */
import { Switch } from '@headlessui/react'
import React from 'react'
import { Tooltip } from 'components/tooltip'
import { CheckIcon } from '@heroicons/react/solid'
import { ToggleProps } from './Toogle.interface'

const classNames = (...classes: string[]): string => {
	return classes.filter(Boolean).join(' ')
}

export const Toogle: React.FC<ToggleProps> = ({
	enabled,
	setEnabled,
	isDisable,
	withToolTip,
	toolTipText
}) => {
	if (withToolTip) {
		return (
			<Tooltip text={toolTipText}>
				<Switch
					checked={enabled as boolean}
					onChange={setEnabled}
					className={classNames(
						enabled ? 'bg-custom' : 'bg-gray-200',
						'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
						isDisable ? 'cursor-not-allowed' : 'cursor-pointer'
					)}
					disabled={isDisable as boolean}
				>
					<span className="sr-only">Use setting</span>
					<span
						aria-hidden="true"
						className={classNames(
							enabled ? 'translate-x-5' : 'translate-x-0',
							'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
						)}
					/>
				</Switch>
			</Tooltip>
		)
	}

	return (
		<Switch
			checked={enabled as boolean}
			onChange={setEnabled}
			className={classNames(
				enabled ? 'bg-custom' : 'bg-gray-200',
				'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
				isDisable ? 'cursor-not-allowed' : 'cursor-pointer'
			)}
			disabled={isDisable as boolean}
		>
			<span className="sr-only">Use setting</span>
			<span
				aria-hidden="true"
				className={classNames(
					enabled ? 'translate-x-5' : 'translate-x-0',
					'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
				)}
			/>
			<span
				aria-hidden="true"
				className={classNames(
					enabled ? 'translate-x-5' : 'translate-x-0',
					'pointer-events-none inline-block h-5 w-5 transform rounded-full shadow ring-0 transition duration-200 ease-in-out absolute top-[2px] left-[2px]'
				)}
			>
				{enabled && <CheckIcon className="w-4 h-4 fill-custom" />}
			</span>
		</Switch>
	)
}
