import { BaseURL } from 'api/environment'
import axios from 'axios'
import { ProfileResponse } from 'interfaces'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const getProfile = async (): Promise<ProfileResponse | null> => {
	let res = null
	const token = cookies.get('token')
	await axios
		.get(`${BaseURL}/users/profile_by_token/`, {
			headers: {
				Authorization: `Token ${token}`
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
