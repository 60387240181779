import axios from 'axios'
import { TypeMetricType } from 'interfaces/api'
import { BaseURL } from '../environment'

export const getListTypeMetrics = async (
	token: string
): Promise<TypeMetricType[] | null> => {
	let res = null as TypeMetricType[] | null
	await axios
		.get(`${BaseURL}/type_metrics/?ordering=-name`, {
			headers: {
				Authorization: `Token ${token}`
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
