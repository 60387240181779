import { BaseURL } from 'api'
import axios from 'axios'
import { PostProgram, programsType } from 'interfaces'

export const editProgram = async (
	token: string,
	customer: number,
	slug: string | undefined,
	program: PostProgram
): Promise<programsType | null> => {
	let res = null as programsType | null
	await axios
		.patch(
			`${BaseURL}/programs/${slug}/?x-customer-id=${customer}`,
			{ ...program },
			{
				headers: {
					Authorization: `Token ${token}`
				}
			}
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
