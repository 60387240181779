import axios from 'axios'
import { LibraryExerciseResponse } from 'interfaces/api'
import { BaseURL } from '../environment'

export const getListLibraryExercises = async (
	token: string,
	customer: number,
	offset: number,
	search?: string
): Promise<LibraryExerciseResponse | null> => {
	let res = null as LibraryExerciseResponse | null
	await axios
		.get(
			`${BaseURL}/library_exercises/?customer_id=${customer}&ordering=-updated_at&limit=10&offset=${offset}${
				search ? `&search=${search}` : ''
			}`,
			{
				headers: {
					Authorization: `Token ${token}`
				}
			}
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
