import React, { useState } from 'react'
import { ChevronDownIcon, PlusIcon, MinusSmIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { BoxAddExercisesType } from 'interfaces'
import { Disclosure, Transition } from '@headlessui/react'

export const BoxAddExercises: React.FC<BoxAddExercisesType> = ({
	exercise,
	editList,
	className
}): JSX.Element => {
	const [added, setAdded] = useState(false)
	const [counter, setCounter] = useState(1)

	const sumCount = (): void => {
		let aux = counter
		aux += 1
		setCounter(aux)
		editList('sum', exercise)
	}

	const restCount = (): void => {
		let aux = counter
		aux -= 1
		if (aux > 0) setCounter(aux)
		else if (aux === 0) {
			setCounter(1)
			setAdded(false)
		}
		editList('res', exercise)
	}

	return (
		<Disclosure>
			{({ open }) => (
				<div className={clsx('relative', className)}>
					<div className="absolute top-10 right-4 cursor-pointer">
						{added ? (
							<div className="flex items-center">
								<div
									onClick={restCount}
									className={clsx(
										'border-[1px] border-black rounded-[3px] p-1 cursor-pointer text-black',
										'hover:bg-black hover:text-white'
									)}
									aria-hidden
								>
									<MinusSmIcon className={clsx('h-4 w-4')} aria-hidden="true" />
								</div>
								<span className="font-Roboto px-4 decoration-inherit">
									{counter}
								</span>
								<div
									onClick={sumCount}
									className={clsx(
										'border-[1px] border-black rounded-[3px] p-1 cursor-pointer text-black',
										'hover:bg-black hover:text-white'
									)}
									aria-hidden
								>
									<PlusIcon className={clsx('h-4 w-4')} aria-hidden="true" />
								</div>
							</div>
						) : (
							<div
								className={clsx(
									'flex justify-center items-center rounded-[4px]',
									'px-3 py-[5px] border-[1px] border-black min-w-[100px]',
									'hover:bg-black hover:text-white'
								)}
								onClick={() => {
									editList('sum', exercise)
									setAdded(true)
								}}
								aria-hidden
							>
								<h3 className="font-Roboto text-xs">Add exercise</h3>
							</div>
						)}
					</div>
					<Disclosure.Button
						type="button"
						className={clsx(
							'bg-white shadow w-full h-20 cursor-pointer rounded-lg',
							'border-[1px] border-gray-300 border-l-4',
							`${added ? 'border-l-custom' : 'border-l-gray-400'}`,
							'hover:bg-gray-200',
							open ? 'rounded-b-none' : ''
						)}
					>
						<div
							className="w-full h-full flex justify-between px-4 py-3"
							aria-hidden
						>
							<div className="max-w-[60%]">
								<h3
									className={clsx(
										'text-left text-base leading-6text-gray-900 font-Roboto font-bold decoration-inherit line-clamp-2'
									)}
								>
									{exercise.name}
								</h3>
								<div className="text-xs text-gray-400 text-left decoration-inherit line-clamp-1">
									<p>{exercise.description}</p>
								</div>
							</div>
							<div className="flex justify-center items-start">
								{exercise.library_metrics.length > 0 && (
									<div className="w-6 h-6 flex items-start justify-start mb-1 cursor-pointer">
										<ChevronDownIcon
											className={clsx(
												open ? 'rotate-180 transform' : '',
												'h-full w-full text-custom'
											)}
										/>
									</div>
								)}
							</div>
						</div>
					</Disclosure.Button>
					<Transition
						enter="transition duration-100 ease-out"
						enterFrom="transform opacity-0"
						enterTo="transform opacity-100"
						leave="transition duration-75 ease-out"
						leaveFrom="transform opacity-100"
						leaveTo="transform opacity-0"
					>
						{exercise.library_metrics.length > 0 && (
							<Disclosure.Panel className="pb-2 text-sm text-gray-500 bg-white border-[1px] border-t-0 border-gray-300">
								<div className="w-full bg-gray-700 py-[5px] flex items-center justify-center">
									<h1 className="text-white font-Roboto ">
										Units of measurement
									</h1>
								</div>
								<div className="px-2">
									{exercise.library_metrics.map(metric => (
										<div
											key={metric.slug}
											className={clsx(
												'flex items-center justify-between w-full pt-2 pb-1',
												'border-b-[1px] border-b-gray-300 last:border-b-0'
											)}
										>
											<h2 className="font-Roboto font-normal text-sm text-gray-700">
												{metric.type_metric}
											</h2>
											<h2 className="font-Roboto font-bold text-sm text-gray-700">
												{metric.default_value}
											</h2>
										</div>
									))}
								</div>
							</Disclosure.Panel>
						)}
					</Transition>
				</div>
			)}
		</Disclosure>
	)
}
