import React, { useEffect, useState } from 'react'
import {
	Button,
	Drawer,
	DrawerCalendar,
	DrawerHistorial,
	Layout,
	Loading
} from 'components'
import clsx from 'clsx'
import moment from 'moment'
import { EventSourceInput } from '@fullcalendar/core'
import { DocumentTextIcon } from '@heroicons/react/solid'
import './styles.css'
import { InGroupType } from 'interfaces'
import { getInGroups, GET_GROUPS } from 'api'
import { useInfiniteQuery } from 'react-query'
import { useCustomer } from 'hooks'
import Cookies from 'universal-cookie'
import { getOffset } from 'lib'
import { CalendarSchedule } from './calendar'

export const Schedule: React.FC = (): JSX.Element => {
	const customer = useCustomer()

	const cookies = new Cookies()
	const token = cookies.get('token')
	const [openDrawer, setOpenDrawer] = useState(false)
	const [historialDrawer, setHistorialDrawer] = useState(false)
	const [dateSelected, setDateSelected] = useState<Date>()

	const [groupSlug, setGroupSlug] = useState<string>('')
	const [groups, setGroups] = useState<InGroupType[]>([])
	const [events, setEvents] = useState<EventSourceInput | undefined>()

	const [viewType] = useState('dayGridMonth')
	const [currentMonth, setCurrentMonth] = useState(moment().toDate())

	const {
		data: dataGroup,
		refetch: refetchGroup,
		isLoading: isLoadingGroup
	} = useInfiniteQuery(
		[GET_GROUPS, customer.id, token, viewType, currentMonth],
		({ pageParam = 0 }) =>
			getInGroups({
				customerId: customer.id,
				page: pageParam,
				limit: 100
			}),
		{
			getNextPageParam: lastPage =>
				lastPage?.next ? getOffset(lastPage?.next) : undefined
		}
	)

	useEffect(() => {
		if (dataGroup?.pages) {
			let auxData = [] as InGroupType[]
			dataGroup.pages.forEach(page => {
				const auxResults = page?.results as unknown as InGroupType[]
				if (auxResults) auxData = [...auxData, ...auxResults]
			})
			setGroups(auxData)
		}
	}, [dataGroup])

	useEffect(() => {
		let auxEvent = undefined as EventSourceInput | undefined
		auxEvent = groups.map(group => ({
			id: group.id.toString(),
			groupSlug: group.slug.toString(),
			title: group.name,
			date: moment(group.date).format('yyyy-MM-DD'),
			start: moment(group.start_at).format('yyyy-MM-DDTHH:mm:ss'),
			end: moment(group.finish_at).format('yyyy-MM-DDTHH:mm')
		}))
		setEvents(auxEvent)
	}, [groups])

	return (
		<Layout stretch>
			<main className="flex-1 min-h-[calc(100vh-68px)] max-h-[calc(100vh-68px)] overflow-hidden">
				<header className="w-full h-20 flex justify-between items-center px-2 md:px-8 shadow-[0px_2px_3px_rgba(0,0,0,0.16)]">
					<p className="w-full text-4xl font-Roboto font-bold text-[#454952]">
						Schedule
					</p>
					<div className="">
						<Button
							type="button"
							rounded
							customColor
							size="full"
							onClick={() => setHistorialDrawer(true)}
							className="w-40 h-7 md:h-10"
						>
							<div className="flex">
								<DocumentTextIcon className="w-5 h-5" />
								Session log
							</div>
						</Button>
					</div>
				</header>
				<section
					className={clsx(
						'w-full h-[calc(100vh-210px)] min-h-[calc(100vh-220px)] max-h-[calc(100vh-220px)] px-8 py-3',
						'overflow-hidden overflow-y-scroll hide-scroll-bar'
					)}
				>
					{isLoadingGroup ? (
						<div className="w-full h-full flex justify-center items-center">
							<Loading />
						</div>
					) : (
						<CalendarSchedule
							events={events}
							setOpenDrawer={setOpenDrawer}
							setGroupSlug={setGroupSlug}
							setDateSelected={setDateSelected}
							initialDate={currentMonth}
							setCurrentMonth={setCurrentMonth}
						/>
					)}
				</section>
				<Drawer
					open={openDrawer}
					setOpen={(val: boolean) => setOpenDrawer(val)}
					title={`Group Session - ${moment(dateSelected)
						.utc()
						.format('ddd, MMMM DD')}`}
					stretch
					mobileFull
				>
					<DrawerCalendar
						type="general"
						dateSelected={moment(dateSelected).utc().format('yyyy-MM-DD')}
						groupSlug={groupSlug}
						setOpenDrawer={setOpenDrawer}
						handleRefetch={() => refetchGroup()}
					/>
				</Drawer>
				<Drawer
					open={historialDrawer}
					setOpen={(val: boolean) => setHistorialDrawer(val)}
					title="Session log"
					stretch
					mobileFull
				>
					<DrawerHistorial />
				</Drawer>
			</main>
		</Layout>
	)
}
