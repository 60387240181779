import { XIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { useCustomer, useHorizontalScroll } from 'hooks'
import { handleRemoveSet } from 'lib'
import React from 'react'
import { SetsNavProps } from './SetsNav.interface'

export const SetsNav = (props: SetsNavProps): JSX.Element => {
	const {
		groupsDivRef,
		makeDraggableGroupSection,
		removeDraggableGroupSection
	} = useHorizontalScroll()
	const customer = useCustomer()
	const { setsBreakDown, setCurrentSet, currentSet, setSetsBreakDown } = props
	return (
		<div
			className="flex whitespace-nowrap overflow-x-auto show-scrollbar-x mb-5"
			ref={groupsDivRef}
			role="menubar"
			tabIndex={0}
			onMouseDown={makeDraggableGroupSection}
			onMouseUp={removeDraggableGroupSection}
			onMouseLeave={removeDraggableGroupSection}
		>
			{setsBreakDown.map((set, idx) => (
				<button
					className=" px-2 rounded text-white font-semibold mt-2 mr-3 mb-1"
					type="button"
					key={set.slug}
					onClick={() => {
						setCurrentSet(idx)
					}}
					style={{
						backgroundColor:
							idx === currentSet ? 'var(--color-custom)' : '#929BB2'
					}}
				>
					<span className="flex gap-2 min-w-[54px] h-[29px] justify-center">
						<p className="text-lg">Set {idx + 1}</p>{' '}
						{idx === currentSet &&
							customer.role.toUpperCase().includes('ADMIN') && (
								<XIcon
									onClick={e => {
										e.stopPropagation()
										handleRemoveSet({
											slug: set.slug,
											setsBreakDown,
											setCurrentSet,
											setSetsBreakDown
										})
									}}
									className={clsx('w-4 h-4 my-auto rounded-full p-[1px]', {
										'hover:text-red-500': idx === currentSet,
										'hover:bg-gray-300': idx === currentSet
									})}
								/>
							)}
					</span>
				</button>
			))}
			<hr />
		</div>
	)
}
