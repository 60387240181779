/* eslint-disable camelcase */
import {
	Layout,
	Loading,
	NotificationGroupCard,
	NotificationItem,
	SettingsCard
} from 'components'
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import { Icons } from 'interfaces'
import { useQuery } from 'react-query'
import {
	UpdateNotificationsBody,
	UpdateNotificationsResponse,
	getNotificationSettings,
	putNotificationSettings
} from 'api'
import { useCustomer, useToast } from 'hooks'
import Cookies from 'universal-cookie'

export const Settings: React.FC = () => {
	const cookies = new Cookies()
	const token = cookies.get('token')
	const customer = useCustomer()
	const { showToast } = useToast()

	const {
		data: notificationSettings,
		refetch,
		isLoading,
		isFetching
	} = useQuery(['GET_NOTIFICATION_SETTINGS'], () =>
		getNotificationSettings(token, customer.id)
	)

	const [openDropdown, setOpenDropdown] = useState(true)
	const [firstNotificationStatus, setFirstNotificationStatus] = useState<
		boolean | undefined | null
	>((notificationSettings && notificationSettings[0]?.global_status) || false)

	// this useEffec start the value of the global notification
	useEffect(() => {
		setFirstNotificationStatus(
			notificationSettings && notificationSettings[0]?.global_status
		)
	}, [notificationSettings && notificationSettings[0]?.global_status])

	const handleSubmit = async (
		data: UpdateNotificationsBody
	): Promise<UpdateNotificationsResponse | void> => {
		const res = await putNotificationSettings(token, customer.id, data)
		refetch()
		if (res) {
			showToast('Notification settings was updated', '', 'success')
			refetch()
		}
	}

	return (
		<Layout stretch>
			{isLoading || isFetching ? (
				<Loading />
			) : (
				<section
					className={clsx(
						'p-3 lg:p-6 flex flex-col gap-8 bg-[#EEEFF2]',
						!openDropdown && 'h-full'
					)}
				>
					{/* page title */}
					<h3
						className={clsx(
							'text-[#393C44] text-[32px] font-bold',
							'xl:w-[157px] max-w-max border-b-4 border-custom'
						)}
					>
						SETTINGS
					</h3>

					<SettingsCard
						onClick={() => {
							setOpenDropdown(!openDropdown)
						}}
						cardIcon={Icons.bell_icon}
						openDropdown={openDropdown}
						isDropDown
					>
						<NotificationItem
							notificationLabel="Allow upperhand notifications"
							checked={firstNotificationStatus}
							handleToggle={() => {
								setFirstNotificationStatus(!firstNotificationStatus)
								handleSubmit({ global_status: !firstNotificationStatus })
							}}
							isDisable={
								customer.role === 'Client' &&
								notificationSettings &&
								!notificationSettings[0].global_admin_status
							}
							valueForStaffMember={
								notificationSettings &&
								notificationSettings[0].global_admin_status
							}
						/>
					</SettingsCard>

					<div className="flex flex-col-reverse gap-8 pb-28 lg:pb-0">
						{openDropdown &&
							notificationSettings?.slice(1, 3)?.map(notificationGroup => {
								const hasReminder = notificationGroup.data?.some(
									items => items.category === 'reminder'
								)

								return (
									<NotificationGroupCard
										handleSubmit={handleSubmit}
										groupId={notificationGroup.id ?? 0}
										key={notificationGroup.id}
										categoryStatus={notificationGroup.category_status}
										notificationGroup={notificationGroup.notification_group}
										notificationItem={notificationGroup.data}
										withReminders={hasReminder}
										isDisable={
											customer.role === 'Client' &&
											!notificationGroup.admin_category_status
										}
									/>
								)
							})}
					</div>
				</section>
			)}
		</Layout>
	)
}
