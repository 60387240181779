import { useHorizontalScrollResponse } from 'interfaces'
import { useCallback, useRef } from 'react'

export const useHorizontalScroll = (): useHorizontalScrollResponse => {
	const groupsDivRef = useRef<HTMLDivElement>(null)
	const { current } = groupsDivRef

	const moveGroupsSection = (movement: number): void => {
		if (!current) return
		current.scrollLeft += current.clientLeft - movement
	}
	const handleMouseMove = (e: MouseEvent): void => {
		moveGroupsSection(e.movementX)
	}
	const makeDraggableGroupSection = useCallback(() => {
		if (!current) return
		current.addEventListener('mousemove', handleMouseMove)
	}, [])
	const removeDraggableGroupSection = useCallback(() => {
		if (!current) return
		current.removeEventListener('mousemove', handleMouseMove)
	}, [])

	return {
		groupsDivRef,
		moveGroupsSection,
		handleMouseMove,
		makeDraggableGroupSection,
		removeDraggableGroupSection
	}
}
