import { API, SESSION_GROUPS_ENDPOINTS } from 'api'
import { BodyProps } from 'interfaces'

type PatchInGroupsProps = {
	customToken?: string
	body: BodyProps[]
	action: 'create' | 'update' | 'delete'
	customerId: number
}

export const patchInGroups = async ({
	action,
	body,
	customerId
}: PatchInGroupsProps): Promise<string | null> => {
	console.warn('1')
	let res = null as string | null
	await API()
		.patch(SESSION_GROUPS_ENDPOINTS.BASE, body, {
			params: {
				'x-customer-id': customerId,
				action
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)

	return res
}

type PatchInGroupProps = {
	customToken?: string
	body: BodyProps
	actionType: string
	customerId: number
	slug: string
}
export const patchInGroup = async ({
	actionType,
	body,
	customerId,
	slug
}: PatchInGroupProps): Promise<string | null> => {
	console.warn('2', body)
	let res = null as string | null
	await API()
		.patch(`${SESSION_GROUPS_ENDPOINTS.BASE}/${slug}/`, body, {
			params: {
				'x-customer-id': customerId,
				action: actionType
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)

	return res
}
