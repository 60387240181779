import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Button, CompletedAssignedProgramItem, Loading } from 'components'
import {
	AssignedProgramType,
	athleteType,
	CompletedAssignedProgramProps
} from 'interfaces'
import { useCustomer } from 'hooks'
import Cookies from 'universal-cookie'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { getClientById, GET_ATHLETE_BY_ID } from 'api'
import { ClipboardIcon } from '@heroicons/react/outline'

export const CompletedAssignedProgram: React.FC<
	CompletedAssignedProgramProps
> = ({ setCompletedDrawer }): JSX.Element => {
	const customer = useCustomer()
	const params = useParams()
	const cookies = new Cookies()
	const token = cookies.get('token')

	const [completedPrograms, setCompletedPrograms] = useState<
		AssignedProgramType[] | undefined
	>([])

	const [athlete, setAthlete] = useState<athleteType>()

	const { data, isLoading } = useQuery(
		[GET_ATHLETE_BY_ID, customer.id, token],
		() => getClientById(token, customer.id, params.athleteId),
		{
			refetchOnMount: 'always'
		}
	)

	useEffect(() => {
		if (data) setAthlete(data.client)
	}, [data])

	useEffect(() => {
		if (athlete) {
			setCompletedPrograms(
				athlete?.assigned_programs?.filter(item => item.completed === true)
			)
		}
	}, [athlete])

	return (
		<div className="bg-white p-4 flex-1">
			{isLoading ? (
				<Loading />
			) : (
				<div
					className={clsx(
						'flex flex-col gap-2',
						'min-h-[calc(100vh-220px)] max-h-[calc(100vh-220px)]',
						'overflow-hidden hide-scroll-bar overflow-y-scroll',
						'md:min-h-[calc(100vh-180px)] md:max-h-[calc(100vh-180px)]'
					)}
				>
					{!completedPrograms?.length ? (
						<div
							className={clsx(
								'flex items-center gap-4 text-gray-500 text-3xl font-Roboto',
								'bg-white border border-gray-300 rounded-lg p-4 shadow'
							)}
						>
							<ClipboardIcon className="text-custom w-7 h-7" />
							List Empty
						</div>
					) : (
						<div className="flex flex-col gap-2">
							{completedPrograms?.map(completedProgram => {
								return (
									<CompletedAssignedProgramItem
										key={completedProgram.slug}
										slug={completedProgram.slug}
										name={completedProgram.name}
										athleteId={completedProgram?.client_reference_id}
										cover={completedProgram.image}
									/>
								)
							})}
						</div>
					)}
				</div>
			)}
			<Button
				customColor
				size="full"
				type="button"
				className="mt-2 lg:mt-4"
				onClick={() => setCompletedDrawer(false)}
			>
				<div className="flex items-center justify-center gap-2">
					<p className="font-Roboto font-semibold text-base mr-2">Cancel</p>
				</div>
			</Button>
		</div>
	)
}
