/* eslint-disable max-len */
import { Dialog, Transition } from '@headlessui/react'
import {
	createMetricExecuted,
	editAssignedProgram,
	getAssignedProgramBySlug,
	getClientById,
	updateMetricExecuted
} from 'api'
import clsx from 'clsx'
import { Button, Notes, ProgressBar } from 'components'
import { useCustomer, useDevice, useToast } from 'hooks'
import {
	AssignedExecutedMetricType,
	AssignedMetricExecute,
	AssignedProgramResponse,
	DrawerInTrainingProps,
	Images,
	athleteType
} from 'interfaces'
import React, { Fragment, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'
import { DropdownSessionInTraining } from './dropdownSession'

export const DrawerInTraining: React.FC<DrawerInTrainingProps> = ({
	athleteId,
	assignedProgramSlug,
	setOpenDrawer
}): JSX.Element => {
	const refProgram = React.useRef<HTMLDivElement | null>(null)
	const [athlete, setAthlete] = useState<athleteType | null | undefined>(
		undefined
	)
	const [metricsx, setMetricsx] = useState<AssignedExecutedMetricType[]>([])
	const [metricsExecuted, setMetricsExecuted] = useState<
		AssignedMetricExecute | undefined
	>()
	const [metricsExecuted2, setMetricsExecuted2] = useState<
		{
			slugAME: string
			body: AssignedExecutedMetricType
		}[]
	>([])
	const [stats, setStats] = useState<number | undefined>(0)
	const [statsExecute, setStatsExecute] = useState<number | undefined>(0)
	const [slugs, setSlugs] = useState<string[]>([])

	const [modalNotes, setModalNotes] = useState(false)
	const [idSession, setIdSession] = useState(0)
	const [session, setSession] = useState<string>('')
	const [sessionsCompleted, setSessionsCompleted] = useState<string[]>([])

	const [lastData, setLastData] = useState({
		programSlug: '',
		sessionSlug: '',
		exerciseSlug: ''
	})
	const [lastScroll, setLastScroll] = useState({
		programSlug: '',
		sessionSlug: '',
		exerciseSlug: ''
	})

	const cookies = new Cookies()
	const token = cookies.get('token')
	const { showToast } = useToast()
	const device = useDevice()

	const customer = useCustomer()

	const { data: data2 } = useQuery(
		[customer.id, athleteId, token],
		() => getClientById(token, customer.id, athleteId.toString()),
		{
			refetchOnMount: 'always'
		}
	)

	const [assignedProgram2, setAssignedProgram2] = useState<
		AssignedProgramResponse | null | undefined
	>(undefined)

	const { data: assignProgram, refetch } = useQuery(
		[],
		() =>
			getAssignedProgramBySlug({
				assignProgramSlug: assignedProgramSlug,
				stats: true
			}),
		{
			refetchOnMount: 'always'
		}
	)

	const [dataRequestPost, setDataRequestPost] = useState<{
		assigned_program_slug: string | undefined
		assigned_executed_metrics: (AssignedExecutedMetricType | undefined)[]
	}>()
	const [dataRequestPut, setDataRequestPut] = useState<
		{
			slugAME: string
			body: AssignedExecutedMetricType
		}[]
	>([])
	const [typeRequest, setTypeRequest] = useState('')

	useEffect(() => {
		setAssignedProgram2(assignProgram)
	}, [assignProgram])

	useEffect(() => {
		setAthlete(data2?.client)
	}, [data2])

	useEffect(() => {
		setStats(assignedProgram2?.assigned_program_stats?.exercises)
		setStatsExecute(assignedProgram2?.assigned_program_stats?.exercises_execute)
	}, [
		assignedProgram2?.assigned_program_stats?.exercises,
		assignedProgram2?.assigned_program_stats?.exercises_execute
	])

	useEffect(() => {
		if (assignedProgram2?.assigned_program_stats?.detail_sessions_execute)
			setSessionsCompleted(
				assignedProgram2?.assigned_program_sessions.map(it =>
					assignedProgram2?.assigned_program_stats?.detail_sessions_execute.includes(
						it.slug
					)
						? it.slug
						: ''
				)
			)

		if (assignedProgram2) {
			setLastData({
				...lastData,
				programSlug: assignedProgram2.slug
			})

			if (!assignedProgram2.last_fields_modified) {
				setLastScroll({
					// ...lastScroll,
					programSlug: assignedProgram2.slug,
					sessionSlug: assignedProgram2.assigned_program_sessions?.[0].slug,
					exerciseSlug:
						assignedProgram2.assigned_program_sessions?.[0]
							.assigned_exercises?.[0].slug
				})
			} else {
				setLastScroll(assignedProgram2.last_fields_modified)
			}
		}
	}, [assignedProgram2, assignedProgramSlug])

	useEffect(() => {
		setMetricsExecuted({
			assigned_program_slug: assignedProgramSlug,
			assigned_executed_metrics: metricsx
		})
	}, [metricsx])

	useEffect(() => {
		const result = metricsx.reduce<string[]>((acc: string[], item) => {
			if (!acc.includes(item.assigned_metric)) {
				acc.push(item.assigned_metric)
			}
			return acc
		}, [])

		setSlugs(result)
	}, [metricsx])

	const handleChangeMetrics = (slug: string, value: string): void => {
		setMetricsx(prev => [
			...prev,
			{
				assigned_metric: slug,
				value
			}
		])
	}

	useEffect(() => {
		const assMeEx = slugs.map(slug => {
			return metricsExecuted?.assigned_executed_metrics
				?.filter(item => item?.assigned_metric === slug)
				.slice(-1)?.[0]
		})

		metricsExecuted2.map(item => {
			if (!item.slugAME) {
				setTypeRequest('post')
				setDataRequestPost({
					assigned_program_slug: metricsExecuted?.assigned_program_slug,
					assigned_executed_metrics: assMeEx
				})
			} else {
				setTypeRequest('put')
				setDataRequestPut(metricsExecuted2)
			}
			return 0
		})
	}, [metricsExecuted, metricsExecuted2])

	const handleSubmit = async (): Promise<void> => {
		try {
			if (typeRequest === 'post') {
				if (dataRequestPost) {
					await createMetricExecuted(token, dataRequestPost)
					showToast(
						'Set Values',
						'metrics was assigned successfully',
						'success'
					)
				}
			} else {
				dataRequestPut.map(async item => {
					const res = await updateMetricExecuted(
						token,
						item?.slugAME,
						item?.body
					)
					if (res) {
						showToast(
							'Set Values',
							'metrics was updated successfully',
							'success'
						)
					}
				})
			}
			const editedAssignedProgram = await editAssignedProgram({
				slug: assignedProgramSlug,
				archived: assignedProgram2?.archived,
				last_fields_modified: lastData
			})
			if (editedAssignedProgram) {
				showToast(
					'Update',
					'assigned program was updated successfully',
					'success'
				)
			}
			setOpenDrawer(false)
			refetch()
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<div
			id={assignedProgram2?.slug}
			ref={refProgram}
			className={clsx(
				'flex-1 relative min-h-[calc(100vh-135px)] max-h-[calc(100vh-135px)]',
				'lg:min-h-[calc(100vh-70px)] lg:max-h-[calc(100vh-70px)]'
			)}
		>
			<div
				className={clsx(
					'flex-1 flex-col shadow-[0px_4px_20px_rgba(0,0,0,0.16)]  h-[175px]',
					'relative rounded-lg'
				)}
			>
				<img
					src={assignedProgram2?.image || Images.program_cover}
					alt="cover"
					className="object-cover w-full h-[175px]"
				/>
				<div className="absolute inset-0 min-h-[175px] p-4 flex flex-col">
					<div className="font-bold font-Roboto text-2xl text-white h-[50%] flex items-center line-clamp-2">
						{assignedProgram2?.name}
					</div>
					<div>
						<div className="flex items-center">
							<img
								src={athlete?.profile_image?.url || Images.default_avatar}
								alt=""
								className="h-[40px] w-[40px]"
							/>
							<span className="font-normal font-Roboto text-md text-white h-[50%] flex items-center ml-4">
								{athlete?.first_name} {athlete?.last_name}
							</span>
						</div>
						<div className="font-normal font-Roboto text-md text-white h-[50%] flex items-center">
							{`${assignedProgram2?.assigned_program_stats?.exercises_execute}/
						${assignedProgram2?.assigned_program_stats?.exercises}
						completed exercises`}
						</div>
						<ProgressBar
							steps={
								assignedProgram2?.assigned_program_stats?.exercises
									? assignedProgram2?.assigned_program_stats.exercises
									: 0
							}
							percent={(100 * (statsExecute || 0)) / (stats || 0)}
						/>
					</div>
				</div>
			</div>
			<div
				className={clsx(
					'p-4 flex-1',
					{
						'min-h-[calc(100vh-250px)] max-h-[calc(100vh-250px)]':
							device === 'web'
					},
					{
						'min-h-[calc(100vh-380px)] max-h-[calc(100vh-380px)]':
							device === 'android' || device === 'ios'
					},
					'min-h-[calc(100vh-380px)] max-h-[calc(100vh-380px)]',
					'overflow-hidden hide-scroll-bar overflow-y-scroll'
				)}
			>
				<div className="flex flex-col gap-3">
					{assignedProgram2?.assigned_program_sessions?.map(item => (
						<div key={item.id}>
							<DropdownSessionInTraining
								isCompletedSession={item.is_completed}
								item={item}
								sessionsCompleted={sessionsCompleted}
								setSession={setSession}
								setIdSession={setIdSession}
								setModalNotes={setModalNotes}
								handleChangeMetrics={handleChangeMetrics}
								lastData={lastData}
								setLastData={setLastData}
								lastScroll={lastScroll}
								setMetricsExecuted2={setMetricsExecuted2}
								setOpenDrawer={setOpenDrawer}
								assignedProgramSlug={assignedProgramSlug}
								refetchData={() => refetch()}
							/>
						</div>
					))}
				</div>
			</div>
			<div className="absolute bottom-0 left-0 right-0 p-4">
				<Button
					type="submit"
					size="full"
					className="w-full"
					customColor
					onClick={() => handleSubmit()}
				>
					<div className="flex items-center justify-center">
						<p className="font-Roboto font-semibold text-base mr-2">Continue</p>
					</div>
				</Button>
			</div>
			<Transition appear show={modalNotes} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-30 inset-0"
					onClose={() => setModalNotes(false)}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<div className="w-[440px] h-[390px] bg-white rounded-lg border border-[#B5BACE]">
									<Notes
										idSession={idSession}
										session={session}
										setModalNotes={setModalNotes}
									/>
								</div>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</div>
	)
}
