import axios from 'axios'
import { NotesResponseType } from 'interfaces'
import { BaseURL } from '../environment'

export const getListNotes = async (
	token: string,
	idSession: number
): Promise<NotesResponseType[] | null> => {
	let res = null as NotesResponseType[] | null
	await axios
		.get(`${BaseURL}/session_annotations/?session=${idSession}`, {
			headers: {
				Authorization: `Token ${token}`
			}
		})
		.then(
			async response => {
				res = response.data.results
			},
			error => {
				console.error(error)
			}
		)

	return res
}
