import React, { useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { BoxExercisesType, Icons } from 'interfaces'
import { Icon } from 'components/icon'

export const BoxExercises: React.FC<BoxExercisesType> = ({
	exercise,
	deleted,
	edit,
	className,
	innerRef,
	dragProvided
}): JSX.Element => {
	const { slug, name, description, metrics } = exercise
	const [open, setOpen] = useState(false)

	return (
		<div
			className={clsx('relative', className)}
			ref={innerRef}
			{...dragProvided?.draggableProps}
			{...dragProvided?.dragHandleProps}
			onClick={() => setOpen(!open)}
			aria-hidden="true"
		>
			<div className="absolute top-[10px] right-[17px]">
				<div className="flex items-center justify-end mb-1">
					<button
						className="max-h-[16px] max-w-[16px] flex items-center justify-center hover:text-custom mt-1"
						type="button"
						onClick={() => deleted(slug)}
					>
						<Icon
							fillPath
							className={clsx('min-h-[16px] min-w-[16px]')}
							src={Icons.trash}
						/>
					</button>
					{exercise.metrics.length > 0 && (
						<ChevronDownIcon
							className={clsx('h-6 w-6 text-custom ml-4')}
							aria-hidden="true"
						/>
					)}
				</div>
				<button
					type="button"
					onClick={() => edit(exercise)}
					className={clsx(
						'px-3 py-[5px] border-[1px] border-black min-w-[100px]',
						'hover:bg-black hover:text-white'
					)}
				>
					<h3 className="font-Roboto text-xs">Edit</h3>
				</button>
			</div>
			<div
				className={clsx(
					'bg-white shadow w-full cursor-pointer rounded-lg',
					'border-[1px] border-gray-300 border-l-4 border-l-custom',
					'hover:bg-gray-200'
				)}
			>
				<div className="flex justify-between px-4 py-3">
					<div className="max-w-[60%]">
						<h3 className="text-left text-base leading-6text-gray-900 font-Roboto font-bold line-clamp-2">
							{name}
						</h3>
						<div className="text-xs text-gray-400 text-left line-clamp-2">
							<p>{description}</p>
						</div>
					</div>
					<div className="flex justify-center items-center flex-col">
						<div className="h-12 w-6" />
					</div>
				</div>
			</div>
			{open && exercise.metrics.length > 0 && (
				<div className="pb-2 text-sm text-gray-500 bg-white border-[1px] border-t-0 border-gray-300">
					<div className="w-full bg-gray-700 py-[5px] flex items-center justify-center">
						<h1 className="text-white font-Roboto ">Units of measurement</h1>
					</div>
					<div className="px-2">
						{metrics.map(metric => (
							<div
								key={metric.slug}
								className={clsx(
									'flex items-center justify-between w-full pt-2 pb-1',
									'border-b-[1px] border-b-gray-300 last:border-b-0'
								)}
							>
								<h2 className="font-Roboto font-normal text-sm text-gray-700">
									{metric.type_metric_info.name}
								</h2>
								<h2 className="font-Roboto font-bold text-sm text-gray-700">
									{metric.default_value}
								</h2>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	)
}
