export const notificationMock: {
	id: number
	subject: string
	name: string | null
	message: string | null
	notification_type: string
	customer: {
		id: number
		name: string
		image: string
	}
	trainer: {
		id: number
		name: string
		image: string
	}
	info: {
		duration: number | null
		date_time: Date
		exercises: number | null
		start_date: Date
	}
	is_seen: boolean
}[] = [
	{
		id: 24,
		notification_type: 'Group Session',
		subject: 'New Group Session',
		name: null,
		message: 'This is a test',
		customer: {
			id: 3,
			name: "Rony's Group",
			image:
				// eslint-disable-next-line max-len
				'https://upperhand-app.s3.amazonaws.com/uFjKEzunEdeYDqRAzXf5G1af?response-content-disposition=inline%3B%20filename%3D%221223_Athletic_Edge_Test_v20170718_cropped.png%22%3B%20filename%2A%3DUTF-8%27%271223_Athletic_Edge_Test_v20170718_cropped.png&response-content-type=image%2Fpng&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJZK7P2TVPBCKYLTQ%2F20230928%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20230928T162023Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Signature=2f4bab04759693bafbcb1cccfaa76acab0132fe20154b14b3a6f19d39b200e1d'
		},
		trainer: {
			id: 2,
			name: 'John Doe',
			image:
				'https://upper-athletes-bti.s3.amazonaws.com/client_images/07bc8eae-5cd4-441e-ba47-b7a5f284fbca.png'
		},
		info: {
			duration: 8,
			date_time: new Date('2023-09-15T10:00:00'),
			exercises: 8,
			start_date: new Date('2023-09-15T10:00:00')
		},
		is_seen: true
	}
]
