import React, { useState } from 'react'
import { DrawerSetValuesConfigProps, metricsToSendInterface } from 'interfaces'
import clsx from 'clsx'
import { Button, OptionMetric } from 'components'
import { editAsignedMetrics } from 'api'
import { useDevice, useHorizontalScroll, useToast } from 'hooks'
import Cookies from 'universal-cookie'

export const DrawerSetValuesConf: React.FC<DrawerSetValuesConfigProps> = ({
	exercise,
	athleteInfo,
	setOpen,
	refetchList,
	refetchAssignedProgram,
	setConfig
}): JSX.Element => {
	const { showToast } = useToast()
	const {
		groupsDivRef,
		makeDraggableGroupSection,
		removeDraggableGroupSection
	} = useHorizontalScroll()
	const defaultURL = '/asset/images/default_avatar.png'
	const device = useDevice()
	const cookies = new Cookies()
	const token = cookies.get('token')

	const [currentSet, setCurrentSet] = useState(0)
	const [arrayOfSets, setArrayOfSets] = useState(exercise.assigned_sets)
	const [metricsToSend, setMetricsToSend] = useState<metricsToSendInterface[]>(
		[]
	)

	const handleChangeMetrics = (slug: string, value: string): void => {
		/* UPDATE METRICS */
		const updatedSets = arrayOfSets.map((itemSet, index) => {
			const updatedMetricsArray = itemSet.assigned_metrics.map(item => {
				if (item.slug === slug) {
					return {
						...item,
						value
					}
				}
				return item
			})

			if (index === currentSet) {
				return {
					...itemSet,
					assigned_metrics: updatedMetricsArray
				}
			}
			return itemSet
		})

		setArrayOfSets(updatedSets)

		/* CREATE ARRAY OF ALL METRICS */
		const arrayToSend = []
		for (let index = 0; index < updatedSets.length; index += 1) {
			arrayToSend.push(...updatedSets[index].assigned_metrics)
		}
		setMetricsToSend(
			arrayToSend.map(metricToSend => ({
				assigned_metric: metricToSend.slug,
				value: metricToSend.value
			}))
		)
	}

	const handleSubmit = async (): Promise<void> => {
		try {
			const res = await editAsignedMetrics(token, metricsToSend)
			if (res?.length) {
				refetchList()
				showToast('Edit metric', 'metric was edited successfully', 'success')
				setOpen(false)
				setConfig(true)
				refetchAssignedProgram()
			} else {
				showToast(
					'Error editing metrics',
					'The metric was not edited',
					'warning'
				)
			}
		} catch (error) {
			console.error(error)
			setConfig(false)
		}
	}

	return (
		<div className="flex-1">
			{/* EXERCISE NAME AND DESCRIP */}
			<div className="flex flex-col">
				<h1 className="font-Roboto font-bold text-lg">{exercise.name}</h1>
				<h3 className="font-Roboto font-medium text-sm">
					{exercise.description}
				</h3>
			</div>

			{/* ATLETHE CARD */}
			<div className="h-[1px] w-full bg-gray-300 my-2" />
			<div className="my-4">
				<h3 className="font-Roboto font-medium text-sm">Athlete</h3>
			</div>
			<div className="w-full border-[1px] border-gray-600 flex p-[2px]">
				<div className="w-[10%]">
					<img
						alt={athleteInfo.first_name}
						src={
							athleteInfo.profile_image && athleteInfo.profile_image.url
								? athleteInfo.profile_image.url
								: defaultURL
						}
						className="w-9 h-9 max-w-[36px]"
						onError={({ currentTarget }) => {
							// eslint-disable-next-line no-param-reassign
							currentTarget.src = defaultURL
						}}
					/>
				</div>
				<div className="w-[90%] flex items-center justify-center">
					<h3 className="font-Roboto font-medium text-sm">{`${athleteInfo.first_name}  ${athleteInfo.last_name}`}</h3>
				</div>
			</div>

			{/* SETS NAVIGATION */}
			<div
				className="flex whitespace-nowrap overflow-x-auto show-scrollbar-x"
				ref={groupsDivRef}
				role="menubar"
				tabIndex={0}
				onMouseDown={makeDraggableGroupSection}
				onMouseUp={removeDraggableGroupSection}
				onMouseLeave={removeDraggableGroupSection}
			>
				{arrayOfSets.map((set, idx) => (
					<button
						className=" px-2 rounded text-white font-semibold mt-2 mr-3 mb-1"
						type="button"
						key={set.slug}
						onClick={() => {
							setCurrentSet(idx)
							setCurrentSet(idx)
						}}
						style={{
							backgroundColor:
								idx === currentSet ? 'var(--color-custom)' : '#929BB2'
						}}
					>
						<span className="flex gap-2 min-w-[54px] h-[29px] justify-center">
							<p className="text-lg">Set {idx + 1}</p>{' '}
						</span>
					</button>
				))}
			</div>

			{/* SETS METRICS */}
			<section
				className={clsx(
					'flex-1 pt-3',
					{
						'min-h-[calc(100vh-320px)] max-h-[calc(100vh-320px)]':
							device === 'web'
					},
					{
						'min-h-[calc(100vh-410px)] max-h-[calc(100vh-410px)]':
							device === 'android' || device === 'ios'
					},
					'min-h-[calc(100vh-410px)] max-h-[calc(100vh-410px)]',
					'overflow-hidden overflow-y-auto'
				)}
			>
				{arrayOfSets[currentSet].assigned_metrics.map(metric => {
					return (
						<div key={metric.slug}>
							<OptionMetric
								key={metric.slug}
								item={metric}
								type={metric.type_metric.type}
								value={metric.value}
								changeValue={handleChangeMetrics}
							/>
						</div>
					)
				})}
			</section>

			{/* SUBMIT BUTTON */}
			<Button
				customColor
				size="full"
				type="submit"
				className="my-4"
				onClick={() => handleSubmit()}
			>
				<div className="flex items-center justify-center">
					<p className="font-Roboto font-semibold text-base mr-2">Set Values</p>
				</div>
			</Button>
		</div>
	)
}
