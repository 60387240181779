import { BaseURL } from 'api/environment'
import axios from 'axios'
import { ExerciseType } from 'interfaces'

type AssignedExecutedMetric = {
	assigned_metric: string
	value: string
}

export const editAsignedMetrics = async (
	token: string,
	body: (AssignedExecutedMetric | undefined)[]
): Promise<ExerciseType[] | null> => {
	let res = [] as ExerciseType[] | null
	await axios
		.put(`${BaseURL}/assigned_metrics/update_metrics/`, body, {
			headers: {
				Authorization: `Token ${token}`
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
