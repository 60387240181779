import React, { useEffect, useRef, useState } from 'react'
import { Modal, Icon, Drawer, Tooltip } from 'components'
import { Icons } from 'interfaces'
import clsx from 'clsx'
import './VideoExerciseStyles.css'
import { formatLongName } from 'lib'
import Cookies from 'universal-cookie'
import { uploadVideo } from 'api'
import { videoFormExerciseProps } from './VideoExercise.interface'

export const VideoFormExercise = (
	props: videoFormExerciseProps
): JSX.Element => {
	const { video, setVideoUrl } = props
	const cookies = new Cookies()
	const token = cookies.get('token')

	const [filled, setFilled] = useState(0)
	const [loading, setLoading] = useState(false)
	const [openDeleteModal, setOpenDeleteModal] = useState(false)
	const [openVideoDrawer, setOpenVideoDrawer] = useState(false)
	const [sizeError, setSizeError] = useState(false)
	const [videoResponse, setVideoResponse] = useState(video)

	const inputFileRef = useRef<HTMLInputElement>(null)

	/* VIDEO CRUD */
	const handleFileChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	): Promise<void> => {
		const file = event.target.files && event.target.files[0]
		const body = {
			video_file: file
		}
		if (file) {
			setSizeError(false)
			setVideoResponse(null)
			setFilled(0)
			if (file.size / (1024 * 1024) < 5) {
				setLoading(true)
				const response = await uploadVideo({ token, body })
				if (response) {
					setLoading(false)
					setSizeError(false)
					setVideoUrl(response.url)
					setVideoResponse({
						name: response.name ?? '',
						url: response.url ?? ''
					})
				} else {
					console.error('ERROR UPLOADING FILE')
				}
			} else {
				setSizeError(true)
			}
		}
	}
	const handleFileRemove = (): void => {
		setFilled(0)
		setVideoResponse(null)
		setVideoUrl(null)
		if (inputFileRef.current) inputFileRef.current.value = ''
	}

	/* LOADING BAR LOGIC */
	useEffect(() => {
		if (filled < 100 && loading) {
			setTimeout(() => {
				setFilled(filled + 1)
			}, 10)
		}
	}, [filled, loading])

	return (
		<div>
			{/* TITLE OF THE SECTION */}
			<div className="flex">
				<p className="text-xs mb-1 font-Roboto font-bold md:text-lg">
					Upload the example video for the exercise (Optional)
				</p>
				<Tooltip
					text={`Capture the full picture!${'\n'}Upload your videos in horizontal format.`}
				>
					<Icon
						className="w-4 h-4 md:w-5 md:h-5 md:mt-1 ml-2 text-custom"
						src={Icons.help}
						fillPath
					/>
				</Tooltip>
			</div>

			{/* VIDEO BUTTON */}
			<div>
				{!videoResponse?.url || loading ? (
					/* BEFORE SELECT AND LOADING BTN */
					<label
						htmlFor="files"
						className={clsx(
							'bg-[#929BB2] w-full font-Roboto font-bold text-white py-3 rounded-lg flex justify-center'
						)}
					>
						<div className="flex justify-center relative w-full">
							<div
								style={{ width: `${filled}%`, transition: 'width 0.5s' }}
								className={clsx(
									'h-[49px] left-0 -top-3 w-4 bg-custom absolute rounded-lg',
									!loading && videoResponse && 'hidden'
								)}
							/>
							<Icon
								src={Icons.addVideo}
								className={clsx(
									'h-5 w-5 mr-2 z-10',
									loading && 'hidden',
									videoResponse && 'hidden'
								)}
							/>
							<p
								className={clsx(
									'fadeOut',
									loading && 'fadeOut-active',
									videoResponse && !loading && 'hidden'
								)}
							>
								Upload video (Format. Mp4, Mov)
							</p>
							<p
								className={clsx(
									'fadeIn h-4',
									loading && 'fadeIn-active',
									videoResponse && !loading && 'hidden'
								)}
							>
								Uploading ...
							</p>
						</div>
					</label>
				) : (
					/* SELECTED VIDEO BTN */
					<div className="flex">
						<label
							htmlFor="files"
							className="bg-custom w-full font-Roboto font-bold text-white py-3 rounded-lg flex justify-center"
						>
							<p>{formatLongName(videoResponse.name)} Uploaded</p>
							<button
								type="button"
								onClick={e => {
									e.preventDefault()
									setOpenDeleteModal(true)
								}}
							>
								<Icon
									src={Icons.trash}
									className="h-5 w-5 ml-2 rounded-full hover:text-red-500 hover:bg-white !p-[3px]"
									fillPath
								/>
							</button>
						</label>
						<button
							type="button"
							className="w-auto bg-custom rounded-lg px-4 py-2 ml-3"
							onClick={() => setOpenVideoDrawer(true)}
						>
							<Icon src={Icons.playVideo} className="w-6 h-6" />
						</button>
					</div>
				)}
				<input
					id="files"
					className="hidden"
					disabled={videoResponse !== null}
					accept=".mp4, .mov"
					type="file"
					ref={inputFileRef}
					onChange={handleFileChange}
				/>
				{sizeError && (
					<div className="flex mt-1">
						<Icon
							src={Icons.error}
							className="w-6 h-6 text-red-600 mr-2"
							fillPath
						/>
						<p className="text-red-600 text-xs mb-1 font-Roboto font-bold md:text-lg">
							Maximum video size exceeded (Maximum 5 MB)
						</p>
					</div>
				)}
			</div>

			{/* MODAL DELETE CONFIRMATION */}
			<Modal
				type="danger"
				open={openDeleteModal}
				setOpen={(val: boolean) => setOpenDeleteModal(val)}
				handleClick={() => {
					handleFileRemove()
					setOpenDeleteModal(false)
				}}
			>
				<>
					<h3 className="text-lg leading-6 font-medium text-gray-900">
						Delete
					</h3>
					<div className="mt-2">
						<p className="text-sm text-gray-500">
							Are you sure you want to delete the video of this exercise?
						</p>
					</div>
				</>
			</Modal>

			{/* DRAWER PLAYER */}
			<Drawer
				open={openVideoDrawer}
				setOpen={(val: boolean) => setOpenVideoDrawer(val)}
				title="TRAINING VIDEO EXERCISE"
				stretch
				mobileFull
			>
				<div className="mt-8 mx-6">
					<video
						width="100%"
						controls
						autoPlay
						loop
						muted
						className="rounded-lg max-h-[500px]"
						src={videoResponse?.url}
					/>
				</div>
			</Drawer>
		</div>
	)
}
