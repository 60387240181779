import { BaseURL } from 'api/environment'
import { GetNotificationsResponse } from 'api/get'
import axios from 'axios'

export const updateNotifications = async (
	token: string,
	notificationId: number
): Promise<GetNotificationsResponse | null> => {
	let response = null as GetNotificationsResponse | null

	await axios
		.patch(
			`${BaseURL}/notifications/${notificationId}/`,
			{},
			{
				headers: {
					Authorization: `Token ${token}`
				}
			}
		)
		.then(async data => {
			response = data
		})
		.catch(error => console.error(error))

	return response
}
