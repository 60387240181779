import { BaseURL } from 'api/environment'
import axios from 'axios'
import { GetNotificationsByIdResponse } from './getNotificationById.interface'

export const getNotificationsById = async (
	token: string,
	notificationId?: string
): Promise<GetNotificationsByIdResponse | null> => {
	let response = null as GetNotificationsByIdResponse | null

	await axios
		.get(`${BaseURL}/notifications/${notificationId}`, {
			headers: {
				Authorization: `Token ${token}`
			}
		})
		.then(async data => {
			response = data.data
		})
		.catch(error => console.error(error))

	return response
}
