import { BaseURL } from 'api/environment'
import axios from 'axios'

export const deleteProgramSession = async (
	token: string,
	slug: string
): Promise<number | null> => {
	let res = null as number | null
	if (slug)
		await axios
			.delete(`${BaseURL}/program_sessions/${slug}/`, {
				headers: {
					Authorization: `Token ${token}`
				}
			})
			.then(
				async response => {
					res = response.status
				},
				error => {
					res = error.status
					console.error(error)
				}
			)
	return res
}
