/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { DraggableProvided } from 'react-beautiful-dnd'
import { FieldError, UseFormRegister, UseFormSetValue } from 'react-hook-form'
import {
	QueryObserverResult,
	RefetchOptions,
	RefetchQueryFilters
} from 'react-query'
import {
	AssignedExecutedMetricType,
	AssignedExercise,
	AssignedMetric,
	AssignedProgramType,
	AthleteByIdResponse,
	LibraryExerciseMetritcPost,
	NotesResponseType,
	TypeMetricType,
	athleteType,
	clientAssignedProgramsType,
	exerciseType,
	libraryExerciseType,
	librarySetsType,
	sessionType,
	setType,
	AssignedProgramSession,
	AssignedProgramResponse
} from './api'
import { AssinedAthetesInputs } from './form'
import { IconsType } from './media'

export interface LayoutProps {
	title?: string
	children: JSX.Element
	isLoading?: boolean
	className?: string
	stretch?: boolean
	back?: boolean
	tabs?: boolean
}

export enum SessionStatus {
	Normal = 1,
	Injured = 2,
	LackOfTime = 3,
	Reluctant = 4,
	NoEquipment = 5,
	Other = 6
}

export interface LoadingProps {
	isSmall?: boolean
}
export interface ButtonProps {
	label?: string
	disabled?: boolean
	color?: 'Primary' | 'Tranparent' | 'Secondary'
	customColor?: true
	rounded?: boolean
	size?: 'extra-small' | 'small' | 'medium' | 'full'
	iconLeft?: IconsType
	iconRight?: IconsType
	linkTo?: string
	className?: string
	children?: JSX.Element
	fill?: boolean
}

export interface ProgramProps {
	id: string
	title: string
	sessions: number
	active?: boolean
	cover?: string | null
	own: boolean
	clickCopy: (program: string) => void
	clickDelete: (program: string) => void
}
export interface IconProps {
	src: IconsType | undefined
	className?: string
	pointer?: boolean
	fillPath?: boolean
	fillLine?: boolean
	fillCircle?: boolean
	fillRect?: boolean
}

export type PaginationType = {
	currentPage: number
	totalPages: number
	pageLimit: number
	totalRecords: number
}

export interface PaginateProps {
	totalRecords: number
	pageLimit?: number
	pageNeighbours?: number
	page?: number
	onPageChanged: (data: PaginationType) => void
}

export interface InputProps {
	name: string
	title?: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	register?: UseFormRegister<any>
	rules?: Record<string, unknown>
	error?: FieldError | undefined
	showEye?: boolean
	rightIcon?: JSX.Element
	leftIcon?: React.ReactNode
	rounded?: boolean
	squared?: boolean
	asterisk?: boolean
	prefix?: string
	sufix?: string
	transparent?: boolean
	strech?: boolean
	cursorDisable?: boolean
	fontSize?: 'small' | 'large' | 'extra-large'
	align?: 'center' | 'left' | 'right'
	rightClick?: () => void
	leftClick?: () => void
	customClick?: () => void
}

export interface InputListProps {
	name: string
	title?: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	register?: UseFormRegister<any>
	rules?: Record<string, unknown>
	error?: FieldError | undefined
	rounded?: boolean
	squared?: boolean
	asterisk?: boolean
	cursorDisable?: boolean
	disableList?: boolean
	fontSize?: 'small' | 'large' | 'extra-large'
	align?: 'center' | 'left' | 'right'
	list: ListElemetProps[]
	defaultValue?: ListElemetProps
	setItem?: (itemToChange: ListElemetProps) => void
	isMetric?: boolean
	inTraining?: boolean
	noBorders?: boolean
	isInjury?: boolean
}

export interface InputTimeProps {
	name: string
	title?: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	register?: UseFormRegister<any>
	rules?: Record<string, unknown>
	error?: FieldError | undefined
	rounded?: boolean
	squared?: boolean
	asterisk?: boolean
	fontSize?: 'small' | 'large' | 'extra-large'
	defaultValue?: string
	setItem?: React.Dispatch<React.SetStateAction<string>>
}

export interface LinkProps {
	label: string
	linkTo: string
	className?: string
}

export interface BoxCustomerType {
	title: string
	rol: string
	className?: string
	onClick?: () => void
}

export interface BoxToolsType {
	type: 'programs' | 'athletes' | 'training' | 'schedule'
}

export interface OptionTap {
	name: string
	panel: () => JSX.Element
}
export interface TabsType {
	tabs: OptionTap[]
}

export interface BoxLibraryExercisesType {
	exercise: libraryExerciseType
	className?: string
	deleted: (slug: string) => void
	clone: (slug: string) => void
	edit: (exercise: libraryExerciseType) => void
}

export interface DrawerType {
	title: string
	open: boolean
	setOpen: (val: boolean) => void
	stretch?: boolean
	mobileFull?: boolean
	children: JSX.Element
}

export interface Media {
	url: string
	file: File
}

export interface BoxSessionType {
	session: sessionType
	clone: (slug: string) => void
	deleted: (slug: string) => void
	config: (sessionVal: sessionType) => void
	className?: string
}

export interface ModalType {
	type: 'success' | 'danger' | 'warning'
	open: boolean
	setOpen: (val: boolean) => void
	handleClick?: () => void
	children: JSX.Element
}

export interface modalStatusExercise {
	open: boolean
	setOpen: (val: boolean) => void
	setValue: (val: { slug: string; status: number }) => void
	value: { slug: string; status: number }
	slug: string
	defaultValue: number
	refetchData: () => void
}

export interface BoxAthleteType {
	id: number
	image: string | undefined
	fullname: string
	status?: boolean
	assignedPrograms?: AssignedProgramType[]
	refetchUp: () => void
	onClick?: () => void
}

export interface BoxExercisesType {
	exercise: exerciseType
	deleted: (slug: string) => void
	edit: (exercise: exerciseType) => void
	className?: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	innerRef?: (element?: HTMLElement | null | undefined) => any
	dragProvided?: DraggableProvided
}

export interface BoxAddExercisesType {
	exercise: libraryExerciseType
	editList: (type: 'sum' | 'res', exercise: libraryExerciseType) => void
	className?: string
}

export interface AssignProgramType {
	slug: string
	name: string
	sessions: number
	active: string
	cover?: string | null
	onClick: (slug: string) => void
}

export interface MetricExerciseType {
	slug: string
	typeForm: 'create' | 'edit' | 'editSession' | 'inTraining'
	listMetric: TypeMetricType[]
	defaultList?: string
	defaultVal?: string
	deleteMetric: () => void
	changeMetric: (slug: string, type: string, value: string) => void
	isInjury?: boolean
}

export type ListElemetProps = {
	id: string
	name: string
	value: string
}

export type ProgressBarProps = {
	percent: number
	steps: number
}

export interface OptionsProps {
	item: AssignedMetric
	type: string
	value: string
	changeValue: (slug: string, value: string) => void
	setChangeAssignedMetric?: React.Dispatch<React.SetStateAction<string>>
	setStateValue?: React.Dispatch<
		React.SetStateAction<{ slug: string; value: string }>
	>
	status?: number
	statusExercise?: number
}

export type OptionMultiListType = {
	name: string
	code: string
	image?: string
}

export type InputMiltiListProps = {
	options: OptionMultiListType[]
	preselectedValues?: OptionMultiListType[]
	subTitle?: string
	placeholder?: string
	loading: boolean
	setSearch: React.Dispatch<React.SetStateAction<string | undefined>>
	setValue?: UseFormSetValue<AssinedAthetesInputs>
}

export type AssignedProgramConfigProps = {
	session: AssignedProgramSession
	athletes: clientAssignedProgramsType[]
	athletesInfo: athleteType[]
	refetchList: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
	) => Promise<
		QueryObserverResult<clientAssignedProgramsType[] | null, unknown>
	>
	refetchAssignedProgram: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
	) => Promise<QueryObserverResult<AssignedProgramResponse | null, unknown>>
}

export type AssignedProgramAthConfigProps = {
	session: AssignedProgramSession
	exercise: AssignedExercise
	athlete: clientAssignedProgramsType
	athleteInfo: athleteType
	refetchList: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
	) => Promise<
		QueryObserverResult<clientAssignedProgramsType[] | null, unknown>
	>
	refetchAssignedProgram: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
	) => Promise<QueryObserverResult<AssignedProgramResponse | null, unknown>>
}

export type PickerTimeProps = {
	title?: string
	asterisk?: boolean
	defaultValue: string | undefined
	setItem?: React.Dispatch<React.SetStateAction<string>>
	icon?: boolean
	format?: 'mm:ss' | 'hh:mm'
	disablePicker?: boolean
	isEdit?: boolean
}

export type DrawerInTrainingProps = {
	athleteId: number
	assignedProgramSlug: string
	setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
	// refetchUp?: <TPageData>(
	// 	options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
	// ) => Promise<
	// 	QueryObserverResult<InfiniteData<AthleteResponse | null>, unknown>
	// >
	refetchUp?: () => void
}

export type DropDownProps = {
	idSession?: number
	status?: SessionStatus
	exercise: AssignedExercise
	handleChangeMetrics: (slug: string, value: string) => void
	// session: string
	lastData: {
		programSlug: string
		sessionSlug: string
		exerciseSlug: string
	}
	setLastData: React.Dispatch<
		React.SetStateAction<{
			programSlug: string
			sessionSlug: string
			exerciseSlug: string
		}>
	>
	lastScroll: {
		programSlug: string
		sessionSlug: string
		exerciseSlug: string
	}
	setMetricsExecuted2?: React.Dispatch<
		React.SetStateAction<
			{
				slugAME: string
				body: AssignedExecutedMetricType
			}[]
		>
	>
	assignedProgramSlug: string
	refetchData: () => void
	isCompletedExercise?: boolean
}

export type NotesProps = {
	idSession: number
	session: string
	setModalNotes: React.Dispatch<React.SetStateAction<boolean>>
}

export type Notes2Props = {
	idSession: number
	setShowNotes: React.Dispatch<React.SetStateAction<boolean>>
	refetch: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
	) => Promise<QueryObserverResult<NotesResponseType[] | null, unknown>>
}

export type NotesEditProps = {
	idNote: number
	idSelected: number
	setEditNotes: React.Dispatch<React.SetStateAction<boolean>>
	refetch: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
	) => Promise<QueryObserverResult<NotesResponseType[] | null, unknown>>
}

export type ItemNotesProps = {
	id: number
	title: string
	date: string
	priority: string
	session: number
	setIdNote: React.Dispatch<React.SetStateAction<number>>
	setEditNotes: React.Dispatch<React.SetStateAction<boolean>>
	setIdSelected: React.Dispatch<React.SetStateAction<number>>
	refetch: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
	) => Promise<QueryObserverResult<NotesResponseType[] | null, unknown>>
}

export type AnnotationType = {
	id: number
	slug: string
	title: string
	description: string
	priority: string
	created_at: string
	session: number
}

export type ArchiveAssignedProgramProps = {
	setArchiveDrawer: React.Dispatch<React.SetStateAction<boolean>>
}

export type CompletedAssignedProgramProps = {
	setCompletedDrawer: React.Dispatch<React.SetStateAction<boolean>>
}

export interface AssignedProgramProps {
	slug: string
	name: string
	athleteId: number | undefined
	refetch: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
	) => Promise<QueryObserverResult<AthleteByIdResponse | null, unknown>>
	checkedProgram: string
	handleCheckProgram: (slug: string) => void
	setProgramName: React.Dispatch<React.SetStateAction<string>>
	setOpenSessionMobile?: React.Dispatch<React.SetStateAction<boolean>>
}

export interface DrawerAthletePerformanceProps {
	infoAthlete: athleteType | undefined
	setUpdateValuesDrawer: React.Dispatch<React.SetStateAction<boolean>>
	refetch: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
	) => Promise<QueryObserverResult<AthleteByIdResponse | null, unknown>>
}

export interface ModalCalendarProps {
	finishedDate: string
	slugProgram: string
	session: string
	sessionsCompleted: string[]
	setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
}

export interface GroupBoxProps {
	id: number
	slug: string
	name: string
	status: string
	date: Date
	start_at: Date
	finish_at: Date
	participants: number
	clients: {
		client: athleteType
	}[]
	handleSelectGroup: (val: string) => void
	handleRefetch: () => void
	refetchGroup: () => void
}

export interface ModalStatusProps {
	isOpen: boolean
	onClose: () => void
}

export interface DrawerSetsProps {
	isInjury?: boolean
	status?: number
	exercise: AssignedExercise
	assignedProgramSlug: string
	handleOpenModalStatus: () => void
	statusExercise: any
	refetchData: () => void
	setOpenSetsDrawer: (val: boolean) => void
}

export interface validationInfoInterface {
	typeForm: 'create' | 'editSession' | 'edit'
	librarySetsBreakDown: librarySetsType[]
	setsBreakDown: setType[]
	name: string
	comments: string
}

export interface onSubmitInfoInterface {
	data: { name: string; comments: string }
	oldMetrics: LibraryExerciseMetritcPost[]
	typeForm: 'create' | 'editSession' | 'edit'
	refetchUp: unknown
	librarySetsBreakDown: librarySetsType[]
	setsBreakDown: setType[]
	showToast: (
		title: string,
		subtitle: string,
		type: 'error' | 'success' | 'warning'
	) => void
	setOpen: (val: boolean) => void
	exercise: exerciseType
	session: sessionType
	libraryExercise: libraryExerciseType
	token: string
}

export interface metricsToSendInterface {
	assigned_metric: string
	value: string
}
