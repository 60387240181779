/* eslint-disable max-len */
import React from 'react'
import { Icons } from 'interfaces'
import { Icon } from 'components'
import clsx from 'clsx'

interface Props {
	data: {
		key: string
		values: {
			actual: string
			previous: string
			relativeChange: number
		}
	}
}

export const DataAthlete: React.FC<Props> = ({ data }): JSX.Element => {
	const { key, values } = data

	return (
		<div
			className={clsx(
				'min-w-[160px] max-w-[160px] w-full h-[180px] lg:h-[222px]',
				'bg-white shadow-xl rounded-xl border border-gray-200 p-3',
				'flex flex-col justify-center gap-2',
				'lg:py-[50px] lg:px-4'
			)}
		>
			<div className={clsx('flex flex-col gap-2')}>
				<div className="flex">
					<div
						className={clsx(
							'bg-custom text-white font-Roboto text-[26px] leading-[30px] p-2 rounded-lg'
						)}
					>
						{key.replace('_', ' ')}
					</div>
				</div>
				<div
					className={clsx(
						'font-Roboto text-[32px] leading-[36px] text-[#454952] p-1'
					)}
				>
					{(values && values.actual) || '-'}
				</div>
			</div>
			<div className="flex justify-evenly items-center w-full gap-3">
				<span>
					{values && values.relativeChange === 0 ? (
						<Icon src={Icons.equals} className="w-8" />
					) : (
						<div>
							{values && values.relativeChange > 0 ? (
								<Icon src={Icons.arrowGreen} className="w-7" />
							) : (
								<Icon src={Icons.arrowRed} className="w-7" />
							)}
						</div>
					)}
				</span>
				<span className="w-5 h-5 flex justify-center items-center">
					{values && values.relativeChange === 0 ? (
						<Icon src={Icons.noVariant} className="w-8" />
					) : (
						<div className="flex justify-center items-center">
							{values && values.relativeChange > 0 ? (
								<svg
									width="23"
									height="14"
									viewBox="0 0 23 14"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M17.3907 3.52018H14.4494V0.166016H21.1577H22.8348V1.8431V8.55143H19.4807V6.17374L13.9499 11.7045L12.764 12.8904L11.5782 11.7045L8.0408 8.16712L2.37176 13.8362L0 11.4644L6.85491 4.60949L8.04079 3.4236L9.22667 4.60948L12.764 8.14685L17.3907 3.52018Z"
										fill="#27834d"
									/>
								</svg>
							) : (
								<svg
									width="23"
									height="14"
									viewBox="0 0 23 14"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M17.3907 10.4808H14.4494V13.835H21.1577H22.8348V12.1579V5.44955H19.4807V7.82724L13.9499 2.2965L12.764 1.11062L11.5782 2.2965L8.0408 5.83386L2.37176 0.164792L0 2.53654L6.85491 9.39149L8.04079 10.5774L9.22667 9.39149L12.764 5.85413L17.3907 10.4808Z"
										fill="#B32032"
									/>
								</svg>
							)}
						</div>
					)}
				</span>
				{values && !values.relativeChange ? (
					<span
						className={clsx(
							'font-Roboto text-[16px] leading-[18px]',
							'lg:text-[20px] lg:leading-[28px]'
						)}
					>
						0 %
					</span>
				) : (
					<span
						className={clsx(
							'font-Roboto text-[16px] leading-[18px]',
							'lg:text-[20px] lg:leading-[28px]'
						)}
					>
						{values && values.relativeChange < 0
							? parseFloat(
									(values.relativeChange * -1).toPrecision(2)
							  ).toLocaleString('fullwide', {
									useGrouping: false
							  })
							: parseFloat(values.relativeChange.toPrecision(2)).toLocaleString(
									'fullwide',
									{
										useGrouping: false
									}
							  )}{' '}
						%
					</span>
				)}
			</div>
		</div>
	)
}
