import { BaseURL } from 'api'
import axios from 'axios'
import { athleteType } from 'interfaces'

export const updateStatusClient = async (
	token: string,
	athleteId: number,
	customer: number,
	status: boolean
): Promise<athleteType | null> => {
	let res = null as athleteType | null
	if (athleteId) {
		await axios
			.put(
				`${BaseURL}/clients/${athleteId}?x-customer-id=${customer}&assigned_program_state=active`,
				{ is_active: status },
				{
					headers: {
						Authorization: `Token ${token}`
					}
				}
			)
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
				}
			)
	}
	return res
}
