import { API, ASSIGNED_PROGRAMS_ENDPOINTS, BaseURL } from 'api'
import axios from 'axios'
import { AssignedProgramResponse } from 'interfaces'

type EditAssignedProgramsPhoto = {
	customToken?: string
	slug: string
	image?: string
}
export const editAssignedProgramsPhoto = async ({
	slug,
	image
}: EditAssignedProgramsPhoto): Promise<AssignedProgramResponse | null> => {
	let res = null as AssignedProgramResponse | null
	if (slug) {
		await API()
			.patch(`${ASSIGNED_PROGRAMS_ENDPOINTS.BASE}/${slug}/`, { image })
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
				}
			)
	}
	return res
}

type EditAssignedProgram = {
	customToken?: string
	slug: string
	archived?: boolean
	last_fields_modified?: {
		programSlug: string
		sessionSlug: string
		exerciseSlug: string
	}
}
export const editAssignedProgram = async ({
	slug,
	archived,
	last_fields_modified: lastFieldsModified
}: EditAssignedProgram): Promise<AssignedProgramResponse | null> => {
	let res = null as AssignedProgramResponse | null
	if (slug) {
		await API()
			.patch(`${ASSIGNED_PROGRAMS_ENDPOINTS.BASE}/${slug}/`, {
				archived,
				last_fields_modified: lastFieldsModified
			})
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
				}
			)
	}
	return res
}

export const updateFinishedDate = async (
	token: string,
	slug: string,
	finishedDate: string | null
): Promise<AssignedProgramResponse | null> => {
	let res = null as AssignedProgramResponse | null
	if (slug) {
		await axios
			.patch(
				`${BaseURL}/assigned_program_session/${slug}/`,
				{ finished_date: finishedDate },
				{
					headers: {
						Authorization: `Token ${token}`
					}
				}
			)
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
				}
			)
	}
	return res
}
