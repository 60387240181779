import React from 'react'
import clsx from 'clsx'
import { BoxSessionType, Icons } from 'interfaces'
import { Icon } from 'components/icon'

export const BoxSession: React.FC<BoxSessionType> = ({
	session,
	clone,
	deleted,
	config,
	className
}): JSX.Element => {
	return (
		<div
			className={clsx(
				'bg-white shadow border-[1px] border-gray-300 border-l-4 border-l-custom w-full rounded-[5px]',
				className
			)}
		>
			<div className="flex justify-between px-4 py-3">
				<div className="">
					<h3 className="text-base leading-6text-gray-900 font-Roboto font-bold lg:text-xl">
						{session.name}
					</h3>
					<div className="text-xs text-gray-500 lg:text-lg text-left">
						<p>{`${session.exercises.length} Exercises`}</p>
					</div>
				</div>
				<div className="flex flex-col justify-center items-center">
					<div className="flex items-center justify-between w-full mb-2 px-[5px]">
						<button
							type="button"
							onClick={() => clone(session.slug)}
							disabled={session.slug === ''}
							className="disabled:cursor-not-allowed hover:text-custom disabled:hover:text-black"
						>
							<div className="max-h-[16px] max-w-[16px] flex items-center justify-center mr-3">
								<Icon
									fillPath
									className={clsx('min-h-[16px] min-w-[16px] ')}
									src={Icons.clone}
								/>
							</div>
						</button>
						<button
							type="button"
							onClick={() => deleted(session.slug)}
							className="disabled:cursor-not-allowed hover:text-custom disabled:hover:text-black"
						>
							<div className="max-h-[16px] max-w-[16px] flex items-center justify-center">
								<Icon
									fillPath
									className={clsx('min-h-[16px] min-w-[16px] ')}
									src={Icons.trash}
								/>
							</div>
						</button>
					</div>
					<button
						type="button"
						className={clsx(
							'px-3 py-[5px] border-[1px] border-black',
							'hover:bg-black hover:text-white'
						)}
						onClick={() => config(session)}
					>
						<h3 className="font-Roboto text-xs">Configure</h3>
					</button>
				</div>
			</div>
		</div>
	)
}
