import React from 'react'
import { BoxToolsType, Images } from 'interfaces'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

export const BoxTools: React.FC<BoxToolsType> = ({ type }): JSX.Element => {
	const icon =
		type === 'programs'
			? Images.programs
			: type === 'athletes'
			? Images.athetles
			: type === 'training'
			? Images.training
			: Images.schedule

	// TODO: solicitar imagen calendar
	const icon2 =
		type === 'programs'
			? Images.programs2
			: type === 'athletes'
			? Images.athetles2
			: type === 'training'
			? Images.training2
			: Images.schedule2

	const text =
		type === 'programs'
			? 'Training Programs'
			: type === 'athletes'
			? 'Athletes'
			: type === 'training'
			? 'In Training'
			: 'Schedule'

	const href =
		type === 'programs'
			? '/programs'
			: type === 'athletes'
			? '/athletes'
			: type === 'training'
			? '/training'
			: '/schedule'

	return (
		<Link to={href}>
			<div
				className={clsx(
					'relative w-full h-40 rounded-2xl px-4 py-8 flex items-end justify-start',
					'cursor-pointer hover:opacity-80',
					{
						'bg-gradient-to-r from-[#14ac68] to-[#4ad693]': type === 'programs'
					},
					{
						'bg-gradient-to-r from-[#009fdd] to-[#0d7bca]': type === 'athletes'
					},
					{
						'bg-[#fcbc02]': type === 'training'
					},
					{
						'bg-[#fc6602]': type === 'schedule'
					},
					'xl:h-[67vh] xl:items-start'
				)}
			>
				<div className="absolute bottom-0 right-2 h-[158px]">
					<img src={icon2} alt="icon2" className="h-full w-full" />
				</div>
				<div className="flex z-[2]">
					<img src={icon} alt="icon" className="mr-3 w-8 h-8" />
					<h1 className="text-white text-[22px] font-Roboto font-bold md:text-4xl">
						{text}
					</h1>
				</div>
			</div>
		</Link>
	)
}
