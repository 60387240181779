import { BaseURL } from 'api/environment'
import axios from 'axios'

export const updateStatusAssignedExercises = async (
	token: string,
	body: { slug: string; status: number }[]
): Promise<string | null> => {
	let res = null as string | null

	await axios
		.patch(
			`${BaseURL}/assigned_exercises/change_status/`,
			{ assigned_exercises: body },
			{
				headers: {
					Authorization: `Token ${token}`
				}
			}
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)

	return res
}
