import { BaseURL } from 'api'
import axios from 'axios'
import { AthleteByIdResponse, AthleteResponse, athleteType } from 'interfaces'

/* clients == athletes */
export const getClients = async (
	token: string,
	customer: number,
	page: number,
	search?: string
): Promise<AthleteResponse | null> => {
	let response = null as AthleteResponse | null
	await axios
		.get(
			`${BaseURL}/clients/?x-customer-id=${customer}&page=${page}&assigned_program_state=active${
				search ? `&search=${search}` : ''
			}`,
			{
				headers: {
					Authorization: `Token ${token}`
				}
			}
		)
		.then(
			current => {
				response = {
					next: `${BaseURL}/clients/?x-customer-id=${customer}&page=${
						page + 1
					}&assigned_program_state=active${search ? `&search=${search}` : ''}`,
					previous: null,
					results: current.data
				}
			},
			error => {
				console.error(error)
			}
		)
	return response
}

export const getClientById = async (
	token: string,
	customer: number,
	athleteId: string | undefined
): Promise<AthleteByIdResponse | null> => {
	let response = null as AthleteByIdResponse | null
	await axios
		.get(
			`${BaseURL}/clients/${athleteId}/?x-customer-id=${customer}&assigned_program_state=active`,
			{
				headers: {
					Authorization: `Token ${token}`
				}
			}
		)
		.then(
			current => {
				response = current.data
			},
			error => {
				console.error(error)
			}
		)

	return response
}

export const getClientByIds = async (
	token: string,
	customer: number,
	athleteIds: string | undefined
): Promise<athleteType[] | null> => {
	let response = null as athleteType[] | null
	if (customer && athleteIds)
		await axios
			.get(`${BaseURL}/clients/?x-customer-id=${customer}&ids=${athleteIds}`, {
				headers: {
					Authorization: `Token ${token}`
				}
			})
			.then(
				current => {
					response = current.data
				},
				error => {
					console.error(error)
				}
			)

	return response
}
