import React from 'react'
import { RequireUser, NoAuth } from 'provider'
import { Routes, Route } from 'react-router-dom'
import {
	Home,
	Customer,
	Signin,
	Programs,
	Program,
	Athletes,
	Athlete,
	Training,
	AthletesAssignConf,
	ForgotPassword,
	Schedule,
	NotFound,
	Settings,
	Notification
} from 'pages'
import { useCustomer } from 'hooks'

export const RoutesElements = (): JSX.Element => {
	const customer = useCustomer()

	return (
		<Routes>
			<Route
				path="/"
				element={
					<RequireUser>
						<Customer />
					</RequireUser>
				}
			/>
			<Route
				path="dashboard"
				element={
					<RequireUser>
						<Home />
					</RequireUser>
				}
			/>
			<Route
				path="programs"
				element={
					<RequireUser isClient={customer.role === 'Client'}>
						<Programs />
					</RequireUser>
				}
			/>
			<Route
				path="program"
				element={
					<RequireUser isClient={customer.role === 'Client'}>
						<Program />
					</RequireUser>
				}
			>
				<Route
					path=":programSlug"
					element={
						<RequireUser isClient={customer.role === 'Client'}>
							<Program />
						</RequireUser>
					}
				/>
			</Route>
			<Route
				path="signin"
				element={
					<NoAuth>
						<Signin />
					</NoAuth>
				}
			/>
			<Route
				path="forgotpassword"
				element={
					<NoAuth>
						<ForgotPassword />
					</NoAuth>
				}
			/>
			<Route
				path="athletes"
				element={
					<RequireUser>
						<Athletes />
					</RequireUser>
				}
			/>
			<Route
				path="assign-program-athlete/:programSlug/:athleteIds/:programSlugs"
				element={
					<RequireUser>
						<AthletesAssignConf />
					</RequireUser>
				}
			/>
			<Route
				path="athlete"
				element={
					<RequireUser>
						<Athlete />
					</RequireUser>
				}
			>
				<Route
					path=":athleteId"
					element={
						<RequireUser>
							<Athlete />
						</RequireUser>
					}
				/>
			</Route>
			<Route
				path="training"
				element={
					<RequireUser>
						<Training />
					</RequireUser>
				}
			/>
			<Route
				path="schedule"
				element={
					<RequireUser isClient={customer.role === 'Client'}>
						<Schedule />
					</RequireUser>
				}
			/>
			<Route
				path="notFound"
				element={
					<RequireUser>
						<NotFound />
					</RequireUser>
				}
			/>
			<Route
				path="settings"
				element={
					<RequireUser>
						<Settings />
					</RequireUser>
				}
			/>
			<Route
				path="notification"
				element={
					<RequireUser>
						<Notification />
					</RequireUser>
				}
			>
				<Route
					path=":notificationId"
					element={
						<RequireUser>
							<Notification />
						</RequireUser>
					}
				/>
			</Route>
			<Route path="*" element={<NotFound />} />
		</Routes>
	)
}
