import { BaseURL } from 'api'
import axios from 'axios'
import { sessionType } from 'interfaces'

export const getProgramSession = async (
	token: string,
	slug: string | undefined
): Promise<sessionType | null> => {
	let res = null as sessionType | null
	if (slug)
		await axios
			.get(`${BaseURL}/program_sessions/${slug}`, {
				headers: {
					Authorization: `Token ${token}`
				}
			})
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
				}
			)

	return res
}
