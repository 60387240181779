import { Images } from 'interfaces'
import React from 'react'

export const EmptyStateNotification: React.FC = () => {
	return (
		<div className="w-full h-full flex flex-col items-center gap-10 pt-10">
			<h3 className="text-[#929BB2] text-2xl text-center font-bold w-[351px]">
				You currently have no notifications in your inbox
			</h3>

			<img src={Images.empty_notifications} alt="empty state notification" />
		</div>
	)
}
