/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { Button, Input, Loading, AssignProgramItem } from 'components'
import { SearchIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { useInView } from 'react-intersection-observer'
import {
	athleteType,
	programsType,
	AssinedAthetesInputs,
	DrawerDrawerAssignProgramType
} from 'interfaces'
import Cookies from 'universal-cookie'
import { useInfiniteQuery } from 'react-query'
import {
	createAssignedPrograms,
	getClients,
	getListProgramsAssign,
	GET_ASSIGN_PROGRAM,
	GET_ATHLETES
} from 'api'
import { getOffset, getPageNumber } from 'lib'
import { useCustomer, useToast } from 'hooks'
import { useNavigate } from 'react-router-dom'
import Select, { MultiValue } from 'react-select'

const searchIcon = <SearchIcon className="text-gray-900 cursor-pointer" />

const customStyles = {
	container: (base: any) => ({
		...base,
		padding: 0
	}),
	control: (base: any, state: any) => ({
		...base,
		background: '#FFF',
		borderColor: null,
		border: 0,
		borderRadius: 9999,
		boxShadow: state.isFocused ? null : null,
		'&:hover': {
			// Overwrittes the different states of border
			borderColor: null
		}
	}),
	multiValue: (base: any) => ({
		...base,
		background: '#00000000',
		borderRadius: 4,
		borderWidth: 1,
		borderColor: process.env.REACT_APP_COLOR
	}),
	option: (base: any, state: any) => ({
		...base,
		background: state.isFocused ? process.env.REACT_APP_COLOR : '#FFF',
		color: state.isFocused ? '#FFF' : '#333',
		fontFamily: 'Roboto Condensed, sans-serif',
		fontWeight: 500,
		fontSize: 18,
		'&:hover': {
			borderColor: '#B6B6B6'
		},
		height: 48
	})
}

export const DrawerAssignProgram: React.FC<DrawerDrawerAssignProgramType> = ({
	athleteUp
}): JSX.Element => {
	const { register, watch, setValue } = useForm<AssinedAthetesInputs>({
		mode: 'onChange'
	})
	const { ref, inView } = useInView()
	const navigate = useNavigate()
	const customer = useCustomer()
	const { showToast } = useToast()
	const watchSearch = watch('search', '')
	const watchList = watch('list', '')
	const [assignPrograms, setAssignPrograms] = useState<programsType[]>([])
	const [search, setSearch] = useState<string | undefined>(undefined)
	const [searchAthlete, setSearchAthlete] = useState<string>('')
	const cookies = new Cookies()
	const token = cookies.get('token')

	const [programActive, setProgramActive] = useState('')
	const [isLoading2, setIsLoading2] = useState(false)

	const [athletes, setAthletes] = useState<athleteType[]>([])
	const [options, setOptions] =
		useState<MultiValue<{ label: string; value: string }>>()
	const [selectedAthlete, setSelectedAthlete] =
		useState<MultiValue<{ label: string; value: string }>>()

	const handleSelectProgram = (slug: string): void => {
		setProgramActive(slug)
	}

	useEffect(() => {
		setSearch(watchSearch)
		setAssignPrograms([])
	}, [watchSearch])

	useEffect(() => {
		if (athleteUp)
			setValue('list', `${athleteUp.id}`, {
				shouldValidate: true,
				shouldDirty: true
			})
	}, [athleteUp])

	const { data: dataList } = useInfiniteQuery(
		[GET_ATHLETES, searchAthlete, customer.id, token],
		({ pageParam = 1 }) =>
			getClients(token, customer.id, pageParam, searchAthlete),
		{
			getNextPageParam: lastPage =>
				lastPage?.next ? getPageNumber(lastPage?.next) : undefined
		}
	)

	useEffect(() => {
		if (dataList && dataList.pages[0]) setAthletes(dataList?.pages[0]?.results)
	}, [dataList])

	useEffect(() => {
		if (dataList?.pages) {
			let auxData = [] as athleteType[]
			dataList.pages.forEach(page => {
				const auxResults = page?.results
				if (auxResults) auxData = [...auxData, ...auxResults]
			})
			setAthletes(auxData)
		}
	}, [dataList])

	useEffect(() => {
		if (athletes)
			setOptions(
				athletes.map(item => ({
					label: `${item.first_name} ${item.last_name}`,
					value: `${item.id}`
				}))
			)
	}, [athletes])

	const { data, isFetchingNextPage, fetchNextPage, isLoading } =
		useInfiniteQuery(
			[GET_ASSIGN_PROGRAM, search, customer.id, token],
			({ pageParam = 0 }) =>
				getListProgramsAssign(token, customer.id, pageParam, search),
			{
				getPreviousPageParam: firstPage =>
					firstPage?.previous ? getOffset(firstPage?.previous) : undefined,
				getNextPageParam: lastPage =>
					lastPage?.next ? getOffset(lastPage?.next) : undefined
			}
		)

	useEffect(() => {
		if (inView) {
			fetchNextPage()
		}
	}, [inView])

	useEffect(() => {
		if (data?.pages) {
			let auxData = [] as programsType[]
			data.pages.forEach(page => {
				const auxResults = page?.results
				if (auxResults) auxData = [...auxData, ...auxResults]
			})
			setAssignPrograms(auxData)
		}
	}, [data])

	const handleSelectChange = (
		newVal: MultiValue<{ label: string; value: string }>
	): void => {
		setSelectedAthlete(newVal)
	}

	const handleChangeAthlete = (newValue: string): void => {
		setSearchAthlete(newValue)
	}

	useEffect(() => {
		console.warn(
			'e',
			watchList,
			selectedAthlete?.map(item => item.value)
		)
	}, [selectedAthlete, watchList])

	const handleSubmit = async (): Promise<void> => {
		setIsLoading2(true)

		const res = await createAssignedPrograms({
			customer: `${customer.id}`,
			athleteIds: selectedAthlete
				? selectedAthlete.map(item => item.value).toString()
				: watchList,
			programSlug: programActive
		})
		if (res) {
			if (res.error) {
				setIsLoading2(false)
				showToast(
					'Error Assigned program',
					`Error: you check that program has info completed`,
					'error'
				)
			} else {
				setIsLoading2(false)
				showToast(
					'Assigned athletes',
					'athletes was assigned successfully',
					'success'
				)

				const slugs = res.slugs[0]
				navigate(
					`/assign-program-athlete/${programActive}/${
						selectedAthlete
							? selectedAthlete.map(item => item.value).toString()
							: watchList
					}/${slugs}`
				)
			}
			setIsLoading2(false)
		}
	}

	return (
		<div className="flex-1 h-full relative">
			<div className="bg-white h-[calc(100vh-144px)] p-4">
				<div className="w-full flex flex-col justify-center items-center">
					<p
						className={clsx(
							'w-full text-xs mb-1 font-Roboto font-bold',
							'md:text-lg'
						)}
					>
						Select athletes
					</p>
					<Select
						name="athlete"
						isMulti
						placeholder="Search athletes"
						className={clsx(
							'appearance-none block w-full py-1 bg-[transparent]',
							'placeholder-gray-400 font-Roboto',
							'border border-gray-300 shadow-sm rounded-[9999px]'
						)}
						options={options}
						styles={customStyles}
						onChange={handleSelectChange}
						onInputChange={handleChangeAthlete}
						defaultValue={
							athleteUp
								? {
										label: `${athleteUp.first_name} ${athleteUp.last_name}`,
										value: `${athleteUp.id}`
								  }
								: selectedAthlete
						}
						components={{
							IndicatorSeparator: () => null,
							ClearIndicator: () => null
						}}
					/>
				</div>
				<div className="h-[1px] w-full bg-gray-300 my-2" />
				<Input
					title="Select training program"
					name="search"
					register={register}
					placeholder="search program"
					type="text"
					className="mb-4 lg:col-span-3 lg:pr-2"
					leftIcon={searchIcon}
					rounded
				/>

				<div
					className={clsx(
						'flex flex-col gap-2',
						'min-h-[calc(100vh-375px)] max-h-[calc(100vh-375px)]',
						'overflow-hidden hide-scroll-bar overflow-y-scroll',
						'md:min-h-[calc(100vh-395px)] md:max-h-[calc(100vh-395px)]'
					)}
				>
					{isLoading ? (
						<Loading />
					) : (
						<>
							{assignPrograms?.map(assignProgram => {
								return (
									<AssignProgramItem
										key={assignProgram.slug}
										slug={assignProgram.slug}
										name={assignProgram.name}
										active={programActive}
										sessions={assignProgram.program_sessions.length}
										cover={assignProgram.image}
										onClick={handleSelectProgram}
									/>
								)
							})}
							<div className="md:col-span-2 xl:col-span-3 2xl:col-span-4 flex items-center justify-center mt-2">
								<div ref={ref}>{isFetchingNextPage && <Loading isSmall />}</div>
							</div>
						</>
					)}
				</div>
				<div className="w-full absolute bottom-16 right-0 left-0 p-4">
					<Button
						customColor
						size="full"
						type="button"
						className={clsx('mt-2 lg:mt-4')}
						disabled={!programActive || watchList?.length < 1 || isLoading2}
						onClick={() => handleSubmit()}
					>
						<div className="flex items-center justify-center">
							<p className="font-Roboto font-semibold text-base mr-2">
								{isLoading2 ? `Loading...` : `Continue`}
							</p>
						</div>
					</Button>
				</div>
			</div>
			{/* <div className="w-full absolute bottom-0 right-0 left-0 p-4">
				<Button
					customColor
					size="full"
					type="button"
					className={clsx('lg:mt-4')}
					disabled={!programActive || watchList.length < 1}
					onClick={() => console.warn('click')}
				>
					<div className="flex items-center justify-center">
						<p className="font-Roboto font-semibold text-base mr-2">Continue</p>
					</div>
				</Button>
			</div> */}
		</div>
	)
}
