import React, { useEffect, useState } from 'react'
import { Icons, Images } from 'interfaces'
import { Button } from 'components'
import clsx from 'clsx'
import Cookies from 'universal-cookie'
import { useQuery } from 'react-query'
import {
	getListAthletesAssignedProgram,
	GET_LIST_ATHLETES_ASSIGNED_PROGRAM
} from 'api'
import styles from './program.module.scss'

interface Props {
	slug: string
	cover: string | null
	name: string
	athleteId: number
	active: string
	onClick: (slug: string) => void
}

export const ArchiveAssignedProgramItem: React.FC<Props> = ({
	slug,
	cover = Images.program_cover,
	name,
	athleteId,
	active,
	onClick
}): JSX.Element => {
	const cookies = new Cookies()
	const token = cookies.get('token')

	const [nSessions, setNSessions] = useState(0)

	const { data } = useQuery(
		[GET_LIST_ATHLETES_ASSIGNED_PROGRAM, slug, athleteId, token],
		() =>
			getListAthletesAssignedProgram({
				athleteIds: `${athleteId}`,
				programSlugs: slug
			}),
		{
			refetchOnMount: 'always'
		}
	)

	useEffect(() => {
		if (data)
			setNSessions(data[0].assigned_program.assigned_program_sessions.length)
	}, [data])

	return (
		<div
			className={clsx(
				'flex flex-col border-[1px] border-custom shadow-[0px_4px_20px_rgba(0,0,0,0.16)] rounded-lg min-h-[100px] max-h-[100px]',
				'relative w-full rounded-lg overflow-hidden'
			)}
		>
			<img
				src={cover || Images.program_cover}
				alt="cover"
				className="object-cover w-full h-[100px] rounded-lg border border-custom"
			/>
			<div
				className={clsx(styles.gradient, 'absolute inset-0 min-h-[114px]')}
			/>
			<div className="absolute inset-0 min-h-[100px] p-2">
				<p className="h-1/2 font-Roboto font-bold text-2xl text-white">
					{name}
				</p>
				<div className="flex justify-between h-1/2">
					<p className="font-Roboto font-bold text-sm text-white">
						Number session {nSessions}
					</p>
					<Button
						onClick={() => onClick(slug)}
						type="button"
						className={clsx('ml-1')}
						label="select"
						customColor={active === slug ? true : undefined}
						color={active === slug ? 'Tranparent' : 'Secondary'}
						rounded
						size="extra-small"
						iconLeft={Icons.unarchive}
					/>
				</div>
			</div>
		</div>
	)
}
