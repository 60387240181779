/* eslint-disable camelcase */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react'
import {
	Button,
	Icon,
	Layout,
	AssignedProgramItem,
	ProgressBar,
	DataAthlete,
	Loading,
	ModalSession,
	ModalExercise,
	HeaderAthlete
} from 'components'
import clsx from 'clsx'
import {
	AssignedExercise,
	AssignedMetric,
	AssignedProgramSession,
	AssignedProgramStats,
	AssignedProgramType,
	athleteType,
	GIF,
	Icons,
	Images
} from 'interfaces'
import { useQuery } from 'react-query'
import {
	getAssignedProgramBySlug,
	getAssignedProgramBySlugsAndIds,
	getClientById,
	GET_ATHLETE_BY_ID
} from 'api'
import { useCustomer, useDevice } from 'hooks'
import { useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import {
	ArchiveIcon,
	CheckCircleIcon,
	ChevronRightIcon
} from '@heroicons/react/outline'
import { PencilIcon } from '@heroicons/react/solid'
import { CardSession } from 'components/box/athletes/cardSession'
import { CardExercise } from 'components/box/athletes/cardExercise'
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
	Tooltip
	// Legend
} from 'chart.js'
import { Bar, Radar } from 'react-chartjs-2'
import { colorGenerator } from 'lib'
import { CarouselMetrics } from 'components/carousel'
import styles from './athlete.module.scss'
import { CardDataAthlete } from './cardDataAthlete'
import { CardDataAthleteMobile } from './cardDataAthleteMobile'
import { HandleDrawers } from './handleDrawers'
import { ArrayOfSets, StatsMetricsInterface } from './athlete.interface'
import { SetsNavigation } from './setsNavigation'
import { TableExercise } from './tableExercise'

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
	Tooltip
)

export const Athlete = (): JSX.Element => {
	const customer = useCustomer()
	const params = useParams()
	const [athlete, setAthlete] = useState<athleteType>()
	const [openDrawer, setOpenDrawer] = useState(false)
	const [archiveDrawer, setArchiveDrawer] = useState(false)
	const [completedDrawer, setCompletedDrawer] = useState(false)

	const [assignedPrograms, setAssignedPrograms] = useState<
		AssignedProgramType[] | undefined
	>([])

	const [unarchivedPrograms, setUnarchivedPrograms] = useState<
		AssignedProgramType[] | undefined
	>([])

	const [athleteId, setAthleteId] = useState<string>()
	const [slugsx, setSlugsx] = useState<string>()
	const [slugs, setSlugs] = useState<string[]>()

	const [response, setResponse] = useState<number>()
	const [programCompleted, setProgramCompleted] = useState<number>(0)
	const [loading, setLoading] = useState(false)
	const cookies = new Cookies()
	const token = cookies.get('token')
	const device = useDevice()

	// new dev
	const [palleteColor, setPalleteColor] = useState<string[]>([])
	const [openAthleteProgram, setOpenAthleteProgram] = useState(false)
	const [isLoadingProgram, setIsLoadingProgram] = useState(false)

	const [updateValuesDrawer, setUpdateValuesDrawer] = useState(false)
	const [updateInfoAthleteDrawer, setUpdateInfoAthleteDrawer] = useState(false)

	const [openModalSession, setOpenModalSession] = useState(false)
	const [openModalExercise, setOpenModalExercise] = useState(false)

	const [openSessionMobile, setOpenSessionMobile] = useState(false)
	const [openExerciseMobile, setOpenExerciseMobile] = useState(false)

	const [listSession, setListSession] = useState<
		AssignedProgramSession[] | undefined
	>([])
	const [listExercise, setListExercise] = useState<AssignedExercise[]>([])

	const [statsSession, setStatsSession] = useState<
		AssignedProgramStats | undefined
	>()
	const [statsIndex, setStatsIndex] = useState(0)

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_statsMetrics, setStatsMetrics] = useState<
		StatsMetricsInterface[] | []
	>([])

	const [percSessionsCompleted, setPercSessionsCompleted] = useState<number[]>(
		[]
	)
	const [labelSessions, setLabelSessions] = useState<string[] | undefined>([])

	const [dataAthlete, setDataAthlete] = useState<
		| {
				[key: string]: {
					actual: string
					previous: string
					relativeChange: number
				}
		  }
		| undefined
	>()

	const [checkSlugProgram, setCheckSlugProgram] = useState('')
	const [checkSlugSession, setCheckSlugSession] = useState('')
	const [checkSlugExercise, setCheckSlugExercise] = useState('')
	const [programName, setProgramName] = useState('')
	const [exerciseName, setExerciseName] = useState('')

	const [arrayOfSets, setArrayOfSets] = useState<ArrayOfSets[]>([])
	const [arrayOfMetrics, setArrayOfMetrics] = useState<
		{
			id: string
			type: string
			name: string
			base: string
			value: string
		}[]
	>(
		arrayOfSets.length > 0
			? arrayOfSets[0].assigned_metrics?.map((items: AssignedMetric) => ({
					id: items.slug,
					type: items.type_metric.type,
					name: items.type_metric.name,
					base: items.value,
					value: items.assigned_execute_metric.value
			  }))
			: []
	)

	const handleCheckProgram = (slug: string): void => {
		setCheckSlugProgram(slug)
	}

	const handleCheckSession = (slug: string): void => {
		setCheckSlugSession(slug)
		setStatsMetrics([])
		setArrayOfMetrics([])
	}

	const handleCheckExercise = (slug: string): void => {
		setCheckSlugExercise(slug)
	}

	const handleAssignProgram = (): void => {
		setOpenDrawer(true)
	}

	const { data, isLoading, refetch } = useQuery(
		[GET_ATHLETE_BY_ID, customer.id, token],
		() => getClientById(token, customer.id, params.athleteId),
		{
			refetchOnMount: 'always'
		}
	)

	useEffect(() => {
		setPalleteColor(colorGenerator(labelSessions?.length || 0))
	}, [labelSessions])

	useEffect(() => {
		if (data) setAthlete(data.client)
	}, [data])

	useEffect(() => {
		setAthleteId(params.athleteId)
		setSlugs(athlete?.assigned_programs?.map(el => el.slug))
		setAssignedPrograms(
			athlete?.assigned_programs?.filter(item => item.completed === false)
		)
		setProgramCompleted(
			athlete?.assigned_programs?.filter(item => item.completed === true)
				.length ?? 0
		)
		if (athlete) setDataAthlete(athlete?.variable_metric_fields)
	}, [athlete])

	useEffect(() => {
		setUnarchivedPrograms(
			assignedPrograms?.filter(item => item.archived === false)
		)
	}, [assignedPrograms])

	useEffect(() => {
		setSlugsx(slugs?.join())
	}, [slugs])

	useEffect(() => {
		if (athleteId && slugsx) {
			setLoading(true)
			getAssignedProgramBySlugsAndIds({
				ids: athleteId,
				programSlug: slugsx
			})
				.then(resp => {
					if (resp) {
						const programComplete =
							resp?.reduce((prev, current) => {
								return current.assigned_program?.assigned_program_stats
									?.program_execute === 100
									? prev +
											current.assigned_program.assigned_program_stats
												.program_execute
									: prev
							}, 0) / resp.length

						setResponse(programComplete)
					}
				})
				.catch(err => {
					console.error(err)
				})
			setLoading(false)
		}
	}, [slugsx])

	const selectProgram = async (): Promise<void> => {
		setIsLoadingProgram(true)
		let res
		try {
			res = await getAssignedProgramBySlug({
				assignProgramSlug: checkSlugProgram,
				stats: true
			})
			if (res) {
				setStatsSession(res.assigned_program_stats)
				setListSession(res?.assigned_program_sessions)
			}
		} catch (error) {
			console.error(error)
		}
		setIsLoadingProgram(false)
	}

	useEffect(() => {
		selectProgram()
		return () => {
			setListExercise([])
			setArrayOfSets([])
			setStatsMetrics([])
			setArrayOfMetrics([])
		}
	}, [checkSlugProgram])

	useEffect(() => {
		setListExercise(
			listSession?.filter(item => item.slug === checkSlugSession)[0]
				?.assigned_exercises ?? []
		)
	}, [checkSlugSession])

	useEffect(() => {
		setLabelSessions(listSession?.map(session => session.program_session.name))
		if (listSession)
			setPercSessionsCompleted(
				listSession
					?.map(item => statsSession?.session_detail[item.slug])
					.map(item2 => item2?.session_execute)
			)
	}, [statsSession, listSession])

	const handleMetricForSets = (order: number): void => {
		setArrayOfMetrics(
			arrayOfSets[order].assigned_metrics.map(items => ({
				id: items.slug,
				type: items.type_metric.type,
				name: items.type_metric.name,
				base: items.value,
				value: items.assigned_execute_metric.value
			}))
		)
	}

	return (
		<Layout back stretch isLoading={isLoading && loading}>
			<div
				className={clsx(
					'p-6 lg:pt-0 lg:h-full',
					'min-h-[calc(100vh-220px)] max-h-[calc(100vh-220px)] overflow-y-scroll',
					'lg:min-h-[calc(100vh-68px)] lg:max-h-[calc(100vh-68px)]'
				)}
			>
				<HeaderAthlete
					athlete={athlete}
					setArchiveDrawer={setArchiveDrawer}
					setCompletedDrawer={setCompletedDrawer}
					setUpdateValuesDrawer={setUpdateValuesDrawer}
				/>
				<div>
					{/* mobile */}
					<div
						className={clsx(
							styles.gradient,
							'h-[50%] flex justify-between items-end m-[-1.5rem] py-3',
							'lg:w-1/6 lg:hidden'
						)}
					>
						<div className="w-1/3 flex flex-col justify-center items-start pl-3 pb-3">
							<span className="text-white text-[24px] leading-[28px] font-bold">
								{athlete?.first_name}
							</span>
							<span className="text-white text-[24px] leading-[28px]">
								{athlete?.last_name}
							</span>
						</div>
						<div className="w-1/3 flex justify-center items-center">
							<div className="border border-gray h-[80px] w-[80px] bg-black">
								<img
									src={athlete?.profile_image?.url || Images.default_avatar}
									alt="avatar"
								/>
							</div>
						</div>
					</div>
					<div className="lg:hidden w-full mt-10 flex flex-col justify-center items-center pr-3 pb-3 gap-2">
						<Button
							type="button"
							rounded
							customColor
							size="full"
							onClick={() => setUpdateValuesDrawer(true)}
							className="w-full h-7"
						>
							<div className="flex">
								<PencilIcon className="w-5 h-5" />
								Athlete&apos;s metrics
							</div>
						</Button>
						<div className="w-full flex justify-center items-center gap-4">
							<Button
								type="button"
								rounded
								customColor
								size="full"
								fill={false}
								onClick={() => setArchiveDrawer(true)}
								className="w-full h-7"
							>
								<div className="w-full flex justify-center items-center">
									<ArchiveIcon className="w-5 h-5" />
									Archived
								</div>
							</Button>
							<Button
								type="button"
								rounded
								customColor
								size="full"
								fill={false}
								onClick={() => setCompletedDrawer(true)}
								className="w-full h-7"
							>
								<div className="w-full flex justify-center items-center">
									<CheckCircleIcon className="w-5 h-5" />
									Completed
								</div>
							</Button>
						</div>
					</div>
					<div
						className={clsx(
							'lg:hidden flex flex-col gap-3',
							openAthleteProgram ? 'hidden' : 'flex'
						)}
					>
						<CardDataAthleteMobile
							athlete={athlete}
							setUpdateInfoAthleteDrawer={setUpdateInfoAthleteDrawer}
						/>
						{!dataAthlete || Object.keys(dataAthlete).length === 0 ? (
							<div
								className={clsx(
									'flex flex-col h-auto shadow-xl border border-gray-300 bg-white rounded-xl py-2 cursor-pointer'
								)}
								aria-hidden
								onClick={() => setUpdateValuesDrawer(true)}
							>
								<span className="font-Roboto font-semibold text-custom text-center">
									This Athlete Doesn&#39;t Have Metrics Assigned
								</span>
							</div>
						) : (
							<div className={clsx('flex gap-2 pb-4', 'w-full overflow-auto')}>
								{dataAthlete &&
									Object.entries(dataAthlete).map(([key, values], idx) => {
										const kk = `key=${idx}`
										return (
											<DataAthlete
												key={kk}
												data={{
													key,
													values
												}}
											/>
										)
									})}
							</div>
						)}
					</div>
					{/* desktop */}
					<div className="hidden lg:flex gap-5">
						<CardDataAthlete
							athlete={athlete}
							setUpdateInfoAthleteDrawer={setUpdateInfoAthleteDrawer}
						/>
						{!dataAthlete || Object.keys(dataAthlete).length === 0 ? (
							<div
								className={clsx(
									'flex flex-col h-[222px] shadow-xl border border-gray-300 bg-white rounded-xl px-3 cursor-pointer'
								)}
								aria-hidden
								onClick={() => setUpdateValuesDrawer(true)}
							>
								<img
									loading="lazy"
									src={GIF.emptyMetric}
									alt="empty state metrics"
									className={clsx('w-[180px]')}
								/>
								<span className="font-Roboto font-semibold text-custom text-center">
									Add athlete&#39;s metrics
								</span>
							</div>
						) : (
							<div className={clsx('flex gap-4', 'w-full overflow-auto')}>
								{dataAthlete &&
									Object.entries(dataAthlete).map(([key, values], idx) => {
										const kk = `key=${idx}`
										return (
											<DataAthlete
												key={kk}
												data={{
													key,
													values
												}}
											/>
										)
									})}
							</div>
						)}
					</div>
				</div>
				<div className="hidden lg:block h-[2px] w-full bg-gray-300 my-3 px-4" />
				<main className="flex gap-4">
					{/* view mobile */}
					<div className={clsx('lg:hidden w-full')}>
						<div
							className={clsx(
								'flex justify-between items-center p-3 bg-white',
								'cursor-pointer border border-gray-200 shadow-lg rounded-lg'
							)}
							aria-hidden
							onClick={() => setOpenAthleteProgram(!openAthleteProgram)}
						>
							<span className="font-Roboto text-[24px] leading-[28px] font-bold text-[#393C44]">
								Athlete Programs
							</span>
							<ChevronRightIcon
								className={clsx(
									'w-5 h-5 text-custom',
									openAthleteProgram ? '-rotate-90' : 'rotate-90'
								)}
							/>
						</div>
						{/* <div className="w-full h-[2px] bg-[#454952] opacity-10" /> */}
						{openAthleteProgram && (
							<div className={clsx('w-full')}>
								<header className="flex flex-col justify-between py-2">
									{customer.role !== 'Client' && (
										<Button
											type="button"
											size="full"
											className="w-full h-10"
											customColor
											onClick={() => handleAssignProgram()}
										>
											<div className="flex items-center justify-center">
												<p className="font-Roboto font-semibold text-sm mr-2">
													ASSIGN PROGRAM
												</p>
												<div className="pt-1">
													<Icon
														className={clsx('h-[28px] w-[30px] text-custom')}
														src={Icons.plus}
														fillPath
													/>
												</div>
											</div>
										</Button>
									)}
									<div className="h-1/4 flex justify-between">
										<span className="text-base font-Roboto text-[#6F7579]">
											Completed programs
										</span>
										<span className="text-2xl font-Roboto font-bold">{`${
											programCompleted ?? 0
										}/${
											athlete?.assigned_programs?.length
												? athlete.assigned_programs?.length
												: 0
										}`}</span>
									</div>
									<ProgressBar
										steps={athlete?.assigned_programs?.length || 0}
										percent={response || 0}
									/>
									<span className="text-sm text-gray-400">
										Select a program for more details
									</span>
								</header>
								<div
									className={clsx(
										{
											'min-h-[calc(100vh-465px)] max-h-[calc(100vh-465px)]':
												device === 'web'
										},
										{
											'min-h-[calc(100vh-380px)] max-h-[calc(100vh-380px)]':
												device === 'android' || device === 'ios'
										},
										'md:h-[340px] md:min-h-[calc(100vh-340px)] md:max-h-[calc(100vh-340px)]',
										'overflow-hidden hide-scroll-bar overflow-y-scroll',
										'w-full flex flex-col'
									)}
								>
									{/* filter archived program in athlete profile */}
									{unarchivedPrograms?.map(item => (
										<AssignedProgramItem
											key={item.slug}
											name={item.name}
											slug={item.slug}
											athleteId={
												params.athleteId
													? parseInt(params.athleteId, 10)
													: undefined
											}
											refetch={refetch}
											checkedProgram={checkSlugProgram}
											handleCheckProgram={handleCheckProgram}
											setProgramName={setProgramName}
											setOpenSessionMobile={setOpenSessionMobile}
										/>
									))}
								</div>
							</div>
						)}
					</div>
					{/* view desktop */}
					<div className="hidden md:w-1/2 xl:w-1/5 py-2 lg:flex flex-col gap-4">
						<header className="flex flex-col justify-between">
							<h3 className="font-Roboto font-bold text-center sm:align-middle sm:text-lg lg:text-2xl pb-4">
								Athlete Programs
							</h3>
							{customer.role !== 'Client' && (
								<Button
									type="button"
									size="full"
									className="w-full h-10"
									customColor
									onClick={() => handleAssignProgram()}
								>
									<div className="flex items-center justify-center">
										<p className="font-Roboto font-semibold text-sm mr-2">
											ASSIGN PROGRAM
										</p>
										<div className="pt-1">
											<Icon
												className={clsx('h-[28px] w-[30px] text-custom')}
												src={Icons.plus}
												fillPath
											/>
										</div>
									</div>
								</Button>
							)}
							<div className="h-1/4 flex justify-between">
								<span className="text-base font-Roboto text-[#6F7579]">
									Completed programs
								</span>
								<span className="text-2xl font-Roboto font-bold">{`${Math.trunc(
									programCompleted || 0
								)}/${
									athlete?.assigned_programs?.length
										? athlete.assigned_programs?.length
										: 0
								}`}</span>
							</div>
							<ProgressBar
								steps={athlete?.assigned_programs?.length || 0}
								percent={response || 0}
							/>
							<span className="text-sm text-gray-400">
								Select a program for more details
							</span>
						</header>
						<div
							className={clsx(
								{
									'min-h-[calc(100vh-465px)] max-h-[calc(100vh-465px)]':
										device === 'web'
								},
								{
									'min-h-[calc(100vh-515px)] max-h-[calc(100vh-515px)]':
										device === 'android' || device === 'ios'
								},
								'md:h-[340px] md:min-h-[calc(100vh-630px)] md:max-h-[calc(100vh-630px)]',
								'overflow-hidden overflow-y-scroll',
								'w-full h-auto flex flex-col'
							)}
						>
							{/* filter archived program in athlete profile */}
							{unarchivedPrograms?.map(item => (
								<AssignedProgramItem
									key={item.slug}
									name={item.name}
									slug={item.slug}
									athleteId={
										params.athleteId
											? parseInt(params.athleteId, 10)
											: undefined
									}
									refetch={refetch}
									checkedProgram={checkSlugProgram}
									handleCheckProgram={handleCheckProgram}
									setProgramName={setProgramName}
								/>
							))}
						</div>
					</div>
					<div className="hidden md:w-1/2 xl:w-2/5 py-2 lg:flex flex-col gap-4">
						<div className="w-full flex justify-between items-center">
							<span className="font-Roboto font-bold text-center sm:align-middle sm:text-lg lg:text-2xl">
								Sessions
							</span>
							<button
								type="button"
								className="h-[30px] border border-gray-400 px-5 rounded-md"
								onClick={() => setOpenModalSession(true)}
							>
								Screenshot Session
							</button>
						</div>
						<div className="w-full flex gap-5">
							<div
								className={clsx(
									'w-full h-[222px] flex justify-center items-center bg-white rounded-xl shadow-xl border border-gray-300'
								)}
							>
								{!labelSessions ? (
									<div className="w-full h-[222px] bg-[transparent] rounded-xl">
										<img
											src={GIF.emptyCharts}
											alt="chart-base"
											className="w-full h-[222px] rounded-xl bg-white"
										/>
									</div>
								) : (
									<div className="w-[222px] h-[222px] flex justify-center items-center py-10">
										{percSessionsCompleted?.length > 4 ? (
											<Radar
												data={{
													// labels: labelSessions,
													labels: labelSessions?.map(() => ''),
													datasets: [
														{
															label: 'Sessions',
															data: percSessionsCompleted,
															backgroundColor: palleteColor,
															borderWidth: 1
														}
													]
												}}
												options={{
													responsive: true,
													maintainAspectRatio: true,
													plugins: {
														title: {
															display: true,
															text: 'Sessions'
														}
													},
													scales: {
														r: {
															suggestedMin: 0,
															suggestedMax: 100
														}
													}
												}}
											/>
										) : (
											<Bar
												data={{
													labels: labelSessions?.map(item => item),
													datasets: [
														{
															data: percSessionsCompleted,
															backgroundColor: palleteColor,
															borderWidth: 1
														}
													]
												}}
												options={{
													responsive: true,
													maintainAspectRatio: true,
													scales: {
														y: {
															min: 0,
															max: 100
														}
													},
													plugins: {
														title: {
															display: true,
															text: 'Sessions'
														}
													}
												}}
											/>
										)}
									</div>
								)}
							</div>
							<div className="w-full h-[222px] bg-white rounded-xl p-3 shadow-xl border border-gray-300">
								<span className="text-xs text-[#454952] font-bold">
									Completed %
								</span>
								<div
									className={clsx(
										'w-full h-full flex justify-between',
										'overflow-hidden hide-scroll-bar overflow-y-scroll',
										'min-h-[170px] max-h-[170px]'
									)}
								>
									<div className="w-full flex flex-col flex-nowrap">
										{listSession?.map((item, idx) => (
											<div key={item.id} className="flex justify-between">
												<span
													key={item.slug}
													className="flex gap-2 text-xs text-[#454952]"
												>
													<span
														className={clsx(
															'w-5 h-3 flex justify-center items-center bg-custom rounded-full text-white'
														)}
													>
														{idx + 1}
													</span>
													{item.program_session.name}
												</span>
												<span className="text-xs text-[#454952]">
													{percSessionsCompleted[idx]} %
												</span>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
						<span className="text-sm text-gray-400">
							Select a session for more details
						</span>
						{isLoadingProgram ? (
							<div
								className={clsx(
									'flex justify-center items-center',
									'min-h-[140px] max-h-[140px]'
								)}
							>
								<Loading isSmall />
							</div>
						) : (
							<div
								className={clsx(
									'flex flex-col gap-3',
									'min-h-[140px] max-h-[140px]',
									'overflow-hidden hide-scroll-bar overflow-y-scroll'
								)}
							>
								{listSession?.map(session => (
									<CardSession
										key={session.slug}
										session={session}
										checkedSession={checkSlugSession}
										handleCheckSession={handleCheckSession}
										setOpenExerciseMobile={setOpenExerciseMobile}
									/>
								))}
							</div>
						)}
						{openModalSession && (
							<ModalSession
								open={openModalSession}
								setOpen={setOpenModalSession}
								programName={programName}
								labelSessions={labelSessions || []}
								percSessionsCompleted={percSessionsCompleted}
							/>
						)}
					</div>
					<div className="hidden w-2/5 py-2 xl:flex flex-col gap-4">
						<div className="w-full flex justify-between items-center">
							<span className="font-Roboto font-bold text-center sm:align-middle sm:text-lg lg:text-2xl">
								Exercises
							</span>
							<button
								type="button"
								className="h-[30px] border border-gray-400 px-5 rounded-md"
								onClick={() => setOpenModalExercise(true)}
							>
								Screenshot Metrics
							</button>
						</div>
						<div className="flex gap-5">
							<div className="w-full h-[222px] bg-white rounded-xl p-3 shadow-xl border border-gray-300">
								<TableExercise
									statsIndex={statsIndex}
									arrayOfSets={arrayOfSets}
								/>
								<SetsNavigation
									statsIndex={statsIndex}
									setStatsIndex={setStatsIndex}
									arrayOfSets={arrayOfSets}
									handleMetricForSets={handleMetricForSets}
								/>
							</div>
							<div
								className={clsx(
									'w-full h-[222px] flex justify-center items-center bg-white rounded-xl shadow-xl border border-gray-300'
								)}
							>
								<CarouselMetrics
									indexSets={statsIndex}
									metrics={arrayOfMetrics}
								/>
							</div>
						</div>
						<span className="text-sm text-gray-400">
							Select a exercise for more details
						</span>
						<div
							className={clsx(
								'flex flex-col gap-3 overflow-auto',
								'min-h-[140px] max-h-[140px]',
								'overflow-hidden hide-scroll-bar overflow-y-scroll'
							)}
						>
							{listExercise?.map(exercise => (
								<CardExercise
									key={exercise.slug}
									exercise={exercise}
									checkedExercise={checkSlugExercise}
									handleCheckExercise={handleCheckExercise}
									setExerciseName={setExerciseName}
									setStatsMetrics={setStatsMetrics}
									statsIndex={statsIndex}
									setStatsIndex={setStatsIndex}
									setArrayOfSets={setArrayOfSets}
									setArrayOfMetrics={setArrayOfMetrics}
								/>
							))}
						</div>
						{openModalExercise && (
							<ModalExercise
								arrayOfSets={arrayOfSets}
								open={openModalExercise}
								setOpen={setOpenModalExercise}
								statsMetrics={arrayOfMetrics}
								exerciseName={exerciseName}
							/>
						)}
					</div>
				</main>
				<HandleDrawers
					athlete={athlete}
					openDrawer={openDrawer}
					setOpenDrawer={setOpenDrawer}
					archiveDrawer={archiveDrawer}
					setArchiveDrawer={setArchiveDrawer}
					completedDrawer={completedDrawer}
					setCompletedDrawer={setCompletedDrawer}
					updateInfoAthleteDrawer={updateInfoAthleteDrawer}
					setUpdateInfoAthleteDrawer={setUpdateInfoAthleteDrawer}
					updateValuesDrawer={updateValuesDrawer}
					setUpdateValuesDrawer={setUpdateValuesDrawer}
					openSessionMobile={openSessionMobile}
					setOpenSessionMobile={setOpenSessionMobile}
					openExerciseMobile={openExerciseMobile}
					refetch={refetch}
					// drawer session mobile
					labelSessions={labelSessions}
					percSessionsCompleted={percSessionsCompleted}
					listSession={listSession}
					palleteColor={palleteColor}
					checkSlugSession={checkSlugSession}
					handleCheckSession={handleCheckSession}
					setOpenExerciseMobile={setOpenExerciseMobile}
					setStatsMetrics={setStatsMetrics}
					isLoadingProgram={isLoadingProgram}
					setOpenModalSession={setOpenModalSession}
					// drawer exercise mobile
					setOpenModalExercise={setOpenModalExercise}
					// statsMetrics={statsMetrics}
					listExercise={listExercise}
					checkSlugExercise={checkSlugExercise}
					handleCheckExercise={handleCheckExercise}
					// setStatsMetrics={setStatsMetrics}
					setExerciseName={setExerciseName}
					// nuevo
					statsIndex={statsIndex}
					setStatsIndex={setStatsIndex}
					handleMetricForSets={handleMetricForSets}
					arrayOfSets={arrayOfSets}
					arrayOfMetrics={arrayOfMetrics}
					setArrayOfSets={setArrayOfSets}
					setArrayOfMetrics={setArrayOfMetrics}
				/>
			</div>
		</Layout>
	)
}

export default Athlete
