import { getNotificationsById } from 'api'
import clsx from 'clsx'
import { Layout, Loading } from 'components'
import moment from 'moment'
import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { InfoItem, InfoItemMobile } from './components/infoItem'

export const Notification: React.FC = () => {
	const cookies = new Cookies()
	const token = cookies.get('token')
	const { notificationId } = useParams()

	const { data: notification, isLoading } = useQuery(
		['GET_NOTIFICATION_BY_ID', notificationId],
		() => getNotificationsById(token, notificationId)
	)

	const assignedDate = notification?.date_time
	const dateFormatted = moment(assignedDate).format('MM/DD/YYYY')

	return (
		<Layout>
			<section className="flex flex-col gap-10 pb-12 lg:pb-0">
				<h3 className="text-[32px] text-[#393C44] font-bold border-b-4 border-[#4AD669] max-w-max">
					Notification
				</h3>

				{isLoading ? (
					<Loading />
				) : (
					<div
						className={clsx(
							'xl:w-[1066px] lg:w-full lg:h-[430px] h-screen p-8 flex flex-col gap-10',
							'bg-notification_mobile_bg lg:bg-notification bg-cover lg:bg-cover bg-no-repeat rounded-[12px]'
						)}
					>
						<span className="text-white text-[32px] font-bold">
							{notification?.subject} : {notification?.name}
						</span>

						<p className="text-[18px] text-white lg:w-[726px]">
							{notification?.message}
						</p>

						<article
							className={clsx(
								'w-full h-full lg:h-[101px]',
								'flex flex-col lg:flex-row justify-between items-center',
								'rounded-2xl border-2 border-[#4AD669] p-4'
							)}
						>
							{/* Desktop */}
							<InfoItem
								title="Assigned"
								value={dateFormatted}
								align="start"
								className="hidden lg:flex"
							/>

							{notification?.notification_type !== 'Program Session' && (
								<InfoItem
									title="Duration"
									value={notification?.info.duration}
									className="hidden lg:flex"
									align="center"
								/>
							)}

							{notification?.notification_type !== 'Group Session' && (
								<InfoItem
									title="Exercises"
									value={notification?.info.exercises}
									className="hidden lg:flex"
									align="center"
								/>
							)}

							<InfoItem
								title="Coach"
								value={notification?.trainer.name}
								imageSrc={notification?.trainer.image}
								className="hidden lg:flex"
							/>

							<InfoItem
								title="Gym"
								value={notification?.customer.name}
								imageSrc={notification?.customer.image}
								className="hidden lg:flex"
							/>

							{/* Mobile */}
							<InfoItem
								title="Assigned"
								value={dateFormatted}
								align="center"
								className="flex lg:hidden"
							/>

							{notification?.notification_type !== 'Group Session' && (
								<InfoItem
									title="Exercises"
									value={notification?.info.exercises}
									className="flex lg:hidden"
									align="center"
								/>
							)}

							{notification?.notification_type !== 'Program Session' && (
								<InfoItem
									title="Duration"
									value={notification?.info.duration}
									className="flex lg:hidden"
									align="center"
								/>
							)}

							<InfoItemMobile
								title="Coach"
								value={notification?.trainer.name}
								imageSrc={notification?.trainer.image}
								className="flex lg:hidden"
							/>
							<InfoItemMobile
								title="Gym"
								value={notification?.customer.name}
								imageSrc={notification?.customer.image}
								className="flex lg:hidden"
							/>
						</article>
					</div>
				)}
			</section>
		</Layout>
	)
}
