import React from 'react'
import { Button } from 'components'
import { AssignProgramType, Icons, Images } from 'interfaces'
import clsx from 'clsx'
import styles from './program.module.scss'

export const AssignProgramItem: React.FC<AssignProgramType> = ({
	slug,
	cover = Images.program_cover,
	name,
	sessions,
	active,
	onClick
}): JSX.Element => {
	return (
		<div
			className={clsx(
				'flex flex-col border-[1px] border-custom shadow-[0px_4px_20px_rgba(0,0,0,0.16)] rounded-lg min-h-[100px] max-h-[100px]',
				'relative w-full rounded-lg overflow-hidden'
			)}
		>
			<img
				src={cover || Images.program_cover}
				alt="cover"
				className="object-cover w-full h-[100px] rounded-lg border border-custom"
			/>
			<div
				className={clsx(styles.gradient, 'absolute inset-0 min-h-[114px]')}
			/>
			<div className="absolute inset-0 flex flex-col min-h-[100px] p-2">
				<p className="h-[58px] font-Roboto font-bold text-2xl text-white line-clamp-2 text-ellipsis">
					{name}
				</p>
				<div className="w-full flex justify-between h-[26px]">
					<p className="font-Roboto font-bold text-sm text-white">
						Number session {sessions}
					</p>
					<Button
						onClick={() => onClick(slug)}
						type="button"
						className={clsx('ml-1')}
						label="select"
						customColor={active === slug ? true : undefined}
						color={active === slug ? 'Tranparent' : 'Secondary'}
						rounded
						size="extra-small"
						iconLeft={Icons.pencil}
					/>
				</div>
			</div>
		</div>
	)
}

export default AssignProgramItem
