export const GET_CHARACTERS = 'GET_CHARACTERS'
export const GET_PROFILE = 'GET_PROFILE'
export const GET_PROGRAMS = 'GET_PROGRAMS'
export const GET_LIBRARY_EXERCISES = 'GET_LIBRARY_EXERCISES'
export const GET_PROGRAM = 'GET_PROGRAM'
export const GET_ATHLETES = 'GET_ATHLETES'
export const GET_ATHLETE_BY_ID = 'GET_ATHLETE_BY_ID'
export const GET_PROGRAM_SESSION = 'GET_PROGRAM_SESSION'
export const GET_ASSIGN_PROGRAM = 'GET_ASSIGN_PROGRAM'
export const GET_ASSIGNED_PROGRAM = 'GET_ASSIGNED_PROGRAM'
export const GET_TYPE_METRICS = 'GET_TYPE_METRICS'
export const GET_LIST_ATHLETES_ASSIGNED_PROGRAM =
	'GET_LIST_ATHLETES_ASSIGNED_PROGRAM'
export const GET_CLIENTS_IDS = 'GET_CLIENTS_IDS'
export const GET_LIST_NOTES = 'GET_LIST_NOTES'
export const GET_GROUPS = 'GET_GROUPS'
export const GET_GROUPS2 = 'GET_GROUPS2'
export const GET_GROUP = 'GET_GROUP'
