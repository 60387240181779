import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import {
	AssignedExercise,
	AssignedProgramAthConfigProps,
	AssignedProgramSession
} from 'interfaces'
import { Drawer, DrawerSetValuesConf } from 'components'

export const AssignedProgramAthConfig: React.FC<
	AssignedProgramAthConfigProps
> = ({
	session,
	exercise,
	athlete,
	athleteInfo,
	refetchList,
	refetchAssignedProgram
}): JSX.Element => {
	const [athletesState, setAthletesState] = useState(athlete)
	const [sessionNow, setSessionNow] = useState<AssignedProgramSession>()
	const [exerciseNow, setExerciseNow] = useState<AssignedExercise>(exercise)
	const [config, setConfig] = useState(false)
	const [openDrawer, setOpenDrawer] = useState(false)
	const defaultURL = '/asset/images/default_avatar.png'

	useEffect(() => {
		if (athlete) setAthletesState(athlete)
	}, [athlete])

	useEffect(() => {
		if (
			athletesState.assigned_program &&
			athletesState.assigned_program.assigned_program_sessions
		) {
			const auxS =
				athletesState.assigned_program.assigned_program_sessions.filter(
					itemS => itemS.program_session.slug === session.slug
				)[0]
			setSessionNow(auxS)
		}
	}, [athletesState])

	useEffect(() => {
		if (sessionNow && sessionNow.assigned_exercises) {
			const auxEx = sessionNow.assigned_exercises.filter(
				itemEx => itemEx.exercise === exercise.slug
			)[0]
			setExerciseNow(auxEx)
		}
	}, [sessionNow])

	const handleClick = (): void => {
		setOpenDrawer(true)
	}

	return (
		<div className="relative">
			<button
				type="button"
				onClick={() => handleClick()}
				className={clsx(
					'w-[88px] absolute top-[15px] right-10 md:right-16',
					'font-Roboto text-sm border-[1px] border-gray-600 px-2 py-[5px] rounded-[3px]',
					'hover:bg-gray-600 hover:text-white'
				)}
			>
				Set Values
			</button>

			<div
				// type="button"
				className={clsx(
					'flex justify-between items-center',
					'h-[60px] p-2 w-[96%] ml-[2%]',
					'shadow border-[1px]',
					'cursor-pointer',
					'bg-white border-gray-300 hover:bg-gray-200'
					// open3 ? 'rounded-b-none' : ''
				)}
			>
				<div className="flex items-center">
					<div className="px-2">
						<img
							alt={athleteInfo.first_name}
							src={
								athleteInfo.profile_image && athleteInfo.profile_image.url
									? athleteInfo.profile_image.url
									: defaultURL
							}
							className="w-9 h-9 max-w-[36px]"
							onError={({ currentTarget }) => {
								// eslint-disable-next-line no-param-reassign
								currentTarget.src = defaultURL
							}}
						/>
					</div>
					<div className="flex flex-col w-full justify-start pl-2">
						<h3
							className={clsx(
								'text-sm text-left leading-6text-gray-900 font-Roboto font-bold',
								'lg:text-lg'
							)}
						>
							{`${athleteInfo.first_name}  ${athleteInfo.last_name}`}
						</h3>
						<div
							className={clsx(
								'text-[10px] text-white w-[88px] px-1 rounded-xl',
								{
									'bg-custom': config
								},
								{
									'bg-gray-700': !config
								}
							)}
						>
							{config ? 'Configured' : 'Not Configured'}
						</div>
					</div>
				</div>
			</div>
			{exerciseNow && (
				<Drawer
					open={openDrawer}
					setOpen={(val: boolean) => setOpenDrawer(val)}
					title="SET ATHLETES VALUES"
					mobileFull
				>
					<DrawerSetValuesConf
						exercise={exerciseNow}
						athleteInfo={athleteInfo}
						setOpen={(val: boolean) => setOpenDrawer(val)}
						refetchList={refetchList}
						refetchAssignedProgram={refetchAssignedProgram}
						setConfig={setConfig}
					/>
				</Drawer>
			)}
		</div>
	)
}
