import { API } from 'api/config'
import { ASSIGNED_PROGRAMS_ENDPOINTS } from 'api/common'

type DeleteAssignedProgramProps = {
	customToken?: string
	slug: string
}

export const deleteAssignedProgram = async ({
	slug
}: DeleteAssignedProgramProps): Promise<number | null> => {
	let res = null as number | null

	await API()
		.delete(`${ASSIGNED_PROGRAMS_ENDPOINTS.BASE}/${slug}/`)
		.then(
			async response => {
				res = response.status
			},
			error => {
				res = error.status
				console.error(error)
			}
		)

	return res
}
