import { BaseURL } from 'api/environment'
import axios from 'axios'
import { sessionType } from 'interfaces'

export const cloneProgramSession = async (
	token: string,
	slug: string
): Promise<sessionType | null> => {
	let res = null as sessionType | null
	await axios
		.post(
			`${BaseURL}/program_sessions/clone/`,
			{ program_session: slug },
			{
				headers: {
					Authorization: `Token ${token}`
				}
			}
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const createProgramSession = async (
	token: string,
	slugProgram: string,
	name: string
): Promise<sessionType | null> => {
	let res = null as sessionType | null
	await axios
		.post(
			`${BaseURL}/program_sessions/`,
			{ name, program: slugProgram },
			{
				headers: {
					Authorization: `Token ${token}`
				}
			}
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
