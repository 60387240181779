import { BaseURL } from 'api/environment'
import axios from 'axios'

export const deleteAllNotifications = async (
	token: string
): Promise<number | null> => {
	let response = null as number | null

	await axios
		.delete(`${BaseURL}/notifications/?clear_all=True`, {
			headers: {
				Authorization: `Token ${token}`
			}
		})
		.then(async data => {
			response = data.status
		})
		.catch(error => {
			console.error(error)
		})

	return response
}
