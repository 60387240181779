import { StatusExerciseContext } from 'context'
import React, { useMemo, useState } from 'react'

interface Props {
	children: JSX.Element
}
export const StatusExerciseProvider = ({ children }: Props): JSX.Element => {
	const [statusExercise, setStatusExercise] = useState({
		slug: '',
		status: 1
	})
	const statusExerciseState = useMemo(
		() => ({ statusExercise, setStatusExercise }),
		[statusExercise, setStatusExercise]
	)
	return (
		<StatusExerciseContext.Provider value={statusExerciseState}>
			{children}
		</StatusExerciseContext.Provider>
	)
}
