import React, { useRef, useState } from 'react'
import { XIcon } from '@heroicons/react/outline'
import { Button, Loading } from 'components'
import { AnnotationType, Images, NotesProps } from 'interfaces'
import { useQuery } from 'react-query'
import { getListNotes, GET_LIST_NOTES } from 'api'
import Cookies from 'universal-cookie'
import clsx from 'clsx'
import { useClickOutside } from 'hooks'
import { ItemNotes, Notes2, NotesEdit } from '.'
import './notes.scss'

export const Notes: React.FC<NotesProps> = ({
	idSession,
	session,
	setModalNotes
}): JSX.Element => {
	const ref = useRef(null)
	const cookies = new Cookies()
	const token = cookies.get('token')

	const [showNotes, setShowNotes] = useState(false)
	const [editNotes, setEditNotes] = useState(false)
	const [idSelected, setIdSelected] = useState(0)
	const [idNote, setIdNote] = useState(0)
	const [annotations, setAnnotations] = useState<AnnotationType[]>([])

	useClickOutside(
		ref,
		showNotes || editNotes
			? () => setModalNotes(true)
			: () => setModalNotes(false)
	)

	const { data, isLoading, refetch } = useQuery(
		[GET_LIST_NOTES],
		() => getListNotes(token, idSession),
		{
			refetchOnMount: 'always'
		}
	)

	React.useEffect(() => {
		if (data) {
			setAnnotations(data)
		}
	}, [data])

	return (
		<>
			<div
				ref={ref}
				className="w-full h-full flex flex-col justify-center items-center"
			>
				<header className="w-full h-[57px] bg-notes px-4 flex justify-between items-center rounded-t-lg">
					<h2 className="font-Roboto text-white">{session}</h2>
					<button
						type="button"
						className="w-6 h-6 cursor-pointer"
						onClick={() => setModalNotes(false)}
					>
						<XIcon className="w-full h-full text-white" />
					</button>
				</header>
				{isLoading ? (
					<Loading />
				) : (
					<div className="w-full h-[325px] flex flex-col justify-center items-center">
						{annotations.length === 0 ? (
							<div className="w-full h-full flex flex-col justify-center items-center gap-3">
								<img
									src={Images.newNotes}
									alt="new notes"
									className="w-[120px] h-[120px]"
								/>
								<p className="text-[#454952] font-Roboto font-normal text-[16px] leading-[24px] opacity-40">
									you dont have any notes yet
								</p>
							</div>
						) : (
							<div
								className={clsx(
									'w-full h-[280px] flex flex-col justify-start items-center gap-3',
									'overflow-hidden hide-scroll-bar overflow-y-scroll'
								)}
							>
								{annotations.map(item => (
									<ItemNotes
										key={item.slug}
										id={item.id}
										session={item.session}
										title={item.title}
										date={item.created_at}
										priority={item.priority}
										setIdNote={setIdNote}
										setEditNotes={setEditNotes}
										setIdSelected={setIdSelected}
										refetch={refetch}
									/>
								))}
							</div>
						)}
					</div>
				)}
				<div className="w-full flex items-center justify-end pb-4 px-4">
					<Button
						label="Add new"
						customColor
						className="w-[179px] h-8 cursor-pointer"
						onClick={() => setShowNotes(true)}
					/>
				</div>
			</div>
			{/* MODAL SHOW NOTES */}
			{showNotes && (
				<div className="relative z-50 inset-0">
					<div className="fixed inset-0 bg-black bg-opacity-25" />
					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<div className="w-full lg:w-[440px] h-[390px] bg-white rounded-lg border border-[#B5BACE]">
								<Notes2
									idSession={idSession}
									setShowNotes={setShowNotes}
									refetch={refetch}
								/>
							</div>
						</div>
					</div>
				</div>
			)}

			{/* * MODAL EDIT */}
			{editNotes && (
				<div className="relative z-30 inset-0">
					<div className="fixed inset-0 bg-black bg-opacity-25" />
					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<div className="w-full lg:w-[440px] h-[390px] bg-white rounded-lg border border-[#B5BACE]">
								<NotesEdit
									idNote={idNote}
									idSelected={idSelected}
									setEditNotes={setEditNotes}
									refetch={refetch}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
