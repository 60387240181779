import { BaseURL } from 'api'
import axios from 'axios'
import { AssignedExecutedMetricType } from 'interfaces'

interface UpdateAssignedMetricExecuteResponse {
	slug: string
	assigned_metric: string
	value: string
}
export const updateMetricExecuted = async (
	token: string,
	metricSlug: string,
	body: AssignedExecutedMetricType
): Promise<UpdateAssignedMetricExecuteResponse | null> => {
	let res = null as UpdateAssignedMetricExecuteResponse | null
	await axios
		.put(`${BaseURL}/assigned_executed_metrics/${metricSlug}/`, body, {
			headers: {
				Authorization: `Token ${token}`
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
