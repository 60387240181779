import { toPng } from 'html-to-image'

export const ScreenshotModal = (
	name: string,
	refDiv: HTMLElement | null
): void => {
	if (refDiv) {
		toPng(refDiv, {
			backgroundColor: '#FFF',
			cacheBust: true,
			style: {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}
		})
			.then(() => {
				toPng(refDiv, {
					backgroundColor: '#FFF',
					cacheBust: true,
					style: {
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}
				})
					.then(dataUrl => {
						const link = document.createElement('a')
						link.download = `${name.replace(' ', '_')}.png`
						link.href = dataUrl
						link.click()
					})
					.catch(err => {
						console.error(err)
					})
			})
			.catch(err => {
				console.error(err)
			})
	}
}
