import { useUserType } from 'interfaces'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { useQuery } from 'react-query'
import { getProfile, GET_PROFILE } from 'api'

const cookies = new Cookies()

export const useUser = (): useUserType => {
	const navigate = useNavigate()
	const { data, isLoading, refetch } = useQuery(
		[GET_PROFILE],
		() => getProfile(),
		{
			refetchOnMount: 'always'
		}
	)

	let newUser
	const token = cookies.get('token')
	const res = data?.user
	if (res) {
		newUser = { ...res, token }
	}

	const signOut = (): void => {
		cookies.remove('token')
		cookies.remove('email')
		cookies.remove('customer')
		cookies.remove('customer_color')
		navigate('/signin')
	}
	return { user: newUser, signOut, isLoading, refetch }
}
