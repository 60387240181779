import { API, ASSIGNED_PROGRAMS_ENDPOINTS, BaseURL } from 'api'
import {
	AssignedProgramResponse,
	AssignedProgramResponse2,
	AssignProgramResponse,
	clientAssignedProgramsType
} from 'interfaces'

type GetAssignedProgramList = {
	customToken?: string
	athleteId: number
}

export const getAssignedProgramList = async ({
	athleteId
}: GetAssignedProgramList): Promise<AssignProgramResponse | null> => {
	let response = null as AssignProgramResponse | null
	await API()
		.get(ASSIGNED_PROGRAMS_ENDPOINTS.BASE, {
			params: {
				ids: athleteId,
				ordering: '-state'
			}
		})
		.then(
			async resp => {
				response = resp.data
			},
			error => {
				console.error(error)
			}
		)

	return response
}

type GetAssignedProgramListBySlug = {
	customToken?: string
	assignProgramSlug: string
	stats?: boolean
}
export const getAssignedProgramBySlug = async ({
	assignProgramSlug,
	stats
}: GetAssignedProgramListBySlug): Promise<AssignedProgramResponse | null> => {
	let response = null as AssignedProgramResponse | null
	await API()
		.get(`${ASSIGNED_PROGRAMS_ENDPOINTS.BASE}/${assignProgramSlug}`, {
			params: {
				stats: stats ? '' : 'stats=false'
			}
		})
		.then(
			async resp => {
				response = resp.data
			},
			error => {
				console.error(error)
			}
		)

	return response
}
type GetAssignedProgramListBySlugAndId = {
	customToken?: string
	ids?: string
	programSlug?: string
}
export const getAssignedProgramBySlugsAndIds = async ({
	ids,
	programSlug
}: GetAssignedProgramListBySlugAndId): Promise<
	AssignedProgramResponse2[] | null
> => {
	let response = null as AssignedProgramResponse2[] | null
	await API()
		.get(`${ASSIGNED_PROGRAMS_ENDPOINTS.BASE}/list_clients`, {
			params: {
				ids,
				slugs: programSlug
			}
		})
		.then(
			async resp => {
				response = resp.data
			},
			error => {
				console.error(error)
			}
		)

	return response
}

type GetListAthletesAssignedProgram = {
	customToken?: string
	athleteIds?: string
	programSlugs?: string
}
export const getListAthletesAssignedProgram = async ({
	athleteIds,
	programSlugs
}: GetListAthletesAssignedProgram): Promise<
	clientAssignedProgramsType[] | null
> => {
	let response = null as clientAssignedProgramsType[] | null
	if (programSlugs && athleteIds)
		await API()
			.get(`${ASSIGNED_PROGRAMS_ENDPOINTS.BASE}/list_clients`, {
				params: {
					ids: athleteIds,
					slugs: programSlugs
				}
			})
			.then(
				async resp => {
					response = resp.data
				},
				error => {
					console.error(error)
				}
			)

	return response
}

export const getAssignedProgramReport = async (
	token: string,
	programId: number,
	programName: string
): Promise<void> => {
	await fetch(`${BaseURL}/assigned_program_report/?id=${programId}`, {
		headers: {
			Authorization: `Token ${token}`
		}
	})
		.then(res => {
			return res.blob()
		})
		.then(data => {
			const a = document.createElement('a')
			a.href = window.URL.createObjectURL(data)
			a.download = programName
			a.click()
		})
		.catch(error => {
			console.error(error)
		})
}
