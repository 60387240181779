import clsx from 'clsx'
import { Icon } from 'components'
import { Icons } from 'interfaces'
import React, { FC } from 'react'
import { useCustomer } from 'hooks'
import { SettingsCardProps } from './settingsCard.interface'

export const SettingsCard: FC<SettingsCardProps> = props => {
	const { onClick, isDropDown, openDropdown, children, cardIcon } = props
	const customer = useCustomer()

	return (
		<div className="bg-white xl:w-[892px] xl:max-h-max rounded-[20px] p-6 flex flex-col gap-6">
			<button
				type="button"
				data-testid="setting_card_button"
				className={clsx(
					'bg-white w-full rounded-[20px]',
					'flex flex-col gap-6'
				)}
				onClick={onClick}
			>
				<div className="w-full flex items-center justify-between">
					<div className="w-full flex items-center gap-4">
						<Icon src={cardIcon} className="h-6 w-6" />

						<span className="text-[#1D2027] text-2xl font-medium">
							Notifications
						</span>
					</div>

					{isDropDown && (
						<div
							data-testid="arrow-dropdown"
							className={clsx(!openDropdown ? 'rotate-180' : 'rotate-0')}
						>
							<Icon src={Icons.arrow_settings} className="h-6 w-6 block" />
						</div>
					)}
				</div>

				<p className="text-[#1D2027] text-left text-lg font-medium">
					{customer.role === 'Client'
						? 'Notifications you receive to remind you of news you may have missed.'
						: 'Configure the type of notifications your customers will receive.'}
				</p>
			</button>

			<div className="w-full">
				{isDropDown && openDropdown && <div className="w-full">{children}</div>}
			</div>
		</div>
	)
}
