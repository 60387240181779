import { BaseURL } from 'api/environment'
import axios from 'axios'
import {
	UpdateNotificationsBody,
	UpdateNotificationsResponse
} from './putNotificationSettings.interface'

export const putNotificationSettings = async (
	token: string,
	customerId: number,
	body: UpdateNotificationsBody
): Promise<UpdateNotificationsResponse | null> => {
	let data = null as UpdateNotificationsResponse | null

	await axios
		.put(
			`${BaseURL}/settings_notifications/?x-customer-id=${customerId}`,
			body,
			{
				headers: {
					Authorization: `Token ${token}`
				}
			}
		)
		.then(async response => {
			data = response.data
		})
		.catch(error => console.error(error))

	return data
}
