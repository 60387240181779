import { Disclosure } from '@headlessui/react'
import { PauseIcon, PlayIcon } from '@heroicons/react/outline'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { updateStatusClient } from 'api'
import clsx from 'clsx'
import { Drawer, DrawerInTraining } from 'components'
import { useCustomer, useToast } from 'hooks'
import { AssignedProgramType, BoxAthleteType, Images } from 'interfaces'
import { StatusExerciseProvider } from 'provider/statusExercise'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'

export const BoxAthletes2: React.FC<BoxAthleteType> = ({
	id,
	image,
	fullname,
	status,
	assignedPrograms,
	refetchUp,
	onClick
}): JSX.Element => {
	const customer = useCustomer()
	const { showToast } = useToast()
	const cookies = new Cookies()
	const token = cookies.get('token')
	const navigate = useNavigate()

	const [isLoading, setIsLoading] = useState(false)
	const [state, setState] = useState(false)
	const [openDrawer, setOpenDrawer] = useState(false)
	const [referenceId, setReferenceId] = useState(id)
	const [slug, setSlug] = useState('')

	const [activePrograms, setActivePrograms] = useState<
		AssignedProgramType[] | undefined
	>([])

	const [nActivePrograms, setnActivePrograms] = useState(0)
	const [nArchivedPrograms, setnArchivedPrograms] = useState(0)

	useEffect(() => {
		if (!openDrawer) navigate('/training')
	}, [openDrawer])

	const changeStatusClient = async (isActive: boolean): Promise<void> => {
		let res
		setIsLoading(true)
		try {
			res = await updateStatusClient(token, id, customer.id, isActive)
			if (res) setState(res.is_active)
			if (res && isActive) {
				showToast(
					'Add to in training',
					`${fullname} was added to training`,
					'success'
				)
			} else if (!isActive) {
				showToast(
					'Remove from in training',
					`${fullname} was removed from training`,
					'success'
				)
			}
		} catch (error) {
			console.error(error)
			if (isActive)
				showToast(
					'Add to in training',
					`${fullname} could not be added to training`,
					'error'
				)
			else if (!isActive)
				showToast(
					'Remove from in training',
					`${fullname} could not be removed to training`,
					'error'
				)
		} finally {
			setIsLoading(false)
			refetchUp()
		}
	}

	useEffect(() => {
		refetchUp()
	}, [state, openDrawer])

	useEffect(() => {
		setActivePrograms(
			assignedPrograms
				?.filter(item => item.archived === false)
				.filter(item => item.completed === false)
		)
		setnActivePrograms(
			(assignedPrograms?.length || 0) -
				(assignedPrograms?.filter(item => item.archived).length || 0) -
				(assignedPrograms?.filter(item => item.completed).length || 0) <=
				0
				? 0
				: (assignedPrograms?.length || 0) -
						(assignedPrograms?.filter(item => item.archived).length || 0) -
						(assignedPrograms?.filter(item => item.completed).length || 0)
		)
		setnArchivedPrograms(
			assignedPrograms?.filter(item => item.archived).length || 0
		)
	}, [assignedPrograms])

	return (
		<Disclosure>
			{({ open }) => (
				<div className="relative">
					<div className="absolute top-[35px] right-[40px] group">
						{nActivePrograms === 0 && (
							<div
								className={clsx(
									'text-white text-xs border border-gray-300 px-2 py-1',
									'bg-gray-400 w-[230px] sm:w-[300px] h-10 z-10 absolute -top-2 right-8 sm:right-10',
									'flex justify-center items-center rounded-lg',
									'group-hover:opacity-100 opacity-0 transition-opacity'
								)}
							>
								<span>
									Can&#39;t switch to In Training because there&#39;s no active
									program.
								</span>
							</div>
						)}
						{status !== undefined && (
							<button
								type="button"
								disabled={nActivePrograms === 0 && status === true}
								onClick={() => changeStatusClient(status || false)}
								className="cursor-pointer"
							>
								{status ? (
									isLoading ? (
										<div className="flex justify-between items-center">
											<svg
												role="status"
												className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-light fill-custom"
												viewBox="0 0 100 101"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d={`M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 
													0 78.2051 0 50.5908C0	22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082
													100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094
													50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921
													72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z`}
													fill="currentColor"
												/>
												<path
													// eslint-disable-next-line max-len
													d={`M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 
													24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 
													63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345
													1.27873C39.2613 1.69328 37.813 
													4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 
													9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 
													70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 
													32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z`}
													fill="currentFill"
												/>
											</svg>
											<div className="border-r-2 border-gray-300 px-1 h-6 self-center" />
										</div>
									) : (
										<div className="flex justify-between items-center">
											<PlayIcon
												className="h-6 w-6 text-[green]"
												aria-hidden="true"
											/>
											<div className="border-r-2 border-gray-300 px-1 h-6 self-center" />
										</div>
									)
								) : isLoading ? (
									<div className="flex justify-between items-center">
										<svg
											role="status"
											className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:color-gray-light fill-custom"
											viewBox="0 0 100 101"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d={`M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 
														0 78.2051 0 50.5908C0	22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082
														100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094
														50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921
														72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z`}
												fill="currentColor"
											/>
											<path
												d={`M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 
													24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422
													4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345
													1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 
													10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642
													10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849
													25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 
													39.6781 93.9676 39.0409Z`}
												fill="currentFill"
											/>
										</svg>
										<div className="border-r-2 border-gray-300 px-1 h-6 self-center" />
									</div>
								) : (
									<div className="flex justify-between items-center">
										<PauseIcon
											className="h-6 w-6 text-[red]"
											aria-hidden="true"
										/>
										<div className="border-r-2 border-gray-300 px-1 h-6 self-center" />
									</div>
								)}
							</button>
						)}
					</div>
					<Disclosure.Button
						type="button"
						onClick={onClick}
						className={clsx(
							'my-2 cursor-pointer',
							'shadow sm:rounded-lg border-[1px] border-gray-300 border-l-4 border-l-custom w-full rounded-lg',
							'bg-white hover:bg-gray-200'
						)}
					>
						<div className="flex justify-between p-2">
							<img
								src={image || Images.default_avatar}
								alt=""
								className="w-14 h-14 p-1"
							/>
							<div className="w-full pl-2">
								<h3 className="text-base text-left leading-6text-gray-900 font-Roboto font-bold lg:text-2xl">
									{fullname}
								</h3>
								<p className="w-[85%] text-xs text-gray-500 lg:text-lg text-left">
									{`${nActivePrograms} active programs ${
										nArchivedPrograms > 0
											? `- ${nArchivedPrograms} archived programs`
											: ''
									}`}
								</p>
							</div>
							<div className="flex justify-center items-center">
								<ChevronRightIcon
									className={clsx(
										'h-6 w-6 text-custom',
										open ? '-rotate-90 transform' : 'rotate-90'
									)}
									aria-hidden="true"
								/>
							</div>
						</div>
					</Disclosure.Button>
					<Disclosure.Panel>
						<div
							className={clsx(
								'border border-gray-200  bg-white',
								'w-[96%] ml-[2%] text-lg font-Roboto font-bold cursor-pointer',
								'shadow-md mt-[-0.5rem]'
							)}
						>
							{activePrograms?.map((item, index) => {
								const pos = index
								return (
									<div key={pos}>
										{!item.completed && (
											<button
												key={`${pos}-${item.slug}`}
												type="button"
												className="flex justify-between items-center
											w-full border border-separate h-[42px]
											shadow mb-[2px]"
												onClick={() => {
													setOpenDrawer(true)
													setReferenceId(item.client_reference_id)
													setSlug(item.slug)
												}}
											>
												<p className="p-2">{item.name}</p>
												<div className="flex justify-center items-center">
													<ChevronRightIcon
														className="h-6 w-6 text-custom"
														aria-hidden="true"
													/>
												</div>
											</button>
										)}
									</div>
								)
							})}
							<Drawer
								open={openDrawer}
								setOpen={(val: boolean) => setOpenDrawer(val)}
								title="SET ATHLETE VALUES"
								stretch
								mobileFull
							>
								<StatusExerciseProvider>
									<DrawerInTraining
										athleteId={referenceId}
										assignedProgramSlug={slug}
										setOpenDrawer={setOpenDrawer}
										refetchUp={() => {
											if (refetchUp) refetchUp()
										}}
									/>
								</StatusExerciseProvider>
							</Drawer>
						</div>
					</Disclosure.Panel>
				</div>
			)}
		</Disclosure>
	)
}
