import React from 'react'
import { ProgressBarProps } from 'interfaces'
import clsx from 'clsx'

export const ProgressBar: React.FC<ProgressBarProps> = ({
	steps,
	percent
}): JSX.Element => {
	const completed = (percent * steps) / 100
	const percents = 100 / steps
	const array = Array(steps).fill('')

	return (
		<div className="w-full h-[2px] flex">
			{array.map((_item, index) => {
				const pos = `item-bar-${index}`
				return (
					<div
						key={pos}
						className="h-[2px] flex px-[2px] first:pl-0 last:pr-0"
						style={{ width: `${percents}%` }}
					>
						<div
							className={clsx(
								{ 'bg-custom': index + 1 <= completed },
								{ 'bg-gray-500': index + 1 > completed },
								'h-[2px] w-full'
							)}
						/>
					</div>
				)
			})}
		</div>
	)
}
