import clsx from 'clsx'
import { Loading } from 'components'
import { CardSession } from 'components/box/athletes/cardSession'
import React from 'react'

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
	Tooltip
	// Legend
} from 'chart.js'
import { Bar, Radar } from 'react-chartjs-2'
import { AssignedProgramSession, GIF } from 'interfaces'

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
	Tooltip
	// Legend
)

export interface DrawerSessionMobileProps {
	labelSessions: string[] | undefined
	percSessionsCompleted: number[]
	listSession: AssignedProgramSession[] | undefined
	palleteColor: string[]
	checkSlugSession: string
	handleCheckSession: (slug: string) => void
	setOpenExerciseMobile: React.Dispatch<React.SetStateAction<boolean>>
	isLoadingProgram: boolean
	setOpenModalSession: React.Dispatch<React.SetStateAction<boolean>>
}

export const DrawerSessionMobile: React.FC<DrawerSessionMobileProps> = ({
	labelSessions,
	percSessionsCompleted,
	listSession,
	palleteColor,
	checkSlugSession,
	handleCheckSession,
	setOpenExerciseMobile,
	isLoadingProgram,
	setOpenModalSession
}): JSX.Element => {
	return (
		<div className="flex flex-col gap-4">
			<div className="px-10">
				<div className="w-full flex justify-end items-center py-2">
					<button
						type="button"
						className="h-[30px] border border-gray-400 px-5 rounded-md"
						onClick={() => setOpenModalSession(true)}
					>
						Screenshot Session
					</button>
				</div>
				<div className="w-full h-[222px] flex justify-center items-center bg-white rounded-xl p-3">
					{!labelSessions ? (
						<div className="w-full h-[222px] bg-[transparent] rounded-xl">
							<img
								src={GIF.emptyCharts}
								alt="chart-base"
								className="w-full h-[222px] rounded-xl"
							/>
						</div>
					) : (
						<div className="w-[222px] h-[222px] flex justify-center items-center">
							{percSessionsCompleted?.length > 4 ? (
								<Radar
									className="shadow-md rounded-md border border-gray-300"
									data={{
										// labels: labelSessions,
										labels: labelSessions?.map(() => ''),
										datasets: [
											{
												label: 'Sessions',
												data: percSessionsCompleted,
												backgroundColor: palleteColor,
												borderWidth: 1
											}
										]
									}}
									options={{
										responsive: true,
										plugins: {
											title: {
												display: true,
												text: 'Sessions'
											}
										},
										scales: {
											r: {
												suggestedMin: 0,
												suggestedMax: 100
											}
										}
									}}
								/>
							) : (
								<Bar
									className="shadow-md rounded-md border border-gray-300 px-4"
									data={{
										labels: labelSessions,
										datasets: [
											{
												data: percSessionsCompleted,
												backgroundColor: palleteColor,
												borderWidth: 1
											}
										]
									}}
									options={{
										responsive: true,
										maintainAspectRatio: true,
										scales: {
											y: {
												min: 0,
												max: 100
											}
										},
										plugins: {
											title: {
												display: true,
												text: 'Sessions'
											}
										}
									}}
								/>
							)}
						</div>
					)}
				</div>
				<div className="w-full h-[222px] bg-white rounded-xl p-3 shadow-xl border border-gray-300 mt-2">
					<span className="text-xs text-[#454952] font-bold">Completed %</span>
					<div
						className={clsx(
							'w-full h-full flex justify-between',
							'overflow-hidden hide-scroll-bar overflow-y-scroll',
							'min-h-[170px] max-h-[170px]'
						)}
					>
						<div className="w-full flex flex-col flex-nowrap">
							{listSession?.map((item, idx) => (
								<span
									key={item.slug}
									className="flex gap-2 text-xs text-[#454952]"
								>
									<span
										className={clsx(
											'w-5 h-3 flex justify-center items-center bg-custom rounded-full text-white'
										)}
									>
										{idx + 1}
									</span>
									{item.program_session.name}
								</span>
							))}
						</div>
						<div className="w-full flex flex-col items-end flex-nowrap">
							{percSessionsCompleted?.map((item, idx) => {
								const pos = `pos-${idx}`
								return (
									<span key={pos} className="text-xs text-[#454952]">
										{item} %
									</span>
								)
							})}
						</div>
					</div>
				</div>
			</div>
			<span className="w-full text-sm text-gray-400 text-center">
				Select a session for more details
			</span>
			{isLoadingProgram ? (
				<div
					className={clsx(
						'flex justify-center items-center',
						'min-h-[140px] max-h-[140px]'
					)}
				>
					<Loading isSmall />
				</div>
			) : (
				<div
					className={clsx(
						'flex flex-col gap-3 px-5 pb-4',
						'min-h-[calc(100vh-440px)] max-h-[calc(100vh-440px)]',
						'lg:min-h-[calc(100vh-610px)] lg:max-h-[calc(100vh-610px)]',
						'overflow-hidden hide-scroll-bar overflow-y-scroll'
					)}
				>
					{listSession?.map(session => (
						<CardSession
							key={session.slug}
							session={session}
							checkedSession={checkSlugSession}
							handleCheckSession={handleCheckSession}
							setOpenExerciseMobile={setOpenExerciseMobile}
						/>
					))}
				</div>
			)}
		</div>
	)
}
