import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { ArchiveAssignedProgramItem, Button, Icon, Loading } from 'components'
import {
	ArchiveAssignedProgramProps,
	AssignedProgramType,
	athleteType,
	Icons
} from 'interfaces'
import { useCustomer, useToast } from 'hooks'
import Cookies from 'universal-cookie'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { editAssignedProgram, getClientById, GET_ATHLETE_BY_ID } from 'api'
import { ClipboardIcon } from '@heroicons/react/outline'

export const ArchiveAssignedProgram: React.FC<ArchiveAssignedProgramProps> = ({
	setArchiveDrawer
}): JSX.Element => {
	const customer = useCustomer()
	const params = useParams()
	const cookies = new Cookies()
	const token = cookies.get('token')
	const { showToast } = useToast()
	const [programActive, setProgramActive] = useState('')

	const [archivedPrograms, setArchivedPrograms] = useState<
		AssignedProgramType[] | undefined
	>([])

	const [athlete, setAthlete] = useState<athleteType>()

	const { data, isLoading, refetch } = useQuery(
		[GET_ATHLETE_BY_ID, customer.id, token],
		() => getClientById(token, customer.id, params.athleteId),
		{
			refetchOnMount: 'always'
		}
	)

	useEffect(() => {
		if (data) setAthlete(data.client)
	}, [data])

	useEffect(() => {
		if (athlete) {
			setArchivedPrograms(
				athlete?.assigned_programs?.filter(item => item.archived === true)
			)
		}
	}, [athlete])

	const handleSelectProgram = (slug: string): void => {
		setProgramActive(slug)
	}

	const handleUnarchive = async (program: string): Promise<void> => {
		const assignedProgramArchived = await editAssignedProgram({
			slug: program,
			archived: false
		})

		if (assignedProgramArchived) {
			showToast(
				'Assigned program unarchived',
				'Assigned program was unarchived successfully',
				'success'
			)
			setArchiveDrawer(false)
			refetch()
		}
	}

	return (
		<div className="bg-white p-4 flex-1">
			{isLoading ? (
				<Loading />
			) : (
				<div
					className={clsx(
						'flex flex-col gap-2',
						'min-h-[calc(100vh-220px)] max-h-[calc(100vh-220px)]',
						'overflow-hidden hide-scroll-bar overflow-y-scroll',
						'md:min-h-[calc(100vh-180px)] md:max-h-[calc(100vh-180px)]'
					)}
				>
					{!archivedPrograms?.length ? (
						<div
							className={clsx(
								'flex items-center gap-4 text-gray-400 text-3xl font-Roboto',
								'bg-white border border-gray-300 rounded-lg p-4 shadow'
							)}
						>
							<ClipboardIcon className="text-custom w-7 h-7" />
							List Empty
						</div>
					) : (
						<div className="flex flex-col gap-2">
							{archivedPrograms?.map(archivedProgram => {
								return (
									<ArchiveAssignedProgramItem
										key={archivedProgram.slug}
										slug={archivedProgram.slug}
										name={archivedProgram.name}
										active={programActive}
										athleteId={archivedProgram?.client_reference_id}
										cover={archivedProgram.image}
										onClick={handleSelectProgram}
									/>
								)
							})}
						</div>
					)}
				</div>
			)}

			<Button
				customColor
				size="full"
				type="button"
				className="mt-2 lg:mt-4"
				onClick={() => handleUnarchive(programActive)}
			>
				<div className="flex items-center justify-center gap-2">
					<Icon src={Icons.unarchive} className="w-5 h-5" />
					<p className="font-Roboto font-semibold text-base mr-2">Unarchive</p>
				</div>
			</Button>
		</div>
	)
}
