import clsx from 'clsx'
import React from 'react'
import TimePicker from 'react-time-picker'
import './myTimePicker.scss'
import 'react-time-picker/dist/TimePicker.css'
import { ClockIcon } from '@heroicons/react/outline'

interface MyTimePickerProps {
	title: string
	onChange: (value: string | null) => void
	value: string
}

export const MyTimePicker: React.FC<MyTimePickerProps> = ({
	title,
	onChange,
	value
}): JSX.Element => {
	return (
		<div className="flex flex-col w-full" aria-hidden="true">
			{title && (
				<label
					htmlFor="time"
					className={clsx(
						'text-xs mb-1 font-Roboto font-bold text-[#454952]',
						'md:text-lg'
					)}
				>
					{title}
				</label>
			)}
			<TimePicker
				clearIcon={null}
				onChange={onChange}
				value={value}
				format="hh:mm a"
				clockIcon={<ClockIcon className="w-5 h-5 text-[#929BB2] font-bold" />}
				minTime="05:00:00"
				maxTime="22:00:00"
			/>
		</div>
	)
}
