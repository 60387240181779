/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable max-len */
import {
	AssignedMetric,
	AssignedSets,
	DrawerSetsProps,
	Icons
} from 'interfaces'
import React, { useEffect, useState } from 'react'
import { useHorizontalScroll, useToast } from 'hooks'
import { Toogle } from 'components/toggle/Toogle'
import clsx from 'clsx'
import { useQuery } from 'react-query'
import {
	GET_TYPE_METRICS,
	createMetricExecuted,
	getListTypeMetrics,
	updateMetricExecuted
} from 'api'
import { statusExcerciseIcons as arrayStatus } from 'components/modal/modalStatus'
import Cookies from 'universal-cookie'
import { Loading } from 'components/loading'
import { Icon } from 'components/icon'
import { InTrainingMetrics } from 'components/inTrainingMetrics'
import { Modal } from 'components/modal'
import { updateComment } from 'api/patch/inTrainingComment'

interface dataToPostType {
	assigned_metric: string
	value: string
}

interface dataToPutType extends dataToPostType {
	slug: string
}

export const DrawerSets: React.FC<DrawerSetsProps> = props => {
	const {
		isInjury,
		status,
		exercise,
		assignedProgramSlug,
		handleOpenModalStatus,
		statusExercise,
		refetchData,
		setOpenSetsDrawer
	} = props
	const {
		groupsDivRef,
		makeDraggableGroupSection,
		removeDraggableGroupSection
	} = useHorizontalScroll()

	// States management
	const [currentSet, setCurrentSet] = useState(0)
	const [showCommentsInput, setShowCommentsInput] = useState<boolean>(
		!!exercise.comment
	)
	const [comment, setComment] = useState(exercise.comment || '')
	const { assigned_sets: assignedSets } = exercise
	const [setsBreakDown, setSetsBreakDown] = useState<AssignedSets[]>(
		assignedSets || []
	)
	const [dataToSendPost, setDataToSendPost] = useState<
		(dataToPostType | null)[]
	>([])
	const [dataToSendPut, setDataToSendPut] = useState<(dataToPutType | null)[]>(
		[]
	)
	const [openDeleteComment, setOpenDeleteComment] = useState(false)

	useEffect(() => {
		let dataPost = [] as (dataToPostType | null)[]
		dataPost = []
			.concat(...setsBreakDown.map((item: any) => item.assigned_metrics))
			.map((item: any) => {
				if (!item.assigned_execute_metric.slug) {
					return {
						assigned_metric: item.slug,
						value: item.assigned_execute_metric.value
					}
				}
				return null
			})
			.filter(item => item !== null)
			.filter(item => item?.value !== '---')
			.filter(item => item?.value !== '')

		setDataToSendPost(dataPost)

		let dataPut = [] as (dataToPutType | null)[]

		dataPut = []
			.concat(...setsBreakDown.map((item: any) => item.assigned_metrics))
			.map((item: any) => {
				if (item.assigned_execute_metric.slug) {
					return {
						slug: item.assigned_execute_metric.slug,
						assigned_metric: item.slug,
						value: item.assigned_execute_metric.value
					}
				}
				return null
			})
			.filter(item => item !== null)
			.filter(item => item?.value !== '---')
			.filter(item => item?.value !== '')

		setDataToSendPut(dataPut)
	}, [setsBreakDown])

	useEffect(() => {
		if (showCommentsInput === false) {
			setComment('')
		}
		setComment(exercise.comment)
	}, [showCommentsInput])

	// Metrics List Logic
	const cookies = new Cookies()
	const token = cookies.get('token')
	const { showToast } = useToast()
	const { data: listMetrics, isLoading } = useQuery(
		[GET_TYPE_METRICS, token],
		() => getListTypeMetrics(token),
		{
			refetchOnMount: 'always'
		}
	)

	const handleSetRequest = async (): Promise<void> => {
		const body: {
			assigned_program_slug: string
			assigned_executed_metrics: dataToPostType[]
		} = {
			assigned_program_slug: assignedProgramSlug,
			assigned_executed_metrics: dataToSendPost as unknown as dataToPostType[]
		}

		/* COMMENT UPDATE FUNCTION */
		try {
			const responseComment = await updateComment(token, exercise.slug, comment)
			if (responseComment?.comment) {
				showToast(
					'Comment Update',
					'The comment was updated successfully',
					'success'
				)
				setComment(responseComment.comment)
			}
		} catch (error) {
			console.error('COMMENT ERROR', error)
		}

		try {
			if (body) {
				if (dataToSendPost.length !== 0) {
					await createMetricExecuted(token, body)
					showToast(
						'Set Values',
						'metrics was assigned successfully',
						'success'
					)
				}

				dataToSendPut.map(async (item, index) => {
					if (item) {
						const response = await updateMetricExecuted(
							token,
							item.slug ?? '',
							item
						)
						if (response && dataToSendPut.length - 1 === index) {
							showToast(
								'Update assigned exercise',
								'assigned exercise information was updated successfully',
								'success'
							)
						}
					}
				})
			}
		} catch (error) {
			console.error(error)
		}

		refetchData()
		setOpenSetsDrawer(false)
	}

	const handleChangeMetric = (
		slug: string,
		typeMetric: string,
		newDefaultValue: string
	): void => {
		console.info(typeMetric)
		const changedLocalMetrics = assignedSets[currentSet].assigned_metrics.map(
			oldMetric => {
				if (oldMetric.slug === slug) {
					return {
						...oldMetric,
						assigned_execute_metric: {
							...oldMetric.assigned_execute_metric,
							value: newDefaultValue
						}
					}
				}
				return oldMetric
			}
		)
		const updatedLocalSets = [...assignedSets]
		updatedLocalSets[currentSet].assigned_metrics =
			changedLocalMetrics as AssignedMetric[]
		setSetsBreakDown(updatedLocalSets)
	}

	if (isLoading) {
		return <Loading />
	}

	const statusName =
		status === 1
			? 'Normal'
			: status === 2
			? 'Injured'
			: status === 3
			? 'Lack of Time'
			: status === 4
			? 'Recluctan to execute'
			: status === 5
			? 'No equipment'
			: 'Other'

	return (
		<div className="flex flex-col h-full w-full absolute">
			<main className="flex-1 h-10 overflow-y-scroll px-4">
				{/* Exercise Card */}
				<section
					className="flex flex-col justify-between h-auto p-2 w-full my-3 shadow sm:rounded-lg border-[1px] border-l-4 bg-white border-gray-300 hover:bg-gray-200 border-l-custom rounded-lg cursor-default"
					aria-hidden
				>
					<p className="w-full font-bold text-2xl line-clamp-2">
						{exercise.name}
					</p>
					<p className="text-[#929BB2] font-Roboto">{exercise.description}</p>
				</section>

				{/* VIDEO PLAYER */}
				{exercise.video && (
					<div className="my-4">
						<video
							width="100%"
							controls
							autoPlay
							loop
							muted
							className="rounded-lg max-h-[220px]"
							src={exercise.video.url}
						/>
					</div>
				)}

				{/* Sets Navigation */}
				<div
					className="flex whitespace-nowrap overflow-x-auto show-scrollbar-x"
					ref={groupsDivRef}
					role="menubar"
					tabIndex={0}
					onMouseDown={makeDraggableGroupSection}
					onMouseUp={removeDraggableGroupSection}
					onMouseLeave={removeDraggableGroupSection}
				>
					{setsBreakDown &&
						setsBreakDown.map((set, idx) => (
							<button
								className=" px-2 rounded text-white font-semibold mt-2 mr-3 mb-1"
								type="button"
								key={set.slug}
								onClick={() => setCurrentSet(idx)}
								style={{
									backgroundColor:
										idx === currentSet ? 'var(--color-custom)' : '#929BB2'
								}}
							>
								<span className="flex gap-2 min-w-[54px] h-[29px] justify-center">
									<p className="text-lg">Set {idx + 1}</p>{' '}
								</span>
							</button>
						))}
				</div>

				{/* Exercise Status */}
				<section>
					<hr className="my-2" />
					<div className="flex w-full justify-between items-center">
						<span className="underline flex flex-1">{statusName}</span>
						<button
							onClick={handleOpenModalStatus}
							className={clsx(
								'cursor-pointer my-auto',
								'px-2 hover:opacity-80',
								'flex flex-row mr-2'
							)}
							type="button"
						>
							<div className="w-6">
								<Icon
									className="ml-2 mt-1"
									src={
										statusExercise.status === 0
											? arrayStatus.filter(
													item => item.id === exercise.status
											  )[0].icon
											: arrayStatus.filter(
													item => item.id === statusExercise.status
											  )[0].icon
									}
								/>
							</div>
						</button>
					</div>
					<hr className="my-2" />
				</section>

				{/* Set Metrics */}
				<section>
					{setsBreakDown &&
						setsBreakDown.map((set, idx) => (
							<div key={set.slug}>
								{idx === currentSet && (
									<>
										<div className="flex font-Roboto font-bold py-1 md:py-3">
											<p className="w-full text-center">Units of mesure</p>
											<p className="w-full text-center">
												<span className="text-custom ml-5">*</span>
												Base value
											</p>
											<p className="w-full text-center">Executed</p>
										</div>
										<div
											className={clsx(
												'flex flex-col gap-3'
												// 'md:min-h-[calc(100vh-850px)] md:max-h-[calc(100vh-850px)]'
											)}
										>
											{set.assigned_metrics?.map(metric => (
												<div key={metric.slug}>
													<InTrainingMetrics
														metricName={metric.type_metric.name}
														baseValue={metric.value}
														isInjury={isInjury}
														key={metric.slug}
														slug={metric.slug}
														defaultList={metric.type_metric.slug}
														defaultVal={
															metric.assigned_execute_metric.value !== ''
																? metric.assigned_execute_metric.value
																: '---'
														}
														listMetric={listMetrics || []}
														changeMetric={(slug, type, value) =>
															handleChangeMetric(slug, type, value)
														}
														statusExercise={statusExercise.status}
													/>
												</div>
											))}
										</div>
									</>
								)}
							</div>
						))}
				</section>
			</main>

			<footer className="flex-none px-4">
				{/* Comments  */}
				<section className="my-2">
					<div className="flex w-full justify-between items-center">
						<div className="w-full flex items-center">
							<Toogle
								enabled={showCommentsInput}
								setEnabled={setShowCommentsInput}
							/>
							<p className="ml-5">Comments</p>
						</div>

						{showCommentsInput && (
							<div className="w-full flex justify-end pr-2">
								<button
									type="button"
									onClick={() => setOpenDeleteComment(true)}
									className="w-4 h-4 flex justify-center items-center hover:text-custom"
								>
									<Icon
										fillPath
										className={clsx('min-h-[16px] min-w-[16px]')}
										src={Icons.trash}
									/>
								</button>
							</div>
						)}
					</div>

					{showCommentsInput && (
						<div className="relative">
							<textarea
								name="comments"
								id="comments"
								className="border border-gray-300 p-2 focus:outline-none placeholder-gray-300 resize-none w-full h-28 overflow-y-auto hide-scroll-bar rounded-lg placeholder:font-bold"
								placeholder="Write your comment ..."
								value={comment ?? ''}
								onChange={e => setComment(e.target.value)}
								maxLength={256}
							/>
							<span className="bg-custom rounded-full text-white font-semibold text-sm h-5 px-2 absolute bottom-4 right-2 opacity-70">
								{comment?.length ?? 0}/256
							</span>
						</div>
					)}
				</section>

				{/* Save Set */}
				<section className="w-full mb-4 self-center">
					<button
						type="button"
						className="w-full h-[45px] bg-custom rounded text-white font-semibold"
						onClick={() => handleSetRequest()}
					>
						Save Set
					</button>
				</section>
			</footer>

			{openDeleteComment && (
				<Modal
					type="danger"
					open={openDeleteComment}
					setOpen={(val: boolean) => setOpenDeleteComment(val)}
					handleClick={() => {
						setComment('')
						setOpenDeleteComment(false)
					}}
				>
					<>
						<h3 className="text-lg leading-6 font-medium text-gray-900">
							Delete
						</h3>
						<div className="mt-2">
							<p className="text-sm text-gray-500">
								Do you Want to delete these comment?
							</p>
						</div>
					</>
				</Modal>
			)}
		</div>
	)
}
