import { BaseURL } from 'api'
import axios from 'axios'
import { ProgramSessions } from 'interfaces'

export const editProgramSession = async (
	token: string,
	slug: string,
	name: string,
	slugsExercises: string[]
): Promise<ProgramSessions | null> => {
	let res = null as ProgramSessions | null
	if (slug) {
		await axios
			.patch(
				`${BaseURL}/program_sessions/${slug}/`,
				{ name, slugsExercises },
				{
					headers: {
						Authorization: `Token ${token}`
					}
				}
			)
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
				}
			)
	}
	return res
}
