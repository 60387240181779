import React, { useEffect, useState } from 'react'
import { InputList, Icon, PickerTime } from 'components'
import { Icons, ListElemetProps, MetricExerciseType } from 'interfaces'
import clsx from 'clsx'
import { Input } from 'components/input'

export const MetricExcercise: React.FC<MetricExerciseType> = ({
	slug,
	listMetric,
	defaultList,
	defaultVal,
	deleteMetric,
	changeMetric,
	typeForm,
	isInjury
}): JSX.Element => {
	let localDefautlValue = defaultVal
	if (localDefautlValue) {
		if (localDefautlValue === '00:00:00') {
			localDefautlValue = '---'
		}
	}
	const list = listMetric.map(item => {
		return {
			id: item.slug,
			name: item.name,
			value: item.slug
		}
	})
	const [item, setItem] = useState(
		defaultList
			? list.filter(itemList => itemList.id === defaultList)[0]
			: list[3]
	)

	const [metric, setMetric] = useState(
		listMetric.filter(itemArray => {
			return itemArray.slug === item.id
		})[0]
	)

	const [selectVal, setSelectVal] = useState<ListElemetProps>({
		id: localDefautlValue || '---',
		name: localDefautlValue || '---',
		value: localDefautlValue || '---'
	})
	const [timeVal, setTimeVal] = useState(defaultVal || '')

	useEffect(() => {
		if (metric && selectVal) {
			if (metric.type === 'select' && defaultVal !== selectVal.value) {
				if (selectVal.id !== '') {
					changeMetric(slug, metric.slug, selectVal.value)
				}
			}
		}
	}, [selectVal])

	useEffect(() => {
		if (metric && metric.type === 'time') {
			if (timeVal !== '') {
				changeMetric(slug, metric.slug, timeVal)
			}
		}
	}, [timeVal])

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		if (metric.type === 'number') {
			const val = e.target.value
			changeMetric(slug, metric.slug, val)
		}
	}
	const handleChangeItem = (itemToChange: ListElemetProps): void => {
		setItem(itemToChange)
		const aux = listMetric.filter(itemArray => {
			return itemArray.slug === itemToChange.id
		})
		setMetric(aux[0])
		if (aux.length > 0) changeMetric(slug, aux[0].slug, '---')
		console.warn({ aux, itemToChange })
	}

	const listOptions =
		metric.options?.map((optionItem, index) => {
			return {
				id: `${index}`,
				name: optionItem,
				value: optionItem
			}
		}) ?? []

	return (
		<div className="flex items-center w-full gap-4">
			<div className="w-full h-full">
				<InputList
					list={list}
					defaultValue={
						defaultList
							? list.filter(itemList => itemList.id === defaultList)[0]
							: list[1]
					}
					name="metric"
					squared
					disableList={isInjury || typeForm === 'inTraining'}
					setItem={handleChangeItem}
				/>
			</div>
			<div className="w-full h-full flex items-center justify-between">
				{metric.type === 'time' ? (
					<PickerTime
						defaultValue={timeVal}
						asterisk
						setItem={setTimeVal}
						icon
						disablePicker={isInjury || typeForm === 'inTraining'}
					/>
				) : metric.type === 'select' ? (
					<InputList
						list={listOptions}
						name={`metric-${item.id}-list-${slug}`}
						asterisk
						squared
						isMetric
						setItem={setSelectVal}
						disableList={isInjury || typeForm === 'inTraining'}
						defaultValue={selectVal}
					/>
				) : (
					<Input
						name={`metric-${item.id}-number-${slug}`}
						asterisk
						placeholder={metric.name}
						squared
						type="number"
						step={0.1}
						onChange={handleChange}
						defaultValue={defaultVal || ''}
						disabled={isInjury || typeForm === 'inTraining'}
						min={1}
					/>
				)}

				{typeForm !== 'inTraining' && (
					<div className="w-1/4 h-full flex justify-end">
						<button
							type="button"
							onClick={() => deleteMetric()}
							className="w-4 h-4 flex justify-center items-center hover:text-custom"
						>
							<Icon
								fillPath
								className={clsx('min-h-[16px] min-w-[16px]')}
								src={Icons.trash}
							/>
						</button>
					</div>
				)}
			</div>
		</div>
	)
}
