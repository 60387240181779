import { BaseURL } from 'api/environment'
import axios from 'axios'

interface UpdateCommentResponse {
	slug: string
	order: number | null
	name: string
	description: string
	status: number
	comment: string
	exercise: number
	assigned_session: string
}

export const updateComment = async (
	token: string,
	slug: string,
	comment: string
): Promise<UpdateCommentResponse | null> => {
	let response = null as UpdateCommentResponse | null

	if (slug) {
		await axios
			.patch(
				`${BaseURL}/assigned_exercises_set/${slug}/`,
				{ comment },
				{
					headers: {
						Authorization: `Token ${token}`
					}
				}
			)
			.then(
				async res => {
					response = res.data
				},
				error => {
					console.error(error)
				}
			)
	}
	return response
}
