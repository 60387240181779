import { createContext } from 'react'

const statusExerciseDefaultValue = {
	statusExercise: {
		slug: '',
		status: 1
	},
	setStatusExercise: () => null
}

export const StatusExerciseContext = createContext<{
	statusExercise: { slug: string; status: number }
	setStatusExercise: React.Dispatch<
		React.SetStateAction<{ slug: string; status: number }>
	>
}>(statusExerciseDefaultValue)
