import React from 'react'
import clsx from 'clsx'
import { AssignedProgramSession } from 'interfaces'
import { ChevronDoubleRightIcon } from '@heroicons/react/outline'

interface Props {
	session: AssignedProgramSession
	checkedSession: string
	handleCheckSession: (slug: string) => void
	// eslint-disable-next-line react/require-default-props
	setOpenExerciseMobile?: React.Dispatch<React.SetStateAction<boolean>>
}
export const CardSession: React.FC<Props> = ({
	session,
	checkedSession,
	handleCheckSession,
	setOpenExerciseMobile
}): JSX.Element => {
	return (
		<div
			className={clsx(
				'w-full h-10 flex justify-between items-center bg-white px-3 group',
				'border border-gray-200 rounded-md cursor-pointer hover:border-2 hover:border-custom',
				session.slug === checkedSession && 'border-2'
			)}
			aria-hidden
			onClick={() => {
				handleCheckSession(session.slug)
				if (setOpenExerciseMobile) setOpenExerciseMobile(true)
			}}
		>
			<span
				className={clsx(
					'group-hover:text-custom',
					session.slug === checkedSession ? 'text-custom' : 'text-[#929BB2]'
				)}
			>
				{session.program_session.name}
			</span>
			{session.slug === checkedSession ? (
				<ChevronDoubleRightIcon className={clsx('w-5 h-5 text-custom')} />
			) : (
				<ChevronDoubleRightIcon
					className={clsx('w-5 h-5 text-[#929BB2] group-hover:text-custom')}
				/>
			)}
		</div>
	)
}
