import clsx from 'clsx'
import React from 'react'
import { ButtonProps } from 'interfaces/index'
import { Icon } from 'components/icon'
import { Link } from 'react-router-dom'

const ButtonContent: React.FC<
	ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
	label,
	disabled = false,
	color = 'Primary',
	customColor = false,
	rounded = false,
	size = 'full',
	iconLeft,
	iconRight,
	fill = true,
	className,
	children,
	...props
}): JSX.Element => {
	return (
		<button
			type="button"
			disabled={disabled}
			className={clsx(
				`${
					!fill
						? disabled
							? 'border-2 border-gray-600 cursor-not-allowed text-gray-600'
							: customColor
							? 'border-2 border-custom bg-[transparent] text-custom hover:bg-custom hover:text-white'
							: color === 'Primary'
							? 'border-2 border-green-400 hover:bg-green-400 hover:text-white'
							: color === 'Secondary'
							? 'border-2 border-gray-700 hover:bg-gray-700 hover:text-white'
							: 'border-2 border-transparent-black'
						: disabled
						? 'bg-gray-600 cursor-not-allowed text-white '
						: customColor
						? `bg-custom border-2 border-custom text-white `
						: color === 'Primary'
						? 'bg-green-400 border-2 border-green-400 text-white '
						: 'bg-transparent-black border-transparent-black text-white '
				}`,
				'flex justify-center items-center border border-transparent font-medium shadow-sm',
				`hover:brightness-90`,
				`${rounded ? 'rounded-3xl' : 'rounded-md'}`,
				{ 'w-full text-base px-4 py-2': size === 'full' },
				{ 'w-auto text-base px-4 py-2': size === 'medium' },
				{ 'w-auto text-base px-3 py-1': size === 'small' },
				{ 'w-auto text-xs px-3 py-1': size === 'extra-small' },
				className
			)}
			{...props}
		>
			<div className="w-full flex items-center justify-center">
				{iconLeft && (
					<Icon
						className={clsx(
							`${size === 'extra-small' ? 'mr-1' : 'mr-2'}`,
							'max-h-4 max-w-[16px]'
						)}
						src={iconLeft}
					/>
				)}
				{children || <span className="font-Roboto font-normal">{label}</span>}
				{iconRight && (
					<Icon
						className={clsx(
							`${size === 'extra-small' ? 'ml-1' : 'ml-2'}`,
							'max-h-4 max-w-[16px]'
						)}
						src={iconRight}
					/>
				)}
			</div>
		</button>
	)
}

export const Button: React.FC<
	ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
	label,
	disabled = false,
	color = 'Primary',
	rounded = false,
	size = 'full',
	iconLeft,
	linkTo = null,
	className,
	...props
}) => {
	return (
		<>
			{linkTo ? (
				<Link to={linkTo} replace>
					<ButtonContent
						label={label}
						disabled={disabled}
						color={color}
						rounded={rounded}
						size={size}
						iconLeft={iconLeft}
						className={className}
						{...props}
					/>
				</Link>
			) : (
				<ButtonContent
					label={label}
					disabled={disabled}
					color={color}
					rounded={rounded}
					size={size}
					iconLeft={iconLeft}
					className={className}
					{...props}
				/>
			)}
			<div className="sr-only">container button</div>
		</>
	)
}

export default Button
