import React from 'react'
import { ChevronRightIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { BoxCustomerType } from 'interfaces'

export const BoxCustomer: React.FC<BoxCustomerType> = ({
	rol,
	title,
	onClick,
	className
}): JSX.Element => {
	return (
		<button
			type="button"
			onClick={onClick}
			className={clsx(
				'bg-white shadow sm:rounded-lg border-[1px] border-gray-300 border-l-4 border-l-custom w-full cursor-pointer rounded-lg',
				'hover:bg-gray-200',
				className
			)}
		>
			<div className="flex justify-between px-4 py-3">
				<div className="">
					<h3
						className={clsx(
							'text-base leading-6text-gray-900 font-Roboto font-bold text-left line-clamp-2',
							'lg:text-2xl '
						)}
					>
						{title}
					</h3>
					<div
						className={clsx('text-xs text-gray-500 text-left', 'lg:text-lg')}
					>
						<p>{rol}</p>
					</div>
				</div>
				<div className="flex justify-center items-center">
					<ChevronRightIcon
						className="h-6 w-6 text-custom"
						aria-hidden="true"
					/>
				</div>
			</div>
		</button>
	)
}
