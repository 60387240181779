import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import {
	AthleteListType,
	athleteType,
	InGroupType,
	SearchInputs
} from 'interfaces'
import {
	BoxAthletes2,
	Drawer,
	DrawerCalendar,
	Input,
	Loading,
	GroupBox
} from 'components'
import { PlusCircleIcon, SearchIcon } from '@heroicons/react/solid'
import { useInfiniteQuery } from 'react-query'
import { useCustomer } from 'hooks'
import { useInView } from 'react-intersection-observer'
import { useForm } from 'react-hook-form'
import { GET_ATHLETES, getClients, GET_GROUPS, getInGroups } from 'api'
import { getOffset, getPageNumber } from 'lib'
import Cookies from 'universal-cookie'
import { ClipboardIcon } from '@heroicons/react/outline'
import moment from 'moment'
import { TrainingGroups } from '.'

const searchIcon = <SearchIcon className="text-gray-900 cursor-pointer" />

export const AthletesList: React.FC<AthleteListType> = ({
	status,
	statusGroup
}): JSX.Element => {
	const customer = useCustomer()
	const { ref, inView } = useInView({
		rootMargin: '0px 0px 0px 250px'
	})
	const { ref: refGroup, inView: inViewGroup } = useInView({
		rootMargin: '0px 0px 0px 250px'
	})
	const { register, watch } = useForm<SearchInputs>({ mode: 'onChange' })
	const watchSearch = watch('search', '')
	const [search, setSearch] = useState<string | undefined>(undefined)
	const [athletes, setAthletes] = useState<athleteType[] | undefined>([])
	const [athletesFiltered, setAthletesFiltered] = useState<
		athleteType[] | undefined
	>([])
	const cookies = new Cookies()
	const token = cookies.get('token')
	// const device = useDevice()
	const [openDrawerGroup, setOpenDrawerGroup] = useState(false)

	const [groupSlug, setGroupSlug] = useState('')
	const [groups, setGroups] = useState<InGroupType[]>([])
	// const [groupsFiltered, setGroupsFiltered] = useState<InGroupType[]>([])

	const { data, isLoading, isFetchingNextPage, fetchNextPage, refetch } =
		useInfiniteQuery(
			[GET_ATHLETES, search, customer.id, token],
			({ pageParam = 1 }) => getClients(token, customer.id, pageParam, search),
			{
				getNextPageParam: lastPage =>
					lastPage?.next ? getPageNumber(lastPage?.next) : undefined
			}
		)

	const {
		data: dataGroup,
		refetch: refetchGroup,
		isLoading: isLoadingGroup,
		isFetching: isFetchingGroup,
		isFetchingNextPage: isFetchingNextPageGroup,
		fetchNextPage: fetchNextPageGroup,
		hasNextPage: hasNextPageGroup
	} = useInfiniteQuery(
		[GET_GROUPS, customer.id, token, statusGroup],
		({ pageParam = 0 }) =>
			getInGroups({
				customerId: customer.id,
				status: statusGroup,
				page: pageParam
			}),
		{
			getNextPageParam: lastPage =>
				lastPage?.next ? getOffset(lastPage?.next) : undefined
		}
	)

	useEffect(() => {
		if (dataGroup?.pages) {
			let auxData = [] as InGroupType[]
			dataGroup.pages.forEach(page => {
				const auxResults = page?.results as unknown as InGroupType[]
				if (auxResults) auxData = [...auxData, ...auxResults]
			})
			setGroups(auxData)
		}
	}, [dataGroup])

	useEffect(() => {
		if (data?.pages) {
			let auxData = [] as athleteType[]
			data.pages.forEach(page => {
				const auxResults = page?.results
				if (auxResults) auxData = [...auxData, ...auxResults]
			})
			setAthletes(auxData)
		}
	}, [data])

	useEffect(() => {
		setSearch(watchSearch)
		setAthletes([])
	}, [watchSearch])

	useEffect(() => {
		if (inView) fetchNextPage()
	}, [inView])

	useEffect(() => {
		if (inViewGroup) fetchNextPageGroup()
	}, [inViewGroup])

	useEffect(() => {
		setAthletesFiltered(
			athletes?.filter(athlete => athlete.is_active === !status)
		)
	}, [athletes])

	useEffect(() => {
		refetchGroup()
	}, [statusGroup])

	const refetchUp = (): void => {
		refetch()
	}

	return (
		<div>
			{isLoadingGroup ? (
				<div
					className={clsx(
						'flex justify-center items-center'
						// 'min-h-[calc(100vh-300px)] max-h-[calc(100vh-300px)]',
						// 'md:min-h-[calc(100vh-210px)] md:max-h-[calc(100vh-210px)]'
					)}
				>
					<Loading />
				</div>
			) : (
				<>
					<header className={clsx('w-full flex flex-col px-6')}>
						<div className="flex flex-col lg:flex-row-reverse lg:justify-between">
							<div className="w-full">
								<div className="w-full flex justify-between items-center">
									<p className="font-Roboto font-bold text-xl lg:text-2xl my-2">
										Search athletes
									</p>
									{customer.role !== 'Client' && (
										<button
											type="button"
											onClick={() => setOpenDrawerGroup(true)}
											className={clsx(
												'w-fit h-full flex justify-center items-center gap-2 px-5',
												'bg-white rounded-lg border-l-4 border-custom cursor-pointer shadow-md'
											)}
										>
											<PlusCircleIcon className="w-7 h-7 text-custom" />
											<p className="text-base md:text-2xl text-[#454952] font-Roboto font-medium">
												Add new group
											</p>
										</button>
									)}
								</div>
								<Input
									name="search"
									register={register}
									placeholder="Search athlete"
									type="text"
									className="mb-4 lg:col-span-3 lg:pr-2"
									leftIcon={searchIcon}
									rounded
								/>
							</div>
						</div>
						<div className="h-[1px] w-full bg-gray-300 my-3 lg:visible" />
					</header>
					<div className="block lg:hidden">
						<TrainingGroups
							groups={groups}
							setGroupSlug={(val: string) => setGroupSlug(val)}
							refetch={() => refetch()}
							refetchGroup={() => refetchGroup()}
							statusGroup={statusGroup}
							refGroup={refGroup}
							hasNextPageGroup={hasNextPageGroup}
							isFetchingNextPageGroup={isFetchingNextPageGroup}
							athletes={athletesFiltered || []}
							status={status}
							isLoading={isLoading}
							refx={ref}
							isFetchingNextPage={isFetchingNextPage}
						/>
					</div>
					<div
						className={clsx(
							'w-full lg:flex',
							openDrawerGroup ? 'flex' : 'hidden'
						)}
					>
						<div className="w-full">
							<p className="w-full px-6 py-2 text-xl text-[#929BB2] font-Roboto font-bold">
								Groups
							</p>
							{isFetchingGroup ? (
								<div className="w-full h-[calc(100vh-400px)] flex justify-center items-center">
									<Loading isSmall />
								</div>
							) : (
								<div
									className={clsx(
										'flex flex-col px-6 py-2',
										'min-h-[calc(100vh-400px)] max-h-[calc(100vh-400px)]',
										'md:min-h-[calc(100vh-350px)] md:max-h-[calc(100vh-350px)]',
										'overflow-hidden hide-scroll-bar overflow-y-scroll'
									)}
								>
									{groups.length < 1 ? (
										<div
											className={clsx(
												'flex items-center gap-4 text-gray-400 text-3xl font-Roboto',
												'bg-white border border-gray-300 rounded-lg p-4 shadow'
											)}
										>
											<ClipboardIcon className="text-custom w-7 h-7" />
											Empty group list
										</div>
									) : (
										groups
											.filter(grp => grp.status === statusGroup)
											.map(group => (
												<GroupBox
													key={group.slug}
													{...group}
													handleSelectGroup={(val: string) => {
														setGroupSlug(val)
													}}
													handleRefetch={() => {
														if (refetch) refetch()
													}}
													refetchGroup={() => refetchGroup()}
												/>
											))
									)}
									<div className="flex items-center justify-center mt-2">
										{hasNextPageGroup && statusGroup === 'active' && (
											<div ref={refGroup}>
												{isFetchingNextPageGroup && <Loading isSmall />}
											</div>
										)}
										{hasNextPageGroup && statusGroup === 'inactive' && (
											<div ref={refGroup}>
												{isFetchingNextPageGroup && <Loading isSmall />}
											</div>
										)}
									</div>
								</div>
							)}
							<Drawer
								open={openDrawerGroup}
								setOpen={(val: boolean) => setOpenDrawerGroup(val)}
								title="NEW GROUP TRAINING"
								stretch
								mobileFull
							>
								<DrawerCalendar
									type="new-group"
									groupSlug={groupSlug}
									setOpenDrawer={setOpenDrawerGroup}
									handleRefetch={refetchGroup}
									dateSelected={moment().format('yyyy-MM-DD')}
								/>
							</Drawer>
						</div>
						<div className="w-full">
							<p className="w-full px-6 py-2 text-xl text-[#929BB2] font-Roboto font-bold">
								Athletes
							</p>
							{isLoading ? (
								<div className="w-full h-[calc(100vh-400px)] flex justify-center items-center">
									<Loading isSmall />
								</div>
							) : (
								<div
									className={clsx(
										'flex flex-col px-6 py-2',
										'min-h-[calc(100vh-400px)] max-h-[calc(100vh-400px)]',
										'md:min-h-[calc(100vh-350px)] md:max-h-[calc(100vh-350px)]',
										'overflow-hidden hide-scroll-bar overflow-y-scroll'
									)}
								>
									{status && isLoading ? (
										athletesFiltered?.map(athlete => (
											<BoxAthletes2
												id={athlete.id}
												key={athlete.id}
												image={athlete.profile_image?.url}
												fullname={`${athlete.first_name} ${athlete.last_name}`}
												status={status}
												assignedPrograms={athlete.assigned_programs}
												refetchUp={refetchUp}
											/>
										))
									) : !athletesFiltered?.length ? (
										<div
											className={clsx(
												'flex items-center gap-4 text-gray-400 text-3xl font-Roboto',
												'bg-white border border-gray-300 rounded-lg p-4 shadow'
											)}
										>
											<ClipboardIcon className="text-custom w-7 h-7" />
											Empty athlete list
										</div>
									) : (
										athletesFiltered?.map(athlete => (
											<BoxAthletes2
												id={athlete.id}
												key={athlete.id}
												image={athlete.profile_image?.url}
												fullname={`${athlete.first_name} ${athlete.last_name}`}
												status={status}
												assignedPrograms={athlete.assigned_programs}
												refetchUp={refetchUp}
											/>
										))
									)}

									<div className="md:col-span-2 xl:col-span-3 2xl:col-span-4 flex items-center justify-center mt-2">
										<div ref={ref}>
											{isFetchingNextPage && <Loading isSmall />}
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</>
			)}
		</div>
	)
}

export default AthletesList
