import { CustomerType } from 'interfaces'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const useCustomer = (): CustomerType => {
	const id = cookies.get('customer')
	const color = cookies.get('customer_color')
	const role = cookies.get('role_type')
	const roleId = cookies.get('role_id')

	const customer = { id, color, role, roleId }
	return customer
}
