import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import { TooltipProps } from './tooltip.interface'

export const Tooltip = (props: TooltipProps): JSX.Element => {
	const { text, children } = props
	const mouseMoveRef = useRef<HTMLDivElement | null>(null)

	const [statusTooltip, setStatusTooltip] = useState(false)

	const showTooltip = (): void => {
		setStatusTooltip(true)
	}

	const hideTooltip = (): void => {
		setStatusTooltip(false)
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const checkHover = (e: any): void => {
		if (mouseMoveRef.current) {
			const mouseOver = mouseMoveRef.current.contains(e.target)
			if (!statusTooltip && mouseOver) {
				showTooltip()
			}

			if (statusTooltip && !mouseOver) {
				hideTooltip()
			}
		}
	}

	useEffect(() => {
		window.addEventListener('mousemove', checkHover)

		return () => {
			window.removeEventListener('mousemove', checkHover)
		}
	}, [statusTooltip])

	return (
		<div
			className="relative inline-block cursor-pointer"
			onMouseEnter={showTooltip}
			onMouseLeave={hideTooltip}
		>
			{/* ITEM TO HOVER ON */}
			<div ref={mouseMoveRef}>{children}</div>

			{/* TOOLTIP */}
			<div
				className={clsx(
					'absolute bg-gray-800 text-white p-4 rounded-lg shadow-lg whitespace-nowrap -right-[15px] -top-[100px]',
					statusTooltip ? 'inline-block' : 'hidden'
				)}
			>
				{text &&
					text.split('\n').map(line => (
						<p key={line} className="mb-1">
							{line}
						</p>
					))}
				<div className="absolute w-4 h-4 bg-gray-800 transform rotate-45 -translate-x-1/2 bottom-[-7px] right-2" />
			</div>
		</div>
	)
}
