import { BaseURL } from 'api/environment'
import axios, { AxiosResponse } from 'axios'
import {
	UploadDocumentCreedentialsResponse,
	uploadVideoProps,
	uploadVideoResponse
} from 'interfaces'

export const uploadVideo = async (
	props: uploadVideoProps
): Promise<uploadVideoResponse | null> => {
	const { token, body } = props

	const formData = new FormData()
	formData.append('path', 'exercise_videos/')
	if (body.video_file && body.video_file.name) {
		formData.append('file_name', body.video_file.name.replace(/[%&#\\+]/g, '_'))
	}

	const { data }: AxiosResponse<UploadDocumentCreedentialsResponse> =
		await axios.post(`${BaseURL}/exercises/upload_video/`, formData, {
			headers: {
				Authorization: `Token ${token}`,
				'Content-Type': 'multipart/form-data'
			}
		})

	let filePath
	const formDataS3 = new FormData()
	if (body.video_file) {
		filePath = data.fields.key.replace(
			// eslint-disable-next-line no-template-curly-in-string
			'${filename}',
			body.video_file?.name.replace(/[%&#\\+]/g, '_')
		)

		formDataS3.append('acl', data.fields.acl)
		formDataS3.append('Content-Type', data.fields['Content-Type'])
		formDataS3.append('x-amz-signature', data.fields['x-amz-signature'])
		formDataS3.append('x-amz-date', data.fields['x-amz-date'])
		formDataS3.append('key', filePath)
		formDataS3.append('policy', data.fields.policy)
		formDataS3.append('x-amz-credential', data.fields['x-amz-credential'])
		formDataS3.append('x-amz-algorithm', data.fields['x-amz-algorithm'])
		formDataS3.append('file', body.video_file)
	}
	const awsResponse = await axios.post(`${data.url}`, formDataS3, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
	if (awsResponse.status === 204) {
		return {
			url: `${data.url}${filePath}`,
			name: body.video_file?.name
		}
	}
	return null
}
