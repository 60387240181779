import React from 'react'
import clsx from 'clsx'
import { ProgramProps, Images, Icons } from 'interfaces'
import { Button } from 'components/buttons'
import { useNavigate } from 'react-router-dom'
import { Icon } from 'components'
import { useCustomer } from 'hooks'
import styles from './program.module.scss'

export const Program = ({
	id,
	cover = Images.program_cover,
	sessions,
	title,
	active,
	own,
	clickCopy,
	clickDelete
}: ProgramProps): JSX.Element => {
	const customer = useCustomer()
	const navigate = useNavigate()

	const handleClickEdit = (idx: string): void => {
		navigate(`/program/${idx}`, { replace: true })
	}

	return (
		<div className="bg-white shadow-[0px_4px_20px_rgba(0,0,0,0.16)] rounded-lg h-[114px] md:h-[220px] flex flex-col">
			<div
				className={clsx(
					'w-full rounded-lg overflow-hidden h-[114px] relative',
					`${active && 'border-2 border-custom'}`
				)}
			>
				<img
					src={cover || Images.program_cover}
					alt={`cover-${id}`}
					className="object-cover w-full h-[114px]"
				/>
				<div
					className={clsx(styles.gradient, 'absolute inset-0 min-h-[114px]')}
				/>
				<div
					className={clsx(
						'absolute inset-0 min-h-[114px] px-[10px] py-2 text-white flex flex-col justify-between'
					)}
				>
					<div className="flex justify-between md:hidden">
						<p className="text-2xl font-bold line-clamp-2">{title}</p>
						<button
							type="button"
							onClick={() => clickDelete(id)}
							className={clsx(
								// 'disabled:cursor-not-allowed disabled:hover:text-black',
								'visible md:hidden',
								'flex justify-center items-center',
								'transparent w-8 h-8 rounded-2xl bg-transparent-black'
							)}
						>
							<Icon
								fillPath
								className={clsx('w-4 h-4 mt-3 md:mt-0')}
								src={Icons.trash}
							/>
						</button>
					</div>
					<div className="flex items-end justify-between md:justify-end md:h-[114px]">
						<div className="text-sm left-4 w-1/2 md:hidden">{`Total session ${sessions}`}</div>
						<div className="flex flex-col justify-between h-full">
							{customer.role.toUpperCase().includes('ADMIN') && (
								<div className="flex">
									<Button
										color="Tranparent"
										rounded
										size="extra-small"
										className="h-[26px] mr-1 invisible sm:invisible md:visible lg:visible"
										onClick={() => clickDelete(id)}
										// disabled={!own}
									>
										<div className="flex items-center justify-center h-[26px]">
											<div className="max-h-[26px] max-w-[26px] flex items-center justify-center mr-1">
												<Icon
													fillPath
													className={clsx(
														'min-h-[15px] min-w-[15px] max-w-[15px]'
													)}
													src={Icons.trash}
												/>
											</div>
											Delete
										</div>
									</Button>
									<Button
										label="Copy"
										color="Tranparent"
										rounded
										size="extra-small"
										iconLeft={Icons.copy}
										onClick={() => clickCopy(id)}
									/>
									<Button
										label="Edit"
										color="Tranparent"
										rounded
										size="extra-small"
										iconLeft={Icons.pencil}
										className="ml-1"
										onClick={() => handleClickEdit(id)}
										// disabled={!own}
									/>
									{/* <Link to={`/program/${id}`}>
									</Link> */}
								</div>
							)}
							{customer.role === 'StaffMember' && (
								<div className="flex">
									{own && (
										<Button
											color="Tranparent"
											rounded
											size="extra-small"
											className="h-[26px] mr-1 invisible sm:invisible md:visible lg:visible"
											onClick={() => clickDelete(id)}
											// disabled={!own}
										>
											<div className="flex items-center justify-center h-[26px]">
												<div className="max-h-[26px] max-w-[26px] flex items-center justify-center mr-1">
													<Icon
														fillPath
														className={clsx(
															'min-h-[15px] min-w-[15px] max-w-[15px]'
														)}
														src={Icons.trash}
													/>
												</div>
												Delete
											</div>
										</Button>
									)}
									<Button
										label="Copy"
										color="Tranparent"
										rounded
										size="extra-small"
										iconLeft={Icons.copy}
										onClick={() => clickCopy(id)}
									/>
									{own && (
										<Button
											label="Edit"
											color="Tranparent"
											rounded
											size="extra-small"
											iconLeft={Icons.pencil}
											className="ml-1"
											onClick={() => handleClickEdit(id)}
											// disabled={!own}
										/>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="hidden md:flex p-2 flex-col items-start justify-between h-[106px]">
				<div className="text-2xl font-Roboto line-clamp-2">
					<p>
						{title.length > 33 ? `${title.substring(0, 30)}...` : `${title}`}
					</p>
				</div>
				<div className="text-sm font-Roboto">
					<h2>{`Total session ${sessions}`}</h2>
				</div>
			</div>
		</div>
	)
}

export default Program
