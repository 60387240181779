import { athleteType } from 'interfaces'
import React from 'react'

export interface InfoAthleteProps {
	athlete: athleteType | undefined
}
export const InfoAthlete: React.FC<InfoAthleteProps> = ({
	athlete
}): JSX.Element => {
	return (
		<>
			<div className="flex justify-between">
				<span className="font-bold">Gender</span>
				<span>{athlete?.gender}</span>
			</div>
			<div className="flex justify-between">
				<span className="font-bold">Age</span>
				<span>{athlete?.age || '-'}</span>
			</div>
			<div className="flex justify-between">
				<span className="font-bold">Height</span>
				<span>{athlete?.height || '-'}</span>
			</div>
			<div className="flex justify-between">
				<span className="font-bold">Weight</span>
				<span>{athlete?.weight || '-'}</span>
			</div>
			<div className="flex justify-between">
				<span className="font-bold">Sport</span>
				<span>{athlete?.sport || '-'}</span>
			</div>
			<div className="flex justify-between">
				<span className="font-bold">Position</span>
				<span>{athlete?.position || '-'}</span>
			</div>
			<div className="flex justify-between">
				<span className="font-bold">School</span>
				<span>{athlete?.school || '-'}</span>
			</div>
		</>
	)
}
