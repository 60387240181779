import React from 'react'
import { Layout, BoxTools } from 'components'
import clsx from 'clsx'
import { useCustomer } from 'hooks'

export const Home = (): JSX.Element => {
	const customer = useCustomer()
	return (
		<Layout>
			<div
				className={clsx(
					'flex-1 flex-col',
					'min-h-[calc(100vh-230px)] max-h-[calc(100vh-230px)] overflow-y-scroll hide-scroll-bar'
				)}
			>
				<div className="mb-2 md:mb-12">
					<h1 className="font-Roboto text-2xl font-bold md:text-[45px] md:w-fit md:border-b-4 md:border-b-custom md:pb-2 pt-1">
						YOUR TOOLS
					</h1>
				</div>
				<div
					className={clsx(
						customer.role !== 'Client'
							? 'grid grid-cols-1 gap-4 xl:grid-cols-4 xl:h-[67vh] xl:gap-10'
							: 'grid grid-cols-1 gap-4 xl:grid-cols-2 xl:h-[67vh] xl:gap-20'
					)}
				>
					{customer.role !== 'Client' && <BoxTools type="programs" />}
					<BoxTools type="athletes" />
					<BoxTools type="training" />
					{customer.role !== 'Client' && <BoxTools type="schedule" />}
				</div>
			</div>
		</Layout>
	)
}
