import React from 'react'
import { Icon } from 'components'
import { Icons } from 'interfaces'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { NotificationCardProps } from './notificationCard.interface'

export const NotificationCard: React.FC<NotificationCardProps> = props => {
	const {
		handleClick,
		image,
		title,
		programName,
		coachName,
		initialDate,
		createdAt,
		itemId,
		notificationType
	} = props

	const currentDate = moment()
	const createdAtFormated = moment(createdAt)
	const startDate = moment(initialDate)
	const initialHour = startDate.format('HH:mm')
	const hoursUntilCreate = currentDate.diff(createdAtFormated, 'hours')
	const navigate = useNavigate()

	return (
		<button
			title="notificationcard"
			type="button"
			onClick={() => {
				handleClick()
				navigate(`/notification/${itemId}`)
			}}
			data-testid="notificationItem"
			className="w-full flex gap-4"
		>
			<img
				src={image}
				alt="customer"
				className="h-[74px] w-[74px] object-contain
            "
			/>

			<div className="flex flex-col items-start">
				<div className="flex items-center gap-2">
					<Icon
						src={
							title === 'New Group Session'
								? Icons.group_notification
								: Icons.program_notification
						}
						className="w-6 h-6"
					/>
					<h3 className="text-base text-[#1F2024] font-semibold line-clamp-1">
						{title}
					</h3>
				</div>

				<p className="text-left text-[#1D2027] line-clamp-1">
					{programName} - {coachName}
				</p>

				{notificationType === 'Group Session' && (
					<span className="text-[#1D2027]">
						{moment(startDate).format('MM/DD/YYYY')} - {initialHour}
					</span>
				)}

				<span
					data-testid="notificationDate"
					className="text-[#4AD669] font-medium"
				>
					{hoursUntilCreate < 24
						? `${hoursUntilCreate} hours ago`
						: moment(createdAt).format('MM/DD/YYYY')}
				</span>
			</div>
		</button>
	)
}
