import { BaseURL } from 'api/environment'
import axios from 'axios'
import { libraryExerciseType, LibraryExercisePost } from 'interfaces'

export const editLibraryExercises = async (
	token: string,
	slug: string,
	body: LibraryExercisePost
): Promise<libraryExerciseType[] | null> => {
	try {
		let res = [] as libraryExerciseType[] | null
		await axios
			.put(`${BaseURL}/library_exercises/${slug}/`, body, {
				headers: {
					Authorization: `Token ${token}`
				}
			})
			.then(async response => {
				res = response.data
			})
		return res
	} catch (error) {
		return null
	}
}
