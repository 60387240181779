import { API, SESSION_GROUPS_ENDPOINTS } from 'api'
import { BodyProps } from 'interfaces'

type PostInGroupProps = {
	customToken?: string
	body: BodyProps[]
	customerId: number
	actionType: string
}
export const postInGroup = async ({
	actionType,
	body,
	customerId
}: PostInGroupProps): Promise<string | null> => {
	let res = null as string | null
	await API()
		.post(`${SESSION_GROUPS_ENDPOINTS.BASE}/`, body, {
			params: {
				'x-customer-id': customerId,
				action: actionType
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)

	return res
}
