import { BaseURL } from 'api/environment'
import axios from 'axios'

export const deleteProgram = async (
	token: string,
	customer: number,
	slug: string
): Promise<number | null> => {
	let res = null as number | null
	await axios
		.delete(`${BaseURL}/programs/${slug}/?x-customer-id=${customer}`, {
			headers: {
				Authorization: `Token ${token}`
			}
		})
		.then(
			async response => {
				res = response.status
			},
			error => {
				res = error.status
				console.error(error)
			}
		)
	return res
}
