import axios from 'axios'
import { programsType, PostProgram } from 'interfaces'
import { BaseURL } from '../environment'

export const cloneProgram = async (
	token: string,
	program: string
): Promise<programsType | null> => {
	let res = null as programsType | null
	await axios
		.post(
			`${BaseURL}/programs/clone/`,
			{ program },
			{
				headers: {
					Authorization: `Token ${token}`
				}
			}
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				res = error.response.data
				// console.error(error)
			}
		)
	return res
}

export const createProgram = async (
	token: string,
	customer: number,
	program: PostProgram
): Promise<programsType | null> => {
	let res = null as programsType | null
	await axios
		.post(
			`${BaseURL}/programs/?x-customer-id=${customer}`,
			{ ...program },
			{
				headers: {
					Authorization: `Token ${token}`
				}
			}
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
