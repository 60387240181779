import React from 'react'
import clsx from 'clsx'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { ArrayOfSets } from './athlete.interface'

interface SetsNavigationProps {
	statsIndex: number
	setStatsIndex: React.Dispatch<React.SetStateAction<number>>
	arrayOfSets: ArrayOfSets[]
	handleMetricForSets: (order: number) => void
}

export const SetsNavigation: React.FC<SetsNavigationProps> = ({
	statsIndex,
	setStatsIndex,
	arrayOfSets,
	handleMetricForSets
}): JSX.Element => {
	return (
		<div className="w-full h-fit flex justify-between">
			<button
				type="button"
				className={clsx(
					statsIndex === 0 && 'opacity-50 cursor-not-allowed bg-gray-600',
					'w-8 h-8 bg-custom text-white rounded cursor-pointer'
				)}
				onClick={() => {
					setStatsIndex(statsIndex < 0 ? arrayOfSets.length : statsIndex - 1)
					handleMetricForSets(
						statsIndex < 0 ? arrayOfSets.length : statsIndex - 1
					)
				}}
				disabled={statsIndex === 0}
			>
				<ChevronLeftIcon className="w-5 h-5" />
			</button>
			<span className="inline-block w-full text-center mb-2 font-bold">
				{arrayOfSets.length > 0
					? `Set ${statsIndex + 1}`
					: 'No sets in exercise'}
			</span>
			<button
				type="button"
				className={clsx(
					statsIndex >= arrayOfSets.length - 1 &&
						'opacity-50 cursor-not-allowed bg-gray-600',
					'w-8 h-8 bg-custom text-white rounded cursor-pointer'
				)}
				onClick={() => {
					setStatsIndex(statsIndex > arrayOfSets.length ? 1 : statsIndex + 1)
					handleMetricForSets(
						statsIndex > arrayOfSets.length ? 1 : statsIndex + 1
					)
				}}
				disabled={statsIndex >= arrayOfSets.length - 1}
			>
				<ChevronRightIcon className="w-5 h-5" />
			</button>
		</div>
	)
}
