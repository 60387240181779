/* eslint-disable jsx-a11y/no-autofocus */
import React, { useRef } from 'react'
import { XIcon, PaperClipIcon } from '@heroicons/react/solid'
import { Button, MessageError } from 'components'
import { SubmitHandler, useForm } from 'react-hook-form'
import Cookies from 'universal-cookie'
import { createNote } from 'api'
import { AnnotationType, Notes2Props } from 'interfaces'
import { useClickOutside, useToast } from 'hooks'

export const Notes2: React.FC<Notes2Props> = ({
	idSession,
	setShowNotes,
	refetch
}): JSX.Element => {
	const ref = useRef(null)
	const { showToast } = useToast()
	const cookies = new Cookies()
	const token = cookies.get('token')
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<AnnotationType>({
		defaultValues: {
			title: '',
			priority: 'Low',
			description: ''
		}
	})

	useClickOutside(ref, () => setShowNotes(false))

	const onSubmit: SubmitHandler<AnnotationType> = async data => {
		const body = {
			title: data.title,
			description: data.description,
			priority: data.priority,
			session: idSession
		}
		try {
			await createNote(token, body)
			showToast(
				'Created exercises',
				'exercise was created successfully',
				'success'
			)
			refetch()
			setShowNotes(false)
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<form
			ref={ref}
			onSubmit={handleSubmit(onSubmit)}
			className="w-full h-full flex flex-col justify-center items-center p-5"
		>
			<header className="w-full h-[65px] flex justify-between items-center rounded-t-lg">
				<div className="w-6 h-8">
					<PaperClipIcon className="w-full h-full text-[#B5BACE]" />
				</div>
				<div className="w-full flex justify-between items-center pl-3">
					<div className="w-full flex flex-col justify-center items-start gap-1">
						<h2 className="w-full font-Roboto text-[#454952] text-[22px] leading-[25px] font-medium text-left">
							<input
								type="text"
								placeholder="Name note"
								autoFocus
								{...register('title', { required: true })}
								className="w-full"
							/>
						</h2>
						{errors.title?.type === 'required' && (
							<MessageError>This field is required</MessageError>
						)}
					</div>
					<div className="w-[120px] flex flex-col justify-center items-center gap-2 pr-3">
						<p className="font-Roboto text-[#9A9EAD] text-[18px] leading-[18px] font-normal">
							Priority
						</p>
						<div className="flex gap-1">
							<input
								type="radio"
								value="Low"
								className="accent-green-600 cursor-pointer"
								{...register('priority')}
							/>
							<input
								type="radio"
								value="Mid"
								className="accent-yellow-400 cursor-pointer"
								{...register('priority')}
							/>
							<input
								type="radio"
								value="High"
								className="accent-red-500 cursor-pointer"
								{...register('priority')}
							/>
						</div>
					</div>
				</div>
				<button
					type="button"
					className="w-6 h-6 cursor-pointer"
					onClick={() => setShowNotes(false)}
				>
					<XIcon className="w-full h-full text-[#B5BACE]" />
				</button>
			</header>
			<div className="w-full h-[325px] flex flex-col justify-center items-center">
				<div className="w-full h-full flex flex-col justify-center items-center gap-3 pb-3">
					<textarea
						placeholder="Write a note of this exercise"
						maxLength={255}
						className="w-full h-full p-3 border border-gray-200 rounded resize-none"
						{...register('description', { required: true })}
					/>
					{errors.description?.type === 'required' && (
						<MessageError>This field is required</MessageError>
					)}
				</div>
				<div className="w-full flex items-center justify-center gap-9">
					<Button
						label="Cancel"
						fill={false}
						customColor
						className="w-[140px] lg:w-[179px] h-8 cursor-pointer bg-white text-custom border border-custom"
						onClick={() => setShowNotes(false)}
					/>
					<Button
						type="submit"
						label="Save"
						customColor
						className="w-[140px] lg:w-[179px] h-8 cursor-pointer"
					/>
				</div>
			</div>
		</form>
	)
}
