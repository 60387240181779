import React, { useEffect, useRef, useState } from 'react'
import { SearchIcon } from '@heroicons/react/solid'
import {
	Button,
	Input,
	DrawerFormExercise,
	Drawer,
	BoxAddExercises,
	Loading
} from 'components'
import { useForm } from 'react-hook-form'
import {
	DrawerAddExerciseType,
	LibraryExerciseListType,
	libraryExerciseType,
	SearchInputs
} from 'interfaces'
import clsx from 'clsx'
import { useInfiniteQuery } from 'react-query'
import {
	createMultipleExercises,
	getListLibraryExercises,
	GET_LIBRARY_EXERCISES
} from 'api'
import { getOffset } from 'lib'
import Cookies from 'universal-cookie'
import { useInView } from 'react-intersection-observer'
import { useCustomer, useDevice, useToast } from 'hooks'

export const DrawerAddExercise: React.FC<DrawerAddExerciseType> = ({
	session,
	setOpen,
	refetchUp,
	refetchProgram
}): JSX.Element => {
	const customer = useCustomer()
	const { register, watch } = useForm<SearchInputs>({ mode: 'onChange' })
	const { ref, inView } = useInView()
	const searchIcon = <SearchIcon className="text-gray-900 cursor-pointer" />
	const watchSearch = watch('search', '')
	const cookies = new Cookies()
	const token = cookies.get('token')
	const scrollRef = useRef<HTMLDivElement>(null)
	const [search, setSearch] = useState<string | undefined>(undefined)
	const [exercises, setExercises] = useState<libraryExerciseType[]>([])
	const [openDrawer, setOpenDrawer] = useState(false)
	const [listExercises, setListExercises] = useState<LibraryExerciseListType[]>(
		[]
	)
	const [isDisabled, setIsDisabled] = useState(true)
	const { showToast } = useToast()
	const device = useDevice()

	useEffect(() => {
		setSearch(watchSearch)
		setExercises([])
	}, [watchSearch])

	const { data, isFetchingNextPage, fetchNextPage, isLoading, refetch } =
		useInfiniteQuery(
			[GET_LIBRARY_EXERCISES, search, token],
			({ pageParam = 0 }) =>
				getListLibraryExercises(token, customer.id, pageParam, search),
			{
				getPreviousPageParam: firstPage =>
					firstPage?.previous ? getOffset(firstPage?.previous) : undefined,
				getNextPageParam: lastPage =>
					lastPage?.next ? getOffset(lastPage?.next) : undefined
			}
		)

	useEffect(() => {
		if (inView) {
			fetchNextPage()
		}
	}, [inView])

	useEffect(() => {
		if (data?.pages) {
			let auxData = [] as libraryExerciseType[]
			data.pages.forEach(page => {
				const auxResults = page?.results
				if (auxResults) auxData = [...auxData, ...auxResults]
			})
			setExercises(auxData)
		}
	}, [data])

	const editList = (
		type: 'sum' | 'res',
		exercise: libraryExerciseType
	): void => {
		const aux = listExercises
		let pos = -1
		aux.forEach((item, index) => {
			if (item.library_exercise === exercise.slug) pos = index
		})

		if (type === 'sum') {
			if (pos >= 0) aux[pos].quota += 1
			else if (pos === -1)
				aux.push({ library_exercise: exercise.slug, quota: 1 })
		}
		if (type === 'res') {
			if (pos >= 0) aux[pos].quota -= 1
			else if (pos === -1) aux.pop()
		}
		setListExercises(aux)
		const quotas = aux
			?.map(item => item.quota)
			.reduce((prev, curr) => prev + curr)
		if (quotas > 0) setIsDisabled(false)
		if (quotas === 0) setIsDisabled(true)
	}

	const saveExercise = async (): Promise<void> => {
		if (session) {
			const body = {
				program_session: session.slug,
				library_exercise_list: listExercises
			}
			const res = await createMultipleExercises(token, body)

			if (res === 'Request Failed.') {
				showToast(
					'Added exercises',
					`exercise wasn't added successfully, exercise's name is too long `,
					'error'
				)
			} else {
				refetchUp()
				refetchProgram()
				setOpen(false)
				showToast(
					'Added exercises',
					'exercise was added successfully',
					'success'
				)
			}
		}
	}

	return (
		<>
			<div className="p-6 pb-0">
				<Input
					name="search"
					register={register}
					placeholder="Search exercise"
					type="text"
					className="mb-4 lg:col-span-3 lg:pr-2"
					leftIcon={searchIcon}
					rounded
				/>
				<div className="w-full py-1">
					<Button
						color="Secondary"
						size="full"
						type="button"
						label="+ Create a new exercise"
						fill={false}
						onClick={() => setOpenDrawer(true)}
					/>
				</div>
				<div className="h-[1px] w-full bg-gray-300 mt-2" />
				<div
					ref={scrollRef}
					className={clsx(
						'flex flex-col gap-2 py-2',
						{
							'min-h-[calc(100vh-280px)] max-h-[calc(100vh-280px)]':
								device === 'web'
						},
						{
							'min-h-[calc(100vh-340px)] max-h-[calc(100vh-340px)]':
								device === 'android' || device === 'ios'
						},
						'min-h-[calc(100vh-280px)] max-h-[calc(100vh-280px)]',
						'overflow-hidden hide-scroll-bar overflow-y-scroll',
						'md:min-h-[calc(100vh-280px)] md:max-h-[calc(100vh-280px)]'
					)}
				>
					{isLoading ? (
						<Loading />
					) : (
						<>
							{exercises.map((item, index) => {
								const pos = index
								return (
									<BoxAddExercises
										key={`${pos}-${item.slug}`}
										exercise={item}
										editList={editList}
									/>
								)
							})}
							<div className="flex items-center justify-center mt-2">
								<div ref={ref}>{isFetchingNextPage && <Loading isSmall />}</div>
							</div>
						</>
					)}
				</div>
			</div>
			<div
				className={clsx(
					'relative bottom-3 bg-white flex items-center justify-center',
					'min-h-[64px] max-h-[64px] shadow-[0px_2px_5px_3px_rgba(0,0,0,0.2)]'
				)}
			>
				<div className="w-full px-4 py-3">
					<Button
						customColor
						size="full"
						type="button"
						label="Add to my session"
						disabled={isDisabled}
						onClick={() => saveExercise()}
					/>
				</div>
			</div>
			<Drawer
				open={openDrawer}
				setOpen={(val: boolean) => setOpenDrawer(val)}
				title="CREATE EXERCISE"
				stretch
				mobileFull
			>
				<DrawerFormExercise
					typeForm="create"
					session={session}
					refetchUp={refetch}
					setOpen={(val: boolean) => setOpenDrawer(val)}
				/>
			</Drawer>
		</>
	)
}
