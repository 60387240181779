import { BaseURL } from 'api/environment'
import axios from 'axios'
import { GetNotificationsResponse } from './getNotificationSettings.interface'

export const getNotificationSettings = async (
	token: string,
	customerId: number
): Promise<GetNotificationsResponse[] | null> => {
	let response = null as GetNotificationsResponse[] | null

	await axios
		.get(`${BaseURL}/settings_notifications/?x-customer-id=${customerId}`, {
			headers: {
				Authorization: `Token ${token}`
			}
		})
		.then(async data => {
			response = data.data
		})
		.catch(error => console.error(error))

	return response
}
