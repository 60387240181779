import React, { useEffect, useMemo, useState } from 'react'
import { NotificationContext } from 'context'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import {
	NotificationType,
	deleteAllNotifications,
	deleteNotifications,
	getNotification,
	updateNotifications
} from 'api'
import Cookies from 'universal-cookie'
import { useCustomer, useToast } from 'hooks'
import { getOffset } from 'lib'
import { useInView } from 'react-intersection-observer'
import moment from 'moment'
import { NotificationProviderProps } from './provider.interface'

export const NotificationProvider = ({
	children
}: NotificationProviderProps): JSX.Element => {
	const queryClient = useQueryClient()
	const [notifications, setNotifications] = useState<NotificationType[]>([])
	const [getAllNotifications, setGetAllNotifications] = useState('ALL')
	const [isRead, setIsRead] = useState(false)
	const cookies = new Cookies()
	const token = cookies.get('token')
	const customer = useCustomer()
	const { ref, inView } = useInView()
	const { showToast } = useToast()
	const timeZone = moment().format('ZZ').slice(0, 3)

	const {
		data,
		isFetchingNextPage,
		fetchNextPage,
		isFetching,
		refetch,
		isLoading,
		isRefetching
	} = useInfiniteQuery(
		['GET_NOTIFICATIONS', token],
		({ pageParam = 0 }) =>
			getNotification(
				token,
				customer.id,
				pageParam,
				getAllNotifications,
				isRead,
				timeZone
			),
		{
			getNextPageParam: lastPage =>
				lastPage?.next ? getOffset(lastPage?.next) : undefined,
			refetchInterval: 60 * 1000
		}
	)

	const { mutateAsync: removeNotifications, isLoading: isDeletingOne } =
		useMutation({
			mutationFn: async (id: number) => {
				deleteNotifications(token, id)
			},
			onSuccess: async () => {
				await refetch()
				showToast('Notification was delete successfully', '', 'success')
				queryClient.invalidateQueries(['GET_NOTIFICATIONS'])
			},
			onError: error => {
				showToast('Error deleting notification', '', 'error')
				console.error(error)
			}
		})

	const { mutateAsync: removeAllNotifications, isLoading: isDeletingAll } =
		useMutation({
			mutationFn: async () => {
				deleteAllNotifications(token)
			},
			onSuccess: async () => {
				await refetch()
				showToast('Notifications was delete successfully', '', 'success')
				queryClient.invalidateQueries(['GET_NOTIFICATIONS'])
			},
			onError: error => {
				showToast('Error deleting notifications', '', 'error')
				console.error(error)
			}
		})

	const { mutateAsync: updateNotificationStatus } = useMutation({
		mutationFn: async (id: number) => {
			updateNotifications(token, id)
		},
		onSuccess: async () => {
			queryClient.invalidateQueries(['GET_NOTIFICATIONS'])
		},
		onError: error => {
			console.error(error)
		}
	})

	useEffect(() => {
		if (data?.pages) {
			let auxData = [] as NotificationType[]
			data.pages.forEach(page => {
				const auxResults = page?.results as unknown as NotificationType[]
				if (auxResults) auxData = [...auxData, ...auxResults]
			})
			setNotifications(auxData)
		}
	}, [data])

	useEffect(() => {
		if (inView) {
			fetchNextPage()
		}
	}, [inView])

	useEffect(() => {
		refetch()
	}, [isRead, getAllNotifications])

	const notificationState = useMemo(
		() => ({
			notifications,
			setNotifications,
			isFetchingNextPage,
			isFetching,
			inView,
			ref,
			removeNotifications,
			isLoading: isLoading || isDeletingOne || isRefetching || isDeletingAll,
			removeAllNotifications,
			updateNotificationStatus,
			setGetAllNotifications,
			setIsRead,
			isRead,
			getAllNotifications
		}),
		[
			notifications,
			setNotifications,
			isFetchingNextPage,
			isFetching,
			inView,
			ref,
			removeNotifications,
			isDeletingOne,
			isRefetching,
			removeAllNotifications,
			updateNotificationStatus,
			setGetAllNotifications,
			setIsRead,
			isRead,
			getAllNotifications
		]
	)

	return (
		<NotificationContext.Provider value={notificationState}>
			{children}
		</NotificationContext.Provider>
	)
}
