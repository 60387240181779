import React, { Fragment } from 'react'
import clsx from 'clsx'
import { TabsType } from 'interfaces'
import { Tab } from '@headlessui/react'

export const Tabs: React.FC<TabsType> = ({ tabs }): JSX.Element => {
	return (
		<Tab.Group>
			<Tab.List className="shadow-[0px_2px_4px_0px_rgba(0,0,0,0.2)] bg-white flex justify-evenly md:justify-start">
				{tabs.map(tab => (
					<Tab as={Fragment} key={`btn-tap-${tab.name}`}>
						{({ selected }) => (
							<button
								type="button"
								className={clsx(
									selected
										? 'border-custom text-gray-800'
										: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-400',
									'whitespace-nowrap py-3 px-8 border-b-2 font-medium text-sm',
									'md:text-2xl'
								)}
							>
								{tab.name}
							</button>
						)}
					</Tab>
				))}
			</Tab.List>
			<Tab.Panels
				className={clsx(
					'pt-4 pb-2 h-full'
					// 'min-h-[calc(100vh-184px)] max-h-[calc(100vh-184px)]'
					// 'min-h-[calc(100vh-186px)] max-h-[calc(100vh-186px)]',
					// 'md:min-h-[calc(100vh-126px)] md:max-h-[calc(100vh-126px)]'
				)}
			>
				{tabs.map(tab => {
					const Panel = tab.panel
					return (
						<Tab.Panel key={`panel-tap-${tab.name}`}>
							<Panel />
						</Tab.Panel>
					)
				})}
			</Tab.Panels>
		</Tab.Group>
	)
}
