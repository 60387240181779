import axios from 'axios'
import { BaseURL } from 'api/environment'

export const deleteNote = async (
	token: string,
	idSession: number
): Promise<number | null> => {
	let res = null as number | null
	await axios
		.delete(`${BaseURL}/session_annotations/${idSession}/`, {
			headers: {
				Authorization: `Token ${token}`
			}
		})
		.then(
			async response => {
				res = response.status
			},
			error => {
				res = error.status
				console.error(error)
			}
		)
	return res
}
