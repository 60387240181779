import axios, { AxiosInstance } from 'axios'
import moment from 'moment'
import Cookies from 'universal-cookie'
import { BaseURL } from 'api'
import { ApiConfigProps } from './api.interface'

export const API = (props?: ApiConfigProps): AxiosInstance => {
	const cookies = new Cookies()
	const token = cookies.get('token')

	const API_INSTANCE = axios.create({
		baseURL: props?.customUrl ?? BaseURL
	})

	API_INSTANCE.interceptors.request.use(
		config => {
			const configuration = config

			if (configuration.headers) {
				if (props?.contentType) {
					configuration.headers['Content-Type'] = props?.contentType
				}
				if (token && !props?.authorization) {
					configuration.headers.Authorization = props?.customAuthorization
						? props.customAuthorization
						: `Token ${token}`
				}

				if (!props?.customUrl) {
					configuration.params = {
						time_zone: moment().utcOffset() / 60,
						...configuration.params
					}
				}
			}
			return configuration
		},
		error => {
			return Promise.reject(error)
		}
	)

	return API_INSTANCE
}
