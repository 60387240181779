import { useToastType } from 'interfaces'
import {
	CheckCircleIcon,
	XCircleIcon,
	ExclamationCircleIcon
} from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import React from 'react'
import toast from 'react-hot-toast'

export const useToast = (): useToastType => {
	const showToast = (
		title: string,
		subtitle: string,
		type: 'success' | 'error' | 'warning'
	): void => {
		toast.custom(t => (
			<div
				className={`${
					t.visible ? 'animate-enter' : 'animate-leave'
				} max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 z-50`}
			>
				<div className="flex-1 w-0 p-4">
					<div className="flex items-start">
						<div className="flex-shrink-0">
							{type === 'success' && (
								<CheckCircleIcon
									className="h-6 w-6 text-green-400"
									aria-hidden="true"
								/>
							)}
							{type === 'error' && (
								<XCircleIcon
									className="h-6 w-6 text-red-400"
									aria-hidden="true"
								/>
							)}
							{type === 'warning' && (
								<ExclamationCircleIcon
									className="h-6 w-6 text-yellow-400"
									aria-hidden="true"
								/>
							)}
						</div>
						<div className="ml-3 w-0 flex-1 pt-0.5">
							<p className="text-sm font-medium text-gray-900">{title}</p>
							<p className="mt-1 text-sm text-gray-500">{subtitle}</p>
						</div>
						<div className="ml-4 flex-shrink-0 flex">
							<button
								className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500"
								type="button"
								onClick={() => {
									toast.remove(t.id)
								}}
							>
								<span className="sr-only">Close</span>
								<XIcon className="h-5 w-5" aria-hidden="true" />
							</button>
						</div>
					</div>
				</div>
			</div>
		))
	}
	return { showToast }
}
