import React from 'react'
import clsx from 'clsx'

export const Skeleton: React.FC = (): JSX.Element => {
	return (
		<div
			className={clsx(
				'w-full h-[100px] animate-pulse',
				'flex flex-col justify-between ',
				'p-2 mt-2 border-4 rounded-lg'
			)}
		>
			<div className="w-full flex gap-3">
				<div className="bg-gray-200  dark:bg-gray-500 opacity-40 w-4/5 h-6 rounded-lg" />
				<div className="bg-gray-200  dark:bg-gray-500 opacity-40 w-1/5 h-6 rounded-lg" />
			</div>
			<div className="w-full flex flex-col gap-2">
				<div className="bg-gray-200  dark:bg-gray-500 opacity-40 w-full h-3 rounded-full" />
				<div className="bg-gray-200  dark:bg-gray-500 opacity-40 w-full h-3 rounded-full" />
			</div>
		</div>
	)
}
