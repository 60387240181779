/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from 'react'
import { TrashIcon } from '@heroicons/react/outline'
import { PencilIcon } from '@heroicons/react/solid'
import { updateMetricsClient } from 'api'
import clsx from 'clsx'
import { Button, Input } from 'components'
import { useCustomer, useDevice, useToast } from 'hooks'
import { DrawerAthletePerformanceProps } from 'interfaces'
import { SubmitHandler, useForm } from 'react-hook-form'
import Cookies from 'universal-cookie'
import { Transition } from '@headlessui/react'

interface DataIntrinsicAthlete {
	[key: string]: {
		actual: string
		previous: string
		relativeChange: number
	}
}

interface IForm {
	[key: string]: string
}

export const DrawerAthletePerformance: React.FC<
	DrawerAthletePerformanceProps
> = ({ infoAthlete, refetch, setUpdateValuesDrawer }): JSX.Element => {
	const cookies = new Cookies()
	const token = cookies.get('token')
	const customer = useCustomer()
	const { showToast } = useToast()
	const device = useDevice()

	const [dataInfoAthlete, setDataInfoAthlete] = useState<DataIntrinsicAthlete>(
		{}
	)
	const [showModal, setShowModal] = useState(false)
	const [type, setType] = useState(false)
	const [valuex, setValuex] = useState(0)

	const {
		register,
		watch,
		handleSubmit,
		resetField,
		setValue,
		getValues,
		formState: { isValid }
	} = useForm<IForm>({
		mode: 'all',
		defaultValues: {}
	})

	const watchKey = watch('key')
	const watchValue = watch('value')

	useEffect(() => {
		if (infoAthlete?.variable_metric_fields)
			setDataInfoAthlete(infoAthlete?.variable_metric_fields)
	}, [infoAthlete])

	const handleAddMetric = (): void => {
		setDataInfoAthlete(prev => ({
			...prev,
			[`${watchKey.replace(' ', '_')}`]: {
				actual: watchValue,
				previous: dataInfoAthlete[watchKey.replace(' ', '_')]?.actual || '0',
				relativeChange:
					((parseInt(watchValue, 10) -
						parseInt(dataInfoAthlete[watchKey.replace(' ', '_')]?.actual, 10)) /
						parseInt(dataInfoAthlete[watchKey.replace(' ', '_')]?.actual, 10)) *
						100 || 0
			}
		}))
		resetField('key')
		resetField('value')
		setValuex(0)
		setShowModal(false)
	}

	const handleEditMetric = (key: string, value: string): void => {
		setValue('key', key.replace('_', ' '))
		setValue('value', value)
		setShowModal(true)
		setType(false)
	}

	const handleDeleteMetric = (keyx: string): void => {
		setDataInfoAthlete(
			Object.assign(
				{},
				...Object.entries(dataInfoAthlete)
					.filter(item => item[0] !== keyx)
					.map(item => ({
						[item[0]]: item[1]
					}))
			)
		)
	}

	const onSubmit: SubmitHandler<IForm> = () => {
		const body = {
			variable_metric_fields: Object.assign(
				{},
				...Object.entries(dataInfoAthlete).map(item => ({
					[item[0]]: item[1]
				}))
			)
		}
		try {
			if (infoAthlete) {
				const res = updateMetricsClient(
					token,
					infoAthlete.id,
					customer.id,
					body
				)
				if (!res) {
					showToast(
						'Update info athlete',
						`Info Athlete was updated susseccfully`,
						'error'
					)
					setUpdateValuesDrawer(false)
				}
			}
		} catch (error) {
			console.error(error)
			showToast('Update info athlete', `Error: error in this action`, 'error')
			setUpdateValuesDrawer(false)
		}
		setUpdateValuesDrawer(false)
		refetch()
	}

	return (
		<div
			className={clsx(
				'flex-1 flex-col justify-start px-5 py-6'
				// 'overflow-hidden hide-scroll-bar overflow-y-scroll'
			)}
		>
			<div className="w-full flex flex-col gap-2">
				<span className="text-[24px] leading-[18px] text-[#454952] font-bold">
					Body
				</span>
				<span className="text-[18px] leading-[18px] text-[#454952] font-normal">
					Measure
				</span>
			</div>
			<div className="w-full h-[2px] my-2 bg-black opacity-10" />
			<form onSubmit={handleSubmit(onSubmit)}>
				<div
					className={clsx(
						'flex flex-col gap-2',
						{
							'min-h-[calc(100vh-310px)] max-h-[calc(100vh-310px)]':
								device === 'web'
						},
						{
							'min-h-[calc(100vh-360px)] max-h-[calc(100vh-360px)]':
								device === 'android' || device === 'ios'
						},
						'min-h-[calc(100vh-310px)] max-h-[calc(100vh-310px)]',
						'overflow-hidden hide-scroll-bar overflow-y-scroll',
						'md:min-h-[calc(100vh-300px)] md:max-h-[calc(100vh-300px)]'
					)}
				>
					<div className="w-full flex flex-col gap-5">
						{dataInfoAthlete &&
							Object.entries(dataInfoAthlete).map(([key, values], idx) => {
								const kk = `key-${idx}`
								return (
									<div key={kk} className="w-full flex gap-2">
										<div className="w-full flex flex-col gap-2">
											<span>{key.replace('_', ' ')}</span>
											<div
												className={clsx(
													'flex items-center pl-3',
													'w-full h-12 border border-gray-200 rounded-sm'
												)}
											>
												<span>{values ? values?.actual : ''}</span>
											</div>
										</div>
										<div className="flex flex-col gap-2 justify-end items-center">
											<button
												type="button"
												className="cursor-pointer group"
												onClick={() => handleDeleteMetric(key)}
											>
												<TrashIcon className="w-5 h-5 group-hover:text-custom text-[#929BB2]" />
											</button>
											<button
												type="button"
												className="cursor-pointer group"
												onClick={() => handleEditMetric(key, values.actual)}
											>
												<PencilIcon className="w-5 h-5 group-hover:text-custom text-[#929BB2]" />
											</button>
										</div>
									</div>
								)
							})}
						<div className="w-full h-full bg-white">
							<Transition appear show={showModal} as={Fragment}>
								<div className={clsx('relative z-50 w-60 h-60 bg-white')}>
									<Transition.Child
										as={Fragment}
										enter="ease-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in duration-200"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="fixed inset-0 bg-black bg-opacity-25" />
									</Transition.Child>

									<div className="fixed inset-0 overflow-y-auto">
										<div className="flex min-h-full items-center justify-center p-4 text-center">
											<Transition.Child
												as={Fragment}
												enter="ease-out duration-300"
												enterFrom="opacity-0 scale-95"
												enterTo="opacity-100 scale-100"
												leave="ease-in duration-200"
												leaveFrom="opacity-100 scale-100"
												leaveTo="opacity-0 scale-95"
											>
												<div
													className={clsx(
														'w-[350px] h-[300px] flex flex-col justify-center items-center',
														'bg-white gap-2 p-4 rounded-md'
													)}
												>
													<div className="w-full flex flex-col justify-center items-center">
														<Input
															name="key"
															type="text"
															title="Name of the metric"
															register={register}
															placeholder="Name of the metric"
															className="mb-3"
															squared
															maxLength={15}
															disabled={!type}
															asterisk
														/>
														<Input
															name="value"
															type="number"
															title="Units of measurement"
															register={register}
															placeholder="Units of measurement"
															className="mb-3"
															squared
															asterisk
															max="999"
															value={valuex === 0 ? '' : valuex}
															onChange={(
																e: React.ChangeEvent<HTMLInputElement>
															) => {
																if (e.target.value) {
																	if (e.target.value.length <= 3) {
																		const inputValue = e.target.value
																			.toString()
																			.replace(/\D/g, '')

																		setValuex(parseInt(inputValue, 10))
																		setValue('value', inputValue)
																	}
																} else {
																	setValuex(0)
																	setValue('value', '')
																}
															}}
														/>
													</div>
													<div className="w-full flex gap-4 justify-around">
														<button
															type="button"
															className={clsx(
																'w-full h-12 border border-custom rounded-md',
																'text-custom hover:bg-custom hover:text-white'
															)}
															onClick={() => {
																setShowModal(false)
																setValuex(0)
															}}
														>
															Cancel
														</button>
														<button
															type="button"
															className={clsx(
																'w-full h-12  rounded-md text-white',
																getValues('key') === '' || valuex === 0
																	? 'bg-gray-600'
																	: 'border border-custom bg-custom hover:opacity-80'
															)}
															onClick={() => handleAddMetric()}
															disabled={getValues('key') === '' || valuex === 0}
														>
															{type ? 'Create' : 'Update'}
														</button>
													</div>
												</div>
											</Transition.Child>
										</div>
									</div>
								</div>
							</Transition>
						</div>
					</div>
				</div>

				<div
					className={clsx(
						'w-full px-6 py-3 flex items-center justify-center',
						'border-2 border-custom rounded-md cursor-pointer',
						'text-custom hover:text-white font-Roboto text-sm underline hover:bg-custom'
					)}
					onClick={() => {
						setShowModal(true)
						resetField('key')
						resetField('value')
						setType(true)
					}}
					aria-hidden="true"
				>
					<span>+ Add metrics</span>
				</div>
				<div className="w-full py-3">
					<Button
						customColor
						size="full"
						type="submit"
						label="Update"
						disabled={!isValid}
					/>
				</div>
			</form>
		</div>
	)
}
