import clsx from 'clsx'
import { LinkProps } from 'interfaces'
import React from 'react'
import { Link } from 'react-router-dom'

export const LinkElement: React.FC<LinkProps> = ({
	label,
	linkTo,
	className
}): JSX.Element => {
	return (
		<div className={clsx('flex items-center justify-center', className)}>
			<Link to={linkTo} replace>
				<div
					className={clsx(
						'block px-4 py-2 text-sm text-custom underline text-center font-Roboto font-bold',
						'hover:brightness-90'
					)}
				>
					{label}
				</div>
			</Link>
		</div>
	)
}
