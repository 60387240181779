import { BaseURL } from 'api/environment'
import axios from 'axios'
import { libraryExerciseType, LibraryExercisePost } from 'interfaces'

export const createLibraryExercises = async (
	token: string,
	body: LibraryExercisePost
): Promise<libraryExerciseType[] | null> => {
	let res = [] as libraryExerciseType[] | null
	await axios
		.post(`${BaseURL}/library_exercises/`, body, {
			headers: {
				Authorization: `Token ${token}`
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const cloneLibraryExercises = async (
	token: string,
	libraryExercise: string
): Promise<libraryExerciseType | null> => {
	let res = null as libraryExerciseType | null
	await axios
		.post(
			`${BaseURL}/library_exercises/clone/`,
			{ library_exercise: libraryExercise },
			{
				headers: {
					Authorization: `Token ${token}`
				}
			}
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				res = error.response.data
				// console.error(error)
			}
		)
	return res
}
