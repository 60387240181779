import axios from 'axios'
import {
	LoginResponse,
	ForgotPasswordResponse,
	ProfileResponse
} from 'interfaces/api'
import { BaseURL } from '../environment'

export const login = async (
	email: string,
	password: string
): Promise<LoginResponse | null> => {
	let res = null as LoginResponse | null
	await axios.post(`${BaseURL}/users/login/`, { email, password }).then(
		async response => {
			res = response.data
		},
		error => {
			console.error(error)
		}
	)
	return res
}

export const forgotPass = async (
	email: string
): Promise<ForgotPasswordResponse | null> => {
	let res = null as ForgotPasswordResponse | null
	await axios.post(`${BaseURL}/users/request_reset_password/`, { email }).then(
		async response => {
			res = response.data
		},
		error => {
			console.error(error)
		}
	)
	return res
}

export const updateProfileImage = async (
	token: string,
	image: string
): Promise<ProfileResponse | null> => {
	let res = null as ProfileResponse | null
	await axios
		.post(
			`${BaseURL}/users/upload_image/`,
			{ image },
			{
				headers: {
					Authorization: `Token ${token}`
				}
			}
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
