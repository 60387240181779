import axios from 'axios'
import { BaseURL } from 'api/environment'
import {} from 'interfaces'

interface bodyProps {
	title: string
	description: string
	priority: string
	session: number
}

export const createNote = async (
	token: string,
	body: bodyProps
): Promise<string | null> => {
	let res = null as string | null
	await axios
		.post(`${BaseURL}/session_annotations/`, body, {
			headers: {
				Authorization: `Token ${token}`
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
