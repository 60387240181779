/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
	Tooltip
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import clsx from 'clsx'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { CarouselMetricsProps } from './carouselMetrics.interface'

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
	Tooltip
)

export const CarouselMetrics: React.FC<CarouselMetricsProps> = ({
	metrics,
	indexSets
}): JSX.Element => {
	const [index, setIndex] = useState(0)

	useEffect(() => {
		setIndex(0)

		return () => {
			setIndex(0)
		}
	}, [indexSets])

	return (
		<div className="w-full h-full flex flex-col justify-between items-center p-3">
			<div className="w-full h-8 flex justify-center items-center">
				Value: {metrics[index]?.value.toUpperCase() || '-'}
			</div>
			<div className="w-[198px] h-auto">
				<Bar
					style={{ height: '140px' }}
					data={{
						labels: ['base', 'execute'],
						datasets: [
							{
								label: metrics[index]?.name,
								data: [
									metrics[index]?.type === 'time'
										? metrics[index]?.base.split(':')[0] ||
										  metrics[index]?.base.split(':')[1]
										: metrics[index]?.type === 'select'
										? metrics[index]?.base === 'low' ||
										  metrics[index]?.base === '6' ||
										  metrics[index]?.base === '12'
											? 1
											: metrics[index]?.base === 'middle' ||
											  metrics[index]?.base === '18' ||
											  metrics[index]?.base === '24'
											? 5
											: 10
										: metrics[index]?.base,
									metrics[index]?.type === 'time'
										? metrics[index]?.value.split(':')[0] ||
										  metrics[index]?.value.split(':')[1]
										: metrics[index]?.type === 'select'
										? metrics[index]?.value === 'low' ||
										  metrics[index]?.value === '6' ||
										  metrics[index]?.value === '12'
											? 1
											: metrics[index]?.value === 'middle' ||
											  metrics[index]?.value === '18' ||
											  metrics[index]?.value === '24'
											? 5
											: 10
										: metrics[index]?.value
								],
								backgroundColor: [
									metrics[index]?.type === 'time'
										? '#929BB2'
										: metrics[index]?.type === 'select'
										? metrics[index]?.base === 'low' ||
										  metrics[index]?.base === '6' ||
										  metrics[index]?.base === '12'
											? 'green'
											: metrics[index]?.base === 'middle' ||
											  metrics[index]?.base === '18' ||
											  metrics[index]?.base === '24'
											? 'yellow'
											: metrics[index]?.base === 'high' ||
											  metrics[index]?.base === '30' ||
											  metrics[index]?.base === '36'
											? 'red'
											: 'transparent'
										: '#929BB2',
									metrics[index]?.type === 'time'
										? process.env.REACT_APP_COLOR
										: metrics[index]?.type === 'select'
										? metrics[index]?.value === 'low' ||
										  metrics[index]?.value === '6' ||
										  metrics[index]?.value === '12'
											? 'green'
											: metrics[index]?.value === 'middle' ||
											  metrics[index]?.value === '18' ||
											  metrics[index]?.value === '24'
											? 'yellow'
											: metrics[index]?.value === 'high' ||
											  metrics[index]?.value === '30' ||
											  metrics[index]?.value === '36'
											? 'red'
											: 'transparent'
										: process.env.REACT_APP_COLOR
								],
								borderWidth: 1
							}
						]
					}}
					options={{
						scales: {
							yAxes: {
								time: {
									unit: 'hour',
									displayFormats: {
										hour: 'HH:mm:ss'
									}
								}
							}
						}
					}}
				/>
			</div>
			<div className="w-full h-10 flex gap-5 justify-between">
				<button
					type="button"
					className={clsx(
						metrics.length < 2 && 'opacity-50 cursor-not-allowed bg-gray-600',
						'w-8 h-8 bg-custom text-white rounded cursor-pointer'
					)}
					onClick={() => {
						if (index === 0) {
							setIndex(metrics.length - 1)
						} else {
							setIndex(index - 1)
						}
					}}
					disabled={metrics.length < 2}
				>
					<ChevronLeftIcon className="w-5 h-5" />
				</button>
				<div className="w-full h-8 flex justify-center items-center font-bold">
					{metrics.length === 0 ? 'No metrics' : metrics[index]?.name}
				</div>
				<button
					type="button"
					className={clsx(
						metrics.length < 2 && 'opacity-50 cursor-not-allowed bg-gray-600',
						'w-8 h-8 bg-custom text-white rounded cursor-pointer'
					)}
					onClick={() => {
						if (index === metrics.length - 1) {
							setIndex(0)
						} else {
							setIndex(index + 1)
						}
					}}
					disabled={metrics.length < 2}
				>
					<ChevronRightIcon className="w-5 h-5" />
				</button>
			</div>
		</div>
	)
}
