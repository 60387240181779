import React from 'react'
import { Layout, Tabs } from 'components'
import { AthleteListActive } from './athleteListActive'
import { AthleteListInactive } from './athleteListInactive'

export const Training = (): JSX.Element => {
	const options = [
		{ name: 'All', panel: AthleteListActive },
		{ name: 'In training', panel: AthleteListInactive }
	]
	return (
		<Layout stretch tabs>
			<Tabs tabs={options} />
		</Layout>
	)
}
