import FullCalendar from '@fullcalendar/react'
// import moment from 'moment'
import React from 'react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction'
import {
	EventClickArg,
	EventContentArg,
	EventSourceInput
} from '@fullcalendar/core'
import moment from 'moment'

export interface Props {
	events: EventSourceInput | undefined
	setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
	setGroupSlug: React.Dispatch<React.SetStateAction<string>>
	setDateSelected: React.Dispatch<React.SetStateAction<Date | undefined>>
	initialDate: Date
	setCurrentMonth: React.Dispatch<React.SetStateAction<Date>>
}

export const CalendarSchedule: React.FC<Props> = ({
	events,
	setOpenDrawer,
	setGroupSlug,
	setDateSelected,
	initialDate,
	setCurrentMonth
}): JSX.Element => {
	const renderEventContent = (eventInfo: EventContentArg): JSX.Element => {
		return (
			<button
				type="button"
				className="w-full h-full flex flex-col md:gap-1 px-[1px] md:px-2 border border-white rounded bg-custom text-white"
			>
				<b className="line-clamp-1">{eventInfo.timeText}</b>
				<i className="line-clamp-1">{eventInfo.event.title}</i>
			</button>
		)
	}

	const handleClickDay = (date: DateClickArg): void => {
		setDateSelected(date.date)
		setOpenDrawer(true)
	}

	const handleClickEvent = (eventClick: EventClickArg): void => {
		let event = new Date()
		event = eventClick.event.start as Date
		setDateSelected(event)
		setGroupSlug(eventClick.event.id)
		setOpenDrawer(true)
	}
	return (
		<FullCalendar
			timeZone="UTC"
			initialDate={initialDate}
			eventTimeFormat={{
				hour: '2-digit',
				minute: '2-digit',
				hour12: true
			}}
			plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
			initialView="dayGridMonth"
			headerToolbar={{
				start: 'prev,next',
				center: 'title',
				end: 'timeGridDay,timeGridWeek,dayGridMonth'
			}}
			allDaySlot={false}
			selectable
			eventColor="var(--color-custom)"
			events={events}
			dateClick={handleClickDay}
			eventClick={handleClickEvent}
			eventContent={renderEventContent}
			datesSet={arg => {
				const y = moment().year()
				const m = moment(arg.start).add(18, 'd').month()
				const aux = new Date(y, m, 1)

				setCurrentMonth(aux)
			}}
		/>
	)
}
