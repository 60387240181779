import {
	HandleAddLibraryMetricProps,
	HandleChangeLibraryMetricProps,
	HandleCreateLibrarySetProps,
	HandleDeleteLibraryMetricProps,
	HandleRemoveLibrarySetProps,
	handleAddMetricProps,
	handleChangeMetricProps,
	handleCreateSetProps,
	handleDeleteMetricProps,
	handleRemoveProps,
	metricType
} from 'interfaces'

export const handleCreateSet = (props: handleCreateSetProps): void => {
	const { exercise, setsBreakDown, setSetsBreakDown } = props
	const newSet = {
		slug: `${new Date().getTime()}`,
		exercise,
		order: setsBreakDown.length + 1,
		metrics: []
	}
	setSetsBreakDown([...setsBreakDown, newSet])
}

export const handleRemoveSet = (props: handleRemoveProps): void => {
	const { slug, setsBreakDown, setCurrentSet, setSetsBreakDown } = props
	const newSets = setsBreakDown.filter(set => set.slug !== slug)
	if (setsBreakDown.at(-1)?.slug === slug && setsBreakDown.length > 1) {
		setCurrentSet(setsBreakDown.length - 2)
	}
	setSetsBreakDown(newSets)
}

export const handleAddMetric = (props: handleAddMetricProps): void => {
	const { setsBreakDown, currentSet, setSetsBreakDown } = props
	const newMetric = {
		slug: `${new Date().getTime()}`,
		type_metric: 'time',
		default_value: '00:00:00'
	}

	const updatedLocalSets = [...setsBreakDown]
	const metricsArr = [
		newMetric,
		...updatedLocalSets[currentSet].metrics
	] as metricType[]
	updatedLocalSets[currentSet].metrics = metricsArr
	setSetsBreakDown(updatedLocalSets)
}

export const handleDeleteMetric = (props: handleDeleteMetricProps): void => {
	const { setsBreakDown, currentSet, slug, setSetsBreakDown } = props
	const newMetricsArr = setsBreakDown[currentSet].metrics.filter(
		oldMetric => oldMetric.slug !== slug
	)
	const updatedLocalSets = [...setsBreakDown]
	updatedLocalSets[currentSet].metrics = newMetricsArr
	setSetsBreakDown(updatedLocalSets)
}

export const handleChangeMetric = (props: handleChangeMetricProps): void => {
	const { slug, type, value, setsBreakDown, currentSet, setSetsBreakDown } =
		props
	const changedLocalMetrics = setsBreakDown[currentSet].metrics.map(
		oldMetric => {
			if (oldMetric.slug === slug) {
				return {
					slug,
					type_metric: type,
					default_value: value
				}
			}
			return oldMetric
		}
	)
	const updatedLocalSets = [...setsBreakDown]
	updatedLocalSets[currentSet].metrics = changedLocalMetrics as metricType[]
	setSetsBreakDown(updatedLocalSets)
}

export const handleCreateLibrarySet = (
	props: HandleCreateLibrarySetProps
): void => {
	const { exercise, setsBreakDown, setSetsBreakDown } = props
	const newSet = {
		slug: `${new Date().getTime()}`,
		exercise,
		order: setsBreakDown.length + 1,
		library_metrics: []
	}
	setSetsBreakDown([...setsBreakDown, newSet])
}

export const handleRemoveLibrarySet = (
	props: HandleRemoveLibrarySetProps
): void => {
	const { setsBreakDown, slug, setCurrentSet, setSetsBreakDown } = props
	const newSets = setsBreakDown.filter(set => set.slug !== slug)
	if (setsBreakDown.at(-1)?.slug === slug && setsBreakDown.length > 1) {
		setCurrentSet(setsBreakDown.length - 2)
	}
	setSetsBreakDown(newSets)
}

export const handleAddLibraryMetric = (
	props: HandleAddLibraryMetricProps
): void => {
	const { setsBreakDown, currentSet, setSetsBreakDown } = props
	const newMetric = {
		slug: `${new Date().getTime()}`,
		type_metric: 'time',
		default_value: '00:00:00'
	}

	const updatedLocalSets = [...setsBreakDown]
	const metricsArr = [
		newMetric,
		...updatedLocalSets[currentSet].library_metrics
	] as metricType[]
	updatedLocalSets[currentSet].library_metrics = metricsArr
	setSetsBreakDown(updatedLocalSets)
}

export const handleDeleteLibraryMetric = (
	props: HandleDeleteLibraryMetricProps
): void => {
	const { setsBreakDown, currentSet, slug, setSetsBreakDown } = props
	const newMetricsArr = setsBreakDown[currentSet].library_metrics.filter(
		oldMetric => oldMetric.slug !== slug
	)
	const updatedLocalSets = [...setsBreakDown]
	updatedLocalSets[currentSet].library_metrics = newMetricsArr
	setSetsBreakDown(updatedLocalSets)
}

export const handleChangeLibraryMetric = (
	props: HandleChangeLibraryMetricProps
): void => {
	const { slug, type, value, setsBreakDown, currentSet, setSetsBreakDown } =
		props
	const changedLocalMetrics = setsBreakDown[currentSet].library_metrics.map(
		oldMetric => {
			if (oldMetric.slug === slug) {
				return {
					slug,
					type_metric: type,
					default_value: value
				}
			}
			return oldMetric
		}
	)
	const updatedLocalSets = [...setsBreakDown]
	updatedLocalSets[currentSet].library_metrics =
		changedLocalMetrics as metricType[]
	setSetsBreakDown(updatedLocalSets)
}
