import { ChevronRightIcon, PlusIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { Icon, ModalCalendar } from 'components'
import {
	AssignedExecutedMetricType,
	AssignedProgramSession,
	Icons
} from 'interfaces'
import React, { useEffect, useState } from 'react'
import { DropDown } from './dropdown'

interface Props {
	item: AssignedProgramSession
	sessionsCompleted: string[]
	setSession: React.Dispatch<React.SetStateAction<string>>
	setIdSession: React.Dispatch<React.SetStateAction<number>>
	setModalNotes: React.Dispatch<React.SetStateAction<boolean>>
	handleChangeMetrics: (slug: string, value: string) => void
	lastData: {
		programSlug: string
		sessionSlug: string
		exerciseSlug: string
	}
	setLastData: React.Dispatch<
		React.SetStateAction<{
			programSlug: string
			sessionSlug: string
			exerciseSlug: string
		}>
	>
	lastScroll: {
		programSlug: string
		sessionSlug: string
		exerciseSlug: string
	}
	setMetricsExecuted2: React.Dispatch<
		React.SetStateAction<
			{
				slugAME: string
				body: AssignedExecutedMetricType
			}[]
		>
	>
	setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
	assignedProgramSlug: string
	refetchData: () => void
	isCompletedSession: boolean
}

export const DropdownSessionInTraining: React.FC<Props> = ({
	item,
	sessionsCompleted,
	setSession,
	setIdSession,
	setModalNotes,
	handleChangeMetrics,
	lastData,
	setLastData,
	lastScroll,
	setMetricsExecuted2,
	setOpenDrawer,
	assignedProgramSlug,
	refetchData,
	isCompletedSession
}): JSX.Element => {
	const refSession = React.useRef<HTMLDivElement | null>(null)

	const [open, setOpen] = useState(false)
	const [lastSession, setLastSession] = useState('')

	useEffect(() => {
		if (refSession.current) {
			if (lastScroll.sessionSlug === refSession.current.id) {
				setOpen(true)
			}
		}
	}, [lastScroll])

	useEffect(() => {
		if (open) setLastSession(item.slug)
	}, [open])

	useEffect(() => {
		setLastData({
			...lastData,
			sessionSlug: lastSession
		})
	}, [lastSession])

	return (
		<div id={item.slug} ref={refSession}>
			<div className="relative">
				<button
					type="button"
					className={clsx(
						'font-Roboto text-base font-medium text-white',
						'absolute right-10 top-3 z-20 bg-custom cursor-pointer',
						'w-[88px] h-7 rounded-full',
						'flex justify-center items-center',
						'px-2 hover:opacity-80'
					)}
					onClick={() => {
						setSession(item.program_session.name)
						setIdSession(item.id)
						setModalNotes(true)
					}}
				>
					<div className="h-4 w-4 flex justify-center items-center">
						<PlusIcon className="h-full w-full text-white" />
					</div>
					Notes
				</button>
				<div id="headlessui-portal-root" />
				<div className="absolute right-0 top-12 w-[128px] h-5 z-10 cursor-pointer">
					<ModalCalendar
						finishedDate={item.finished_date}
						slugProgram={item.slug}
						session={item.program_session.name}
						sessionsCompleted={sessionsCompleted}
						setOpenDrawer={setOpenDrawer}
					/>
				</div>
				<div id="modal-calendar" />
				<div
					id="button"
					className={clsx(
						'flex justify-between',
						'h-full p-2 w-full relative',
						'shadow sm:rounded-lg border-[1px] border-l-4 border-l-custom rounded-lg',
						'bg-white hover:bg-gray-200 border-gray-300 cursor-pointer'
					)}
					aria-hidden
					onClick={() => {
						setOpen(!open)
					}}
				>
					<div className="flex flex-col w-full justify-start pl-2">
						<div className="w-3/4 flex justify-start">
							{isCompletedSession && (
								<div className="flex justify-center items-center">
									<Icon
										src={Icons.check}
										fillCircle
										className="w-5 h-5 text-custom"
									/>
								</div>
							)}
							<p className="w-full font-Roboto font-bold text-2xl line-clamp-2">
								{item.program_session.name}
							</p>
						</div>
						<div className="font-Roboto font-normal text-lg text-gray-500 flex justify-start">
							{item.assigned_exercises.length} Exercises
						</div>
					</div>
					<div className="relative top-1 h-full flex justify-center items-center">
						<ChevronRightIcon
							className={clsx(
								open ? '-rotate-90 transform' : 'rotate-90',
								'h-6 w-6 text-custom'
							)}
							aria-hidden="true"
						/>
					</div>
				</div>
				{open && (
					<div id="panel" className="">
						{item.assigned_exercises.map(exercise => {
							return (
								<div key={exercise.slug}>
									<DropDown
										isCompletedExercise={exercise.is_completed}
										status={exercise.status}
										idSession={item.id}
										exercise={exercise}
										handleChangeMetrics={handleChangeMetrics}
										lastData={lastData}
										setLastData={setLastData}
										lastScroll={lastScroll}
										setMetricsExecuted2={setMetricsExecuted2}
										assignedProgramSlug={assignedProgramSlug}
										refetchData={refetchData}
									/>
								</div>
							)
						})}
					</div>
				)}
			</div>
		</div>
	)
}
