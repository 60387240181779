import { BaseURL } from 'api/environment'
import axios from 'axios'
import { NotificationsResponse } from './getNotifications.interface'

export const getNotification = async (
	token: string,
	customerId: number,
	offset: number,
	getAll?: string,
	isRead?: boolean,
	tz?: string
): Promise<NotificationsResponse | null> => {
	let response = null as NotificationsResponse | null

	await axios
		.get(
			`${BaseURL}/notifications/?x-customer-id=${customerId}&tz=${tz}&limit=10&offset=${offset}&${
				getAll !== 'ALL' && `&is_seen=${isRead}`
			}`,
			{
				headers: {
					Authorization: `Token ${token}`
				}
			}
		)
		.then(async data => {
			response = data.data
		})
		.catch(error => console.error(error))

	return response
}
