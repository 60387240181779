import React, { useState } from 'react'
import { ChevronDownIcon, DotsHorizontalIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { BoxLibraryExercisesType } from 'interfaces'
import { Disclosure, Transition } from '@headlessui/react'
import { Drawer } from 'components/drawer'

export const BoxLibraryExercises: React.FC<BoxLibraryExercisesType> = ({
	exercise,
	deleted,
	clone,
	edit,
	className
}): JSX.Element => {
	const [openDrawer, setOpenDrawe] = useState(false)
	const {
		slug,
		name: title,
		description: subtitle,
		library_metrics: metrics
	} = exercise

	return (
		<>
			<div className={className}>
				<Disclosure>
					{({ open }) => (
						<div className="relative">
							<div className="absolute top-[15px] right-[17px]">
								<button type="button" onClick={() => setOpenDrawe(!openDrawer)}>
									<DotsHorizontalIcon
										className={clsx('h-6 w-6 text-custom')}
										aria-hidden="true"
									/>
								</button>
							</div>
							<Disclosure.Button
								type="button"
								className={clsx(
									'bg-white shadow w-full cursor-pointer rounded-lg',
									'border-[1px] border-gray-300 border-l-4 border-l-custom',
									'hover:bg-gray-200',
									open ? 'rounded-b-none' : ''
								)}
							>
								<div className="flex justify-between px-4 py-3">
									<div className="">
										<h3
											className="text-left text-base leading-6text-gray-900
											font-Roboto font-bold lg:text-2xl line-clamp-1"
										>
											{title}
										</h3>
										<div className="text-xs text-gray-400 lg:text-lg text-left line-clamp-1">
											<p>{subtitle}</p>
										</div>
									</div>
									<div className="flex justify-center items-center flex-col">
										<div className="h-6 w-6" />
										{exercise.library_metrics.length > 0 && (
											<ChevronDownIcon
												className={clsx(
													open ? 'rotate-180 transform' : '',
													'h-6 w-6 text-custom'
												)}
												aria-hidden="true"
											/>
										)}
									</div>
								</div>
							</Disclosure.Button>
							<Transition
								enter="transition duration-100 ease-out"
								enterFrom="transform opacity-0"
								enterTo="transform opacity-100"
								leave="transition duration-75 ease-out"
								leaveFrom="transform opacity-100"
								leaveTo="transform opacity-0"
							>
								{exercise.library_metrics.length > 0 && (
									// eslint-disable-next-line max-len
									<Disclosure.Panel className="pb-2 text-sm text-gray-500 bg-white border-[1px] border-t-0 border-gray-300">
										<div className="w-full bg-gray-700 py-[5px] flex items-center justify-center">
											<h1 className="text-white font-Roboto ">
												Units of measurement
											</h1>
										</div>
										<div className="px-2">
											{metrics.map(metric => (
												<div
													key={metric.slug}
													className={clsx(
														'flex items-center justify-between w-full pt-2 pb-1',
														'border-b-[1px] border-b-gray-300 last:border-b-0'
													)}
												>
													<h2 className="font-Roboto font-normal text-sm text-gray-700">
														{metric.type_metric_info.name}
													</h2>
													<h2 className="font-Roboto font-bold text-sm text-gray-700">
														{metric.default_value}
													</h2>
												</div>
											))}
										</div>
									</Disclosure.Panel>
								)}
							</Transition>
						</div>
					)}
				</Disclosure>
			</div>
			<Drawer
				open={openDrawer}
				setOpen={(val: boolean) => setOpenDrawe(val)}
				title={title}
				stretch
			>
				<div
					className={clsx(
						'h-full divide-y',
						'md:flex md:flex-col md:justify-center'
					)}
					aria-hidden="true"
				>
					<button
						type="button"
						onClick={() => {
							setOpenDrawe(false)
							edit(exercise)
						}}
						className={clsx(
							'text-black group flex items-center justify-center h-1/4 p-4 w-full',
							'cursor-pointer hover:bg-custom hover:text-white',
							'md:h-auto'
						)}
					>
						<span className="text-lg font-Roboto text-center font-medium">
							Edit
						</span>
					</button>
					<button
						type="button"
						onClick={() => {
							setOpenDrawe(false)
							clone(slug)
						}}
						className={clsx(
							'text-black group flex items-center justify-center h-1/4 p-4 w-full',
							'cursor-pointer hover:bg-custom hover:text-white',
							'md:h-auto md:px-8'
						)}
					>
						<span className="text-lg font-Roboto text-center font-medium">
							Copy
						</span>
					</button>
					<button
						type="button"
						onClick={() => {
							setOpenDrawe(false)
							deleted(slug)
						}}
						className={clsx(
							'text-black group flex items-center justify-center h-1/4 p-4 w-full',
							'cursor-pointer hover:bg-custom hover:text-white',
							'md:h-auto md:px-8'
						)}
					>
						<span className="text-lg font-Roboto text-center font-medium">
							Erase
						</span>
					</button>
					<button
						type="button"
						onClick={() => setOpenDrawe(false)}
						className={clsx(
							'text-black group flex items-center justify-center h-1/4 p-4 w-full',
							'cursor-pointer hover:bg-custom hover:text-white',
							'md:h-auto'
						)}
					>
						<span className="text-lg font-Roboto text-center font-medium">
							Cancel
						</span>
					</button>
				</div>
			</Drawer>
		</>
	)
}
