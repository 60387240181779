import React, { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import {
	Button,
	Icon,
	Input,
	Layout,
	BoxSession,
	Modal,
	Drawer,
	DrawerSession
} from 'components'
import {
	Icons,
	CreateProgramInputs,
	PostProgram,
	sessionType
} from 'interfaces'
import { useForm } from 'react-hook-form'
import { CameraIcon } from '@heroicons/react/outline'
import { useCustomer, useToast } from 'hooks'
import {
	cloneProgramSession,
	createProgram,
	editProgram,
	getProgram,
	deleteProgramSession,
	GET_PROGRAM,
	createProgramSession
} from 'api'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'
import { ProgramContext } from 'context'
import styles from './program.module.scss'

export const Program = (): JSX.Element => {
	const params = useParams()
	const { showToast } = useToast()
	const location = useLocation()
	const cookies = new Cookies()
	const token = cookies.get('token')
	const customer = useCustomer()
	const navigate = useNavigate()
	const { data, isLoading, refetch } = useQuery(
		[GET_PROGRAM, params.programSlug, token],
		() => getProgram(token, customer.id, params.programSlug),
		{
			refetchOnMount: 'always'
		}
	)
	const { register, watch, setValue } = useForm<CreateProgramInputs>({
		mode: 'onChange'
	})
	const watchName = watch('name')
	// const watchNameSession = watchSession('name', '')
	const [showModal, setShowModal] = useState(false)
	const [openDrawer, setOpenDrawer] = useState(false)
	const [cover, setCover] = useState<string | undefined>()
	const [sessions, setSessions] = useState<sessionType[]>([])
	const [counterSession, setCounterSession] = useState(0)
	const [slugDelete, setSlugDelete] = useState<string>('')
	const [posDelete, setPosDelete] = useState<number | null>(null)
	const [session, setSession] = useState<sessionType>()
	const [newSession, setNewSession] = useState<sessionType>()
	const [nameSession, setNameSession] = useState<string | undefined>()
	const [activePublish, setActivePublish] = useState(true)
	// const device = useDevice()

	const handleAddNewSession = async (nSession: string): Promise<void> => {
		if (params.programSlug && newSession) {
			const res = await createProgramSession(
				token,
				params?.programSlug,
				nSession
			)
			if (res) {
				setSessions([res, ...sessions])
				showToast(
					'Session Created',
					`${nSession} was created successfully`,
					'success'
				)
			} else {
				showToast(
					'Error Session',
					'Error: Name session may not be blank.',
					'error'
				)
			}
		}
	}

	const addNewSession = (): void => {
		const newSession1 = {
			created_at: '',
			exercises: [],
			name: `Untitled Session`,
			order: null,
			program: '',
			slug: '',
			updated_at: ''
		}
		handleAddNewSession(newSession1.name)
		// setSessions([newSession1, ...sessions])
		setNewSession(newSession1)
	}

	useEffect(() => {
		refetch()
	}, [nameSession, openDrawer])

	useEffect(() => {
		const listExer = sessions.map(item => item.exercises.length > 0 && true)
		if (listExer.length > 0)
			setActivePublish(listExer.reduce((prev, curr) => prev && curr))
	}, [sessions])

	useEffect(() => {
		if (data) {
			if (location.search) {
				if (data.program_sessions.length === 0) {
					addNewSession()
				}
			}
			if (data.image) setCover(data.image)
			if (data.program_sessions.length > 0) {
				setSessions(data.program_sessions)
				setCounterSession(data.program_sessions.length)
			}
			setValue('name', data.name)
		}
	}, [data])

	const handleMediaUpload = (file: File): void => {
		const reader = new FileReader()
		reader.onload = e => {
			const uploadedObj = { file, url: e.target?.result as string }

			setCover(uploadedObj.url)
		}
		reader.readAsDataURL(file) // convert to base64 string
	}

	const { stateProgram, setStateProgram } = useContext(ProgramContext)

	const handleSave = async (active: boolean): Promise<void> => {
		setStateProgram(!stateProgram)
		let json = {
			name: watchName,
			state: active ? 'active' : 'inactive'
		} as PostProgram

		if (cover) json = { ...json, image: cover }

		if (params.programSlug) {
			const program = await editProgram(
				token,
				customer.id,
				params.programSlug,
				json
			)
			if (program) {
				showToast('Program edit', 'program was edited successfully', 'success')
				navigate({
					pathname: `/programs/`
				})
			}
			if (json.state === 'inactive' && !program?.program_sessions.length) {
				showToast('Warning', 'Program must have sessions', 'warning')
			}
			if (json.state === 'active' && !program?.program_sessions.length) {
				showToast('Warning', 'Program must have sessions', 'warning')
			}
		} else {
			const program = await createProgram(token, customer.id, json)
			if (program) {
				showToast(
					'Program Create',
					'program was created successfully',
					'success'
				)
				navigate(
					{
						pathname: `/program/${program.slug}`,
						search: '?session=true'
					},
					{ replace: true }
				)
			}
		}
	}

	const handleService = (): void => {
		setCounterSession(counterSession + 1)
		if (params.programSlug) {
			addNewSession()
		} else {
			handleSave(false)
		}
	}

	const cloneSession = async (slug: string): Promise<void> => {
		const res = await cloneProgramSession(token, slug)
		if (res) {
			showToast('Copy session', 'session was cloned successfully', 'success')
			refetch()
		}
	}

	const deleteSession = async (): Promise<void> => {
		if (slugDelete !== '') {
			try {
				const res = await deleteProgramSession(token, slugDelete)
				if (res && res === 204) {
					showToast(
						'Delete session',
						'session was deleted successfully',
						'warning'
					)
				}
			} catch (error) {
				console.error(error)
			}
			refetch()
		} else if (posDelete !== null) {
			const result = sessions.filter((_item, index) => index !== posDelete)
			setSessions(result)
			setPosDelete(null)
			refetch()
		}
		setShowModal(false)
		if (sessions.length === 1) setSessions([])
		refetch()
	}

	const configSession = (sessionVal: sessionType): void => {
		if (sessionVal.slug !== '') {
			setSession(sessionVal)
		} else {
			setSession(newSession)
		}
		setOpenDrawer(true)
	}

	return (
		<Layout back stretch isLoading={isLoading}>
			<div className="relative overflow-hidden">
				<div
					className={clsx('flex-1 flex-col', 'lg:flex-row lg:justify-between')}
				>
					<div
						className={clsx(
							'bg-gray-700 text-white relative h-[176px]',
							'lg:bg-[transparent] lg:flex lg:pr-0 lg:h-auto lg:px-8 lg:py-3'
						)}
					>
						{cover && (
							<>
								<div
									className={clsx(
										styles.gradient,
										'absolute inset-0 min-h-[114px] lg:hidden'
									)}
								/>
								<img
									src={cover}
									alt="cover"
									className="object-fill w-full h-[176px] lg:hidden"
								/>
							</>
						)}

						<input
							type="file"
							id="upload-media"
							name="upload-image"
							accept="image/*"
							className="hidden"
							onChange={event =>
								event.target.files
									? handleMediaUpload(event.target.files[0])
									: null
							}
						/>
						<div className="absolute inset-x-8 inset-y-3 lg:static lg:flex">
							<div
								className={clsx(
									'flex justify-between mb-3',
									'lg:justify-start'
								)}
							>
								<label
									htmlFor="upload-media"
									className={clsx(
										'flex items-center cursor-pointer hover:underline overflow-hidden',
										'lg:bg-gray-700 lg:p-2 lg:rounded-[9px] lg:w-[348px] lg:h-[138px] lg:justify-center lg:relative'
									)}
								>
									{cover && (
										<>
											<div
												className={clsx(
													styles.gradient,
													'absolute inset-0 min-h-[114px] hidden lg:block'
												)}
											/>
											<img
												src={cover}
												alt="cover"
												className=" hidden object-fill w-full h-[138px] absolute inset-0 lg:block"
											/>
										</>
									)}
									<div
										className={clsx(
											{
												'border-2 border-white rounded-full max-h-[64px] max-w-[64px] mr-4 p-4':
													!cover
											},
											{
												'max-h-[12px] max-w-[12px] mr-2': cover
											},
											`lg:absolute ${
												cover ? 'lg:left-[112px]' : 'lg:left-[90px]'
											}`
										)}
									>
										<CameraIcon
											className={clsx(
												`${cover ? 'h-[12px] w-[12px]' : 'h-[30px] w-[30px]'}`,
												' text-white'
											)}
										/>
									</div>
									<span
										className={clsx(
											'font-Roboto',
											`lg:absolute ${cover ? '' : 'lg:right-[100px]'}`
										)}
									>{`${cover ? ' Change Image' : 'Add a photo'}`}</span>
								</label>
								<label htmlFor="field-name" className="cursor-pointer">
									<div
										className={clsx(
											'max-h-[22px] max-w-[16px]',
											'flex items-center justify-center',
											' lg:mt-3 lg:mx-2 xl:mt-7 '
										)}
									>
										<Icon
											fillPath
											className={clsx(
												'h-[22px] w-[22px] text-[#7c7f86] hover:text-custom'
											)}
											src={Icons.pencil}
										/>
									</div>
								</label>
							</div>
							<div className="2xl:min-w-[40vw]">
								<Input
									id="field-name"
									name="name"
									register={register}
									placeholder="Name of program"
									type="text"
									className="lg:text-black"
									transparent
									strech
									fontSize="extra-large"
									maxLength={50}
								/>
								<h2
									className={clsx(
										'text-white font-Roboto',
										'lg:text-black lg:text-2xl'
									)}
								>
									{`Total sessions ${sessions.length}`}
								</h2>
							</div>
						</div>
					</div>
					<div className="p-4">
						<Button
							customColor
							size="full"
							type="button"
							className="lg:min-w-[20vw]"
							disabled={watchName === '' || watchName === undefined}
							onClick={() => handleService()}
						>
							<div className="flex items-center justify-center">
								<p className="font-Roboto font-semibold text-base mr-2">
									Create a Sesion
								</p>
								<div className="pt-1">
									<Icon
										fillPath
										className={clsx('h-[28px] w-[30px] text-custom')}
										src={Icons.plus}
									/>
								</div>
							</div>
						</Button>
					</div>
				</div>
				<div className="px-4 pb-2 pt-0">
					<div className="h-[1px] w-full bg-gray-300" />
				</div>
				<div
					className={clsx(
						'w-full flex flex-col px-4 min-h-[calc(100vh-525px)] max-h-[calc(100vh-525px)]',
						'lg:min-h-[calc(100vh-400px)] lg:max-h-[calc(100vh-400px)]',
						'relative',
						'overflow-hidden hide-scroll-bar overflow-y-scroll'
					)}
				>
					{sessions.length < 1 && data && !data?.program_sessions.length ? (
						// <div
						// 	className={clsx(
						// 		'flex flex-col',
						// 		{
						// 			'min-h-[calc(100vh-480px)] max-h-[calc(100vh-480px)]':
						// 				device === 'web'
						// 		},
						// 		{
						// 			'min-h-[calc(100vh-470px)] max-h-[calc(100vh-470px)]':
						// 				device === 'android' || device === 'ios'
						// 		},
						// 		'overflow-hidden hide-scroll-bar overflow-y-scroll',
						// 		'md:min-h-[calc(100vh-470px)] md:max-h-[calc(100vh-470px)]',
						// 		'lg:min-h-[calc(100vh-320px)] lg:max-h-[calc(100vh-320px)]'
						// 	)}
						// >
						// </div>
						<h3 className="font-Roboto text-sm text-center text-gray-400">
							Create a session will create your program.
						</h3>
					) : (
						// <div
						// 	className={
						// 		clsx()
						// 		// {
						// 		// 	'min-h-[calc(100vh-480px)] max-h-[calc(100vh-480px)]':
						// 		// 		device === 'web'
						// 		// },
						// 		// {
						// 		// 	'min-h-[calc(100vh-464px)] max-h-[calc(100vh-464px)]':
						// 		// 		device === 'android' || device === 'ios'
						// 		// },
						// 		// 'overflow-hidden hide-scroll-bar overflow-y-scroll',
						// 		// 'md:min-h-[calc(100vh-470px)] md:max-h-[calc(100vh-470px)]',
						// 		// 'lg:min-h-[calc(100vh-320px)] lg:max-h-[calc(100vh-320px)]'
						// 	}
						// >
						// </div>
						sessions.length > 0 &&
						sessions.map((item, index) => {
							const key = `${item.slug}-${index}`
							return (
								<BoxSession
									key={key}
									session={item}
									className="my-2"
									clone={cloneSession}
									deleted={() => {
										setShowModal(true)
										if (item.slug !== '') setSlugDelete(item.slug)
										else setPosDelete(index)
									}}
									config={configSession}
								/>
							)
						})
					)}
				</div>
				<div
					className={clsx(
						'fixed bottom-0 min-w-full max-w-full lg:relative z-10 bg-[#F2F5F7] lg:bg-[#eeeff2]',
						'flex items-center justify-between gap-4 mb-[70px] lg:mb-0 px-4 pb-2'
					)}
				>
					<Button
						label={params.programSlug ? 'Unpublish' : 'Save'}
						fill={false}
						customColor
						disabled={watchName === '' || watchName === undefined}
						onClick={() => handleSave(false)}
						className="w-full"
					/>
					<Button
						label="Publish"
						customColor
						disabled={
							watchName === '' ||
							watchName === undefined ||
							!sessions.length ||
							!activePublish
						}
						onClick={() => handleSave(true)}
						className="w-full"
					/>
					{/* <div className="w-1/2 mr-4">
					</div> */}
					{/* <div className="w-1/2 ml-4">
					</div> */}
				</div>
				<Modal
					type="danger"
					open={showModal}
					setOpen={(val: boolean) => setShowModal(val)}
					handleClick={() => deleteSession()}
				>
					<>
						<h3 className="text-lg leading-6 font-medium text-gray-900">
							Delete
						</h3>
						<div className="mt-2">
							<p className="text-sm text-gray-500">
								Do you Want to delete these session?
							</p>
						</div>
					</>
				</Modal>
				{data && (
					<Drawer
						open={openDrawer}
						setOpen={(val: boolean) => setOpenDrawer(val)}
						title="Configure session"
						stretch
						mobileFull
					>
						<DrawerSession
							program={data.slug}
							sessionInitial={session}
							setName={setNameSession}
							refetchProgram={refetch}
							setOpen={(val: boolean) => setOpenDrawer(val)}
						/>
					</Drawer>
				)}
			</div>
		</Layout>
	)
}
