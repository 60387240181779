import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { CameraIcon, XIcon } from '@heroicons/react/outline'
import { Bar, Radar } from 'react-chartjs-2'
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
	Tooltip
	// Legend
} from 'chart.js'
import { colorGenerator, ScreenshotModal } from 'lib'

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
	Tooltip
	// Legend
)

interface ModalSessionProps {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	programName: string
	labelSessions: string[]
	percSessionsCompleted: number[]
}

export const ModalSession: React.FC<ModalSessionProps> = ({
	open,
	setOpen,
	programName,
	labelSessions,
	percSessionsCompleted
}): JSX.Element => {
	const [refDiv, setRefDiv] = useState<HTMLElement | null>(null)
	const [palleteColor, setPalleteColor] = useState<string[]>([])

	useEffect(() => {
		setPalleteColor(colorGenerator(labelSessions?.length || 0))
	}, [labelSessions])

	useEffect(() => {
		setRefDiv(document.getElementById('screenshot-session'))
	}, [])

	const handleScreenShot = useCallback(
		name => ScreenshotModal(name, refDiv),
		[refDiv]
	)

	return (
		<Transition appear show={open} as={Fragment}>
			<Dialog
				as="div"
				className="z-50 fixed inset-0"
				open={open}
				onClose={() => setOpen(false)}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-60" />
				</Transition.Child>

				<div
					aria-hidden="true"
					className="fixed inset-0 overflow-y-auto"
					onClick={() => setOpen(false)}
				/>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95"
				>
					<div
						className={clsx(
							'absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%]',
							'w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left',
							'align-middle transition-all',
							'md:min-w-min',
							labelSessions.length === 0 && 'w-[310px]'
						)}
					>
						<header className="w-full h-5 flex justify-between">
							<span className="font-Roboto font-semibold text-[#393C44]">
								Session
							</span>
							<button type="button" onClick={() => setOpen(false)}>
								<XIcon className="w-5 h-5 text-custom" />
							</button>
						</header>
						<div
							id="screenshot-session"
							className="w-full flex flex-col md:flex-row justify-center items-center p-5"
						>
							{labelSessions?.length === 0 ? (
								<div className="w-full flex flex-col">
									<div className="font-Roboto py-5">
										Select a Program to view Session Details
									</div>
									<div className="w-full flex justify-center">
										<button
											type="button"
											className={clsx(
												'w-40 h-7 rounded-full flex justify-center items-center gap-3',
												'border border-custom p-2 bg-custom text-white',
												'hover:opacity-80'
											)}
											onClick={() => setOpen(false)}
										>
											<span className="font-Roboto font-medium">Close</span>
										</button>
									</div>
								</div>
							) : (
								<>
									<div className="w-[222px] h-[222px] flex justify-center items-center py-10">
										{percSessionsCompleted?.length > 4 ? (
											<Radar
												data={{
													// labels: labelSessions,
													labels: labelSessions?.map(() => ''),
													datasets: [
														{
															label: 'Sessions',
															data: percSessionsCompleted,
															backgroundColor: palleteColor,
															borderWidth: 1
														}
													]
												}}
												options={{
													responsive: true,
													maintainAspectRatio: true,
													plugins: {
														title: {
															display: true,
															text: 'Sessions'
														}
													},
													scales: {
														r: {
															suggestedMin: 0,
															suggestedMax: 100
														}
													}
												}}
											/>
										) : (
											<Bar
												// className="w-full h-full"
												data={{
													labels: labelSessions,
													datasets: [
														{
															data: percSessionsCompleted,
															backgroundColor: palleteColor,
															borderWidth: 1
														}
													]
												}}
												options={{
													responsive: true,
													maintainAspectRatio: true,
													scales: {
														y: {
															suggestedMin: 0,
															suggestedMax: 100
														}
													}
												}}
											/>
										)}
									</div>
									<div className="w-[1px] h-[290px] bg-gray-300 mx-3 hidden md:block" />
									<div className="w-full h-[1px] bg-gray-300 my-2 block md:hidden" />
									<div className="w-full md:w-1/2 flex flex-col justify-between gap-2">
										<header className="font-Roboto font-semibold text-[#393C44]">
											Detail:
										</header>
										<div
											className={clsx(
												'w-full flex flex-col flex-nowrap',
												'rounded-md border p-3',
												'overflow-hidden hide-scroll-bar overflow-y-scroll',
												'h-fit md:h-[230px]'
											)}
										>
											{labelSessions?.map((item, idx) => {
												const kk = `key-${idx}`
												return (
													<div
														key={kk}
														className="w-full flex justify-between items-center mb-1"
													>
														<div className="flex gap-4">
															<span
																className={clsx(
																	'w-6 h-6 rounded-full flex justify-center items-center',
																	'bg-custom text-white'
																)}
															>
																{idx + 1}
															</span>
															<span className="font-Roboto font-semibold text-[#393C44]">
																{item}
															</span>
														</div>
														<span className="font-Roboto font-normal text-[#393C44] pr-2">
															{percSessionsCompleted[idx]} %
														</span>
													</div>
												)
											})}
										</div>
										<div className="w-full flex justify-endgap-3">
											<button
												type="button"
												className={clsx(
													'w-full h-7 rounded-full flex justify-center items-center gap-3',
													'border border-custom p-2 bg-custom text-white',
													'hover:opacity-80'
												)}
												onClick={() => handleScreenShot(programName)}
											>
												<CameraIcon className="w-5 h-5" />
												<span className="font-Roboto font-medium">
													Screenshot
												</span>
											</button>
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				</Transition.Child>
			</Dialog>
		</Transition>
	)
}
