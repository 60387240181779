/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { InputList } from 'components/input-list'
import { PickerTime } from 'components/input-time'
import { Input } from 'components/input'
import React, { useEffect, useState } from 'react'
import { InTrainingProps } from './inTrainingMetrics.interface'

export const InTrainingMetrics: React.FC<InTrainingProps> = props => {
	const {
		isInjury,
		listMetric,
		defaultList,
		defaultVal,
		slug,
		changeMetric,
		metricName,
		baseValue,
		statusExercise
	} = props

	const list = listMetric.map((item: any) => {
		return {
			id: item.slug,
			name: item.name,
			value: item.slug
		}
	})

	const [item, _setItem] = useState(
		defaultList
			? list.filter((itemList: any) => itemList.id === defaultList)[0]
			: list[0]
	)

	const [metric, _setMetric] = useState(
		listMetric.filter(itemArray => {
			return itemArray.slug === item.id
		})[0]
	)

	const [timeVal, setTimeVal] = useState(defaultVal ?? '')

	useEffect(() => {
		if (metric && metric.type === 'time') {
			if (timeVal !== '') {
				changeMetric(slug, metric.slug, timeVal)
			}
		}
	}, [timeVal])

	let localDefautlValue = defaultVal
	if (localDefautlValue) {
		if (localDefautlValue === '00:00:00') {
			localDefautlValue = '---'
		}
	}

	const [selectVal, setSelectVal] = useState<{
		id: string
		name: string
		value: string
	}>(() => {
		if (statusExercise !== 1) {
			return {
				id: '---',
				name: '---',
				value: '---'
			}
		}
		if (localDefautlValue) {
			return {
				id: localDefautlValue,
				name: localDefautlValue,
				value: localDefautlValue
			}
		}
		return {
			id: '---',
			name: '---',
			value: '---'
		}
	})

	useEffect(() => {
		if (metric && selectVal) {
			if (metric.type === 'select' && defaultVal !== selectVal.value) {
				if (selectVal.id !== '') {
					changeMetric(slug, metric.slug, selectVal.value)
				}
			}
		}
	}, [selectVal])

	const listOptions =
		metric.options?.map((optionItem: any, index: number) => {
			return {
				id: `${index}`,
				name: optionItem,
				value: optionItem
			}
		}) ?? []

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		if (metric.type === 'number') {
			const val = e.target.value
			changeMetric(slug, metric.slug, val)
		}
	}

	return (
		<div className="w-full flex items-center justify-between gap-2">
			<div className="w-full flex justify-center items-center border border-gray-300 h-[47px]">
				<span className="text-base font-normal text-gray-400">
					{metricName}
				</span>
			</div>

			<div className="w-full flex justify-center items-center border border-gray-300 h-[47px]">
				<span className="text-base font-normal text-gray-400">
					{baseValue.toUpperCase()}
				</span>
			</div>

			<div className="w-full">
				{metric.type === 'time' ? (
					<PickerTime
						defaultValue={statusExercise !== 1 ? '---' : timeVal}
						asterisk
						setItem={setTimeVal}
						icon
						disablePicker={statusExercise !== 1}
						isEdit
					/>
				) : metric.type === 'select' ? (
					<InputList
						disableList={statusExercise !== 1}
						list={listOptions}
						name={`metric-${item.id}-list-${slug}`}
						asterisk
						isMetric
						squared
						setItem={setSelectVal}
						inTraining
						defaultValue={selectVal}
						isInjury={isInjury}
					/>
				) : (
					<Input
						cursorDisable={statusExercise !== 1}
						disabled={statusExercise !== 1}
						name={`metric-${item.id}-number-${slug}`}
						asterisk
						placeholder="---"
						squared
						type="number"
						step={0.1}
						onChange={handleChange}
						defaultValue={statusExercise !== 1 ? '---' : defaultVal}
						min={1}
					/>
				)}
			</div>
		</div>
	)
}
