/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
import {
	deleteGroup,
	getClientById,
	getClients,
	getInGroup,
	getInGroups,
	GET_ATHLETES,
	GET_GROUP,
	GET_GROUPS2,
	patchInGroup,
	patchInGroups,
	postInGroup
} from 'api'
import { useCustomer, useToast } from 'hooks'
import { athleteType, BodyProps, InGroupType } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { useInfiniteQuery, useQuery } from 'react-query'
import Cookies from 'universal-cookie'
import Select, { MultiValue } from 'react-select'
import { getPageNumber } from 'lib'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
	Button,
	HeaderCalendar,
	Loading,
	Modal,
	MyTimePicker
} from 'components'
import clsx from 'clsx'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { CalendarIcon } from '@heroicons/react/outline'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'
import { customStyles } from './constant'

export interface DrawerCalendarProps {
	type: 'general' | 'new-group' | 'edit' | 'historial'
	groupSlug: string
	setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
	dateSelected?: string
	handleRefetch?: () => void
}

export interface field {
	search: string
	name: string
}

export const DrawerCalendar: React.FC<DrawerCalendarProps> = ({
	type = 'general',
	groupSlug,
	setOpenDrawer,
	dateSelected,
	handleRefetch
}): JSX.Element => {
	const customer = useCustomer()
	const cookies = new Cookies()
	const token = cookies.get('token')
	const { showToast } = useToast()
	// const groupsDivRef = useRef<HTMLDivElement>(null)

	/// /// handle Form
	const {
		register,
		watch,
		setValue,
		handleSubmit,
		formState: { errors }
	} = useForm<field>({
		mode: 'all'
	})
	const watchSearch = watch('search', '')
	const [isLoading, setIsLoading] = useState(false)

	const [slugGroup, setSlugGroup] = useState<string>('')
	const [groups, setGroups] = useState<InGroupType[]>(
		type === 'new-group'
			? [
					{
						id: Date.now(),
						slug: `${Date.now()}`,
						name: 'New group',
						status: 'active',
						date: dateSelected
							? moment(dateSelected, 'yyyy-MM-DD').toDate()
							: new Date(),
						start_at: moment(Date.now()).toDate(),
						finish_at: moment(Date.now()).toDate(),
						participants: 0,
						clients: []
					}
			  ]
			: []
	)
	const [posGroup, setPosGroup] = useState(0)

	const [athletes, setAthletes] = useState<athleteType[] | undefined>([])
	const [options, setOptions] =
		useState<MultiValue<{ label: string; value: string }>>()
	const [selectedAthletes, setSelectedAthletes] =
		useState<MultiValue<{ label: string; value: string }>>()
	const [listAthletes, setListAthletes] = useState<{ client: athleteType }[]>(
		[]
	)

	const [search, setSearch] = useState<string | undefined>(undefined)
	const [showModal, setShowModal] = useState(false)
	const [openCalendar, setOpenCalendar] = useState(false)

	const [dateGroup, setDateGroup] = useState<Date | null>(
		type === 'new-group' ? moment(dateSelected, 'YYYY-MM-DD').toDate() : null
	)
	const [timeFrom, setTimeFrom] = useState('12:00')
	const [timeTo, setTimeTo] = useState('12:00')

	const [startTime, setStartTime] = useState('')
	const [finishTime, setFinishTime] = useState('')
	const [timeError, setTimeError] = useState(false)

	// request groups ////////////////////////////////////////////////////
	const {
		data: dataGroup,
		isLoading: isLoadingGroup,
		isRefetching: isRefetchingGroup
		// refetch: refetchGroup
	} = useQuery(
		[GET_GROUPS2, customer.id, token],
		() =>
			getInGroups({
				customerId: customer.id,
				date: dateSelected
			}),
		{
			refetchOnMount: 'always'
		}
	)

	const [initialGroupCount, setInitialGroupCount] = useState(0)

	useEffect(() => {
		if (type !== 'new-group') {
			const auxGroup = dataGroup?.results as unknown as InGroupType[]
			if (dataGroup?.results) {
				if (type === 'general') setGroups(auxGroup)
				if (type === 'edit')
					setGroups(auxGroup.filter(grp => grp.slug === groupSlug))
				if (type === 'historial')
					setGroups(auxGroup.filter(grp => grp.slug === groupSlug))
			}
		}
		setInitialGroupCount(dataGroup?.results.length as number)
	}, [dataGroup || groups])

	const { data: groupList, isLoading: isLoadingList } = useQuery(
		[GET_GROUP, customer.id, slugGroup],
		() =>
			getInGroup({
				customerId: customer.id,
				slug: slugGroup
			})
	)
	const [clientList, setClientList] = useState<{ client: athleteType }[]>([])

	useEffect(() => {
		const aux = groupList?.clients
		if (aux) setClientList(aux?.map(it => ({ client: it.client })))
	}, [groupList])

	const [clientsIds, setClientsIds] = useState<number[]>([])

	useEffect(() => {
		setClientsIds(clientList.map(item => item.client.id))
	}, [clientList])

	const groupsOrdered = (): InGroupType[] => {
		if (!groups) return []
		const selectedGroup = groups.filter(group => group?.slug === slugGroup)[0]
		const unselectedGroups = groups.filter(group => group?.slug !== slugGroup)

		return [selectedGroup, ...unselectedGroups]
	}

	useEffect(() => {
		setSlugGroup(groups[posGroup]?.slug)
		setDateGroup(groups[posGroup]?.date)
		setTimeFrom(moment(groups[posGroup]?.start_at).format('kk:mm'))
		setTimeTo(moment(groups[posGroup]?.finish_at).format('kk:mm'))
		setValue('name', groups[posGroup]?.name)
	}, [groups])

	const handleNewGroup = (): void => {
		setPosGroup(groups.length)
		if (dateSelected) {
			const year = parseInt(dateSelected.split('-')[0], 10)
			const month = parseInt(dateSelected.split('-')[1], 10) - 1
			const day = parseInt(dateSelected.split('-')[2], 10)

			const auxGroup = {
				id: Date.now(),
				slug: `${Date.now()}`,
				name: 'New group',
				status: 'active',
				date: moment(dateSelected, 'YYYY-MM-DD').toDate(),
				start_at: new Date(year, month, day),
				finish_at: new Date(year, month, day),
				participants: 0,
				clients: []
			}
			setSlugGroup(auxGroup?.slug)
			setDateGroup(auxGroup?.date)
			setTimeFrom(moment(auxGroup?.start_at).format('kk:mm'))
			setTimeTo(moment(auxGroup?.finish_at).format('kk:mm'))
			setValue('name', auxGroup?.name)
			setGroups([...groups, auxGroup])
		}
	}

	/// /////request athltes list ///////////////////////////////////////////
	const {
		data: dataAthletes,
		isLoading: isLoadingAth,
		isFetching
	} = useInfiniteQuery(
		[GET_ATHLETES, search, customer.id, token],
		({ pageParam = 1 }) => getClients(token, customer.id, pageParam, search),
		{
			getNextPageParam: lastPage =>
				lastPage?.next ? getPageNumber(lastPage?.next) : undefined
		}
	)
	useEffect(() => {
		if (dataAthletes && dataAthletes.pages)
			setAthletes(dataAthletes?.pages[0]?.results)
	}, [dataAthletes])
	useEffect(() => {
		if (dataAthletes?.pages) {
			let auxData = [] as athleteType[]
			dataAthletes.pages.forEach(page => {
				const auxResults = page?.results
				if (auxResults) auxData = [...auxData, ...auxResults]
			})
			setAthletes(auxData)
		}
	}, [dataAthletes])
	useEffect(() => {
		if (athletes) {
			setOptions(
				athletes.map(item => ({
					label: `${item.first_name} ${item.last_name}`,
					value: `${item.id}`
				}))
			)
		}
	}, [athletes])

	useEffect(() => {
		setSearch(watchSearch)
		setAthletes([])
	}, [watchSearch])

	const getAthlete = async (
		athleteID: string
	): Promise<athleteType | undefined> => {
		let athlete = undefined as athleteType | undefined
		try {
			const res = await getClientById(token, customer.id, athleteID)
			athlete = res?.client
		} catch (error) {
			console.error(error)
		}
		return athlete
	}

	const handleSelectChange = async (
		newVal: MultiValue<{ label: string; value: string }>
	): Promise<void> => {
		try {
			const aux = (await Promise.all(
				newVal.map(val =>
					getAthlete(val.value)
						.then(res => ({ client: res }))
						.catch(err => console.error(err))
				)
			)) as unknown as { client: athleteType }[]

			setListAthletes(aux)
			setSelectedAthletes(
				aux.map(item => ({
					label: `${item?.client?.first_name}`,
					value: item?.client?.id.toString()
				})) as unknown as MultiValue<{ label: string; value: string }>
			)
			setClientsIds(aux.map(c => c.client.id))
		} catch (error) {
			console.error(error)
		}
	}

	const handleChangeAthlete = (newValue: string): void => {
		setSearch(newValue)
	}

	const handleDeleteGroup = async (): Promise<void> => {
		setIsLoading(true)
		const sluggroup = groups[posGroup].slug
		try {
			const res = await deleteGroup({
				actionType: 'delete',
				customerId: customer.id,
				slugGroup: sluggroup
			})
			if (res && res === 200) {
				showToast(
					'Delete',
					`${groups[posGroup]?.name} was deleted sucessfully`,
					'success'
				)
			} else {
				showToast(
					'Delete',
					`Error: group ${groups[posGroup]?.name} wasn't deleted`,
					'error'
				)
			}
			if (groups.length <= 1) {
				setOpenDrawer(false)
			} else {
				setOpenDrawer(true)
			}
		} catch (error) {
			console.error(error)
		} finally {
			setIsLoading(false)
			if (handleRefetch) handleRefetch()
			setShowModal(false)
		}
	}

	useEffect(() => {
		const newState = groups.map((group, idx) => {
			if (idx === posGroup) {
				return {
					...group,
					name: watch('name')
				}
			}
			return {
				...group
			}
		}) as unknown as InGroupType[]
		setGroups(newState)
	}, [watch('name')])

	useEffect(() => {
		const newState = groups.map((group, idx) => {
			if (idx === posGroup) {
				return {
					...group,
					date: moment(dateGroup).format('yyyy-MM-DD'),
					start_at: moment(dateGroup)
						.set('hours', parseInt(timeFrom.split(':')[0], 10))
						.set('minutes', parseInt(timeFrom.split(':')[1], 10))
						.utc()
						.format(),
					finish_at: moment(dateGroup)
						.set('hours', parseInt(timeTo.split(':')[0], 10))
						.set('minutes', parseInt(timeTo.split(':')[1], 10))
						.utc()
						.format()
				}
			}
			return {
				...group
			}
		}) as unknown as InGroupType[]
		setGroups(newState)
	}, [dateGroup])

	useEffect(() => {
		setStartTime(
			moment(dateGroup)
				.set('hours', parseInt(timeFrom.split(':')[0], 10))
				.set('minutes', parseInt(timeFrom.split(':')[1], 10))
				.utc()
				.format()
		)
		const newState = groups.map((group, idx) => {
			if (idx === posGroup) {
				return {
					...group,
					start_at: moment(dateGroup)
						.set('hours', parseInt(timeFrom.split(':')[0], 10))
						.set('minutes', parseInt(timeFrom.split(':')[1], 10))
						.utc()
						.format()
				}
			}
			return {
				...group
			}
		}) as unknown as InGroupType[]
		setGroups(newState)
	}, [timeFrom])

	useEffect(() => {
		setFinishTime(
			moment(dateGroup)
				.set('hours', parseInt(timeTo.split(':')[0], 10))
				.set('minutes', parseInt(timeTo.split(':')[1], 10))
				.utc()
				.format()
		)
		const newState = groups.map((group, idx) => {
			if (idx === posGroup) {
				return {
					...group,
					finish_at: moment(dateGroup)
						.set('hours', parseInt(timeTo.split(':')[0], 10))
						.set('minutes', parseInt(timeTo.split(':')[1], 10))
						.utc()
						.format()
				}
			}
			return {
				...group
			}
		}) as unknown as InGroupType[]
		setGroups(newState)
	}, [timeTo])

	useEffect(() => {
		setTimeError(startTime > finishTime)
	}, [startTime, finishTime])

	useEffect(() => {
		const auxDa = groups.map((group, idx) => {
			if (idx === posGroup) {
				return {
					...group,
					clients: listAthletes.map(ath => ({
						client: ath.client
					}))
				}
			}
			return {
				...group
			}
		}) as unknown as InGroupType[]
		setGroups(auxDa)
	}, [listAthletes])

	const actionType =
		groups.length > initialGroupCount
			? 'create'
			: groups.length === initialGroupCount
			? 'update'
			: 'delete'

	const onSubmit: SubmitHandler<field> = async () => {
		try {
			if (groups.length > 0) {
				if (type === 'new-group') {
					const res = await postInGroup({
						body: groups
							.filter((_, idx) => idx === posGroup)
							.map(item => ({
								...item,
								date: moment(item.date).format('yyyy-MM-DD'),
								clients: item?.clients.map(ath => ath.client?.id)
							})) as unknown as BodyProps[],
						actionType: 'create',
						customerId: customer.id
					})
					if (res)
						showToast(
							'Create new group',
							'group was created successfully',
							'success'
						)
					if (handleRefetch) handleRefetch()
				}
				if (type === 'general') {
					const res = await patchInGroups({
						action: actionType,
						customerId: customer.id,
						body: groups.map(group => ({
							...group,
							date: moment(group.date).format('yyyy-MM-DD'),
							clients: group?.clients.map(ath => ath.client.id)
						})) as unknown as BodyProps[]
					})
					if (res)
						showToast(
							'Update list',
							'list groups was updated successfully',
							'success'
						)
					if (handleRefetch) handleRefetch()
				}
				if (type === 'edit') {
					const res = await patchInGroup({
						actionType: 'update',
						body: groups
							.filter(group => group.slug === groupSlug)
							.map(item => {
								return {
									...item,
									clients: clientsIds
								}
							})[0] as unknown as BodyProps,
						customerId: customer.id,
						slug: groupSlug
					})
					if (res)
						showToast(
							'Group Update',
							`group ${
								groups.filter(grp => grp.slug === groupSlug)[0].name
							} was updated successfully`,
							'success'
						)
					if (handleRefetch) handleRefetch()
				}
				if (type === 'historial') {
					const res = await patchInGroups({
						body: groups
							.filter(group => group.slug === groupSlug)
							.map(group => ({
								...group,
								slug: '',
								clients: group?.clients?.map(ath => ath.client.id)
							})) as unknown as BodyProps[],
						action: 'update',
						customerId: customer.id
					})
					if (res)
						showToast(
							'Create new group',
							'new group was created successfully',
							'success'
						)
					if (handleRefetch) handleRefetch()
				}
			}
			setOpenDrawer(false)
		} catch (error) {
			console.error(error)
			showToast('Error', 'request failed', 'error')
		}
		if (handleRefetch) handleRefetch()
	}

	const handleChangeFrom = (value: string | null): void => {
		setTimeFrom(value || '00:00')
	}
	const handleChangeTo = (value: string | null): void => {
		setTimeTo(value || '00:00')
	}

	useEffect(() => {
		setValue(
			'name',
			groupsOrdered().filter((grp, idx) => idx === 0 && grp)[0]?.name
		)
	}, [slugGroup, groups])

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className={clsx(
				'relative w-full h-full flex flex-col px-5 py-4 bg-[#F2F5F7]',
				'min-h-[calc(100vh-140px)] max-h-[calc(100vh-140px)] overflow-hidden overflow-y-scroll',
				'md:min-h-[calc(100vh-70px)] md:max-h-[calc(100vh-70px)] overflow-hidden md:hide-scroll-bar'
			)}
		>
			{isLoading || isLoadingGroup || isRefetchingGroup ? (
				<div className="absolute inset-0 w-full h-full">
					<Loading />
				</div>
			) : (
				<>
					<HeaderCalendar
						type={type}
						groupSlug={groupSlug}
						slugGroup={slugGroup}
						setSlugGroup={setSlugGroup}
						setPosGroup={setPosGroup}
						groups={groups}
						handleNewGroup={handleNewGroup}
						watchName={watch('name')}
					/>

					<section className="w-full ">
						{groups?.length > 0 ? (
							groupsOrdered().map((group, idx) => (
								<div key={group?.slug}>
									{idx === 0 && (
										<div className="relative">
											<section id="section-date-time" className="w-full">
												<div className="w-full flex flex-col md:flex-row gap-2">
													<div className="w-full flex flex-col">
														<label
															htmlFor="date"
															className={clsx(
																'text-xs mb-1 font-Roboto font-bold',
																'md:text-lg'
															)}
														>
															Date
														</label>
														<div
															className=""
															aria-hidden
															onClick={() => setOpenCalendar(true)}
														>
															<ReactDatePicker
																// {...register('date')}
																open={openCalendar}
																selected={
																	group?.date
																		? moment(group.date).toDate()
																		: dateGroup
																}
																onChange={(date: Date) => {
																	setDateGroup(date)
																	setOpenCalendar(false)
																	setOpenDrawer(true)
																}}
																onSelect={(date: Date) => {
																	setDateGroup(date)
																	setOpenCalendar(false)
																	setOpenDrawer(true)
																}}
																onCalendarClose={() => setOpenDrawer(true)}
																className="w-full flex gap-1 text-[#6B7280] text-lg"
																withPortal={openCalendar}
																portalId="modal-calendar"
																customInput={
																	<div
																		className={clsx(
																			'w-full h-10 flex justify-center items-center bg-white',
																			'py-2 cursor-pointer border border-gray-300 shadow-sm'
																		)}
																	>
																		<p className="h-full flex items-center text-[#929BB2]">
																			{dateGroup
																				? moment(dateGroup).format('MM/DD/yyyy')
																				: moment(group?.date).format(
																						'MM/DD/yyyy'
																				  )}
																		</p>
																		<CalendarIcon
																			className={clsx(
																				'w-[20px] flex justify-center items-center cursor-pointer',
																				'text-[#929BB2] font-bold'
																			)}
																		/>
																	</div>
																}
																renderCustomHeader={({
																	date,
																	decreaseMonth,
																	increaseMonth,
																	prevMonthButtonDisabled,
																	nextMonthButtonDisabled
																}) => (
																	<div
																		style={{
																			marginLeft: 16,
																			marginRight: 16,
																			display: 'flex',
																			justifyContent: 'space-between'
																		}}
																	>
																		<button
																			type="button"
																			onClick={decreaseMonth}
																			disabled={prevMonthButtonDisabled}
																		>
																			{prevMonthButtonDisabled ? (
																				<div className="w-5 h-5" />
																			) : (
																				<ChevronLeftIcon className="w-5 h-5 text-custom" />
																			)}
																		</button>
																		<span className="react-datepicker__current-month">
																			{moment(date).format('MMMM')}{' '}
																			{moment(date).year()}
																		</span>
																		<button
																			type="button"
																			onClick={increaseMonth}
																			disabled={nextMonthButtonDisabled}
																		>
																			{nextMonthButtonDisabled ? (
																				<div className="w-5 h-5" />
																			) : (
																				<ChevronRightIcon className="w-5 h-5 text-custom" />
																			)}
																		</button>
																	</div>
																)}
															/>
														</div>
														<div id="modal-calendar" />
													</div>
													<div className="flex w-full justify-between gap-2">
														<div className="min-w-[130px] w-full">
															<MyTimePicker
																onChange={handleChangeFrom}
																title="From"
																value={timeFrom}
															/>
														</div>
														<div className="min-w-[130px] w-full">
															<MyTimePicker
																// {...register('finish_at')}
																onChange={handleChangeTo}
																title="To"
																value={timeTo}
															/>
														</div>
													</div>
												</div>
												{timeError && (
													<p className="w-fit h-6 bg-red-500 rounded text-white text-left mt-2 px-3">
														Start time should be earlier than end time.
													</p>
												)}
												{/* <div className="w-1/2" /> */}
											</section>
											<section className="w-full flex flex-col gap-2 py-2">
												<p className="w-full text-base text-[#454952] font-Roboto font-bold">
													Group name
												</p>
												<input
													type="text"
													placeholder="Group name"
													defaultValue={group?.name || 'New group'}
													// value={watchName || group?.name}
													className="w-full h-12 px-4 border border-[#B5BACE] rounded-sm"
													{...register('name', {
														required: {
															value: true,
															message: 'This field is required'
														},
														maxLength: {
															value: 20,
															message: 'Must be max length 20 characters'
														},
														minLength: {
															value: 5,
															message: 'Must be min length 5 characters'
														}
													})}
												/>
												{errors && errors.name && (
													<p className="text-red-500 text-left px-1 font-Roboto rounded-xl">
														{errors.name.message}
													</p>
												)}
											</section>
											<div className="w-full h-[1px] bg-gray-300 px-4 my-3" />
											<section className="w-full">
												<div className="w-full flex justify-between pb-3">
													<span className="text-base text-[#5A5A5A] font-bold font-Roboto">
														Athletes
													</span>
													<span className="text-base text-[#5A5A5A] font-bold font-Roboto">
														{[...listAthletes, ...clientList].length > 0
															? listAthletes.length
															: 0}
														/10
													</span>
												</div>
												{isLoadingList ? (
													<p className="w-full h-6 text-lg text-center text-custom font-Roboto">
														Loading . . .
													</p>
												) : (
													<Select
														isMulti
														placeholder="search athletes"
														className={clsx(
															'appearance-none block w-full py-1 bg-[transparent]',
															'placeholder-gray-400 font-Roboto',
															'border border-gray-300 shadow-sm mb-2'
														)}
														options={options}
														// eslint-disable-next-line react/no-unstable-nested-components
														noOptionsMessage={obj => {
															return (
																<div
																	className={`font-Roboto text-base  ${
																		isFetching || isLoadingAth
																			? 'text-custom'
																			: 'text-red-500'
																	}`}
																>
																	{isFetching || isLoadingAth
																		? 'Loading...'
																		: `${obj.inputValue} not found`}
																</div>
															)
														}}
														styles={customStyles}
														onChange={handleSelectChange}
														onInputChange={handleChangeAthlete}
														defaultValue={groupList?.clients?.map(item => ({
															label: `${item?.client.first_name} ${item?.client.last_name}`,
															value: item?.client.id.toString()
														}))}
														isDisabled={selectedAthletes?.length === 10}
														components={{
															IndicatorSeparator: () => null,
															ClearIndicator: () => null
														}}
													/>
												)}
												<div
													className={clsx(
														'w-full min-h-[144px] max-h-[362px]',
														'md:min-h-[calc(100vh-585px)] md:max-h-[calc(100vh-585px)',
														'overflow-hidden overflow-y-scroll hide-scroll-bar'
													)}
												>
													{isLoadingAth ? (
														<div className="relative top-20 w-full h-fit">
															<Loading isSmall />
														</div>
													) : listAthletes.length > 0 ? (
														listAthletes?.map(athl => (
															<div
																key={athl?.client.id}
																className={clsx(
																	'my-2 shadow border-gray-300 border-l-4 border-l-custom',
																	'sm:rounded-lg border-[1px]  w-full rounded-lg',
																	'bg-white hover:bg-gray-200'
																)}
															>
																<div className="flex justify-between p-2">
																	<img
																		src={athl?.client?.profile_image?.url}
																		alt=""
																		className="w-14 h-14 p-1"
																	/>
																	<div className="w-full pl-2">
																		<h3
																			className={clsx(
																				'text-base text-left leading-6text-gray-900',
																				'font-Roboto font-bold lg:text-2xl'
																			)}
																		>
																			{`${athl?.client?.first_name} ${athl?.client?.last_name}`}
																		</h3>
																		<p className="text-xs text-gray-500 lg:text-lg text-left">
																			{(athl?.client?.assigned_programs
																				?.length || 0) -
																				(athl?.client?.assigned_programs?.filter(
																					item => item.completed
																				).length || 0)}{' '}
																			- active programs
																		</p>
																	</div>
																</div>
															</div>
														))
													) : (
														clientList?.map(athl => (
															<div
																key={athl?.client.id}
																className={clsx(
																	'my-2 shadow border-gray-300 border-l-4 border-l-custom',
																	'sm:rounded-lg border-[1px]  w-full rounded-lg',
																	'bg-white hover:bg-gray-200'
																)}
															>
																<div className="flex justify-between p-2">
																	<img
																		src={athl?.client?.profile_image?.url}
																		alt=""
																		className="w-14 h-14 p-1"
																	/>
																	<div className="w-full pl-2">
																		<h3
																			className={clsx(
																				'text-base text-left leading-6text-gray-900',
																				'font-Roboto font-bold lg:text-2xl'
																			)}
																		>
																			{`${athl?.client?.first_name} ${athl?.client?.last_name}`}
																		</h3>
																		<p className="text-xs text-gray-500 lg:text-lg text-left">
																			{(athl?.client?.assigned_programs
																				?.length || 0) -
																				(athl?.client?.assigned_programs?.filter(
																					item => item.completed
																				).length || 0)}{' '}
																			- active programs
																		</p>
																	</div>
																</div>
															</div>
														))
													)}
												</div>
											</section>
										</div>
									)}
								</div>
							))
						) : (
							<div className="w-full h-full">
								<p
									className={clsx(
										'w-full h-10 flex justify-center items-center border border-custom rounded shadow-lg text-custom'
									)}
								>
									This schedule don&#39;t have groups
								</p>
							</div>
						)}
					</section>
					{groups.length > 0 && (
						<section className="w-full flex justify-between gap-2">
							<Button
								type="button"
								label="Delete group"
								customColor
								fill={false}
								className="px-2"
								onClick={() => setShowModal(true)}
							/>
							<Button
								type="submit"
								label="Save group"
								customColor
								className="px-2"
								// disabled={!isValid}
							/>
						</section>
					)}
					<Modal
						type="danger"
						open={showModal}
						setOpen={(val: boolean) => setShowModal(val)}
						handleClick={() => handleDeleteGroup()}
					>
						<>
							<h3 className="text-lg leading-6 font-medium text-gray-900">
								Delete {groups[posGroup]?.name}
							</h3>
							<div className="mt-2">
								<p className="text-sm text-gray-500">
									Do you want to delete these group?
								</p>
							</div>
						</>
					</Modal>
				</>
			)}
		</form>
	)
}
