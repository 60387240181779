import { createContext, useCallback, useState } from 'react'

interface ContextProgramType {
	stateProgram: boolean
	setStateProgram: (data: boolean) => void
}

export const ProgramContext = createContext<ContextProgramType>({
	stateProgram: false,
	setStateProgram: () => null
})

export const useContextProgram = (): ContextProgramType => {
	const [stateProgram, setStateProgramx] = useState(false)

	const setStateProgram = useCallback(
		(data: boolean) => {
			setStateProgramx(data)
		},
		[setStateProgramx]
	)

	return {
		stateProgram,
		setStateProgram
	}
}
