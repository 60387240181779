import { Transition } from '@headlessui/react'
import { ClockIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { useClickOutside } from 'hooks'
import { PickerTimeProps } from 'interfaces'
import React, { Fragment, useEffect, useRef, useState } from 'react'
// import { minutes, seconds } from './timeConst'
import { hours, millisecs, minutes, seconds } from './timeConst'

export const PickerTime: React.FC<PickerTimeProps> = ({
	defaultValue,
	title,
	asterisk = false,
	setItem,
	disablePicker = false,
	icon = false,
	format = 'mm:ss',
	isEdit
}): JSX.Element => {
	const defaultVal = defaultValue
		? defaultValue?.split(':').map(item => (item === 'NaN' ? '00' : item))
		: '00:00:00'.split(':')

	const [hour, setHour] = useState(0)
	const [minute, setMinute] = useState(parseInt(defaultVal[0], 10) || 0)
	const [second, setSecond] = useState(parseInt(defaultVal[1], 10) || 0)
	const [millis, setMillis] = useState(parseInt(defaultVal[2], 10) || 0)
	const [time, setTime] = useState(defaultValue)
	const [error, setError] = useState(false)

	const timeIsZero = minute === 0 && second === 0 && millis === 0

	const [isOpen, setIsOpen] = useState(false)

	const ref = useRef(null)
	useClickOutside(ref, () => setIsOpen(false))

	useEffect(() => {
		if (minute < 0 || minute > 59) {
			setError(true)
		} else {
			setError(false)
		}
	}, [minute])

	useEffect(() => {
		if (second < 0 || second > 59) {
			setError(true)
		} else {
			setError(false)
		}
	}, [second])

	useEffect(() => {
		if (millis < 0 || millis > 99) {
			setError(true)
		} else {
			setError(false)
		}
	}, [millis])

	const handleOk = (mm: number, ss: number, ms: number): void => {
		const valuesTime = [
			mm < 10 ? `0${mm}` : mm.toString(),
			ss < 10 ? `0${ss}` : ss.toString(),
			ms < 10 ? `0${ms}` : ms.toString()
		]
		setTime(valuesTime.join(':'))

		setIsOpen(false)
	}

	const handleCancel = (): void => {
		setHour(parseInt(defaultVal[0], 10))
		setMinute(parseInt(defaultVal[1], 10))
		setSecond(parseInt(defaultVal[2], 10))

		setIsOpen(false)
	}

	useEffect(() => {
		if (setItem && time) setItem(time)
	}, [time])

	return (
		<div
			className="flex flex-col w-full"
			onKeyUp={e => {
				if (e.key === 'Enter') handleOk(minute, second, millis)
			}}
			aria-hidden
		>
			{title && (
				<label
					htmlFor="email-address"
					className={clsx('text-xs mb-1 font-Roboto font-bold', 'md:text-lg')}
				>
					{asterisk && <span className="text-red-700 mr-1">*</span>}
					{title}
				</label>
			)}
			<div className="h-full">
				<button
					disabled={disablePicker}
					type="button"
					id="modal"
					onClick={() => {
						setIsOpen(true)
						document.getElementById('hours')?.focus()
					}}
					className={clsx(
						'py-2 px-2 cursor-pointer border-gray-300 shadow-sm h-[47px] w-full bg-white',
						icon ? 'border' : 'border-0',
						disablePicker ? 'cursor-not-allowed' : 'cursor-pointer'
					)}
					aria-hidden="true"
				>
					<div className="flex justify-between bg-white h-full">
						<div className="flex justify-evenly items-center h-full w-full text-[#929BB2]">
							{format === 'hh:mm' && (
								<>
									<div>{hours[hour >= 0 && hour <= 23 ? hour : 0]}</div>:
									<div>{minutes[minute >= 0 && minute <= 59 ? minute : 0]}</div>
								</>
							)}
							{format === 'mm:ss' && (
								<>
									<div>
										{timeIsZero && isEdit
											? '---'
											: minutes[minute >= 0 && minute <= 59 ? minute : 0]}
									</div>
									:
									<div>
										{timeIsZero && isEdit
											? '---'
											: seconds[second >= 0 && second <= 59 ? second : 0]}
									</div>
									:
									<div>
										{timeIsZero && isEdit
											? '---'
											: millisecs[millis >= 0 && millis <= 99 ? millis : 0]}
									</div>
								</>
							)}
						</div>
						{icon && (
							<ClockIcon className="w-[20px] flex justify-center items-center cursor-pointer text-[#929BB2]" />
						)}
					</div>
				</button>
			</div>
			<Transition appear show={isOpen} as={Fragment}>
				<div className="relative z-50">
					<div className="fixed inset-0 bg-black bg-opacity-25" />
					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<div
								ref={ref}
								className={clsx(
									'w-[300px]  rounded-lg',
									'flex flex-col justify-center items-center',
									'bg-white'
								)}
							>
								<div className="flex justify-center gap-3 w-full h-[80px] bg-custom p-5  rounded-t-lg">
									{format === 'hh:mm' && (
										<>
											<div className="flex flex-col justify-center items-center relative">
												<label
													htmlFor="hours"
													className="text-white text-xs absolute -top-3 left-1"
												>
													hours
												</label>
												<input
													defaultValue={hours[hour]}
													type="number"
													min={0}
													max={23}
													id="hours"
													className="w-[55px] bg-custom text-white font-bold text-3xl"
													onChange={e => setHour(parseInt(e.target.value, 10))}
													onFocus={e => e.currentTarget.select()}
												/>
											</div>
											<span className="text-white text-2xl font-bold">:</span>
											<div className="flex flex-col justify-center items-center relative">
												<label
													htmlFor="minutes"
													className="text-white text-xs absolute -top-3 -left-1"
												>
													minutes
												</label>
												<input
													defaultValue={minutes[minute]}
													type="number"
													min={0}
													max={59}
													id="minutes"
													className="w-[55px] bg-custom text-white font-bold text-3xl"
													onChange={e =>
														setMinute(parseInt(e.target.value, 10))
													}
													onFocus={e => e.currentTarget.select()}
												/>
											</div>
										</>
									)}
									{format === 'mm:ss' && (
										<>
											<div className="flex flex-col justify-center items-center relative">
												<label
													htmlFor="minutes"
													className="text-white text-xs absolute -top-3 -left-1"
												>
													Minutes
												</label>
												<input
													defaultValue={minutes[minute]}
													type="number"
													min={0}
													max={59}
													id="minutes"
													className="w-[55px] bg-custom text-white font-bold text-3xl"
													onChange={e =>
														setMinute(parseInt(e.target.value, 10))
													}
													onFocus={e => e.currentTarget.select()}
												/>
											</div>
											<span className="text-white text-2xl font-bold">:</span>
											<div className="flex flex-col justify-center items-center relative">
												<label
													htmlFor="seconds"
													className="text-white text-xs absolute -top-3 -left-1"
												>
													Seconds
												</label>
												<input
													defaultValue={seconds[second]}
													type="number"
													min={0}
													max={59}
													id="seconds"
													className="w-[55px] bg-custom text-white font-bold text-3xl"
													onChange={e =>
														setSecond(parseInt(e.target.value, 10))
													}
													onFocus={e => e.currentTarget.select()}
												/>
											</div>
											<span className="text-white text-2xl font-bold">:</span>
											<div className="flex flex-col justify-center items-center relative">
												<label
													htmlFor="millisecs"
													className="text-white text-xs absolute -top-3 -left-1"
												>
													Millisecs
												</label>
												<input
													defaultValue={millisecs[millis]}
													type="number"
													min={0}
													max={99}
													id="seconds"
													className="w-[55px] bg-custom text-white font-bold text-3xl"
													onChange={e =>
														setMillis(parseInt(e.target.value, 10))
													}
													onFocus={e => e.currentTarget.select()}
												/>
											</div>
										</>
									)}
								</div>
								{error && (
									<div className="w-full bg-red-500">
										<p className="text-white">Input invalid</p>
									</div>
								)}
								<div className="flex flex-col gap-2 w-full p-2">
									<div className="h-[40px] w-full flex justify-end gap-3">
										<button
											type="button"
											onClick={() => handleCancel()}
											onKeyUp={e => {
												if (e.key === 'Escape') handleCancel()
											}}
											className={clsx(
												'w-full border border-custom cursor-pointer',
												'rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white',
												'text-base font-medium text-custom hover:bg-custom hover:text-white focus:outline-none'
											)}
										>
											cancel
										</button>
										<button
											type="button"
											disabled={error}
											onClick={() => handleOk(minute, second, millis)}
											className={clsx(
												'w-full bg-custom',
												'rounded-md border border-transparent shadow-sm px-4 py-2',
												'bg-custom text-base font-medium text-white hover:brightness-90 focus:outline-none',
												error ? 'cursor-not-allowed' : 'cursor-pointer'
											)}
										>
											Ok
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>{' '}
				</div>
			</Transition>
		</div>
	)
}
