/* eslint-disable camelcase */
import React, { useState } from 'react'
import { Disclosure } from '@headlessui/react'
import { PlayIcon, PauseIcon } from '@heroicons/react/outline'
import { ChevronRightIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { athleteType, BodyProps, GroupBoxProps, Icons } from 'interfaces'
import { Drawer, DrawerCalendar, Icon } from 'components'
import moment from 'moment'
import { getInGroup, patchInGroup } from 'api'
import { useCustomer } from 'hooks'
import { BoxAthletes2 } from '..'

export const GroupBox: React.FC<GroupBoxProps> = ({
	slug,
	name,
	date,
	start_at,
	finish_at,
	participants,
	// clients,
	status,
	handleSelectGroup,
	handleRefetch,
	refetchGroup
}): JSX.Element => {
	const customer = useCustomer()
	const [clientList, setClientList] = useState<athleteType[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [isLoadingClient, setIsLoadingClient] = useState(false)
	const [openD, setOpenD] = useState(false)

	const changeStatus = async (): Promise<void> => {
		setIsLoading(true)
		const body = {
			// name,
			status: status === 'active' ? 'inactive' : 'active',
			// date,
			// start_at,
			// finish_at,
			clients: clientList.map(item => item.id)
		} as unknown as BodyProps
		try {
			const res = await patchInGroup({
				actionType: 'update',
				slug,
				body,
				customerId: customer.id
			})
			console.warn('res', res)
		} catch (error) {
			console.error(error)
		} finally {
			setIsLoading(false)
		}
		if (refetchGroup) refetchGroup()
	}

	const handleRequest = async (): Promise<void> => {
		setIsLoadingClient(true)
		try {
			const res = await getInGroup({
				customerId: customer.id,
				slug
			})
			if (res) setClientList(res.clients.map(it => it.client))
		} catch (error) {
			console.error(error)
		} finally {
			setIsLoadingClient(false)
		}
	}

	const changeStatusGroup = React.useCallback(
		() => changeStatus(),
		[slug, status]
	)

	const handleLoadClient = React.useCallback(
		() => handleRequest(),
		[slug, customer.id]
	)

	return (
		<Disclosure>
			{({ open }) => (
				<div className="relative">
					<div className="absolute top-4 sm:top-[30px] right-[40px] group flex flex-col">
						<button
							type="button"
							// disabled={nActivePrograms === 0 && status === true}
							onClick={() => changeStatusGroup()}
							className="cursor-pointer"
						>
							{status === 'active' ? (
								isLoading ? (
									<div className="flex justify-between items-center">
										<svg
											role="status"
											className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-light fill-custom"
											viewBox="0 0 100 101"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d={`M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 
													0 78.2051 0 50.5908C0	22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082
													100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094
													50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921
													72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z`}
												fill="currentColor"
											/>
											<path
												d={`M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 
													24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 
													63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345
													1.27873C39.2613 1.69328 37.813 
													4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 
													9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 
													70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 
													32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z`}
												fill="currentFill"
											/>
										</svg>
										<div className="border-r-2 border-gray-300 px-1 h-6 self-center" />
									</div>
								) : (
									<div className="flex justify-between items-center">
										<PlayIcon
											className="h-6 w-6 text-[green]"
											aria-hidden="true"
										/>
										<div className="border-r-2 border-gray-300 px-1 h-6 self-center" />
									</div>
								)
							) : isLoading ? (
								<div className="flex justify-between items-center">
									<svg
										role="status"
										className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:color-gray-light fill-custom"
										viewBox="0 0 100 101"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d={`M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 
														0 78.2051 0 50.5908C0	22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082
														100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094
														50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921
														72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z`}
											fill="currentColor"
										/>
										<path
											d={`M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 
													24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422
													4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345
													1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 
													10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642
													10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849
													25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 
													39.6781 93.9676 39.0409Z`}
											fill="currentFill"
										/>
									</svg>
									<div className="border-r-2 border-gray-300 px-1 h-6 self-center" />
								</div>
							) : (
								<div className="flex justify-between items-center">
									<PauseIcon
										className="h-6 w-6 text-[red]"
										aria-hidden="true"
									/>
									<div className="border-r-2 border-gray-300 px-1 h-6 self-center" />
								</div>
							)}
						</button>
					</div>
					<div
						className={clsx(
							'absolute top-10 right-[40px] flex justify-center items-center sm:hidden'
						)}
					>
						{customer.role !== 'Client' && (
							<button
								type="button"
								className="w-full h-full flex justify-center items-center p-1 rounded hover:bg-custom group"
								onClick={() => {
									handleSelectGroup(slug)
									setOpenD(true)
								}}
							>
								<Icon
									src={Icons.pencil}
									fillPath
									className="w-5 h-5 text-custom group-hover:text-white"
								/>
							</button>
						)}
						<div className="border-r-2 border-gray-300 px-1 h-6 self-center" />
					</div>
					{customer.role !== 'Client' && (
						<div
							className={clsx(
								'hidden sm:block absolute top-[25px] right-[90px] w-8 h-7 border-2 border-custom bg-inherit rounded-md',
								'hover:bg-custom group'
							)}
						>
							<button
								type="button"
								className="w-full h-full flex justify-center items-center"
								onClick={() => {
									handleSelectGroup(slug)
									setOpenD(true)
								}}
							>
								<Icon
									src={Icons.pencil}
									fillPath
									className="w-5 h-5 text-custom group-hover:text-white"
								/>
							</button>
						</div>
					)}
					<Disclosure.Button
						type="button"
						className={clsx(
							'my-1 cursor-pointer',
							'shadow sm:rounded-lg border-[1px] border-gray-300 border-l-4 border-l-custom w-full rounded-lg',
							'bg-white hover:bg-gray-200'
						)}
					>
						<div
							className="flex justify-between p-2"
							aria-hidden
							onClick={() => handleLoadClient()}
						>
							<div className="w-3/4 sm:w-full h-14 flex flex-col justify-between pl-2">
								<div className="w-full flex gap-2">
									<div className="flex gap-1 items-center border-2 border-custom rounded px-1">
										<Icon
											src={Icons.schedule}
											fillPath
											className="w-5 h-5 text-custom"
										/>
										<p className="text-base text-left text- text-custom font-Roboto lg:text-xl">
											{moment(date).format('MMM DD')}
										</p>
									</div>

									<p
										className={clsx(
											'max-w-[100px] flex items-center line-clamp-1',
											'text-lg text-left leading-6 text-gray-900 font-Roboto font-bold lg:text-2xl'
										)}
									>
										{name}
									</p>
								</div>
								<div className="w-fit flex items-center gap-2 md:gap-5">
									<p className="text-xs md:text-sm text-[#6B7280] font-Roboto lg:text-lg text-left">
										{`${moment(start_at).format('HH:mm')} - ${moment(
											finish_at
										).format('HH:mm')}`}
									</p>
									<div className="w-6 h-6">
										<Icon
											src={Icons.group}
											fillPath
											className="w-full h-full text-[#5A606C]"
										/>
									</div>
									<p className="text-xs md:text-sm text-[#6B7280] font-Roboto lg:text-lg text-left">
										Participants: {participants}
									</p>
								</div>
							</div>
							<div className="flex justify-center items-center">
								<ChevronRightIcon
									className={clsx(
										'h-6 w-6 text-custom',
										open ? '-rotate-90 transform' : 'rotate-90'
									)}
									aria-hidden="true"
								/>
							</div>
						</div>
					</Disclosure.Button>
					<Disclosure.Panel>
						<div
							className={clsx(
								'border border-gray-200  bg-inherit',
								'w-[96%] ml-[2%] text-lg font-Roboto font-bold cursor-pointer',
								'shadow-md mt-[-0.5rem]'
							)}
						>
							{isLoadingClient ? (
								<div className="w-full py-3 text-center text-custom font-Roboto text-base bg-white">
									Loading . . .{' '}
								</div>
							) : clientList.length > 0 ? (
								clientList?.map(client => (
									<BoxAthletes2
										key={client.id}
										id={client.id}
										image={client.profile_image?.url}
										fullname={`${client.first_name} ${client.last_name}`}
										// status={status}
										assignedPrograms={client?.assigned_programs}
										refetchUp={() => {
											if (handleRefetch) handleRefetch()
										}}
									/>
								))
							) : (
								<div className="w-full py-3 text-base text-center text-custom font-Roboto bg-white">
									Group without athletes
								</div>
							)}
						</div>
					</Disclosure.Panel>
					<Drawer
						open={openD}
						setOpen={(val: boolean) => setOpenD(val)}
						title="GROUP TRAINING"
						stretch
						mobileFull
					>
						<DrawerCalendar
							type="edit"
							groupSlug={slug}
							dateSelected={moment(date).utc().format('yyyy-MM-DD')}
							setOpenDrawer={setOpenD}
							handleRefetch={() => {
								if (refetchGroup) refetchGroup()
							}}
						/>
					</Drawer>
				</div>
			)}
		</Disclosure>
	)
}
