import axios from 'axios'
import { ProgramsResponse, programsType } from 'interfaces'
import { BaseURL } from '../environment'

export const getListPrograms = async (
	token: string,
	customer: number,
	offset: number,
	search?: string
): Promise<ProgramsResponse | null> => {
	let res = null as ProgramsResponse | null
	await axios
		.get(
			`${BaseURL}/programs/?x-customer-id=${customer}&ordering=state,-updated_at&limit=10&offset=${offset}${
				search ? `&search=${search}` : ''
			}`,
			{
				headers: {
					Authorization: `Token ${token}`
				}
			}
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)

	return res
}

export const getListProgramsAssign = async (
	token: string,
	customer: number,
	offset: number,
	search?: string
): Promise<ProgramsResponse | null> => {
	let res = null as ProgramsResponse | null
	// '/programs?x-customer-id=1&ordering=-state&state=active&&limit=10'
	await axios
		.get(
			`${BaseURL}/programs/?x-customer-id=${customer}&ordering=state&state=active&limit=10&offset=${offset}${
				search ? `&search=${search}` : ''
			}`,
			{
				headers: {
					Authorization: `Token ${token}`
				}
			}
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)

	return res
}

export const getProgram = async (
	token: string,
	customer: number,
	slug: string | undefined
): Promise<programsType | undefined> => {
	let res = undefined as programsType | undefined
	if (slug)
		await axios
			.get(`${BaseURL}/programs/${slug}/?x-customer-id=${customer}`, {
				headers: {
					Authorization: `Token ${token}`
				}
			})
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
				}
			)
	return res
}
