import { ChevronDoubleRightIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { CardExerciseProps } from 'pages/athlete/athlete.interface'
import React from 'react'

export const CardExercise: React.FC<CardExerciseProps> = ({
	exercise,
	checkedExercise,
	handleCheckExercise,
	setExerciseName,
	statsIndex,
	setStatsIndex,
	setArrayOfSets,
	setStatsMetrics,
	setArrayOfMetrics
}): JSX.Element => {
	React.useEffect(() => {
		return () => {
			handleCheckExercise('')
		}
	}, [])

	const handleClickCard = (): void => {
		setStatsIndex(0)
		handleCheckExercise(exercise.slug)
		if (setArrayOfSets) setArrayOfSets(exercise.assigned_sets)
		if (exercise.assigned_sets.length > 0 && setArrayOfMetrics)
			setArrayOfMetrics(
				exercise.assigned_sets[statsIndex ?? 0].assigned_metrics.map(items => ({
					id: items.slug,
					type: items.type_metric.type,
					name: items.type_metric.name,
					base: items.value,
					value: items.assigned_execute_metric.value
				}))
			)
		setExerciseName(exercise.name)
		if (setStatsMetrics) {
			setStatsMetrics(
				exercise.assigned_sets[statsIndex ?? 0].assigned_metrics.map(items => ({
					id: items.slug,
					type: items.type_metric.type,
					name: items.type_metric.name,
					base: items.value,
					value: items.assigned_execute_metric.value
				}))
			)
		}
	}

	return (
		<div
			className={clsx(
				'w-full h-10 flex justify-between items-center bg-white px-3 group',
				'border border-gray-200 rounded-md cursor-pointer hover:border-2 hover:border-custom',
				exercise.slug === checkedExercise && 'border-2'
			)}
			aria-hidden
			onClick={handleClickCard}
		>
			<span
				className={clsx(
					'group-hover:text-custom',
					exercise.slug === checkedExercise ? 'text-custom' : 'text-[#929BB2]'
				)}
			>
				{exercise.name}
			</span>
			{exercise.slug === checkedExercise ? (
				<ChevronDoubleRightIcon className={clsx('w-5 h-5 text-custom')} />
			) : (
				<ChevronDoubleRightIcon
					className={clsx('w-5 h-5 text-[#929BB2] group-hover:text-custom')}
				/>
			)}
		</div>
	)
}
