import { ArchiveIcon, CheckCircleIcon } from '@heroicons/react/outline'
import { PencilIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { Button } from 'components'
import { athleteType } from 'interfaces'
import React from 'react'

export interface Props {
	athlete: athleteType | undefined
	setArchiveDrawer: React.Dispatch<React.SetStateAction<boolean>>
	setCompletedDrawer: React.Dispatch<React.SetStateAction<boolean>>
	setUpdateValuesDrawer: React.Dispatch<React.SetStateAction<boolean>>
}

export const HeaderAthlete: React.FC<Props> = ({
	athlete,
	setArchiveDrawer,
	setCompletedDrawer,
	setUpdateValuesDrawer
}): JSX.Element => {
	return (
		<header className="hidden lg:flex flex-col justify-between lg:flex-row h-[100px]">
			<div className="sm:w-full flex flex-col justify-evenly h-[100px]">
				<div className="flex justify-between">
					<div className="w-full flex gap-5">
						<div className="flex sm:flex-row lg:flex-col justify-center lg:justify-around">
							<span
								className={clsx(
									'h-1/4 font-bold text-2xl sm:text-2xl lg:font-Robotofont-bold lg:text-5xl lg:h-[50%]'
								)}
							>
								{athlete?.first_name}
							</span>
							<span
								className={clsx(
									'h-1/4 font-bold text-2xl ml-2 lg:ml-0 lg:font-Robotofont-normal lg:text-4xl lg:h-[50%]'
								)}
							>
								{athlete?.last_name}
							</span>
						</div>
						<div className="h-full flex items-end" />
					</div>

					<div className="h-min flex gap-2">
						<Button
							type="button"
							rounded
							customColor
							fill={false}
							onClick={() => setArchiveDrawer(true)}
						>
							<div className="flex justify-center items-center gap-2">
								<ArchiveIcon className="w-5 h-5" />
								<span
									className={clsx(
										'font-Roboto text-[13px] leading-[15px] font-bold',
										'hidden sm:block'
									)}
								>
									Archived
								</span>
							</div>
						</Button>
						<Button
							type="button"
							rounded
							customColor
							fill={false}
							onClick={() => setCompletedDrawer(true)}
						>
							<div className="flex justify-center items-center gap-2">
								<CheckCircleIcon className="w-5 h-5" />
								<span
									className={clsx(
										'font-Roboto text-[13px] leading-[15px] font-bold',
										'hidden sm:block'
									)}
								>
									Completed
								</span>
							</div>
						</Button>
						<Button
							type="button"
							rounded
							customColor
							onClick={() => setUpdateValuesDrawer(true)}
						>
							<div className="flex justify-center items-center gap-2">
								<PencilIcon className="w-5 h-5" />
								<span
									className={clsx(
										'font-Roboto text-[13px] leading-[15px] font-bold',
										'hidden sm:block'
									)}
								>
									Athlete&#39;s metrics
								</span>
							</div>
						</Button>
					</div>
				</div>
			</div>
		</header>
	)
}
