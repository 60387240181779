import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import {
	CreateProgramInputs,
	clientAssignedProgramsType,
	athleteType,
	AssignedProgramSession
} from 'interfaces'
import { useForm } from 'react-hook-form'
import { CameraIcon } from '@heroicons/react/outline'
import { useCustomer, useDevice, useToast } from 'hooks'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'
import { Button, Input, Layout, AssignedProgramConfig } from 'components'
import {
	GET_LIST_ATHLETES_ASSIGNED_PROGRAM,
	getListAthletesAssignedProgram,
	getClientByIds,
	GET_CLIENTS_IDS,
	editAssignedProgramsPhoto,
	getAssignedProgramBySlug
} from 'api'
import styles from './athletesAssignConf.module.scss'

export const AthletesAssignConf = (): JSX.Element => {
	const params = useParams()
	const { programSlugs: assignedProgramSlug } = params
	const cookies = new Cookies()
	const token = cookies.get('token')
	const customer = useCustomer()
	const navigate = useNavigate()
	const [cover, setCover] = useState<string | undefined>()
	const [sessions, setSessions] = useState<AssignedProgramSession[]>([])
	const [athletes, setAthletes] = useState<clientAssignedProgramsType[]>([])
	const [athletesInfo, setAthletesInfo] = useState<athleteType[]>([])
	const { showToast } = useToast()
	const device = useDevice()

	const {
		data,
		isLoading,
		refetch: refetchAssignedProgram
	} = useQuery(
		[assignedProgramSlug, token],
		() =>
			getAssignedProgramBySlug({
				assignProgramSlug: assignedProgramSlug ?? '',
				stats: false
			}),
		{
			refetchOnMount: 'always'
		}
	)

	const {
		data: listAthletes,
		isLoading: isLoading2,
		isRefetching,
		refetch
	} = useQuery(
		[
			GET_LIST_ATHLETES_ASSIGNED_PROGRAM,
			params.programSlugs,
			params.athleteIds,
			token
		],
		() =>
			getListAthletesAssignedProgram({
				athleteIds: params.athleteIds,
				programSlugs: params.programSlugs
			}),
		{
			refetchOnMount: 'always'
		}
	)

	const { data: listAthletesInfo, isLoading: isLoading3 } = useQuery(
		[GET_CLIENTS_IDS, customer.id, params.athleteIds, token],
		() => getClientByIds(token, customer.id, params.athleteIds),
		{
			refetchOnMount: 'always'
		}
	)

	const { register, watch, setValue } = useForm<CreateProgramInputs>({
		mode: 'onChange'
	})
	const watchName = watch('name')

	useEffect(() => {
		if (data) {
			if (data.assigned_program_sessions.length > 0)
				setSessions(data.assigned_program_sessions)
			setValue('name', data.name)
		}
	}, [data])

	useEffect(() => {
		if (listAthletes) {
			setAthletes(listAthletes)
			if (
				listAthletes[0].assigned_program &&
				listAthletes[0].assigned_program.image
			)
				setCover(listAthletes[0].assigned_program.image)
		}
	}, [listAthletes])

	useEffect(() => {
		if (listAthletesInfo) {
			setAthletesInfo(listAthletesInfo)
		}
	}, [listAthletesInfo])

	const editPhotos = async (): Promise<void> => {
		if (params.programSlugs && cover) {
			const auxSlugs = params.programSlugs.split(',')
			let res
			try {
				auxSlugs.forEach(async slug => {
					res = await editAssignedProgramsPhoto({ slug, image: cover })
				})
				if (res)
					showToast('Edit photo', 'photo was edited successfully', 'success')
			} catch (error) {
				console.error(error)
			}
		}
	}

	useEffect(() => {
		editPhotos()
	}, [cover])

	const handleMediaUpload = (file: File): void => {
		const reader = new FileReader()
		reader.onload = e => {
			const uploadedObj = { file, url: e.target?.result as string }
			setCover(uploadedObj.url)
		}
		reader.readAsDataURL(file) // convert to base64 string
	}

	const handleContinue = (): void => {
		navigate(`/athletes`)
	}

	return (
		<Layout
			back
			stretch
			isLoading={isLoading || isLoading2 || isLoading3 || isRefetching}
		>
			<div className="flex-1 w-full">
				<div className={clsx('flex-1 flex-col')}>
					<div
						className={clsx(
							'bg-gray-700 text-white relative h-[176px]',
							'lg:bg-[transparent] lg:flex lg:pr-0 lg:h-auto lg:px-8 lg:py-3'
						)}
					>
						{cover && (
							<>
								<div
									className={clsx(
										styles.gradient,
										'absolute inset-0 min-h-[114px] lg:hidden'
									)}
								/>
								<img
									src={cover}
									alt="cover"
									className="object-fill w-full h-[176px] lg:hidden"
								/>
							</>
						)}

						<input
							type="file"
							id="upload-media"
							name="upload-image"
							accept="image/*"
							className="hidden"
							onChange={event =>
								event.target.files
									? handleMediaUpload(event.target.files[0])
									: null
							}
						/>
						<div className="absolute inset-x-8 inset-y-3 lg:static lg:flex">
							<div
								className={clsx(
									'flex justify-between mb-3',
									'lg:justify-start'
								)}
							>
								<label
									htmlFor="upload-media"
									className={clsx(
										'flex items-center cursor-pointer hover:underline overflow-hidden',
										'lg:bg-gray-700 lg:p-2 lg:rounded-[9px] lg:w-[348px] lg:h-[138px] lg:justify-center lg:relative'
									)}
								>
									{cover && (
										<>
											<div
												className={clsx(
													styles.gradient,
													'absolute inset-0 min-h-[114px] hidden lg:block'
												)}
											/>
											<img
												src={cover}
												alt="cover"
												className=" hidden object-fill w-full h-[138px] absolute inset-0 lg:block"
											/>
										</>
									)}
									<div
										className={clsx(
											{
												'border-2 border-white rounded-full max-h-[64px] max-w-[64px] mr-4 p-4':
													!cover
											},
											{
												'max-h-[12px] max-w-[12px] mr-2': cover
											},
											`lg:absolute ${
												cover ? 'lg:left-[112px]' : 'lg:left-[90px]'
											}`
										)}
									>
										<CameraIcon
											className={clsx(
												`${cover ? 'h-[12px] w-[12px]' : 'h-[30px] w-[30px]'}`,
												' text-white'
											)}
										/>
									</div>
									<span
										className={clsx(
											'font-Roboto',
											`lg:absolute ${cover ? '' : 'lg:right-[100px]'}`
										)}
									>{`${cover ? ' Change Image' : 'Add a photo'}`}</span>
								</label>
							</div>
							<div className="2xl:min-w-[40vw] pl-4">
								<Input
									id="field-name"
									name="name"
									readOnly
									register={register}
									placeholder="Name of program"
									type="text"
									className="mb-1 lg:text-black !cursor-default"
									transparent
									strech
									fontSize="extra-large"
								/>
								<h2
									className={clsx(
										'text-white font-Roboto',
										'lg:text-black lg:text-2xl'
									)}
								>
									{`Total sessions ${sessions.length}`}
								</h2>
							</div>
						</div>
					</div>
					<div className="w-full flex items-center justify-center">
						<div
							className={clsx(
								'flex items-center justify-center py-[5px] border-b-[3px] border-custom mb-1 w-full',
								'lg:max-w-[552px]'
							)}
						>
							<h3 className="font-Roboto text-md">
								Configure the program and all athletes to continue
							</h3>
						</div>
					</div>
					{/* aqui */}
				</div>
				<div className="flex-1 px-4 mt-3 min-h-[calc(100vh-380px)] max-h-[calc(100vh-380px)]">
					<div
						className={clsx(
							{
								'min-h-[calc(100vh-480px)] max-h-[calc(100vh-480px)]':
									device === 'web'
							},
							{
								'min-h-[calc(100vh-490px)] max-h-[calc(100vh-490px)]':
									device === 'android' || device === 'ios'
							},
							// 'min-h-[calc(100vh-358px)] max-h-[calc(100vh-358px)]',
							'overflow-hidden hide-scroll-bar overflow-y-scroll',
							'xl:min-h-[calc(100vh-352px)] xl:max-h-[calc(100vh-352px)]'
						)}
					>
						{sessions.map((item, index) => {
							const key = `${item.slug}-${index}`
							return (
								<AssignedProgramConfig
									key={key}
									session={item}
									athletes={athletes}
									athletesInfo={athletesInfo}
									refetchList={refetch}
									refetchAssignedProgram={refetchAssignedProgram}
								/>
							)
						})}
					</div>

					<div className="flex items-center justify-between">
						<div className="w-full">
							<Button
								label="Continue"
								customColor
								disabled={watchName === '' || watchName === undefined}
								onClick={() => handleContinue()}
							/>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}
