import { deviceDetect } from 'lib'
import { useEffect, useState } from 'react'

export const useDevice = (): 'android' | 'ios' | 'web' => {
	const [device, setDevice] = useState<'android' | 'ios' | 'web'>('web')

	useEffect(() => {
		const aux = deviceDetect()
		setDevice(aux)
	}, [])

	return device
}
