import React from 'react'
import { Toogle, Icon, Tooltip } from 'components'
import { useCustomer } from 'hooks'
import { NotificationItemProps } from './notificationItem.interface'

export const NotificationItem: React.FC<NotificationItemProps> = props => {
	const {
		notificationLabel,
		handleToggle,
		checked,
		withIcon,
		iconSrc,
		isDisable,
		valueForStaffMember
	} = props

	const customer = useCustomer()

	return (
		<div className="w-full flex justify-between">
			<div className="flex gap-3 xl:gap-4 max-h-max w-[90%] xl:w-full">
				{withIcon && <Icon src={iconSrc} className="w-6 h-6" />}
				<span
					data-testid="notification-label"
					className="text-base text-[#1D2027] text-left w-full md:pr-0 md:w-full xl:w-full"
				>
					{notificationLabel}
				</span>
			</div>
			{customer.role !== 'StaffMember' ? (
				<Toogle
					isDisable={isDisable}
					enabled={isDisable ? false : checked}
					setEnabled={handleToggle}
					withToolTip={isDisable}
					toolTipText="Notification is disabled by administrator"
				/>
			) : (
				<Tooltip text="Only the administrator has permission to change the settings">
					<button type="button" disabled className="cursor-not-allowed">
						<Toogle
							isDisable={customer.role === 'StaffMember'}
							enabled={valueForStaffMember}
							setEnabled={handleToggle}
						/>
					</button>
				</Tooltip>
			)}
		</div>
	)
}
