import React, { useContext, useEffect, useRef, useState } from 'react'
import { BoxProgram, Button, Icon, Input, Loading, Modal } from 'components'
import { useForm } from 'react-hook-form'
import { Icons, programsType, SearchInputs } from 'interfaces'
import { SearchIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { useInfiniteQuery } from 'react-query'
import { cloneProgram, getListPrograms, GET_PROGRAMS, deleteProgram } from 'api'
import { getOffset } from 'lib'
import { useCustomer, useDevice, useToast } from 'hooks'
import { useInView } from 'react-intersection-observer'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { ProgramContext } from 'context'

export const TabPrograms = (): JSX.Element => {
	const { showToast } = useToast()
	const { register, watch } = useForm<SearchInputs>({ mode: 'onChange' })
	const { ref, inView } = useInView()
	const customer = useCustomer()
	const searchIcon = <SearchIcon className="text-gray-900 cursor-pointer" />
	const watchSearch = watch('search', '')
	const [programs, setPrograms] = useState<programsType[]>([])
	const [showModal, setShowModal] = useState(false)
	const [slugProgram, setSlugProgram] = useState('')
	const [search, setSearch] = useState<string | undefined>(undefined)
	const scrollRef = useRef<HTMLDivElement>(null)
	const cookies = new Cookies()
	const token = cookies.get('token')
	const device = useDevice()

	const { stateProgram } = useContext(ProgramContext)

	React.useEffect(() => {
		setSearch(watchSearch)
		setPrograms([])
	}, [watchSearch])

	const { data, isFetchingNextPage, fetchNextPage, isLoading, refetch } =
		useInfiniteQuery(
			[GET_PROGRAMS, search, customer.id, token],
			({ pageParam = 0 }) =>
				getListPrograms(token, customer.id, pageParam, search),
			{
				getPreviousPageParam: firstPage =>
					firstPage?.previous ? getOffset(firstPage?.previous) : undefined,
				getNextPageParam: lastPage =>
					lastPage?.next ? getOffset(lastPage?.next) : undefined
			}
		)

	React.useEffect(() => {
		if (inView) {
			fetchNextPage()
		}
	}, [inView])

	useEffect(() => {
		if (data?.pages) {
			let auxData = [] as programsType[]
			data.pages.forEach(page => {
				const auxResults = page?.results
				if (auxResults) auxData = [...auxData, ...auxResults]
			})
			setPrograms(auxData)
		}
	}, [data])

	useEffect(() => {
		refetch()
	}, [stateProgram])

	const scrollToRef = (
		refScroll: React.MutableRefObject<HTMLDivElement | null>
	): void => {
		if (refScroll) {
			const element = refScroll.current
			if (element)
				element.scrollTo({
					behavior: 'smooth',
					top: 0
				})
		}
	}

	const handleCloneProgram = async (program: string): Promise<void> => {
		const res = await cloneProgram(token, program)
		if (res) {
			if (res.error) {
				showToast(
					'Cloned Program',
					`Error: Program wasn't cloned successfully`,
					'error'
				)
				refetch()
			} else {
				showToast(
					'Cloned program',
					'program was cloned successfully',
					'success'
				)
				setPrograms([res, ...programs])
				if (scrollRef) scrollToRef(scrollRef)
				refetch()
			}
		}
	}

	const handleModalDel = (slug: string): void => {
		setSlugProgram(slug)
		setShowModal(true)
	}

	const handleDeleteProgram = async (slug: string): Promise<void> => {
		if (slug !== '') {
			try {
				const res = await deleteProgram(token, customer.id, slug)
				if (res && res === 204) {
					showToast(
						'Delete program',
						'Program was deleted successfully',
						'warning'
					)
					refetch()
				}
			} catch (error) {
				console.error(error)
			}
		}
		setShowModal(false)
	}

	return (
		<>
			<div className={clsx('px-6 grid grid-cols-1', 'lg:grid-cols-4')}>
				<Input
					name="search"
					register={register}
					placeholder="Search program"
					type="text"
					className="mb-4 lg:col-span-3 lg:pr-2"
					leftIcon={searchIcon}
					rounded
				/>
				{customer.role.toUpperCase().includes('ADMIN') && (
					<Link to="/program">
						<Button customColor size="full" type="button">
							<div className="flex items-center justify-center">
								<p className="font-Roboto font-semibold text-base mr-2">
									Create a program
								</p>
								<div className="pt-1">
									<Icon
										fillPath
										className={clsx('h-[28px] w-[30px] text-custom')}
										src={Icons.plus}
									/>
								</div>
							</div>
						</Button>
					</Link>
				)}
			</div>
			<div className="h-[1px] w-full bg-gray-300 mt-1" />
			{!isLoading ? (
				<div
					ref={scrollRef}
					className={clsx(
						'flex flex-col gap-8 pt-4 px-6 ',
						{
							'min-h-[calc(100vh-300px)] max-h-[calc(100vh-300px)]':
								device === 'web'
						},
						{
							'min-h-[calc(100vh-315px)] max-h-[calc(100vh-315px)]':
								device === 'android' || device === 'ios'
						},
						'overflow-hidden hide-scroll-bar overflow-y-scroll',
						'md:grid md:grid-cols-2',
						'md:min-h-[calc(100vh-210px)] md:max-h-[calc(100vh-210px)]',
						'xl:grid-cols-3',
						'2xl:grid-cols-4'
					)}
				>
					{programs.map((item, index) => {
						const pos = index
						return (
							<BoxProgram
								key={`${pos}-${item.slug}`}
								id={item.slug}
								sessions={item.program_sessions?.length}
								title={item.name}
								active={item.state === 'active'}
								cover={item.image}
								own={item.own}
								clickCopy={handleCloneProgram}
								clickDelete={handleModalDel}
							/>
						)
					})}
					<div className="md:col-span-2 xl:col-span-3 2xl:col-span-4 flex items-center justify-center mt-2">
						<div ref={ref}>{isFetchingNextPage && <Loading isSmall />}</div>
					</div>
				</div>
			) : (
				<Loading />
			)}
			<Modal
				type="danger"
				open={showModal}
				setOpen={(val: boolean) => setShowModal(val)}
				handleClick={() => handleDeleteProgram(slugProgram)}
			>
				<>
					<h3 className="text-lg leading-6 font-medium text-gray-900">
						Delete
					</h3>
					<div className="mt-2">
						<p className="text-sm text-gray-500">
							Do you want to delete this program?
						</p>
					</div>
				</>
			</Modal>
		</>
	)
}
