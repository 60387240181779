import { NotificationType } from 'api'
import React, { createContext } from 'react'

const NotificationDefaultValue = {
	notifications: [],
	setNotifications: () => null,
	isFetchingNextPage: false,
	isFetching: false,
	inView: false,
	ref: () => null,
	removeNotifications: async () => null,
	isLoading: false,
	removeAllNotifications: async () => null,
	updateNotificationStatus: async () => null,
	setGetAllNotifications: () => null,
	setIsRead: () => null,
	isRead: false,
	getAllNotifications: 'ALL'
}

export const NotificationContext = createContext<{
	notifications: NotificationType[]
	setNotifications: React.Dispatch<React.SetStateAction<NotificationType[]>>
	isFetchingNextPage: boolean
	isFetching: boolean
	inView: boolean
	ref: (node?: Element | null) => void
	removeNotifications: (id: number) => Promise<void | null>
	removeAllNotifications: () => Promise<void | null>
	updateNotificationStatus: (id: number) => Promise<void | null>
	isLoading: boolean
	setGetAllNotifications: React.Dispatch<React.SetStateAction<string>>
	setIsRead: React.Dispatch<React.SetStateAction<boolean>>
	isRead: boolean
	getAllNotifications: string
}>(NotificationDefaultValue)
