import clsx from 'clsx'
import {
	ArchiveAssignedProgram,
	CompletedAssignedProgram,
	Drawer,
	DrawerAssignProgram,
	DrawerAthletePerformance,
	DrawerInfoGeneralAthlete
} from 'components'
import React from 'react'
import { HandleDrawersProps } from './athlete.interface'
import { DrawerExerciseMobile } from './drawerExercisemobile'
import { DrawerSessionMobile } from './drawerSessionMobile'

export const HandleDrawers: React.FC<HandleDrawersProps> = ({
	athlete,
	openDrawer,
	setOpenDrawer,
	archiveDrawer,
	setArchiveDrawer,
	completedDrawer,
	setCompletedDrawer,
	updateInfoAthleteDrawer,
	setUpdateInfoAthleteDrawer,
	updateValuesDrawer,
	setUpdateValuesDrawer,
	openSessionMobile,
	setOpenSessionMobile,
	openExerciseMobile,
	refetch,
	// drawer session mobile
	labelSessions,
	percSessionsCompleted,
	listSession,
	palleteColor,
	checkSlugSession,
	handleCheckSession,
	setStatsMetrics,
	setOpenExerciseMobile,
	isLoadingProgram,
	setOpenModalSession,
	// drawer exercise mobile
	setOpenModalExercise,
	// statsMetrics,
	listExercise,
	checkSlugExercise,
	handleCheckExercise,
	// setStatsMetrics,
	setExerciseName,
	// nouev
	statsIndex,
	setStatsIndex,
	handleMetricForSets,
	arrayOfSets,
	arrayOfMetrics,
	setArrayOfSets,
	setArrayOfMetrics
}): JSX.Element => {
	return (
		<div>
			{athlete && (
				<Drawer
					open={openDrawer}
					setOpen={(val: boolean) => setOpenDrawer(val)}
					title="Assign program"
					stretch
					mobileFull
				>
					<DrawerAssignProgram athleteUp={athlete} />
				</Drawer>
			)}
			{archiveDrawer && (
				<Drawer
					open={archiveDrawer}
					setOpen={(val: boolean) => setArchiveDrawer(val)}
					title="Archived assigned programs"
					stretch
					mobileFull
				>
					<ArchiveAssignedProgram setArchiveDrawer={setArchiveDrawer} />
				</Drawer>
			)}
			{completedDrawer && (
				<Drawer
					open={completedDrawer}
					setOpen={(val: boolean) => setCompletedDrawer(val)}
					title="Completed assigned programs"
					stretch
					mobileFull
				>
					<CompletedAssignedProgram setCompletedDrawer={setCompletedDrawer} />
				</Drawer>
			)}
			{updateInfoAthleteDrawer && (
				<Drawer
					open={updateInfoAthleteDrawer}
					setOpen={(val: boolean) => setUpdateInfoAthleteDrawer(val)}
					title="General Information"
					stretch
					mobileFull
				>
					<DrawerInfoGeneralAthlete
						athlete={athlete}
						setUpdateInfoAthleteDrawer={setUpdateInfoAthleteDrawer}
						refetch={refetch}
					/>
				</Drawer>
			)}
			{updateValuesDrawer && (
				<Drawer
					open={updateValuesDrawer}
					setOpen={(val: boolean) => setUpdateValuesDrawer(val)}
					title="Athlete's performance"
					stretch
					mobileFull
				>
					<DrawerAthletePerformance
						infoAthlete={athlete}
						refetch={refetch}
						setUpdateValuesDrawer={setUpdateValuesDrawer}
					/>
				</Drawer>
			)}
			{openSessionMobile && (
				<Drawer
					open={openSessionMobile}
					setOpen={(val: boolean) => setOpenSessionMobile(val)}
					title="Sessions"
					stretch
					mobileFull
				>
					<div
						className={clsx(
							'flex-1 min-h-[calc(100vh-150px)] max-h-[calc(100vh-150px)]',
							'overflow-hidden overflow-y-scroll hide-scroll-bar'
						)}
					>
						<DrawerSessionMobile
							labelSessions={labelSessions}
							percSessionsCompleted={percSessionsCompleted}
							listSession={listSession}
							palleteColor={palleteColor}
							checkSlugSession={checkSlugSession}
							handleCheckSession={handleCheckSession}
							setOpenExerciseMobile={setOpenExerciseMobile}
							isLoadingProgram={isLoadingProgram}
							setOpenModalSession={setOpenModalSession}
						/>
					</div>
				</Drawer>
			)}
			{openExerciseMobile && (
				<div className="xl:hidden">
					<Drawer
						open={openExerciseMobile}
						setOpen={(val: boolean) => setOpenExerciseMobile(val)}
						title="Exercises"
						stretch
						mobileFull
					>
						<div
							className={clsx(
								'flex-1 min-h-[calc(100vh-150px)] max-h-[calc(100vh-150px)]',
								'overflow-hidden overflow-y-scroll hide-scroll-bar'
							)}
						>
							<DrawerExerciseMobile
								setOpenModalExercise={setOpenModalExercise}
								listExercise={listExercise}
								checkSlugExercise={checkSlugExercise}
								handleCheckExercise={handleCheckExercise}
								setExerciseName={setExerciseName}
								statsIndex={statsIndex}
								setStatsIndex={setStatsIndex}
								handleMetricForSets={handleMetricForSets}
								arrayOfSets={arrayOfSets}
								arrayOfMetrics={arrayOfMetrics}
								setArrayOfSets={setArrayOfSets}
								setStatsMetrics={setStatsMetrics}
								setArrayOfMetrics={setArrayOfMetrics}
							/>
						</div>
					</Drawer>
				</div>
			)}
		</div>
	)
}
