import React, { useState } from 'react'
import { PlusIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { MetricExcercise } from 'components/metric-excercise'
import {
	handleAddMetric,
	handleChangeMetric,
	handleCreateSet,
	handleDeleteMetric
} from 'lib'
import { SetsMetricsListProps } from './SetsMetricsList.interface'
import { SetsNav } from '../SetsNav/SetsNav'

export const SetsMetricsList: React.FC<SetsMetricsListProps> = props => {
	// Internal logic of the sets
	const { exercise, listMetrics, setSetsBreakDown, setsBreakDown } = props
	const [currentSet, setCurrentSet] = useState(0)

	return (
		<section>
			{/* ADD SET BUTTON */}
			<button
				type="button"
				className="bg-custom px-2 rounded text-white font-semibold mt-5"
				onClick={() =>
					handleCreateSet({ exercise, setsBreakDown, setSetsBreakDown })
				}
			>
				<span className="flex gap-2 w-[72px] h-[32px] text-lg justify-center">
					Set
					<PlusIcon className="w-4 h-4 my-auto" />
				</span>
			</button>
			{/* SETS NAV */}
			<SetsNav
				currentSet={currentSet}
				setCurrentSet={setCurrentSet}
				setSetsBreakDown={setSetsBreakDown}
				setsBreakDown={setsBreakDown}
			/>
			{/* SETS RENDER SECTION */}
			<section>
				{setsBreakDown.map((set, idx) => (
					<div key={set.slug}>
						{idx === currentSet && (
							<>
								<div className="flex font-Roboto font-bold my-6 -ml-9 text-center">
									<p className="w-full">Units of mesure</p>
									<p className="w-full">
										<span className="text-custom ml-4">*</span>
										Base value
									</p>
								</div>
								<div className="flex flex-col gap-4 overflow-y-visible">
									{/* SETS METRICS RENDER */}
									{setsBreakDown[currentSet].metrics.map(metric => {
										return (
											<div className="mb-2" key={metric.slug}>
												<MetricExcercise
													key={metric.slug}
													slug={metric.slug}
													typeForm="editSession"
													defaultList={metric.type_metric}
													defaultVal={metric.default_value}
													listMetric={listMetrics}
													deleteMetric={() =>
														handleDeleteMetric({
															setsBreakDown,
															currentSet,
															slug: metric.slug,
															setSetsBreakDown
														})
													}
													changeMetric={(slug, type, value) =>
														handleChangeMetric({
															slug,
															type,
															value,
															setsBreakDown,
															currentSet,
															setSetsBreakDown
														})
													}
												/>
											</div>
										)
									})}
								</div>
								{/* ADD METRIC BUTTON */}
								<div
									className={clsx(
										'w-full flex items-center justify-center py-2 mt-2',
										'border-2 border-custom rounded-md cursor-pointer',
										'text-custom hover:text-white font-Roboto text-sm underline hover:bg-custom'
									)}
									onClick={() =>
										handleAddMetric({
											setsBreakDown,
											currentSet,
											setSetsBreakDown
										})
									}
									aria-hidden="true"
								>
									<span>+ Add new metric</span>
								</div>
							</>
						)}
					</div>
				))}
			</section>
		</section>
	)
}
