import { BaseURL } from 'api/environment'
import axios from 'axios'
import { ExerciseType, EditExercisePut } from 'interfaces'

export const editExercises = async (
	token: string,
	slug: string,
	body: EditExercisePut
): Promise<ExerciseType[] | null> => {
	let res = [] as ExerciseType[] | null
	await axios
		.put(`${BaseURL}/exercises/${slug}/`, body, {
			headers: {
				Authorization: `Token ${token}`
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
