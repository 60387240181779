import React, { useEffect, useRef } from 'react'
import clsx from 'clsx'
import { ModalType } from 'interfaces'
import { ExclamationIcon, CheckIcon } from '@heroicons/react/outline'
import { Button } from 'components/buttons'
import { useClickOutside } from 'hooks'
import { Transition } from '@headlessui/react'
// import { createPortal } from 'react-dom'
// const container = document.getElementById('modal') as Element

export const Modal: React.FC<ModalType> = ({
	open,
	setOpen,
	type,
	handleClick,
	children
}): JSX.Element => {
	const ref = useRef(null)
	useClickOutside(ref, () => setOpen(false))
	useEffect(() => {
		return () => {
			setOpen(false)
		}
	}, [])

	return (
		<Transition show={open} className={clsx('z-20 fixed inset-0')}>
			<div className="fixed inset-0 z-0 bg-black bg-opacity-25" />
			{open && (
				<div
					ref={ref}
					className={clsx(
						'absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%]',
						'w-full max-w-xs transform overflow-hidden rounded-2xl bg-white p-6 text-left',
						'align-middle shadow-xl transition-all',
						'md:max-w-md z-20'
					)}
				>
					<div className="sm:flex sm:items-start">
						<div
							className={clsx(
								'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full',
								'sm:mx-0 sm:h-10 sm:w-10',
								{ 'bg-red-100 ': type === 'danger' }
							)}
						>
							{type === 'danger' && (
								<ExclamationIcon
									className="h-6 w-6 text-red-600"
									aria-hidden="true"
								/>
							)}
							{type === 'warning' && (
								<ExclamationIcon
									className="h-6 w-6 text-yellow-600"
									aria-hidden="true"
								/>
							)}
							{type === 'success' && (
								<CheckIcon
									className="h-6 w-6 text-green-600"
									aria-hidden="true"
								/>
							)}
						</div>
						<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
							{children}
						</div>
					</div>
					<div className="mt-5 flex flex-row-reverse sm:mt-4">
						<div className="w-1/2 px-2">
							<Button
								label="Ok"
								customColor
								onClick={() => (handleClick ? handleClick() : setOpen(false))}
								className="ml-3"
								size="full"
							/>
						</div>
						<div className="w-1/2 px-2">
							<Button
								label="Cancel"
								customColor
								fill={false}
								size="full"
								onClick={() => setOpen(false)}
							/>
						</div>
					</div>
				</div>
			)}
		</Transition>
	)
}
