import clsx from 'clsx'
import React from 'react'
import { ArrayOfSets } from './athlete.interface'

interface TableExerciseProps {
	statsIndex: number
	arrayOfSets: ArrayOfSets[]
}

export const TableExercise: React.FC<TableExerciseProps> = ({
	statsIndex,
	arrayOfSets
}): JSX.Element => {
	return (
		<div
			className={clsx(
				'w-full h-[150px] max-h-[150px] flex flex-col items-center gap-1 overflow-hidden hide-scroll-bar overflow-y-scroll'
			)}
		>
			<div className="w-full flex justify-between items-center">
				<span className="text-xs text-[#454952] font-bold w-1/3">Metric</span>
				<span className="text-xs text-[#454952] font-bold w-1/3">Base</span>
				<span className="text-xs text-[#454952] font-bold w-1/3">Execute</span>
			</div>

			{arrayOfSets[statsIndex]?.assigned_metrics.map(item => (
				<div
					key={item.slug}
					className="w-full flex justify-between items-center"
				>
					<span className="text-xs text-[#454952] w-1/3">
						{item.type_metric.name}
					</span>
					<span className="text-xs text-[#454952] w-1/3">{item.value}</span>
					<span className="text-xs text-[#454952] w-1/3">
						{item.assigned_execute_metric.value || '-'}
					</span>
				</div>
			))}
		</div>
	)
}
