import React, { useState } from 'react'
import { Button, SimpleLayout, Input, LinkElement } from 'components'
import { useForm, SubmitHandler } from 'react-hook-form'
import { ForgotInputs } from 'interfaces'
import clsx from 'clsx'
import { forgotPass } from 'api'
import { useToast } from 'hooks'

export const ForgotPassword = (): JSX.Element => {
	const {
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid }
	} = useForm<ForgotInputs>({ mode: 'all' })
	const { showToast } = useToast()

	const [loading, setLoading] = useState(false)

	const rules = {
		email: {
			required: { value: true, message: 'This is required' }
		},
		password: {
			required: { value: true, message: 'This is required' }
		}
	}

	const onSubmit: SubmitHandler<ForgotInputs> = async data => {
		// 'qa+dev@getupperhand.com', 'Testing1234'
		setLoading(true)
		const res = await forgotPass(data.email)
		if (res?.success) {
			showToast(
				'Success',
				'Please check your email to recover your password',
				'success'
			)
		} else {
			setLoading(false)
			showToast(
				'Error',
				'The email does not exist, please check and try again',
				'error'
			)
		}
	}

	return (
		<SimpleLayout>
			<div className="flex items-center justify-center">
				<div className="w-full space-y-8">
					<div>
						<h2
							className={clsx(
								'text-center font-bold text-gray-700 font-Roboto text-2xl',
								'md:text-4xl'
							)}
						>
							ENTER YOUR EMAIL
						</h2>
						<h3
							className={clsx(
								'text-center font-normal text-gray-400 font-Roboto text-sm',
								'md:text-lg'
							)}
						>
							We will send you an email to recover your password.
						</h3>
					</div>
					<form className="mt-8 block" onSubmit={handleSubmit(onSubmit)}>
						<div className="rounded-md shadow-sm -space-y-px">
							<Input
								name="email"
								title="Email address"
								register={register}
								rules={rules.email}
								placeholder="Email address"
								error={errors.email}
								type="email"
								className="mb-4"
								asterisk
							/>
						</div>
						<LinkElement
							label="Log In"
							linkTo="/signin"
							className="mt-4 mb-20"
						/>
						<Button
							label={`${loading ? 'Loading' : 'Continue'}`}
							color="Primary"
							size="full"
							type="submit"
							customColor
							disabled={!isDirty || !isValid}
						/>
					</form>
				</div>
			</div>
		</SimpleLayout>
	)
}
