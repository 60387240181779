import { PencilIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { athleteType } from 'interfaces'
import React from 'react'
import { InfoAthlete } from './infoAthlete'

export interface CardDataAthleteMobileProps {
	athlete: athleteType | undefined
	setUpdateInfoAthleteDrawer: React.Dispatch<React.SetStateAction<boolean>>
}

export const CardDataAthleteMobile: React.FC<CardDataAthleteMobileProps> = ({
	athlete,
	setUpdateInfoAthleteDrawer
}): JSX.Element => {
	return (
		<div className="w-full flex justify-center items-center">
			<div className="w-full h-full p-4 rounded-2xl bg-white shadow-2xl border border-gray-300 text-base font-Roboto">
				<div className="w-full flex justify-between items-center">
					<span className="font-bold">Details:</span>
					<div
						className={clsx(
							'w-[100px] flex gap-1 justify-center items-center cursor-pointer',
							'hover:bg-custom group rounded-full border-2 border-custom'
						)}
						aria-hidden
						onClick={() => setUpdateInfoAthleteDrawer(true)}
					>
						<PencilIcon className="w-5 h-5 text-custom group-hover:text-white" />
						<span
							className={clsx(
								'font-Roboto text-custom text-[13px] leading-[15px] font-bold',
								'sm:block group-hover:text-white'
							)}
						>
							Edit
						</span>
					</div>
				</div>
				<div className="h-[1px] bg-slate-400 opacity-20 mt-3" />
				<InfoAthlete athlete={athlete} />
				<div className="h-[1px] bg-slate-400 opacity-20" />
			</div>
		</div>
	)
}
