import { BaseURL } from 'api/environment'
import axios from 'axios'
import { libraryExerciseType, PostCreateMultipleExercises } from 'interfaces'

export const createMultipleExercises = async (
	token: string,
	body: PostCreateMultipleExercises
): Promise<libraryExerciseType[] | null | string> => {
	let res = [] as libraryExerciseType[] | null | string
	await axios
		.post(`${BaseURL}/exercises/create_multiple_exercises/`, body, {
			headers: {
				Authorization: `Token ${token}`
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				res = error.response.data.error
				// console.error(error)
			}
		)
	return res
}
