import { ContextCustomerType, CustomerType } from 'interfaces'
import { createContext, useCallback, useState } from 'react'

const CustomerContextDefaultValue: ContextCustomerType = {
	customer: {
		id: 0,
		color: '',
		role: '',
		roleId: 0
	},
	setCustomer: () => null
}

export const CustomerContext = createContext<ContextCustomerType>(
	CustomerContextDefaultValue
)

export const useContextCustomer = (): ContextCustomerType => {
	const [customer, setCustomerVal] = useState<CustomerType>(
		CustomerContextDefaultValue.customer
	)

	const setCustomer = useCallback(
		(data: CustomerType) => {
			setCustomerVal(data)
		},
		[setCustomerVal]
	)

	return {
		customer,
		setCustomer
	}
}
