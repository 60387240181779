import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import {
	Button,
	Drawer,
	Icon,
	Input,
	Modal,
	BoxExercises,
	DrawerAddExercise,
	DrawerFormExercise
} from 'components'
import {
	CreateSessionInputs,
	DrawerSessionType,
	exerciseType,
	Icons
} from 'interfaces'
import { useQuery } from 'react-query'
import {
	createProgramSession,
	deleteExercise,
	editProgramSession,
	getProgramSession,
	GET_PROGRAM_SESSION
} from 'api'
import Cookies from 'universal-cookie'
import { useDevice, useToast } from 'hooks'
import { Loading } from 'components/loading'
import { useForm } from 'react-hook-form'
import {
	DropResult,
	DragDropContext,
	Droppable,
	Draggable,
	DraggableProvided
} from 'react-beautiful-dnd'

export const DrawerSession: React.FC<DrawerSessionType> = ({
	program,
	sessionInitial,
	setName,
	setOpen,
	refetchProgram
}): JSX.Element => {
	const { showToast } = useToast()
	const cookies = new Cookies()
	const token = cookies.get('token')
	const device = useDevice()
	const [session, setSession] = useState(sessionInitial)
	const { data, refetch, isLoading } = useQuery(
		[GET_PROGRAM_SESSION, session?.slug, token],
		() => getProgramSession(token, session?.slug),
		{
			refetchOnMount: 'always'
		}
	)

	const [exercises, setExercises] = useState<exerciseType[]>([])
	const [slugsExercises, setSlugsExercises] = useState<string[]>([])
	const [exercise, setExercise] = useState<exerciseType>()

	const [showModal, setShowModal] = useState(false)
	const [openDrawer, setOpenDrawer] = useState(false)
	const [openDrawer2, setOpenDrawer2] = useState(false)
	const [slugDel, setSlugDel] = useState('')
	const { register, watch } = useForm<CreateSessionInputs>({
		mode: 'onChange',
		defaultValues: {
			name: session?.name
		}
	})
	const watchName = watch('name')

	useEffect(() => {
		setName(watchName)
	}, [watchName])

	useEffect(() => {
		const slugs = exercises.map(item => item.slug)
		setSlugsExercises(slugs)
	}, [exercises])

	useEffect(() => {
		if (data) setExercises(data.exercises)
	}, [data])

	const handleModalDeletd = (slug: string): void => {
		setSlugDel(slug)
		setShowModal(true)
	}

	const handleDeleteExercise = async (): Promise<void> => {
		if (slugDel !== '') {
			try {
				const res = await deleteExercise(token, slugDel)
				if (res && res === 204) {
					showToast(
						'Delete exercise',
						'exercise was deleted successfully',
						'warning'
					)
					refetch()
				}
			} catch (error) {
				console.error(error)
			}
		}
		setShowModal(false)
	}

	const handleEditExercise = (exerciseF: exerciseType): void => {
		setExercise(exerciseF)
		setOpenDrawer2(true)
	}

	const handleAddExercise = async (): Promise<void> => {
		if (!session?.slug) {
			const res = await createProgramSession(token, program, watchName)
			if (res) {
				setSession(res)
				setOpenDrawer(true)
				showToast(
					'Session Created',
					`${watchName} was created successfully`,
					'success'
				)
			} else {
				showToast(
					'Error Session',
					'Error: Name session may not be blank.',
					'error'
				)
			}
		} else {
			setOpenDrawer(true)
		}
	}

	const saveSession = async (): Promise<void> => {
		if (session) {
			const res = await editProgramSession(
				token,
				session.slug,
				watchName,
				slugsExercises
			)
			if (res) {
				refetch()
				refetchProgram()
				setOpen(false)
				showToast(
					'Edited session',
					'session was edited successfully',
					'success'
				)
			} else {
				refetch()
				refetchProgram()
				setOpen(false)
				showToast('Edited session', 'session must have exercises', 'error')
			}
		}
	}

	const onDragEnd = (result: DropResult): void => {
		const { source, destination } = result
		if (!destination) return

		const items = Array.from(exercises)
		const [newOrder] = items.splice(source.index, 1)
		items.splice(destination.index, 0, newOrder)

		setExercises(items)
	}

	return (
		<>
			{isLoading ? (
				<Loading />
			) : (
				<div className={clsx('h-full relative')} aria-hidden="true">
					<div
						className={clsx(
							'bg-gradient-to-r from-[#454952] to-[#141c1f] text-white relative h-[112px] p-4'
						)}
					>
						<h2 className="font-Roboto text-sm text-custom text-center">
							Session Name
						</h2>
						<div className="flex items-center justify-center">
							<div className="w-[260px]">
								<Input
									id="field-name-session"
									name="name"
									register={register}
									// placeholder={
									// 	session?.slug === '' ? 'Name of session' : session?.name
									// }
									className="mb-1 text-center"
									transparent
									strech
									fontSize="large"
									align="center"
									maxLength={50}
								/>
							</div>
							<label htmlFor="field-name-session" className="cursor-pointer">
								<div
									className={clsx(
										'max-h-[22px] max-w-[16px]',
										'flex items-center justify-center'
									)}
								>
									<Icon
										fillPath
										className={clsx(
											'h-[22px] w-[22px] text-white hover:text-custom'
										)}
										src={Icons.pencil}
									/>
								</div>
							</label>
						</div>
						<div
							className={clsx(
								'bg-[#384147] flex items-center justify-center w-[240px] h-[32px]',
								'absolute left-0 right-0 ml-auto mr-auto -bottom-4',
								'shadow-[-3px_0px_4px_2px_rgba(0,0,0,0.1)] rounded-[5px]'
							)}
						>
							<span className="font-Roboto text-white text-sm">{`${exercises.length} Exercises`}</span>
						</div>
					</div>
					<div
						className={clsx(
							'p-4',
							'min-h-[calc(100vh-255px)] max-h-[calc(100vh-255px)]',
							'md:min-h-[calc(100vh-245px)] md:max-h-[calc(100vh-245px)]'
						)}
					>
						<div className="h-[1px] w-full bg-gray-300 mt-2" />
						<div className="w-full px-4 py-3">
							<Button
								color="Secondary"
								size="full"
								type="button"
								label="+ Add an exercise"
								fill={false}
								onClick={() => handleAddExercise()}
							/>
						</div>
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId="container-exercises">
								{dropProvided => (
									<div
										{...dropProvided.droppableProps}
										ref={dropProvided.innerRef}
										className={clsx(
											'flex flex-col gap-2',
											{
												'min-h-[calc(100vh-363px)] max-h-[calc(100vh-363px)]':
													device === 'web'
											},
											{
												'min-h-[calc(100vh-413px)] max-h-[calc(100vh-413px)]':
													device === 'android' || device === 'ios'
											},
											'overflow-hidden hide-scroll-bar overflow-y-scroll',
											'md:min-h-[calc(100vh-350px)] md:max-h-[calc(100vh-350px)]'
										)}
									>
										{exercises.map((item, index) => {
											const pos = index
											return (
												<Draggable
													key={`${pos}-${item.slug}`}
													draggableId={item.slug}
													index={index}
												>
													{(dragProvided: DraggableProvided) => (
														<BoxExercises
															innerRef={dragProvided.innerRef}
															dragProvided={dragProvided}
															exercise={item}
															deleted={handleModalDeletd}
															edit={handleEditExercise}
														/>
													)}
												</Draggable>
											)
										})}
										{dropProvided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</div>
					<div
						className={clsx(
							'absolute bottom-0 left-0 right-0',
							'shadow-[0px_2px_5px_3px_rgba(0,0,0,0.2)] bg-white flex items-center justify-center'
						)}
					>
						<div className="w-full px-4 py-3">
							<Button
								customColor
								size="full"
								type="button"
								label="Save session"
								disabled={!watchName || !exercises.length}
								onClick={() => saveSession()}
							/>
						</div>
					</div>
					<Modal
						type="danger"
						open={showModal}
						setOpen={(val: boolean) => setShowModal(val)}
						handleClick={() => handleDeleteExercise()}
					>
						<>
							<h3 className="text-lg leading-6 font-medium text-gray-900">
								Delete
							</h3>
							<div className="mt-2">
								<p className="text-sm text-gray-500">
									Do you Want to delete these exercise?
								</p>
							</div>
						</>
					</Modal>
				</div>
			)}

			<Drawer
				open={openDrawer}
				setOpen={(val: boolean) => setOpenDrawer(val)}
				title="ADD EXERCISE"
				mobileFull
				stretch
			>
				<DrawerAddExercise
					session={session}
					refetchUp={refetch}
					refetchProgram={refetchProgram}
					setOpen={(val: boolean) => setOpenDrawer(val)}
				/>
			</Drawer>
			<Drawer
				open={openDrawer2}
				setOpen={(val: boolean) => setOpenDrawer2(val)}
				title="EDIT EXCERCISE"
				stretch
				mobileFull
			>
				<DrawerFormExercise
					typeForm="editSession"
					session={session}
					refetchUp={refetch}
					setOpen={(val: boolean) => setOpenDrawer2(val)}
					exercise={exercise}
				/>
			</Drawer>
		</>
	)
}
