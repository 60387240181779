import React from 'react'
import { Layout, Tabs } from 'components'
import { TabExercises, TabPrograms } from 'pages'

export const Programs = (): JSX.Element => {
	const options = [
		{ name: 'Programs', panel: TabPrograms },
		{ name: 'Exercises', panel: TabExercises }
	]
	return (
		<Layout stretch>
			<Tabs tabs={options} />
		</Layout>
	)
}
