import {
	ExclamationCircleIcon,
	EyeIcon,
	EyeOffIcon
} from '@heroicons/react/solid'
import clsx from 'clsx'
import { InputProps } from 'interfaces'
import React from 'react'

export const Input: React.FC<
	InputProps & React.InputHTMLAttributes<HTMLInputElement>
> = ({
	name,
	title,
	register,
	rules,
	error,
	rightIcon,
	leftIcon,
	rightClick,
	leftClick,
	showEye,
	rounded = false,
	squared = false,
	asterisk = false,
	transparent = false,
	strech = false,
	cursorDisable = false,
	fontSize = 'small',
	className,
	align = 'right',
	type,
	...props
}): JSX.Element => {
	const [isVisible, setIsVisible] = React.useState(false)

	let rulesInput = rules
	if (type === 'email')
		rulesInput = {
			...rules,
			pattern: {
				value: /^[\w-.+]+@([\w-]+.)+[\w-]{2,4}$/,
				message: 'Invalid email address'
			}
		}

	const handleShowPass = (): void => {
		setIsVisible(!isVisible)
	}

	const eyeIcon = isVisible ? (
		<EyeIcon className="text-gray-900 cursor-pointer" />
	) : (
		<EyeOffIcon className="text-gray-800 cursor-pointer" />
	)

	const registerInput = register && register(name, rulesInput)
	return (
		<div className={clsx('flex flex-col w-full h-fit relative', className)}>
			{title && (
				<label
					htmlFor="email-address"
					className={clsx('text-xs mb-1 font-Roboto font-bold', 'md:text-lg')}
				>
					{asterisk && <span className="text-red-700 mr-1">*</span>}
					{title}
				</label>
			)}
			<div className={clsx('relative')}>
				<input
					id={name}
					type={!showEye ? type : isVisible ? 'text' : 'password'}
					autoComplete="email"
					className={clsx(
						'appearance-none block w-full h-full py-2 bg-[transparent]',
						'placeholder-gray-400 font-Roboto',
						!squared && (rounded ? 'rounded-[20px]' : 'rounded-md'),
						{ 'h-[46px]': type === 'number' },
						{ 'border border-gray-300 shadow-sm': !transparent },
						{ 'pr-10': rightIcon || showEye },
						{ 'pl-10': leftIcon },
						{ 'px-3': !strech },
						{ 'text-sm': fontSize === 'small' },
						{ 'text-2xl': fontSize === 'large' },
						{ 'text-2xl xl:text-5xl': fontSize === 'extra-large' },
						{ 'text-center': align === 'center' },
						{ 'text-left': align === 'left' },
						'focus:outline-none font-Roboto',
						'md:text-lg',
						cursorDisable ? 'cursor-not-allowed' : 'cursor-default'
					)}
					{...registerInput}
					{...props}
				/>
				{showEye && (
					<button
						type="button"
						className={clsx('absolute right-4 top-[35%] w-4 h-4 ')}
						onClick={handleShowPass}
					>
						{eyeIcon}
					</button>
				)}
				{rightIcon && !showEye && (
					<button
						type="button"
						className={clsx('absolute right-4 top-[35%] w-4 h-4 ')}
						onClick={rightClick}
					>
						{rightIcon}
					</button>
				)}
				{leftIcon && (
					<button
						type="button"
						className={clsx('absolute left-4 top-[35%] w-4 h-4 ')}
						onClick={leftClick}
					>
						{leftIcon}
					</button>
				)}
			</div>
			{error && error.message && (
				<div className="flex items-center bottom-0 text-red-500">
					<div className="mr-1 w-4 h-4">
						<ExclamationCircleIcon
							className="w-4 h-4 text-red-500"
							aria-hidden="true"
						/>
					</div>
					<h1 className="text-xs">{error.message}</h1>
				</div>
			)}
		</div>
	)
}
