import React from 'react'

// styles
import { Images } from 'interfaces'
import { useNavigate } from 'react-router-dom'
import styles from './notFound.module.scss'

export const NotFound: React.FC = () => {
	const navigate = useNavigate()

	return (
		<section className={styles.main_container}>
			<div className={styles.info_container}>
				<h2 className={styles.title}>This is a wormhole, not a web page.</h2>

				<button
					className={styles.button_back}
					type="button"
					onClick={() => {
						navigate('/dashboard')
					}}
				>
					BACK TO HOME
				</button>
			</div>

			<div className={styles.image_container}>
				<img
					className={styles.notfound_img}
					src={Images.notFound}
					alt="Not Found"
				/>
			</div>
		</section>
	)
}
