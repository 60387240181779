import React, { useContext, useState } from 'react'
import { Button, SimpleLayout, Input, LinkElement } from 'components'
import { useForm, SubmitHandler } from 'react-hook-form'
import { SigninInputs } from 'interfaces'
import Cookies from 'universal-cookie'
import { UserContext } from 'context'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { login } from 'api'
import { useToast } from 'hooks'

export const Signin = (): JSX.Element => {
	const {
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid }
	} = useForm<SigninInputs>({ mode: 'all' })
	const { showToast } = useToast()
	const navigate = useNavigate()
	const cookies = new Cookies()
	const { setUser } = useContext(UserContext)
	const [loading, setLoading] = useState(false)

	const date = new Date()
	const midnight = new Date(
		date.getFullYear(),
		date.getMonth(),
		date.getDate(),
		23,
		59,
		59
	)

	const rules = {
		email: {
			required: { value: true, message: 'This is required' }
		},
		password: {
			required: { value: true, message: 'This is required' }
		}
	}

	const onSubmit: SubmitHandler<SigninInputs> = async data => {
		setLoading(true)
		const res = await login(data.email, data.password)
		if (res?.user) {
			cookies.set('token', res.token, { path: '/', expires: midnight })
			cookies.set('email', res.user.email, { path: '/' })
			setUser({ ...res.user, token: res.token })
			setLoading(false)
			navigate('/')
		} else {
			setLoading(false)
			showToast('Login error', 'Credential invalid', 'error')
		}
	}

	return (
		<SimpleLayout>
			<div className="h-auto flex items-center justify-center">
				<div className="w-full space-y-8">
					<div>
						<h2
							className={clsx(
								'text-center font-bold text-gray-700 font-Roboto text-2xl',
								'md:text-4xl'
							)}
						>
							ATHLETE TRAINING PROGRAM
						</h2>
						<h3
							className={clsx(
								'text-center font-normal text-gray-400 font-Roboto text-sm',
								'md:text-lg'
							)}
						>
							Enter your Upper Hand information below.
						</h3>
					</div>
					<form className="mt-8 block" onSubmit={handleSubmit(onSubmit)}>
						<div className="rounded-md -space-y-px">
							<Input
								name="email"
								title="Email address"
								register={register}
								rules={rules.email}
								placeholder="Email address"
								error={errors.email}
								type="email"
								className="mb-4"
							/>
							<Input
								name="password"
								title="Password"
								register={register}
								rules={rules.email}
								placeholder="Password"
								error={errors.password}
								showEye
								className="mt-3 mb-3"
							/>
						</div>
						<LinkElement
							label="Forgot password"
							linkTo="/forgotpassword"
							className="mt-4 mb-5 md:mb-20"
						/>
						<Button
							label={`${loading ? 'Loading' : 'Log in'}`}
							color="Primary"
							size="full"
							type="submit"
							customColor
							disabled={!isDirty || !isValid}
						/>
					</form>
				</div>
			</div>
		</SimpleLayout>
	)
}
