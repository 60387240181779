export const getPage = (url: string): string => {
	const aux = url.split('page=')
	const page = aux[1]
	return page
}

export const getOffset = (url: string): string => {
	const offset = url.split('offset=')[1].split('&')[0]
	return offset
}

export const getPageNumber = (url: string): number => {
	const aux = url.split('page=')
	const page = aux[1].split('&')[0]
	return parseInt(page, 10)
}
