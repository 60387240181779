import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { InputListProps } from 'interfaces'
import React, { Fragment, useState } from 'react'

export const InputList: React.FC<InputListProps> = ({
	title,
	name,
	rounded = false,
	squared = false,
	asterisk = false,
	cursorDisable = false,
	disableList = false,
	align = 'left',
	fontSize = 'small',
	list,
	defaultValue,
	isMetric = false,
	setItem,
	noBorders,
	isInjury
}): JSX.Element => {
	const [options, setOptions] = useState(defaultValue || list[0])

	return (
		<Listbox
			disabled={disableList}
			value={options}
			onChange={option => {
				setOptions(option)
				setItem?.(option)
			}}
		>
			{({ open }) => (
				<div className={clsx('flex flex-col w-full h-full')}>
					<input type="hidden" id={name} />
					{title && (
						<Listbox.Label
							htmlFor="email-address"
							className={clsx(
								'text-xs mb-1 font-Roboto font-bold',
								'md:text-lg'
							)}
						>
							{asterisk && <span className="text-red-700 mr-1">*</span>}
							{title}
						</Listbox.Label>
					)}
					<div className="relative">
						<Listbox.Button
							className={clsx(
								'appearance-none block w-full px-3 py-3 bg-[transparent]',
								'placeholder-gray-400 font-Roboto',
								!squared && (rounded ? 'rounded-[20px]' : 'rounded-md'),
								`${
									noBorders ? 'border-none' : 'border border-gray-300 shadow-sm'
								}`,
								'focus:outline-none font-Roboto',
								'md:text-lg',
								cursorDisable || disableList
									? 'cursor-not-allowed'
									: 'cursor-default'
							)}
						>
							<span
								className={clsx(
									'block truncate font-Roboto',
									{ 'text-sm': fontSize === 'small' },
									{ 'text-2xl': fontSize === 'large' },
									{ 'text-2xl xl:text-5xl': fontSize === 'extra-large' },
									{ 'text-right': align === 'right' },
									{ 'text-center': align === 'center' },
									{ 'text-left': align === 'left' }
								)}
							>
								{options && isMetric
									? isInjury
										? options?.value
										: options?.value.toUpperCase()
									: options?.name}
							</span>
							<span
								className={clsx(
									'absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'
								)}
							>
								{!disableList && (
									<SelectorIcon
										className="h-5 w-5 text-gray-400"
										aria-hidden="true"
									/>
								)}
							</span>
						</Listbox.Button>

						<Transition
							show={open}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Listbox.Options
								className={clsx(
									'absolute z-[99] mt-1 w-full bg-white shadow-lg max-h-32 rounded-md text-base',
									'border border-gray-300 shadow-sm',
									'ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'
								)}
							>
								<Listbox.Option
									className={({ active }) =>
										clsx(
											active ? 'text-white bg-custom' : 'text-gray-900',
											'cursor-pointer select-none relative py-2 pl-3 pr-1',
											{ 'text-sm': fontSize === 'small' },
											{ 'text-2xl': fontSize === 'large' },
											{ 'text-2xl xl:text-5xl': fontSize === 'extra-large' }
										)
									}
									value="---"
									disabled
								>
									{({ selected, active }) => (
										<>
											<span
												className={clsx(
													selected ? 'font-semibold' : 'font-normal',
													'block truncate'
												)}
											>
												---
											</span>

											{selected ? (
												<span
													className={clsx(
														active ? 'text-white' : 'text-indigo-600',
														'absolute inset-y-0 right-0 flex items-center pr-4'
													)}
												>
													<CheckIcon className="h-5 w-5" aria-hidden="true" />
												</span>
											) : null}
										</>
									)}
								</Listbox.Option>
								{list.map(person => (
									<Listbox.Option
										key={person.id}
										className={({ active }) =>
											clsx(
												active ? 'text-white bg-custom' : 'text-gray-900',
												'cursor-pointer select-none relative py-2 pl-3 pr-1',
												{ 'text-sm': fontSize === 'small' },
												{ 'text-2xl': fontSize === 'large' },
												{ 'text-2xl xl:text-5xl': fontSize === 'extra-large' }
											)
										}
										value={person}
									>
										{({ selected, active }) => (
											<>
												<span
													className={clsx(
														selected ? 'font-semibold' : 'font-normal',
														'block truncate'
													)}
												>
													{person.name.toUpperCase()}
												</span>

												{selected ? (
													<span
														className={clsx(
															active ? 'text-white' : 'text-indigo-600',
															'absolute inset-y-0 right-0 flex items-center pr-4'
														)}
													>
														<CheckIcon className="h-5 w-5" aria-hidden="true" />
													</span>
												) : null}
											</>
										)}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
				</div>
			)}
		</Listbox>
	)
}
