import React, { useState } from 'react'
import { useCustomer, useHorizontalScroll } from 'hooks'
import { PlusIcon, XIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { MetricExcercise } from 'components/metric-excercise'
import {
	handleAddLibraryMetric,
	handleChangeLibraryMetric,
	handleCreateLibrarySet,
	handleDeleteLibraryMetric,
	handleRemoveLibrarySet
} from 'lib'
import { LibrarySetsListProps } from './LibrarySetsList.interface'

export const LibrarySetsList: React.FC<LibrarySetsListProps> = props => {
	const customer = useCustomer()

	// Internal logic of the sets
	const { exercise, listMetrics, setSetsBreakDown, setsBreakDown } = props
	const [currentSet, setCurrentSet] = useState(0)
	const {
		groupsDivRef,
		makeDraggableGroupSection,
		removeDraggableGroupSection
	} = useHorizontalScroll()

	return (
		<section>
			{/* ADD SET BUTTON */}
			<button
				type="button"
				className="bg-custom px-2 rounded text-white font-semibold mt-5"
				onClick={() =>
					handleCreateLibrarySet({
						exercise,
						setsBreakDown,
						setSetsBreakDown
					})
				}
			>
				<span className="flex gap-2 w-[72px] h-[32px] text-lg justify-center">
					Set
					<PlusIcon className="w-4 h-4 my-auto" />
				</span>
			</button>

			{/* SETS NAV */}
			<div
				className="flex whitespace-nowrap overflow-x-auto show-scrollbar-x mb-5"
				ref={groupsDivRef}
				role="menubar"
				tabIndex={0}
				onMouseDown={makeDraggableGroupSection}
				onMouseUp={removeDraggableGroupSection}
				onMouseLeave={removeDraggableGroupSection}
			>
				{setsBreakDown.map((set, idx) => (
					<button
						className=" px-2 rounded text-white font-semibold mt-2 mr-3 mb-1"
						type="button"
						key={set.slug}
						onClick={() => {
							setCurrentSet(idx)
						}}
						style={{
							backgroundColor:
								idx === currentSet ? 'var(--color-custom)' : '#929BB2'
						}}
					>
						<span className="flex gap-2 min-w-[54px] h-[29px] justify-center">
							<p className="text-lg">Set {idx + 1}</p>{' '}
							{idx === currentSet &&
								customer.role.toUpperCase().includes('ADMIN') && (
									<XIcon
										onClick={e => {
											e.stopPropagation()
											handleRemoveLibrarySet({
												slug: set.slug,
												setCurrentSet,
												setsBreakDown,
												setSetsBreakDown
											})
										}}
										className={clsx('w-4 h-4 my-auto rounded-full p-[1px]', {
											'hover:text-red-500': idx === currentSet,
											'hover:bg-gray-300': idx === currentSet
										})}
									/>
								)}
						</span>
					</button>
				))}
				<hr />
			</div>

			{/* SETS RENDER SECTION */}
			<section>
				{setsBreakDown.map((set, idx) => (
					<div key={set.slug}>
						{idx === currentSet && (
							<>
								<div className="flex font-Roboto font-bold my-6 -ml-9 text-center">
									<p className="w-full">Units of mesure</p>
									<p className="w-full">
										<span className="text-custom ml-4">*</span>
										Base value
									</p>
								</div>
								<div className="flex flex-col gap-4 overflow-y-visible">
									{/* SETS METRICS RENDER */}
									{setsBreakDown[currentSet].library_metrics.map(metric => {
										return (
											<div className="mb-2" key={metric.slug}>
												<MetricExcercise
													key={metric.slug}
													slug={metric.slug}
													typeForm="editSession"
													defaultList={metric.type_metric}
													defaultVal={metric.default_value}
													listMetric={listMetrics}
													deleteMetric={() =>
														handleDeleteLibraryMetric({
															setsBreakDown,
															currentSet,
															slug: metric.slug,
															setSetsBreakDown
														})
													}
													changeMetric={(slug, type, value) =>
														handleChangeLibraryMetric({
															slug,
															type,
															value,
															setsBreakDown,
															currentSet,
															setSetsBreakDown
														})
													}
												/>
											</div>
										)
									})}
								</div>
								{/* ADD METRIC BUTTON */}
								<div
									className={clsx(
										'w-full flex items-center justify-center py-2 mt-2',
										'border-2 border-custom rounded-md cursor-pointer',
										'text-custom hover:text-white font-Roboto text-sm underline hover:bg-custom'
									)}
									onClick={() =>
										handleAddLibraryMetric({
											setsBreakDown,
											currentSet,
											setSetsBreakDown
										})
									}
									aria-hidden="true"
								>
									<span>+ Add new metric</span>
								</div>
							</>
						)}
					</div>
				))}
			</section>
		</section>
	)
}
