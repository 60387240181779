/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	BookmarkIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	ClockIcon,
	SearchIcon
} from '@heroicons/react/solid'
import { getInGroups, GET_GROUPS } from 'api'
import clsx from 'clsx'
import { Drawer, Icon, Input, Loading } from 'components'
import { useCustomer } from 'hooks'
import { Icons, InGroupType, SearchInputs } from 'interfaces'
import { getOffset } from 'lib'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useInView } from 'react-intersection-observer'
import { useInfiniteQuery } from 'react-query'
import Select, { SingleValue } from 'react-select'
import Cookies from 'universal-cookie'
import { DrawerCalendar } from '.'

const searchIcon = <SearchIcon className="text-gray-900 cursor-pointer" />

const options = [
	{ value: 'month', label: 'Month' },
	{ value: 'week', label: 'Week' },
	{ value: 'day', label: 'Day' }
]

const customStyles = {
	container: (base: any) => ({
		...base,
		padding: 0,
		width: 140,
		borderRadius: 4,
		border: `2px solid ${process.env.REACT_APP_COLOR}`
	}),
	control: (base: any, state: any) => ({
		...base,
		background: '#FFF',
		border: 'none',
		boxShadow: state.isFocused ? null : null,
		'&:hover': {
			// Overwrittes the different states of border
			borderColor: null
		}
	}),
	singleValue: (base: any) => ({
		...base,
		color: process.env.REACT_APP_COLOR,
		fontSize: 16,
		fontFamily: 'Roboto Condensed, sans-serif',
		fontWeight: 700,
		textAlign: 'center'
	}),
	option: (base: any, state: any) => ({
		...base,
		background: state.isFocused ? process.env.REACT_APP_COLOR : '#FFF',
		color: state.isFocused ? '#FFF' : '#333',
		fontFamily: 'Roboto Condensed, sans-serif',
		fontWeight: 500,
		fontSize: 18,
		'&:hover': {
			borderColor: '#B6B6B6'
		},
		height: 48
	})
}

export interface Filter {
	label: string
	value: string
}

export const DrawerHistorial: React.FC = (): JSX.Element => {
	const customer = useCustomer()
	const cookies = new Cookies()
	const token = cookies.get('token')
	const { ref, inView } = useInView()
	const [yearSelected, setYearSelected] = useState<number>(moment().year())
	const [weekSelected, setWeekSelected] = useState<number>(moment().week())
	const [startWeek, setStartWeek] = useState<string>('')
	const [endWeek, setEndWeek] = useState<string>('')
	const [monthSelected, setMonthSelected] = useState<number>(moment().month())
	const [monthSelect, setMonthSelect] = useState<number | undefined>()
	const [daySelected, setDaySelected] = useState<Date>(moment().toDate())
	const [dateSelect, setDateSelect] = useState<Date | null>(null)

	const [keyDate, setKeyDate] = useState<string>()

	const { register, watch } = useForm<SearchInputs>({ mode: 'onChange' })
	const watchSearch = watch('search', '')

	const [filter, setFilter] = useState<Filter>({
		label: 'Month',
		value: 'month'
	})

	const [openDrawer, setOpenDrawer] = useState(false)
	const [groupSlug, setGroupSlug] = useState<string>('')
	const [inGroups, setInGroups] = useState<InGroupType[]>([])

	useEffect(() => {
		setMonthSelect(moment().month())
	}, [])

	const { data, isFetching, isFetchingNextPage, fetchNextPage, refetch } =
		useInfiniteQuery(
			[
				GET_GROUPS,
				token,
				customer,
				keyDate,
				monthSelect,
				startWeek,
				endWeek,
				watchSearch
			],
			({ pageParam = 0 }) =>
				getInGroups({
					customerId: customer.id,
					date: keyDate || '',
					month: monthSelect ? monthSelect + 1 : undefined,
					startWeek,
					endWeek,
					search: watchSearch,
					page: pageParam,
					limit: 10
				}),
			{
				getNextPageParam: lastPage =>
					lastPage?.next ? getOffset(lastPage?.next) : undefined
			}
		)

	useEffect(() => {
		if (data?.pages) {
			let auxData = [] as InGroupType[]
			data.pages.forEach(page => {
				const auxResults = page?.results as unknown as InGroupType[]
				if (auxResults) auxData = [...auxData, ...auxResults]
			})
			setInGroups(auxData)
		}
	}, [data])

	const onChange = (
		newValue: SingleValue<{
			value: string
			label: string
		}>
	): void => {
		if (newValue)
			setFilter({
				label: newValue?.label,
				value: newValue?.value
			})
	}

	const handlePrev = (): void => {
		switch (filter.value) {
			case 'month':
				setYearSelected(prev => {
					if (monthSelected === 0) return prev - 1
					return prev
				})
				setMonthSelected(prev => {
					if (prev === 0) return 11
					return prev - 1
				})
				break
			case 'week':
				setYearSelected(prev => {
					if (weekSelected === 0) return prev - 1
					return prev
				})
				setWeekSelected(prev => {
					if (prev === 0) return 53
					return prev - 1
				})
				setYearSelected(prev => {
					if (weekSelected === 0) {
						return prev - 1
					}
					return prev
				})
				break
			case 'day':
				setDaySelected(prev => moment(prev).subtract(1, 'd').toDate())
				break

			default:
				setYearSelected(prev => {
					if (monthSelected === 0) return prev - 1
					return prev
				})
				setMonthSelected(prev => {
					if (prev === 0) return 11
					return prev - 1
				})
				break
		}
	}

	const handleNext = (): void => {
		switch (filter.value) {
			case 'month':
				setYearSelected(prev => {
					if (monthSelected === 11) return prev + 1
					return prev
				})
				setMonthSelected(prev => {
					if (prev === 11) return 0
					return prev + 1
				})
				break
			case 'week':
				setYearSelected(prev => {
					if (weekSelected === 53) return prev + 1
					return prev
				})
				setWeekSelected(prev => {
					if (prev === 53) return 0
					return prev + 1
				})
				break
			case 'day':
				setDaySelected(prev => moment(prev).add(1, 'd').toDate())
				break

			default:
				setYearSelected(prev => {
					if (monthSelected === 11) return prev + 1
					return prev
				})
				setMonthSelected(prev => {
					if (prev === 11) return 0
					return prev + 1
				})
				break
		}
	}

	useEffect(() => {
		switch (filter.value) {
			case 'month':
				setMonthSelect(monthSelected)
				setStartWeek('')
				setEndWeek('')
				setKeyDate('')
				break
			case 'week':
				setStartWeek(
					moment().day('sunday').week(weekSelected).format('yyyy-MM-DD')
				)
				setEndWeek(
					moment().day('saturday').week(weekSelected).format('yyyy-MM-DD')
				)
				setMonthSelect(undefined)
				setKeyDate('')
				break
			case 'day':
				setKeyDate(moment(daySelected).format('yyyy-MM-DD'))
				setMonthSelect(undefined)
				setStartWeek('')
				setEndWeek('')
				break
			default:
				setMonthSelect(monthSelected)
				setStartWeek('')
				setEndWeek('')
				setKeyDate('')
				break
		}
	}, [filter, daySelected, weekSelected, monthSelected])

	useEffect(() => {
		if (inView) fetchNextPage()
	}, [inView])

	return (
		<div className="w-full h-full">
			<section className="w-full flex justify-between p-4">
				<button
					type="button"
					onClick={() => handlePrev()}
					className="rounded-md group hover:bg-custom"
				>
					<ChevronLeftIcon className="w-6 h-full text-neutral-600 group-hover:text-white" />
				</button>
				<p className="w-[240px] text-lg text-center text-white font-Roboto font-bold bg-custom rounded-md">
					{(() => {
						switch (filter.value) {
							case 'month':
								return `${moment()
									.month(monthSelected)
									.format('MMMM')} ${yearSelected}`
								break
							case 'week':
								return `${moment()
									.day('sunday')
									.week(weekSelected)
									.format('MMM DD')} - ${moment()
									.day('saturday')
									.week(weekSelected)
									.format('MMM DD')}, ${yearSelected}`
								break
							case 'day':
								return `${moment(daySelected).format('MMMM Do')}`
								break

							default:
								return `${monthSelected} ${yearSelected}`
								break
						}
					})()}
				</p>
				<button
					type="button"
					onClick={() => handleNext()}
					className="rounded-md group hover:bg-custom"
				>
					<ChevronRightIcon className="w-6 h-full text-neutral-600 group-hover:text-white" />
				</button>
			</section>
			<section className="w-full flex justify-between items-center px-4">
				<p className="w-28 text-base font-Roboto font-bold">Session list</p>
				<Select
					options={options}
					onChange={val => onChange(val)}
					value={filter}
					styles={customStyles}
					components={{
						IndicatorSeparator: () => null
					}}
					isSearchable={false}
				/>
			</section>
			<section className="w-full p-4">
				<Input
					name="search"
					register={register}
					placeholder="Search group"
					type="text"
					className=""
					leftIcon={searchIcon}
					rounded
				/>
			</section>
			<section
				className={clsx(
					'w-full min-h-[calc(100vh-250px)] max-h-[calc(100vh-250px)] overflow-hidden overflow-y-scroll hide-scroll-bar px-4 pb-5'
				)}
			>
				{isFetching ? (
					<div className="flex items-center justify-center max-h-[70vh]">
						<Loading />
					</div>
				) : (
					<div>
						{inGroups.length > 0 ? (
							<>
								{inGroups.map(group => (
									<button
										type="button"
										key={group.slug}
										className="w-full py-2 cursor-pointer rounded-lg"
										onClick={() => {
											setDateSelect(group.date)
											setGroupSlug(group.slug)
											setOpenDrawer(true)
											console.warn('click')
										}}
									>
										<div
											className={clsx(
												'w-full max-h-9 h-9 flex justify-between items-center px-2',
												'shadow-md rounded-lg border border-[#929BB2]'
											)}
										>
											<div className="w-full flex justify-start items-center gap-1 ">
												<span className="w-[76px] min-w-[76px] flex gap-1 items-center">
													<Icon
														src={Icons.schedule}
														fillPath
														className="w-5 h-5 text-custom"
													/>
													<p className="text-base text-left text-[#393D44] font-Roboto lg:text-lg">
														{moment(group.date).format('MMM DD')}
													</p>
												</span>
												<span
													className={clsx(
														'w-[112px] min-w-[112px] md:w-[118px] md:min-w-[118px] flex gap-1 items-center'
													)}
												>
													<ClockIcon className="w-5 h-5 text-custom" />
													<p className="text-base text-left text-[#393D44] font-Roboto lg:text-lg">
														{moment(group.start_at).format('HH:mm')}-
														{moment(group.finish_at).format('HH:mm')}
													</p>
												</span>
												<span className="w-auto flex items-center">
													<BookmarkIcon className="w-5 h-5 min-w-5 max-w-5 text-custom" />
													<p className="text-base text-left text-[#393D44] font-Roboto lg:text-lg line-clamp-1">
														{group.name.length > 17
															? `${group.name.slice(0, 17)}...`
															: group.name}
													</p>
												</span>
											</div>
										</div>
									</button>
								))}
							</>
						) : (
							<div className="w-full h-full py-2 text-custom text-base text-center border border-custom rounded-md">
								Empty list
							</div>
						)}
					</div>
				)}
				<div className="flex items-center justify-center mt-2">
					<div ref={ref}>{isFetchingNextPage && <Loading isSmall />}</div>
				</div>
				<Drawer
					open={openDrawer}
					setOpen={(val: boolean) => setOpenDrawer(val)}
					title={`Group Session - ${moment(dateSelect)
						.utc()
						.format('ddd, DD MMMM')}`}
					stretch
					mobileFull
				>
					<DrawerCalendar
						type="historial"
						dateSelected={moment(dateSelect).utc().format('yyyy-MM-DD')}
						groupSlug={groupSlug}
						setOpenDrawer={setOpenDrawer}
						handleRefetch={() => refetch()}
					/>
				</Drawer>
			</section>
		</div>
	)
}
