import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { RequireUserProps } from './provider.interface'

export const RequireUser: React.FC<RequireUserProps> = ({
	children,
	isClient
}) => {
	const location = useLocation()
	const cookies = new Cookies()
	// cookies.remove('token')
	const token = cookies.get('token')

	if (!token) {
		return <Navigate to="/signin" state={{ from: location }} replace />
	}

	if (isClient) {
		return <Navigate to="/notFound" state={{ from: location }} replace />
	}

	return children
}
