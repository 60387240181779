import clsx from 'clsx'
import React from 'react'
import { InfoItemProps } from './infoItem.interface'

export const InfoItem: React.FC<InfoItemProps> = props => {
	const { title, value, imageSrc, className, align } = props

	return (
		<div
			className={clsx(
				'flex gap-4 items-center w-full lg:max-w-max text-white',
				className
			)}
		>
			{imageSrc && (
				<img
					src={imageSrc}
					className="w-11 h-11 object-contain rounded-full"
					alt="information coach or gym logo"
				/>
			)}
			<div
				className={clsx(
					'flex flex-col w-full',
					align === 'center' ? 'items-center' : 'items-start'
				)}
			>
				<span>{title}</span>
				<span className="font-bold text-xl">{value}</span>
			</div>
		</div>
	)
}

export const InfoItemMobile: React.FC<InfoItemProps> = props => {
	const { title, value, imageSrc, className } = props

	return (
		<div
			className={clsx(
				'flex flex-col gap-2 items-center w-full lg:max-w-max lg:items-start text-white',
				className
			)}
		>
			<span>{title}</span>
			{imageSrc && (
				<img
					src={imageSrc}
					className="w-11 h-11 object-contain bg-white rounded-full"
					alt="information coach or gym logo"
				/>
			)}
			<span className="font-bold text-xl">{value}</span>
		</div>
	)
}
