/* eslint-disable jsx-a11y/no-autofocus */
import React, { useRef, useState } from 'react'
import { PaperClipIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import { Button, Loading } from 'components'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { editNote, getListNotes, GET_LIST_NOTES } from 'api'
import { AnnotationType, NotesEditProps } from 'interfaces'
import Cookies from 'universal-cookie'
import clsx from 'clsx'
import moment from 'moment'
import { useClickOutside, useToast } from 'hooks'

export const NotesEdit: React.FC<NotesEditProps> = ({
	idNote,
	idSelected,
	setEditNotes,
	refetch
}): JSX.Element => {
	const ref = useRef(null)
	const { showToast } = useToast()
	const cookies = new Cookies()
	const token = cookies.get('token')

	const [annotation, setAnnotation] = useState<AnnotationType[]>([])

	useClickOutside(ref, () => setEditNotes(false))

	const { data, isLoading } = useQuery(
		[GET_LIST_NOTES],
		() => getListNotes(token, idSelected),
		{
			refetchOnMount: 'always'
		}
	)

	const { register, handleSubmit, setValue } = useForm<AnnotationType>({
		defaultValues: {
			priority: annotation[0]?.priority
		}
	})

	React.useEffect(() => {
		if (data) {
			setAnnotation(data?.filter(item => item.id === idNote))
		}
	}, [data])

	React.useEffect(() => {
		if (annotation) {
			setValue('title', annotation[0]?.title)
			setValue('description', annotation[0]?.description)
			setValue('priority', annotation[0]?.priority)
			setValue('session', annotation[0]?.session)
		}
	}, [annotation])

	const onSubmit: SubmitHandler<AnnotationType> = async data2 => {
		const body = {
			title: data2.title,
			description: data2.description,
			priority: data2.priority,
			session: idSelected
		}

		try {
			await editNote(token, idNote, body)
			showToast('Edit note', 'Note was edited successfully', 'success')
			refetch()
			setEditNotes(false)
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<form
			ref={ref}
			onSubmit={handleSubmit(onSubmit)}
			className="w-full h-full flex flex-col justify-center items-center p-5"
		>
			{isLoading ? (
				<Loading />
			) : (
				<>
					<header className="w-full h-[56px] flex justify-between items-center rounded-t-lg">
						<div className="w-6 h-8">
							<PaperClipIcon className="w-full h-full text-[#B5BACE]" />
						</div>
						<div className="w-full flex justify-between items-center pl-3">
							<div className="w-full flex flex-col justify-center items-start gap-1">
								<h2 className="w-full font-Roboto text-[#454952] text-[22px] leading-[25px] font-medium text-left">
									{annotation[0]?.title}
								</h2>
								<span
									className={clsx(
										'w-[120px] font-Roboto text-[#9A9EAD] text-[18px] leading-[18px] font-normal text-left'
									)}
								>
									{moment(annotation[0]?.created_at).format('MM/DD/YYYY')}
								</span>
							</div>
							<div className="w-[120px] flex flex-col justify-center items-center gap-2 pr-3">
								<p className="font-Roboto text-[#9A9EAD] text-[18px] leading-[18px] font-normal">
									Priority
								</p>
								<div className="flex gap-1">
									<input
										type="radio"
										value="Low"
										className="accent-green-600 cursor-pointer"
										{...register('priority')}
									/>
									<input
										type="radio"
										value="Mid"
										className="accent-yellow-300 cursor-pointer"
										{...register('priority')}
									/>
									<input
										type="radio"
										value="High"
										className="accent-red-500 cursor-pointer"
										{...register('priority')}
									/>
								</div>
							</div>
						</div>
						<button
							type="button"
							className="w-6 h-6 cursor-pointer"
							onClick={() => setEditNotes(false)}
						>
							<XIcon className="w-full h-full text-[#B5BACE]" />
						</button>
					</header>
					<div className="w-full h-[325px] flex flex-col justify-center items-center">
						<div className="w-full h-full flex flex-col justify-center items-center gap-3 pb-3">
							<textarea
								id="description"
								autoFocus
								placeholder={annotation[0]?.description}
								maxLength={255}
								className="w-full h-full p-2 border border-gray-200 rounded resize-none"
								{...register('description')}
							/>
						</div>
						<div className="w-full flex items-center justify-center gap-9">
							<Button
								label="Cancel"
								fill={false}
								customColor
								className="min-w-[140px] w-full lg:w-[179px] h-8 cursor-pointer bg-white text-custom border border-custom"
								onClick={() => setEditNotes(false)}
							/>
							<Button
								type="submit"
								label="Edit"
								customColor
								className="min-w-[140px] w-full lg:w-[179px] h-8 cursor-pointer"
							/>
						</div>
					</div>
				</>
			)}
		</form>
	)
}
