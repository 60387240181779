import clsx from 'clsx'
import { InGroupType } from 'interfaces'
import React, { useCallback, useRef } from 'react'

export interface HeaderCalendarProps {
	type: 'general' | 'new-group' | 'edit' | 'historial'
	groupSlug: string
	slugGroup: string
	setSlugGroup: React.Dispatch<React.SetStateAction<string>>
	setPosGroup: React.Dispatch<React.SetStateAction<number>>
	groups: InGroupType[]
	handleNewGroup: () => void
	watchName: string
}
export const HeaderCalendar: React.FC<HeaderCalendarProps> = ({
	type,
	groupSlug,
	slugGroup,
	setSlugGroup,
	setPosGroup,
	groups,
	handleNewGroup,
	watchName
}): JSX.Element => {
	const groupsDivRef = useRef<HTMLDivElement>(null)

	const moveGroupsSection = (movement: number): void => {
		const { current } = groupsDivRef
		if (!current) return
		current.scrollLeft += current.clientLeft - movement
	}
	const handleMouseMove = (e: MouseEvent): void => {
		moveGroupsSection(e.movementX)
	}
	const makeDraggableGroupSection = useCallback(() => {
		const { current } = groupsDivRef
		if (!current) return
		current.addEventListener('mousemove', handleMouseMove)
	}, [])
	const removeDraggableGroupSection = useCallback(() => {
		const { current } = groupsDivRef
		if (!current) return
		current.removeEventListener('mousemove', handleMouseMove)
	}, [])

	return (
		<header className="w-full">
			{(() => {
				switch (type) {
					case 'general':
						return (
							<div className="w-full flex flex-col">
								<button
									type="button"
									className={clsx(
										'flex justify-center items-center gap-2',
										'min-w-[124px] max-w-[124px] h-7 px-3 text-center text-[18px] leading-[21px]',
										'text-white font-bold font-Roboto rounded-md bg-custom',
										'border border-custom md: border-none'
									)}
									onClick={() => handleNewGroup()}
								>
									+ new group
								</button>
								<div className="w-full flex items-center gap-2 py-2">
									<section
										className={clsx(
											'w-full flex gap-4 pb-1 overflow-y-hidden overflow-x-scroll show-scrollbar-x'
										)}
										ref={groupsDivRef}
										role="menubar"
										tabIndex={0}
										onMouseDown={makeDraggableGroupSection}
										onMouseUp={removeDraggableGroupSection}
										onMouseLeave={removeDraggableGroupSection}
									>
										{groups?.map((item, idx) => (
											<button
												type="button"
												key={item?.slug}
												className={clsx(
													'min-w-[124px] max-w-[124px] h-7 px-3 rounded-md line-clamp-1 truncate',
													'text-[18px] leading-[21px] text-white font-bold font-Roboto',
													slugGroup === item?.slug
														? 'bg-custom'
														: 'bg-[#929BB2]'
												)}
												onClick={() => {
													setSlugGroup(item.slug)
													// setIndexGroup(item.id)
													setPosGroup(idx)
												}}
											>
												{item?.name}
											</button>
										))}
									</section>
								</div>
							</div>
						)
					case 'edit':
						return (
							groupSlug && (
								<p
									className={clsx(
										'w-full text-[18px] leading-[21px] text-white text-center font-bold font-Roboto',
										'bg-custom rounded-md py-2 mb-3'
									)}
								>
									{groups.filter(grp => grp?.slug === groupSlug)[0]?.name}
								</p>
							)
						)
					case 'historial':
						return (
							groupSlug && (
								<p
									className={clsx(
										'w-full text-[18px] leading-[21px] text-white text-center font-bold font-Roboto',
										'bg-custom rounded-md py-2 mb-3'
									)}
								>
									{groups.filter(grp => grp.slug === groupSlug)[0]?.name}
								</p>
							)
						)
					case 'new-group':
						return (
							<p
								className={clsx(
									'w-full text-[18px] leading-[21px] text-white text-center font-bold font-Roboto',
									'bg-custom rounded-md py-2 mb-3'
								)}
							>
								{watchName?.length === 0 ? 'New Group' : watchName}
							</p>
						)
					default:
						return (
							<div className="w-full flex flex-col">
								<button
									type="button"
									className={clsx(
										'flex justify-center items-center gap-2',
										'min-w-[124px] max-w-[124px] h-7 px-3 text-center text-[18px] leading-[21px]',
										'text-white font-bold font-Roboto rounded-md bg-custom',
										'border border-custom md: border-none'
									)}
									onClick={() => handleNewGroup()}
								>
									+ new group
								</button>
								<div className="w-full flex items-center gap-2 py-2">
									<section
										className={clsx(
											'w-full flex gap-4 pb-1 overflow-y-hidden overflow-x-scroll show-scrollbar-x'
										)}
										ref={groupsDivRef}
										role="menubar"
										tabIndex={0}
										onMouseDown={makeDraggableGroupSection}
										onMouseUp={removeDraggableGroupSection}
										onMouseLeave={removeDraggableGroupSection}
									>
										{groups?.map((item, idx) => (
											<button
												type="button"
												key={item?.slug}
												className={clsx(
													'min-w-[124px] max-w-[124px] h-7 px-3 rounded-md',
													'text-[18px] leading-[21px] text-white font-bold font-Roboto',
													slugGroup === item?.slug
														? 'bg-custom'
														: 'bg-[#929BB2]'
												)}
												onClick={() => {
													setSlugGroup(item.slug)
													// setIndexGroup(item.id)
													setPosGroup(idx)
												}}
											>
												{item?.name}
											</button>
										))}
									</section>
								</div>
							</div>
						)
				}
			})()}
			<div className="w-full h-[1px] bg-gray-300 px-4 mb-2" />
		</header>
	)
}
