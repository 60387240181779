import { BaseURL } from 'api'
import axios from 'axios'
import {
	AssignedExecutedMetricType,
	AssignedMetricsExecutedResponse
} from 'interfaces'

export const createMetricExecuted = async (
	token: string,
	body: {
		assigned_program_slug: string | undefined
		assigned_executed_metrics: (AssignedExecutedMetricType | undefined)[]
	}
): Promise<AssignedMetricsExecutedResponse | null> => {
	let res = null as AssignedMetricsExecutedResponse | null
	await axios
		.post(`${BaseURL}/assigned_executed_metrics/`, body, {
			headers: {
				Authorization: `Token ${token}`
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
