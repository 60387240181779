import React, { useState } from 'react'
import { EyeIcon, TrashIcon } from '@heroicons/react/outline'
import { PaperClipIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { Modal } from 'components'
import { deleteNote } from 'api'
import { ItemNotesProps } from 'interfaces'
import Cookies from 'universal-cookie'
import moment from 'moment'
import { useToast } from 'hooks'

export const ItemNotes: React.FC<ItemNotesProps> = ({
	id,
	title,
	date,
	priority,
	session,
	setIdNote,
	setEditNotes,
	setIdSelected,
	refetch
}): JSX.Element => {
	const { showToast } = useToast()
	const cookies = new Cookies()
	const token = cookies.get('token')

	const [showModal, setShowModal] = useState(false)

	const handleDeleteNote = async (): Promise<void> => {
		try {
			const res = await deleteNote(token, id)
			if (res && res === 204) {
				showToast('Delete note', 'Note was deleted successfully', 'warning')
				refetch()
			}
		} catch (error) {
			console.error(error)
		}
		setShowModal(false)
	}

	return (
		<div className="w-full flex justify-center items-center px-3 py-2">
			<div className="w-6 h-10 relative">
				<div
					className={clsx(
						'inline-flex absolute top-3 right-0 justify-center items-center w-2 h-2 rounded-full',
						priority === 'High'
							? 'bg-red-500'
							: priority === 'Mid'
							? 'bg-yellow-300'
							: priority === 'Low'
							? 'bg-green-500'
							: '' // priority
					)}
				/>
				<PaperClipIcon className="w-full h-full text-[#B5BACE]" />
			</div>
			<div className="w-full flex flex-col justify-center items-start gap-1 pl-3">
				<h2 className="w-full font-Roboto text-[#454952] text-[22px] leading-[25px] font-medium text-left">
					{title}
				</h2>
				<h3 className="w-[120px] font-Roboto text-[#9A9EAD] text-[18px] leading-[18px] font-normal text-left">
					{moment(date).format('MM/DD/YYYY')}
				</h3>
			</div>
			<div className="flex justify-center items-center gap-4">
				<div
					className="w-6 h-6 cursor-pointer"
					aria-hidden="true"
					onClick={() => {
						setIdNote(id)
						setIdSelected(session)
						setEditNotes(true)
					}}
				>
					<EyeIcon className="w-full h-full text-[#B5BACE] hover:text-custom" />
				</div>
				<div
					className="w-6 h-6 cursor-pointer"
					aria-hidden="true"
					onClick={() => setShowModal(true)}
				>
					<TrashIcon className="w-full h-full text-[#B5BACE] hover:text-custom" />
				</div>
				<Modal
					type="danger"
					open={showModal}
					setOpen={(val: boolean) => setShowModal(val)}
					handleClick={() => handleDeleteNote()}
				>
					<>
						<h3 className="text-lg leading-6 font-medium text-gray-900">
							Delete
						</h3>
						<div className="mt-2">
							<p className="text-sm text-gray-500">
								Do you Want to delete these note?
							</p>
						</div>
					</>
				</Modal>
			</div>
		</div>
	)
}
