import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { athleteType, Icons, SearchInputs } from 'interfaces'
import {
	Button,
	Icon,
	Input,
	Layout,
	Loading,
	BoxAthletes,
	DrawerAssignProgram,
	// Drawer2,
	Drawer
} from 'components'
import { SearchIcon } from '@heroicons/react/solid'
import { useInfiniteQuery } from 'react-query'
import { useCustomer, useDevice } from 'hooks'
import { useInView } from 'react-intersection-observer'
import { useForm } from 'react-hook-form'
import { GET_ATHLETES, getClients } from 'api'
import { getPageNumber } from 'lib'
import Cookies from 'universal-cookie'
import { ClipboardIcon } from '@heroicons/react/outline'

const searchIcon = <SearchIcon className="text-gray-900 cursor-pointer" />

export const Athletes = (): JSX.Element => {
	const customer = useCustomer()
	const { ref, inView } = useInView()
	const { register, watch } = useForm<SearchInputs>({ mode: 'onChange' })
	const watchSearch = watch('search', '')
	const [search, setSearch] = useState<string | undefined>(undefined)
	const [athletes, setAthletes] = useState<athleteType[] | undefined>([])
	const [openDrawer, setOpenDrawer] = useState(false)
	const device = useDevice()

	const cookies = new Cookies()
	const token = cookies.get('token')

	const { data, isLoading, isFetchingNextPage, fetchNextPage, refetch } =
		useInfiniteQuery(
			[GET_ATHLETES, search, customer.id, token],
			({ pageParam = 1 }) => getClients(token, customer.id, pageParam, search),
			{
				getNextPageParam: lastPage =>
					lastPage?.next ? getPageNumber(lastPage?.next) : undefined
			}
		)

	useEffect(() => {
		if (data && data.pages) setAthletes(data?.pages[0]?.results)
	}, [data])

	useEffect(() => {
		if (data?.pages) {
			let auxData = [] as athleteType[]
			data.pages.forEach(page => {
				const auxResults = page?.results
				if (auxResults) auxData = [...auxData, ...auxResults]
			})
			setAthletes(auxData)
		}
	}, [data])

	useEffect(() => {
		setSearch(watchSearch)
		setAthletes([])
	}, [watchSearch])

	useEffect(() => {
		if (inView) fetchNextPage()
	}, [inView])

	const handleAssignProgram = (): void => {
		setOpenDrawer(true)
	}

	return (
		<Layout stretch>
			<>
				<div
					className={clsx('px-6 grid grid-cols-1', 'lg:grid-cols-1', {
						'overflow-hidden': openDrawer
					})}
				>
					<div className="flex flex-col xl:flex-row-reverse xl:justify-between">
						{customer.role !== 'Client' && (
							<Button
								customColor
								size="full"
								type="button"
								className="mt-2 xl:mt-12 h-[48px]"
								onClick={() => handleAssignProgram()}
							>
								<div className="flex items-center justify-center">
									<p className="font-Roboto font-semibold text-base mr-2">
										Assign program to athletes
									</p>
									<div className="pt-1">
										<Icon
											className={clsx('h-[28px] w-[30px] text-custom')}
											src={Icons.plus}
											fillPath
										/>
									</div>
								</div>
							</Button>
						)}
						<div className="w-full">
							<div className="h-[1px] w-full bg-gray-300 mt-1 visible lg:invisible" />
							<p className="font-Roboto font-bold text-xl lg:text-2xl my-2">
								Athletes Programs
							</p>
							<Input
								name="search"
								register={register}
								placeholder="Search athlete"
								type="text"
								className="mb-4 lg:col-span-3 lg:pr-2"
								leftIcon={searchIcon}
								rounded
							/>
						</div>
					</div>
					<div className="h-[1px] w-full bg-gray-300 mt-1 lg:visible" />
				</div>
				<div>
					{isLoading ? (
						<div
							className={clsx(
								'flex flex-col justify-between items-center',
								{
									'min-h-[calc(100vh-320px)] max-h-[calc(100vh-320px)]':
										device === 'web'
								},
								{
									'min-h-[calc(100vh-370px)] max-h-[calc(100vh-370px)]':
										device === 'android' || device === 'ios'
								},
								'md:min-h-[calc(100vh-258px)] md:max-h-[calc(100vh-258px)]',
								'xl:min-h-[calc(100vh-210px)] xl:max-h-[calc(100vh-210px)]'
							)}
						>
							<Loading />
						</div>
					) : (
						<div
							className={clsx(
								'flex flex-col px-6 py-2',
								'min-h-[calc(100vh-320px)] max-h-[calc(100vh-320px)]',
								'overflow-hidden hide-scroll-bar overflow-y-scroll',
								'md:min-h-[calc(100vh-258px)] md:max-h-[calc(100vh-258px)]',
								'xl:min-h-[calc(100vh-210px)] xl:max-h-[calc(100vh-210px)]'
							)}
						>
							{athletes?.length === 0 && (
								<div
									className={clsx(
										'flex items-center gap-4 text-gray-400 text-3xl font-Roboto',
										'bg-white border border-gray-300 rounded-lg p-4 shadow'
									)}
								>
									<ClipboardIcon className="text-custom w-7 h-7" />
									List Empty
								</div>
							)}
							{athletes?.map((athlete, index) => {
								const pos = `${index}-${athlete.id}`
								return (
									<BoxAthletes
										id={athlete.id}
										key={pos}
										image={athlete.profile_image?.url}
										fullname={`${athlete.first_name} ${athlete.last_name}`}
										assignedPrograms={athlete.assigned_programs}
										refetchUp={refetch}
									/>
								)
							})}
							<div className="md:col-span-2 xl:col-span-3 2xl:col-span-4 flex items-center justify-center mt-2">
								<div ref={ref}>{isFetchingNextPage && <Loading isSmall />}</div>
							</div>
						</div>
					)}
				</div>
				<Drawer
					open={openDrawer}
					setOpen={(val: boolean) => setOpenDrawer(val)}
					title="Assign program"
					stretch
					mobileFull
				>
					<DrawerAssignProgram />
				</Drawer>
			</>
		</Layout>
	)
}

export default Athletes
