import React, { useEffect, useState } from 'react'
import { Images } from 'interfaces'
import clsx from 'clsx'
import { FlagIcon } from '@heroicons/react/solid'
import Cookies from 'universal-cookie'
import { useQuery } from 'react-query'
import {
	getAssignedProgramReport,
	getListAthletesAssignedProgram,
	GET_LIST_ATHLETES_ASSIGNED_PROGRAM
} from 'api'
import { DownloadIcon } from '@heroicons/react/outline'
import { Drawer, DrawerInTraining } from 'components'
import styles from './program.module.scss'

interface Props {
	slug: string
	cover: string | null
	name: string
	athleteId: number
}

export const CompletedAssignedProgramItem: React.FC<Props> = ({
	slug,
	cover = Images.program_cover,
	name,
	athleteId
}): JSX.Element => {
	const cookies = new Cookies()
	const token = cookies.get('token')

	const [nSessions, setNSessions] = useState(0)
	const [downloaded, setDownloaded] = useState(false)
	const [assignedProgramId, setAssignedProgramId] = useState(0)

	const [openDrawer, setOpenDrawer] = useState(false)

	const { data } = useQuery(
		[GET_LIST_ATHLETES_ASSIGNED_PROGRAM, slug, athleteId, token],
		() =>
			getListAthletesAssignedProgram({
				athleteIds: `${athleteId}`,
				programSlugs: slug
			}),
		{
			refetchOnMount: 'always'
		}
	)

	useEffect(() => {
		if (data) {
			setNSessions(data[0].assigned_program.assigned_program_sessions.length)
			setAssignedProgramId(data[0].assigned_program.id)
		}
	}, [data])

	const handleExportReport = async (): Promise<void> => {
		setDownloaded(true)
		await getAssignedProgramReport(token, assignedProgramId, name)
		setDownloaded(false)
	}

	return (
		<>
			<div
				className={clsx(
					'relative w-full min-h-[100px] max-h-[100px] rounded-lg overflow-hidden cursor-pointer',
					'flex flex-col border-[1px] border-custom shadow-[0px_4px_20px_rgba(0,0,0,0.16)] rounded-lg'
				)}
				aria-hidden
				onClick={() => setOpenDrawer(true)}
			>
				<img
					src={cover || Images.program_cover}
					alt="cover"
					className="object-cover w-full h-[100px] rounded-lg border border-custom"
				/>
				<div
					className={clsx(styles.gradient, 'absolute inset-0 min-h-[114px]')}
				/>
				<div className="absolute inset-0 min-h-[100px] p-2 flex flex-col justify-between">
					<div className="flex justify-between gap-3">
						<p className="font-Roboto font-bold text-2xl text-white line-clamp-1">
							{name}
						</p>
						<button
							type="button"
							className="bg-custom flex justify-center items-center rounded-2xl px-3 h-8"
							onClick={() => handleExportReport()}
						>
							{downloaded ? (
								<svg
									width="20"
									height="20"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
									className="animate-spin fill-white"
								>
									<path
										d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
										opacity=".25"
									/>
									<path
										// eslint-disable-next-line max-len
										d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
									/>
								</svg>
							) : (
								<DownloadIcon className={clsx('w-5 h-5 text-white')} />
							)}
						</button>
					</div>
					<div className="flex justify-between h-8">
						<p className="font-Roboto font-bold text-sm text-white">
							Number session {nSessions}
						</p>
						<div
							className={clsx(
								'h-8 flex justify-center items-center text-white font-bold bg-custom px-2 rounded-full cursor-default'
							)}
						>
							<FlagIcon className="w-5 h-5" />
							<span>Completed</span>
						</div>
					</div>
				</div>
			</div>
			<Drawer
				open={openDrawer}
				setOpen={(val: boolean) => setOpenDrawer(val)}
				title="ASSIGNED PROGRAM COMPLETED"
				stretch
				mobileFull
			>
				<DrawerInTraining
					athleteId={athleteId}
					assignedProgramSlug={slug}
					setOpenDrawer={setOpenDrawer}
				/>
			</Drawer>
		</>
	)
}
