import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { BoxAthleteType, Images } from 'interfaces'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

export const BoxAthletes: React.FC<BoxAthleteType> = ({
	id,
	image,
	fullname,
	assignedPrograms,
	onClick
}): JSX.Element => {
	const [nActivePrograms, setnActivePrograms] = useState(0)
	const [nArchivedPrograms, setnArchivedPrograms] = useState(0)

	useEffect(() => {
		setnActivePrograms(
			(assignedPrograms?.length || 0) -
				(assignedPrograms?.filter(item => item.archived).length || 0) -
				(assignedPrograms?.filter(item => item.completed).length || 0) <=
				0
				? 0
				: (assignedPrograms?.length || 0) -
						(assignedPrograms?.filter(item => item.archived).length || 0) -
						(assignedPrograms?.filter(item => item.completed).length || 0)
		)
		setnArchivedPrograms(
			assignedPrograms?.filter(item => item.archived).length || 0
		)
	}, [assignedPrograms])

	return (
		<Link to={`/athlete/${id}`}>
			<button
				type="button"
				onClick={onClick}
				className={clsx(
					'my-2',
					'shadow sm:rounded-lg border-[1px] border-gray-300 border-l-4 border-l-custom w-full cursor-pointer rounded-lg',
					'bg-white hover:bg-gray-200'
				)}
			>
				<div className="flex justify-between p-2">
					<img
						src={image || Images.default_avatar}
						alt=""
						className="w-14 h-14 p-1"
					/>
					<div className="w-full pl-2">
						<h3 className="text-base text-left leading-6text-gray-900 font-Roboto font-bold lg:text-2xl">
							{fullname}
						</h3>
						<p className="text-xs text-gray-500 lg:text-lg text-left">
							{`${nActivePrograms} active programs ${
								nArchivedPrograms > 0
									? `- ${nArchivedPrograms} archived programs`
									: ''
							}`}
						</p>
					</div>
					<div className="flex justify-center items-center">
						<ChevronRightIcon
							className="h-6 w-6 text-custom"
							aria-hidden="true"
						/>
					</div>
				</div>
			</button>
		</Link>
	)
}
