/* eslint-disable no-console */
import React, { useEffect } from 'react'
import {
	CustomerContext,
	useContextCustomer,
	UserContext,
	useContextUser,
	ProgramContext,
	useContextProgram
} from 'context'
import { Toaster } from 'react-hot-toast'
import 'App.css'
import { RoutesElements } from 'routes'
import { QueryClient, QueryClientProvider } from 'react-query'
import Cookies from 'universal-cookie'
import { NotificationProvider } from 'provider'

const App = (): JSX.Element => {
	const customerContextValue = useContextCustomer()
	const userContextValue = useContextUser()
	const twentyFourHoursInMs = 1000 * 60 * 60 * 24
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
				refetchOnMount: false,
				refetchOnReconnect: false,
				retry: false,
				staleTime: twentyFourHoursInMs
			}
		}
	})
	const root = document.documentElement
	const cookies = new Cookies()
	const color = cookies.get('customer_color')

	const programContextValue = useContextProgram()

	useEffect(() => {
		if (color) root.style.setProperty('--color-custom', color)
		else if (process.env.REACT_APP_COLOR)
			root.style.setProperty('--color-custom', process.env.REACT_APP_COLOR)
	}, [])

	return (
		<UserContext.Provider value={userContextValue}>
			<CustomerContext.Provider value={customerContextValue}>
				<ProgramContext.Provider value={programContextValue}>
					<QueryClientProvider client={queryClient}>
						<NotificationProvider>
							<>
								<RoutesElements />
								<Toaster
									position="top-right"
									toastOptions={{
										duration: 1500
									}}
								/>
							</>
						</NotificationProvider>
					</QueryClientProvider>
				</ProgramContext.Provider>
			</CustomerContext.Provider>
		</UserContext.Provider>
	)
}

export default App
