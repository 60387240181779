import React, { useContext, useEffect, useState } from 'react'
import { SimpleLayout, BoxCustomer, Loading, Input, Icon } from 'components'
import { CustomerContext } from 'context'
import { useNavigate } from 'react-router-dom'
import { useUser } from 'hooks'
import Cookies from 'universal-cookie'
import { Icons, RoleType, SearchInputs } from 'interfaces'
import { useForm } from 'react-hook-form'
import { SearchIcon } from '@heroicons/react/solid'

export const Customer = (): JSX.Element => {
	const { user, isLoading, signOut } = useUser()
	const { setCustomer } = useContext(CustomerContext)
	const navigate = useNavigate()
	const cookies = new Cookies()
	const colore = cookies.get('customer_color')
	const root = document.documentElement
	const { register, watch } = useForm<SearchInputs>({ mode: 'onChange' })
	const watchSearch = watch('search', '')
	const [customers, setCustomers] = useState<RoleType[]>([])
	const [initial, setInitial] = useState(true)

	const selectCustomer = (
		id: number,
		color: string,
		role: string,
		roleId: number
	): void => {
		setCustomer({ id, color, role, roleId })
		cookies.set('customer', id, { path: '/' })
		cookies.set('role_type', role, { path: '/' })
		cookies.set('role_id', roleId, { path: '/' })
		if (color !== '' && color) {
			cookies.set('customer_color', color, { path: '/' })
			root.style.setProperty('--color-custom', color)
		}
		navigate('/dashboard')
	}

	useEffect(() => {
		if (colore) root.style.setProperty('--color-custom', colore)
		else if (process.env.REACT_APP_COLOR)
			root.style.setProperty('--color-custom', process.env.REACT_APP_COLOR)
	}, [])

	const searchIcon = <SearchIcon className="text-gray-900 cursor-pointer" />

	useEffect(() => {
		if (user && initial) {
			setInitial(false)
			setCustomers(user.roles)
		}
	}, [user])

	useEffect(() => {
		if (customers && watchSearch && watchSearch !== '' && user) {
			let aux = [] as RoleType[]
			aux = user.roles.filter(itemList =>
				itemList.customer_name.toLowerCase().includes(watchSearch.toLowerCase())
			)
			setCustomers(aux)
		}
		if (customers && watchSearch === '' && user) {
			setCustomers(user.roles)
		}
	}, [watchSearch])

	return (
		<SimpleLayout>
			{isLoading ? (
				<div className="flex items-center justify-center max-h-[70vh]">
					<Loading />
				</div>
			) : (
				<>
					<button
						type="button"
						className="absolute top-4 flex justify-center items-center px-3 hover:bg-custom rounded-full group"
						onClick={() => signOut()}
					>
						<p className="text-custom font-Roboto group-hover:text-white">
							Sign out
						</p>
						<Icon
							src={Icons.logout}
							fillPath
							className="w-4 h-4 text-custom flex justify-center items-center group-hover:text-white"
						/>
					</button>
					<Input
						name="search"
						register={register}
						placeholder="Search customer"
						type="text"
						className="mb-4 lg:col-span-3 lg:pr-2"
						leftIcon={searchIcon}
						rounded
					/>
					<div className="h-full max-h-[calc(100vh-250px)] overflow-auto hide-scroll-bar pb-8">
						{customers &&
							customers.map((item, index) => {
								const pos = index
								return (
									<BoxCustomer
										title={item.customer_name}
										rol={item.role_type}
										key={`${item.role_id}-${pos}`}
										className="my-2"
										onClick={() =>
											selectCustomer(
												item.customer_id,
												item.customer_color,
												item.role_type,
												item.role_id
											)
										}
									/>
								)
							})}
					</div>
				</>
			)}
		</SimpleLayout>
	)
}
