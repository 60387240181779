import React, { useEffect, useState } from 'react'
import { Icons } from 'interfaces'
import clsx from 'clsx'
import { notification } from 'api'
import { useCustomer } from 'hooks'
import { NotificationItem } from 'components'
import { NotificationGroupCardProps } from './notificationGroupCard.interface'

export const NotificationGroupCard: React.FC<
	NotificationGroupCardProps
> = props => {
	const {
		notificationGroup,
		notificationItem,
		categoryStatus,
		withReminders,
		groupId,
		handleSubmit,
		isDisable
	} = props
	const customer = useCustomer()
	const [notifications] = useState(notificationItem)

	// all notifications
	const [notificationSet, setNotificationSet] = useState<
		notification[] | undefined
	>([])

	// reminders
	const [reminderSet, setReminderSet] = useState<notification[] | undefined>([])

	// booleans state
	const [groupStatus, setGroupstatus] = useState(categoryStatus)
	const [emailState, setEmailState] = useState(false)
	const [inAppState, setInAppState] = useState(false)
	const [reminderState, setReminderState] = useState(false)

	useEffect(() => {
		// save all notifications in the state
		const generalNotifications = notifications?.filter(item => {
			return item.category !== 'reminder'
		})
		setNotificationSet(generalNotifications)

		if (notifications) {
			notifications.forEach(item => {
				if (item.category === 'email') {
					setEmailState(item.is_active)
				}
				if (item.category === 'app') {
					setInAppState(item.is_active)
				}
			})
		}

		// save all reminders in the state
		const reminders = notifications?.filter(item => {
			return item.category === 'reminder'
		})
		setReminderSet(reminders)

		if (notifications) {
			notifications.forEach(item => {
				if (item.category === 'reminder') {
					setReminderState(item.is_active)
				}
			})
		}
	}, [])

	const handleStates = (key: string): void => {
		switch (key) {
			case 'email':
				setEmailState(!emailState)
				break
			case 'app':
				setInAppState(!inAppState)
				break
			case 'reminder':
				setReminderState(!reminderState)
				break
			default:
				console.error('the key was encountered')
		}
	}

	return (
		<section
			className={clsx(
				'bg-white xl:w-[892px] xl:max-h-max rounded-[20px]',
				'flex flex-col gap-6 p-6'
			)}
		>
			<p className="text-[#1D2027] text-lg font-medium">
				{notificationGroup === 'Group Session'
					? 'Notification of group sessions'
					: 'Assigned Program Notifications'}
			</p>

			<NotificationItem
				notificationLabel={
					notificationGroup === 'Group Session'
						? 'Allow group session notifications'
						: 'Allow notifications from assigned programs'
				}
				checked={groupStatus ?? false}
				handleToggle={() => {
					setGroupstatus(!groupStatus)
					handleSubmit({ id: groupId, category_status: !groupStatus })
				}}
				isDisable={customer.role === 'Client' && isDisable}
				valueForStaffMember={categoryStatus}
			/>

			<div className="flex flex-col gap-4">
				<span className="text-[#1D2027] text-lg font-medium">
					{customer.role !== 'Client'
						? 'Where do your customers receive these notifications?'
						: 'Where do you receive these notifications?'}
				</span>
				{notificationSet?.map(item => {
					return (
						<NotificationItem
							key={item.id}
							notificationLabel={item.category === 'email' ? 'Email' : 'In App'}
							iconSrc={
								item.category === 'email'
									? Icons.inapp_icon
									: Icons.email_notification_icon
							}
							handleToggle={() => {
								handleSubmit({
									id: item.id,
									is_active:
										item.category === 'email' ? !emailState : !inAppState
								})
								handleStates(item.category)
							}}
							checked={item.category === 'email' ? emailState : inAppState}
							withIcon
							isDisable={customer.role === 'Client' && !item.admin_status}
							valueForStaffMember={item.admin_status}
						/>
					)
				})}
			</div>

			{withReminders && (
				<div className="flex flex-col gap-4">
					<span
						data-testid="Reminders"
						className="text-[#1D2027] text-lg font-medium"
					>
						Reminders
					</span>
					{reminderSet?.map(reminder => (
						<NotificationItem
							key={reminder.id}
							notificationLabel={`${
								customer.role === 'Client'
									? 'Allow reminders to be sent to you about group sessions'
									: 'Allow reminders to be sent to your customer about group sessions'
							}`}
							checked={reminderState}
							handleToggle={() => {
								handleSubmit({
									id: reminder.id,
									is_active: !reminderState
								})
								handleStates(reminder.category)
							}}
							iconSrc={Icons.reminder}
							withIcon
							isDisable={customer.role === 'Client' && !reminder.admin_status}
							valueForStaffMember={reminder.admin_status}
						/>
					))}
				</div>
			)}
		</section>
	)
}
