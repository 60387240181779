/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { CameraIcon, XIcon } from '@heroicons/react/outline'

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
	Tooltip
} from 'chart.js'
import { SetsNavigation } from 'pages'
import { CarouselMetrics } from 'components/carousel'
import { AssignedMetric } from 'interfaces'
import { ScreenshotModal } from 'lib'
import { ModalExerciseProps } from './modalExercise.interface'

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
	Tooltip
)

export const ModalExercise: React.FC<ModalExerciseProps> = ({
	open,
	setOpen,
	statsMetrics,
	arrayOfSets,
	exerciseName
}): JSX.Element => {
	const [refDiv, setRefDiv] = useState<HTMLElement | null>(null)
	const [indexSets, setIndexSets] = useState(0)
	const [arrayOfMetrics, setArrayOfMetrics] = useState<
		{
			id: string
			type: string
			name: string
			base: string
			value: string
		}[]
	>(
		arrayOfSets.length > 0
			? arrayOfSets[0].assigned_metrics?.map((items: AssignedMetric) => ({
					id: items.slug,
					type: items.type_metric.type,
					name: items.type_metric.name,
					base: items.value,
					value: items.assigned_execute_metric.value
			  }))
			: []
	)

	useEffect(() => {
		setRefDiv(document.getElementById('screenshot-exercise'))
	}, [])

	const handleScreenShot = useCallback(
		name => ScreenshotModal(name, refDiv),
		[refDiv]
	)

	const handleMetricForSets = (order: number): void => {
		setArrayOfMetrics(
			arrayOfSets[order].assigned_metrics.map((items: AssignedMetric) => ({
				id: items.slug,
				type: items.type_metric.type,
				name: items.type_metric.name,
				base: items.value,
				value: items.assigned_execute_metric.value
			}))
		)
	}

	return (
		<Transition appear show={open} as={Fragment}>
			<Dialog
				as="div"
				className="z-50 fixed inset-0"
				open={open}
				onClose={() => setOpen(false)}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25" />
				</Transition.Child>

				<div
					aria-hidden="true"
					className="fixed inset-0 overflow-y-auto"
					onClick={() => setOpen(false)}
				/>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95"
				>
					<div
						className={clsx(
							'absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%]',
							'w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left',
							'align-middle transition-all',
							'md:min-w-min',
							arrayOfSets.length === 0 && 'w-[310px]'
						)}
					>
						<header className="w-full h-5 flex justify-between">
							<span className="font-Roboto font-semibold text-[#393C44]">
								{exerciseName}
							</span>
							<button type="button" onClick={() => setOpen(false)}>
								<XIcon className="w-5 h-5 text-custom" />
							</button>
						</header>
						<div
							id="screenshot-exercise"
							className="flex flex-col lg:flex-row gap-4 p-5"
						>
							{statsMetrics.length !== 0 && (
								<div className="flex flex-col w-full h-[222px]">
									<div
										className={clsx(
											'w-full h-full flex flex-col justify-between bg-white',
											'rounded-xl p-3 shadow-md border border-gray-100'
										)}
									>
										<div>
											<div className="flex justify-between items-center">
												<span className="text-xs text-[#454952] font-bold w-1/3">
													Metric
												</span>
												<span className="text-xs text-[#454952] font-bold w-1/3">
													Base
												</span>
												<span className="text-xs text-[#454952] font-bold w-1/3">
													Execute
												</span>
											</div>

											{arrayOfSets[indexSets].assigned_metrics?.map(
												(item: any) => (
													<div
														key={item.id}
														className="flex justify-between items-center"
													>
														<span className="text-xs text-[#454952] w-1/3">
															{item.type_metric.name ?? '-'}
														</span>
														<span className="text-xs text-[#454952] w-1/3">
															{item.value ?? '0'}
														</span>
														<span className="text-xs text-[#454952] w-1/3">
															{item.assigned_execute_metric.value || '-'}
														</span>
													</div>
												)
											)}
										</div>
										<SetsNavigation
											statsIndex={indexSets}
											setStatsIndex={setIndexSets}
											arrayOfSets={arrayOfSets}
											handleMetricForSets={handleMetricForSets}
										/>
									</div>
								</div>
							)}
							<div
								className={clsx(
									'w-full flex flex-col justify-center items-center gap-4 bg-[transparent]',
									'overflow-hidden overflow-x-scroll hide-scroll-bar',
									arrayOfMetrics.length === 0 ? 'h-auto' : 'h-[222px]'
								)}
							>
								{arrayOfMetrics.length === 0 ? (
									<p className="font-Roboto text-base">
										Select a exercise to view charts
									</p>
								) : (
									<div
										className={clsx(
											'w-full h-full shadow-xl rounded-xl border border-gray-100',
											'flex justify-center items-center flex-wrap'
										)}
									>
										<CarouselMetrics
											indexSets={indexSets}
											metrics={arrayOfMetrics}
										/>
									</div>
								)}
							</div>
						</div>
						<div
							className={clsx(
								'w-full flex gap-3',
								statsMetrics.length === 0 ? 'justify-center' : 'justify-end'
							)}
						>
							{statsMetrics.length === 0 ? (
								<button
									type="button"
									className={clsx(
										'w-40 h-7 rounded-full flex justify-center items-center gap-3',
										'border border-custom p-2 bg-custom text-white',
										'hover:opacity-80'
									)}
									onClick={() => setOpen(false)}
								>
									<span className="font-Roboto font-medium">Close</span>
								</button>
							) : (
								<button
									type="button"
									className={clsx(
										'w-40 h-7 rounded-full flex justify-center items-center gap-3',
										'border border-custom p-2 bg-custom text-white',
										'hover:opacity-80'
									)}
									onClick={() => handleScreenShot(exerciseName)}
								>
									<CameraIcon className="w-5 h-5" />
									<span className="font-Roboto font-medium">Screenshot</span>
								</button>
							)}
						</div>
					</div>
				</Transition.Child>
			</Dialog>
		</Transition>
	)
}
