import clsx from 'clsx'
import { Input, InputList, PickerTime } from 'components'
import { ListElemetProps, OptionsProps } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

export const OptionMetric2: React.FC<OptionsProps> = ({
	item,
	type,
	value,
	status,
	changeValue,
	setChangeAssignedMetric,
	setStateValue
}): JSX.Element => {
	const [selectVal, setSelectVal] = useState<ListElemetProps>({
		id: '',
		name: '',
		value
	})
	const [timeVal, setTimeVal] = useState(value)

	const { register, watch } = useForm({
		mode: 'onChange',
		defaultValues: {
			value
		}
	})
	const watchName = watch('value')

	let option = [] as ListElemetProps[]
	if (item.type_metric.options) {
		option = item.type_metric.options.map((optionItem, index) => {
			return {
				id: `${index}`,
				name: optionItem,
				value: optionItem
			}
		})
	}

	useEffect(() => {
		if (type === 'select')
			if (selectVal.id !== '') {
				changeValue(item.slug, selectVal.value)
				if (setStateValue)
					setStateValue({
						slug: item.assigned_execute_metric.slug,
						value: selectVal.value
					})
			}

		if (setChangeAssignedMetric) setChangeAssignedMetric(selectVal.value)
	}, [selectVal])

	useEffect(() => {
		if (type === 'time')
			if (timeVal !== '') {
				changeValue(item.slug, timeVal)
				if (setStateValue)
					setStateValue({
						slug: item.assigned_execute_metric.slug,
						value: timeVal
					})
			}
		if (setChangeAssignedMetric) setChangeAssignedMetric(timeVal)
	}, [timeVal])

	useEffect(() => {
		if (type === 'number') {
			changeValue(item.slug, watchName)
			if (setStateValue)
				setStateValue({
					slug: item.assigned_execute_metric.slug,
					value: watchName
				})
		}
		if (setChangeAssignedMetric) setChangeAssignedMetric(watchName)
	}, [watchName])

	return (
		<div
			key={item.slug}
			className="w-full font-Roboto font-bold text-lg text-gray-700 flex justify-center items-center"
		>
			{type === 'select' ? (
				<InputList
					list={option}
					name="option"
					defaultValue={
						item.assigned_execute_metric.value
							? option.filter(itemList => itemList.value === value)[0]
							: { id: '0', name: '--', value: '--' }
					}
					squared
					align="center"
					setItem={setSelectVal}
					cursorDisable={status !== 1}
					disableList={status !== 1}
					noBorders
				/>
			) : type === 'time' ? (
				<PickerTime
					disablePicker={status !== 1}
					defaultValue={value}
					asterisk
					setItem={setTimeVal}
				/>
			) : (
				<Input
					autoComplete="off"
					transparent
					squared
					name="value"
					type="number"
					align="center"
					register={register}
					className={clsx(
						'font-Roboto font-bold text-lg text-gray-700',
						status !== 1 ? 'cursor-not-allowed' : 'cursor-pointer'
					)}
					min={0}
					placeholder={item.assigned_execute_metric.value ? watchName : '--'}
					disabled={status !== 1}
					cursorDisable={status !== 1}
					value={
						item.assigned_execute_metric.value !== '' || watchName !== ''
							? watchName
							: ''
					}
				/>
			)}
		</div>
	)
}
