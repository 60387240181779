export const deviceDetect = (): 'android' | 'ios' | 'web' => {
	let res = 'web' as 'android' | 'ios' | 'web'
	if (
		navigator.userAgent.match(/Android/i) ||
		navigator.userAgent.match(/webOS/i) ||
		navigator.userAgent.match(/BlackBerry/i) ||
		navigator.userAgent.match(/Windows Phone/i)
	) {
		res = 'android'
	} else if (
		navigator.userAgent.match(/iPhone/i) ||
		navigator.userAgent.match(/iPad/i) ||
		navigator.userAgent.match(/iPod/i)
	) {
		res = 'ios'
	}

	return res
}
